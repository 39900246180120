
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import { Container, Card, CardBody} from "reactstrap"



const Bienestar = (props) => {

  const [loading, setLoading] = useState(false)

  //const convocatorias = useSelector(state => state.convocatoria.data);
  //const solicitudes = useSelector(state => state.solicitud.data);
  //const modal = useRef();
  const dispatch = useDispatch();

  useEffect(()=>{
    //dispatch(getConvocatorias())
    //dispatch(getSolicituds())
  },[dispatch])

  /*const createSolicitud = (values, validData) => {
    console.log(values, validData)
    
    if (validData){
      setLoading(true)
      dispatch(postSolicitud(values, 
        ()=>{
          setLoading(false)
          dispatch(getSolicituds())
          modal.current.toggle()
          props.history.push("/solicitud")
        }))
    }
  }*/
  
  return (
    <React.Fragment>
      {loading?"":""}
      <div className="page-content mt-6">
        <Container fluid>
          <h2><b>Bienestar</b></h2>
          <Card className={"box-shadow"}>
            
            <CardBody style={{padding:0}}>

              <div style={{display:"flex", alignItems:"center"}}>



                <div style={{flex:1, fontSize:17, padding:40, }}>
                <div className={"d-lg-none mb-3"}>
                  <img src={"http://lideresdelmanana.itesm.mx/sites/all/themes/lideres/images/LDM-Bienestar.jpg"} style={{width:"100%", borderRadius:5}}/>
                </div>
                  <b>¡Bienvenid@!</b> <br/> <br/>
                  
                  En este espacio encontrarás recursos para tu bienestar emocional y salud mental que te ayudarán en el fortalecimiento del tu capital psicológico y desarrollo humano como cursos, conferencias, talleres y programas para acompañarte cuando lo necesites.
                
                  <div style={{color:"grey"}}><br/> <br/><b>Por ahora no hay ningún recurso</b> </div>
                </div>

                <div className={"d-none d-lg-block"} style={{flex:1.5}}>
                  <img src={"http://lideresdelmanana.itesm.mx/sites/all/themes/lideres/images/LDM-Bienestar.jpg"} style={{width:"100%", borderBottomRightRadius:5, borderTopRightRadius:5 }}/>
                </div>


              </div>
            </CardBody>
          </Card>
            
        </Container>
      </div>
    </React.Fragment>
  )
}


Bienestar.propTypes = {
 
}


export default (withRouter(Bienestar))



