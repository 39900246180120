import React, { createRef } from "react"
import { FormGroup, Label } from "reactstrap"
import PropTypes from 'prop-types';
import { ReactLiquid } from "react-liquid"

import Checkbox from "../Fields/Checkbox";

import BaseField from "./BaseField"



class CheckboxGroup extends BaseField{

    constructor(props) {
        super(props);
        this.state = {
            value:[],
            otherValue:""
        }

        this.checkboxRefs  = {};
        this.props.options.map((option)=>
            this.checkboxRefs[option.value] = createRef()
        )

    }

    getValue = () =>{
        let v = JSON.parse(JSON.stringify(this.state.value))
        if ( v &&  v.includes("Otro")){
            v.map((value, index)=>{
                if (value == "Otro"){
                    v[index] = "Otro: " + this.state.otherValue
                }
            })
        }

        return this.props.required  && this.state.value.length == 0 ? null :  v
    }

    setValue = (values) => {
        
       if (values){
            this.props.options.map((v)=>{
                this.checkboxRefs[v.value].current.setValue(values.includes(v.value))
                return this.checkboxRefs[v.value]
            })
            this.setState({value:values})
        }else{
            this.props.options.map((v)=>{
                this.checkboxRefs[v.value].current.setValue(false)
                return this.checkboxRefs[v.value]
            })
            this.setState({value:[]})
        }

    }

    setOtherValue = (value) =>{
        //console.log(value)
        this.setState({otherValue:value})
    }

    checkValue = (checked, value) =>{
        //console.log(checked, value)
        if (checked && !this.state.value.includes(value)){
            this.state.value.push(value)
            this.setState({value:this.state.value})

            if (this.props.onChange){
                this.props.onChange(this.state.value)
            }

        }else{
            const index = this.state.value.indexOf(value);
            if (index > -1) {
                this.state.value.splice(index, 1);
            }
            this.setState({value:this.state.value})

            if (this.props.onChange){
                this.props.onChange(this.state.value)
            }
        }

    }

   


    render(){
        const {name, label, options, disabled, other, otherLabel } = this.props;

        return (
            <FormGroup>
                <Label>{label?<ReactLiquid template={label + (this.props.required ? '<span class="invalid-feedback" style="display:inline;font-size:14px"> *</span>':"")} data={{}} html={true} />:null}</Label>
                

                {options.map((option, idx)=>{
                    
                    
                    
                    return (
                 
                        <Checkbox 
                            className={"pl-2"}
                        
                            ref={this.checkboxRefs[option.value]}
                            key={idx}
                            disabled={disabled}
                            checked={this.state.value.includes(option.value)}
                            onChange={(checked)=>{
                                //console.log(disabled)
                                if (disabled){
                                  
                                }else{
                                    this.checkValue(checked, option.value)}

                                }
                            }
                            name={name + (idx ? "-"+idx:"")}  
    
                            label={option.label} 
                            value={option.value}
                            />
                    )
                })}
              {other ? 
                 <div className="form-check">
                 <input
                     type="checkbox"
                     id={label+"-other" +  "-"+999}  
                     className="form-check-input"
  
                     checked={this.state.value.includes("Otro")}
                     onChange={(e)=>this.checkValue(e.target.checked, "Otro")}
                 />
                  <label
                    className="form-check-label"
                    style={{fontWeight:"normal"}}
                    htmlFor={label+"-other" +  "-"+999}
                    >
                  {otherLabel? otherLabel : "Otro:"}{" "}<input value={this.state.otherValue} className="form-check-label" style={{fontWeight:"normal", borderWidth:0, borderBottomWidth:1}} onChange={(e)=>this.setOtherValue(e.target.value)} htmlFor={this.id}/> 
              </label>
              </div>
       
       :null  
            }
                {this.renderErrorMessage()}
            </FormGroup>
        )
    }
}



CheckboxGroup.propTypes =  Object.assign({}, BaseField.propTypes,   {
    options: PropTypes.array.isRequired,
    disabled:false
});


export default CheckboxGroup