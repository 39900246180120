
import React, { useEffect, useRef, useState } from "react"


import {  Link, withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { API } from "../../utils/backend/api_helper"
import swal from "sweetalert"
import { LDMDB, Loader } from "../../utils/Configs"
import Header from "./Header"


const ACTIVIDADES = [
   { key:"WA", title:"REGISTRA TU WHATSAPP", link:"/candidatos/whatsapp"},
]

const ACTIVIDADES2 = [
    //{ key:"EC", title:"EXPEDIENTE CANDIDATO", link:"/candidatos/expediente"},
    { key:"AI", title:"EVALUACIÓN ADVANCED INSIGHTS", link:"/candidatos/advance-insights"},
    { key:"MMPI", title:"BIENESTAR INTEGRAL (MMPI)", link:"/candidatos/mmpi"},
    { key:"ACCUTEST", title:"ACCUTEST", link:"/candidatos/accutest"},
    { key:"INGLES", title:"OXFORD ENGLISH-LEVEL-TEST", link:"/candidatos/ingles"},
    { key:"HMS", title:"HEALTHY MINDS STUDY (HMS)", link:"/candidatos/hms"},
    { key:"TMMS", title:"INTELIGENCIA EMOCIONAL Y LIDERAZGO (TMMS)", link:"/candidatos/tmms"},

 ]

 const ACTIVIDADES3 = [
    { key:"VIDEO", title:"VIDEO DE HISTORIA DE VIDA, MOTIVADORES PERSONALES Y ACCIONES REALIZADAS", link:"/candidatos/video"}, 

     { key:"ENTREVISTA_STATUS", title:"ENTREVISTA CON EL CAMPUS", link:"/candidatos/entrevista"},
     { key:"SES", title:"CUESTIONARIO SOBRE EL CONTEXTO FAMILIAR", link:"/candidatos/take-ses"},
     { key:"LOGROS", title:"LOGROS Y EXPECTATIVAS A FUTURO", link:"/candidatos/take-logros"}


  ]

const HomeCandidato = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);
    const [candidato, setCandidato] = useState({})
    const [actividades2, setActividades2] = useState([])

    

  
    console.log(currentUser)

    useEffect(()=>{
        setLoading(true)
        API.get(
            "/candidato",
            (resp)=>{
    
            
                let ses = "INCOMPLETA"
                LDMDB
                .database()
                .ref("answers/"+"vulnerabilidad-23"+ "/" + "candidato-"+currentUser.id +"/"+"vulnerabilidad"+"/").once('value').then(v=>{
                    console.log(v.val())
        
                    if(v.val()){
                        ses = "COMPLETA"
    
                  
                    } 
                    LDMDB
                        .database()
                        .ref("answers/"+"logros-24"+ "/" + "candidato-"+currentUser.id +"/"+"logros"+"/").once('value').then(v=>{
                            console.log(v.val())
                
                            if(v.val()){
                    
                                setCandidato({...candidato, ...resp.data, SES: ses, LOGROS: "COMPLETA"})
                                setLoading(false)
                            } else{
                                setCandidato({...candidato, ...resp.data, SES: ses})
                                setLoading(false)
                            }
                        
                        
                        })
         
                  
                
                })
         

            },
            (resp)=>{
                swal({
                    title:"¡Error!",
                    text:resp,
                    icon:"error"
                })
                setLoading(false)

            }
        )
        let acts = ACTIVIDADES2

        if (currentUser.candidato_admin){
           

        }

       
        setActividades2(acts)
       
    },[])

  return (
    <React.Fragment>
            {loading ? <Loader message={"Cargando datos..."} />:null}

      <React.Fragment>
        <Header/>
        <div className="container mt-4">
            <div className="card shadow-sm">
            <div className="card-body fs-5">
            Apreciable <b>{currentUser.full_name}</b>:<br/><br/>

            A nombre del Tecnológico de Monterrey, queremos agradecer tu interés en el programa e informarte a continuación las tareas que debes realizar para avanzar con tu solicitud al programa Líderes del Mañana. 
<br/><br/>Revisa las instrucciones de las actividades que se solicitan y realízalas con la calidad que te ha distinguido hasta ahora.
            </div>
            </div>


            <div className="">
                <div className="">

                <ul className="card shadow-sm mt-3 list-group">
  <li className="list-group-item"><b>SISTEMA DE COMUNICACIÓN</b></li>

    {ACTIVIDADES.map((act, index)=>
    
    <li key={index} className="list-group-item d-flex align-items-center">
        <div className="flex-grow-1  lead"><div className="ms-3  text-wrap">{index +1}. {act.title}<span className="d-md-none"><br/><Link to={act.link}>Ir a registro <i className="fas fa-arrow-right"/></Link></span></div></div>
        <div className=" d-none d-md-block m-2 px-3"><Link to={act.link}>Ir al registro <i className="fas fa-arrow-right"/></Link></div>
        
        
        
        {candidato[act.key]  == "COMPLETA" ? <div  className="bg-success  align-self-end text-center text-white p-4 p-md-3  h-100" > <i className="fas fa-check-circle fs-4"/> <span style={{width:100}} className="d-none d-md-block">COMPLETA</span></div>:
        <div className="bg-warning align-self-end text-white text-center p-4 p-md-3   h-100" > <i className="fas fa-exclamation-circle fs-4"/> <span style={{width:100}}  className="d-none d-md-block">INCOMPLETA</span></div>}
    </li>)}

    <li className="list-group-item"></li>

</ul>


 <ul className="card shadow-sm mt-3 list-group">
  <li className="list-group-item"><b>PRUEBAS ADICIONALES</b></li>

    {actividades2.map((act, index)=>
    
    <li key={index} className="list-group-item  d-flex align-items-center">
        <div className="flex-grow-1  lead"><div className="ms-3  text-wrap">{index +1}. {act.title}<span className="d-md-none"><br/><Link to={act.link}>Ir a la prueba <i className="fas fa-arrow-right"/></Link></span></div></div>
        <div className=" d-none d-md-block m-2 px-3"><Link to={act.link}>Ir a la prueba <i className="fas fa-arrow-right"/></Link></div>
        
        
        
        {candidato[act.key]  == "COMPLETA" ? <div  className="bg-success  align-self-end text-center text-white p-4 p-md-3  h-100" > <i className="fas fa-check-circle fs-4"/> <span style={{width:100}} className="d-none d-md-block">COMPLETA</span></div>:
        <div className="bg-warning align-self-end text-white text-center p-4 p-md-3   h-100" > <i className="fas fa-exclamation-circle fs-4"/> <span style={{width:100}}  className="d-none d-md-block">INCOMPLETA</span></div>}
    </li>)}

    <li className="list-group-item"></li>

</ul>

<ul className="card shadow-sm mt-3 list-group">
  <li className="list-group-item"><b>ACTIVIDADES ADICIONALES</b></li>

    {ACTIVIDADES3.map((act, index)=>
    
    <li key={index} className="list-group-item d-flex align-items-center">
        <div className="flex-grow-1  lead"><div className="ms-3  text-wrap">{index +1}. {act.title}<span className="d-md-none"><br/><Link to={act.link}>Ir a la actividad <i className="fas fa-arrow-right"/></Link></span></div></div>
        <div className=" d-none d-md-block m-2 px-3"><Link to={act.link}>Ir a la actividad <i className="fas fa-arrow-right"/></Link></div>
        
        
        
        {candidato[act.key]  == "COMPLETA" ? <div  className="bg-success  align-self-end text-center text-white p-4 p-md-3  h-100" > <i className="fas fa-check-circle fs-4"/> <span style={{width:100}} className="d-none d-md-block">COMPLETA</span></div>:
        <div className="bg-warning align-self-end text-white text-center p-4 p-md-3   h-100" > <i className="fas fa-exclamation-circle fs-4"/> <span style={{width:100}}  className="d-none d-md-block">INCOMPLETA</span></div>}
    </li>)}

    <li className="list-group-item"></li>

</ul>

                </div>
            </div>
            <div style={{height:50}}></div>

        </div>
        </React.Fragment>
    </React.Fragment>
  )
}


HomeCandidato.propTypes = {
 
}


export default (withRouter(HomeCandidato))



