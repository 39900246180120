import React from "react"
import { FormGroup, Label } from "reactstrap"

import PropTypes from 'prop-types';

import BaseField from "./BaseField"
import {default as SelectReact} from "react-select"

class Select extends BaseField {

  constructor(props) {
      super(props);

      let optionsX = JSON.parse(JSON.stringify(props.options))
 
      if (props.other){
        
        optionsX.push({label:"Otro", value:"Otro"})
    
      }
      optionsX =  optionsX.map((op)=>{
        return {...op, key:op.value}
      })


      this.state = {
        value:   null,
        otherValue: null, 
        options: JSON.parse(JSON.stringify(optionsX))
      }

      
  
     
    }
    setOtherValue = (value) =>{
      //console.log(value)
      this.setState({otherValue:value})
  }

    componentWillMount(){
      if (this.props.default){
 
        this.setValue(this.props.default)
      }

      
      // let optionsX = JSON.parse(JSON.stringify(this.props.options))
 
      // if (this.props.other){
        
      //   optionsX.push({label:"Otro", value:"Otro"})
    
      // }
      // this.state.options = optionsX
    }


 

    getValue = () =>{
        //console.log(this.state.value )

        if (this.props.multiple){
          let values  = [];
          if (this.state.value){
            this.state.value.map((op)=> {
              values.push(op.value)
              return null
            })
          }

          return values
        }else{
          console.log(this.props.name, this.props.other)
          console.log(this.props.name, this.state.value)
    
          if (this.props.other && this.state.value && this.state.value.value == "Otro"){
            console.log(this.props.name, 1)
            return this.state.otherValue ?  this.state.otherValue : null
          }
          console.log(2)
          return this.state.value ?  this.state.value.value : null
        }

    }

    setValue = (value) => {

      if(this.props.multiple){

        if (value){
          let values  = []
          this.props.options.map((op)=> {
            if (value.includes(op.value)){
              values.push(op)
            }
            return null
          })
          //console.log(values)
          this.setState({value:values})
        }else{
          this.setState({value:[]})
        }
      }else{
        this.setState({value:null})
        this.state.options.map((op)=> {

          if (value == op.value){
           
            this.setState({value:op})
           

          
          }
          this.props.onChange &&  this.props.onChange(value)

          return null
        })
      }

    }


render(){
    const {  label, options, disabled, containerClassName, other } = this.props;

    
    

    return (
        <FormGroup className={`${containerClassName}`}>
            <Label>{label}{this.props.required ? <span className={"invalid-feedback"} style={{display:"inline", fontSize:14}}> *</span>:null}</Label>
            <div>
            
                <SelectReact
            
                  styles={{menu:(provided, state)=>{return {...provided, zIndex:1000} }}}
                  ref={(r)=>this.sel=r}
                  isDisabled={this.props.disabled}
                  value={this.state.value}
                  isMulti={this.props.multiple}
                  style={disabled? {backgroundColor:"#f5f5f5"}:{}}
                  onChange={(selection)=>{
                    console.log(selection)
                    if (disabled){
                      return
                    }

                    if(!this.props.multiple){
       
                      this.setValue(selection.value)
                    }else{
                      //console.log(selection)
                      let values  = []
                      if (selection){
                        selection.map((op)=> {
                            values.push(op.value)
                            return null
                        })
                      }
                      this.setValue(values)

                    }
                  }}
                  options={this.state.options.map((op)=>{
                    return {...op, key:op.value}
                  })}
                  classNamePrefix="select2-selection"
                />
            </div>
              
            {other && this.state.value && (this.state.value.value == "Otro" || this.state.value == "Otro")  ? 
                 <div className="mt-1">
                  
                  {"Otro (especifica): "}<input value={this.state.otherValue} className="form-control "  onChange={(e)=>this.setOtherValue(e.target.value)} htmlFor={this.id}/> 
            
              </div>
       
       :null  
            }
            {this.renderErrorMessage()}
      </FormGroup>
    )}
}

Select.propTypes = {
    options: PropTypes.array.isRequired,  
    multiple: PropTypes.bool,
    disabled:false
}


export default Select