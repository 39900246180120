import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { withRouter } from "react-router-dom"
import { Card, CardBody} from "reactstrap"

import { searchContact, updateListView } from "../../../store/actions"
import { Loader } from "../../../utils/Configs"



const Dashboard = (props) => {

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")

  const listInfo = useSelector(state => state.contact.listData);

  const dispatch = useDispatch();

  useEffect(()=>{

    if (!listInfo){
        setLoading(true)
        dispatch(searchContact("",1,10,(data)=>{
            //console.log(data)
            setLoading(false)
            dispatch(updateListView(data))
          },()=>{},[]))
    }
  },[])





  return (
    <React.Fragment>
         
      {loading?<Loader message={message}/> :""}

      <div className="d-flex align-items-center">
          <h3 className="flex-fill"><i className="fas fa-chart-pie"/> Dashboard</h3>
      </div>


        <div className={"mt-2"}/>
        <Card className={"box-shadow mt-0 pt-0"}>
                
                <CardBody className={"mt-2 pt-0"}>

                <iframe width="100%" height="900" src="https://lookerstudio.google.com/embed/reporting/37ee625f-0387-48bc-bf53-f549143e086c" frameBorder="0" style={{border:0}} allowFullScreen={false}></iframe>

      </CardBody></Card>
    </React.Fragment>
  )
}


Dashboard.propTypes = {
 
}


export default (withRouter(Dashboard))



