 
export const GET_ALL_CONTACT = "GET_ALL_CONTACT"
export const GET_CONTACT = "GET_CONTACT"
export const POST_CONTACT = "POST_CONTACT"
export const PUT_CONTACT = "PUT_CONTACT"
export const DELETE_CONTACT = "DELETE_CONTACT"
export const GET_ALL_CONTACT_SUCCESS = "GET_ALL_CONTACT_SUCCESS"
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS"
export const PUT_CONTACT_LOADING = "PUT_CONTACT_LOADING"
export const SEARCH_CONTACT = "SEARCH_CONTACT"
export const GET_CONTACT_PROFILE = "GET_CONTACT_PROFILE"
export const SEARCH_SUCCESS = "SEARCH_SUCCESS"

export const UPDATE_LIST_VIEW = "UPDATE_LIST_VIEW"