import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { withRouter } from "react-router-dom"

import { getAllForms, getContact, setFormsNames } from "../../../store/actions"
import { LDMDB, Loader } from "../../../utils/Configs"
import StackGrid from "react-stack-grid";

import FormCard from "../../../components/FormCard"
import { API } from "../../../utils/backend/api_helper"

const FormsView = (props) => {

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [data, setData] = useState([])
  const [dataMy, setDataMy] = useState([])
  const [listInfo, setListInfo] = useState([])
  const [listInfoMy, setListInfoMy] = useState([])
  const formNames = useSelector(state => state.form.formNames);

  const contacts = useSelector(state => state.contact.dictionary);


  const dispatch = useDispatch();

  const getContactDict = (id) =>{
    
    if (!contacts[id] ){
      dispatch(getContact(id,(data)=>{
        ////console.log(data)
      }))
      return false
    }

    return contacts[id]

  }


  useEffect(()=>{

 
    setLoading(true)
    API.get("/forms/my/1/100",(resposne)=>{
      setListInfoMy(resposne.data)
      API.get("/forms/1/100",(resposne)=>{
        setListInfo(resposne.data)
        setLoading(false)
      },()=>{})

  },()=>{})


  },[])

  const onChangePage = (page, nextPage) => {
      //console.log(page,nextPage)
    setLoading(true)
    dispatch(getAllForms("",nextPage,10,(data)=>{
        //console.log(data)
        setLoading(false)
    },()=>{}))
  }

  useEffect(()=>{
    if (listInfo && listInfo.data ){


      listInfo.data.map((i)=>{
        getContactDict(i.user_id)
        LDMDB.database().ref("forms/"+i.form_id+"/title/").once('value').then((snapshot)=>{
          if (snapshot.exists()) {
            //console.log(snapshot.val())
            const nf = formNames
            nf[i.form_id] = snapshot.val()
            dispatch(setFormsNames(nf))

          } else {
            //console.log(snapshot)
          }
        });
  
      })

      setData(listInfo.data)
    }

  },[listInfo])


  useEffect(()=>{
    if (listInfoMy && listInfoMy.data ){


      listInfoMy.data.map((i)=>{
        getContactDict(i.user_id)
        LDMDB.database().ref("forms/"+i.form_id+"/title/").once('value').then((snapshot)=>{
          if (snapshot.exists()) {
            //console.log(snapshot.val())
            const nf = formNames
            nf[i.form_id] = snapshot.val()
            dispatch(setFormsNames(nf))

          } else {
            //console.log(snapshot)
          }
        });
  
      })

      setDataMy(listInfoMy.data)
    }

  },[listInfoMy])


  //console.log(listInfo)
  return (
    <React.Fragment>
      {loading?<Loader message={message}/> :""}
      
      <h3>Mis Formularios</h3>
      <hr/>
      
      <StackGrid  gutterWidth={10} gutterHeight={10} columnWidth={"25%"}>
          {dataMy.map((item, key) =>
                <FormCard key={key} item={item}/>)}
             </StackGrid>
      <h3>Otros Formularios</h3>
      <hr/>

      <StackGrid  gutterWidth={10} gutterHeight={10} columnWidth={"25%"}>
          {data.map((item, key) =>
                <FormCard key={key} item={item}/>)}
             </StackGrid>
    </React.Fragment>
  )
}


FormsView.propTypes = {
 
}


export default (withRouter(FormsView))



