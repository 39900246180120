
import React, {  useRef, useState } from "react"


import {  Link, withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"


import { Loader } from "../../utils/Configs"


const MMPILDM = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);

    //const [candidato, setCandidato] = useState({})


 

  return (
    <React.Fragment >

      {loading ? <Loader message={"Enviando..."} />:null}
      
      <div className="vh-100 mt-5" >
     

        <div className="container mt-4  h-100 " style={{paddingTop:100}}>
            <div className="card shadow-sm">
            <div className="card-body p-0">

              <div className="rounded-top ">
              <h3 className="fw-bold bg-primary  rounded-top text-white p-4">PRUEBA DE BIENESTAR INTEGRAL (MMPI)</h3>
                <p className=" fs-5 fs-md-4 p-4">
                Contar con buena salud es importante para tu desarrollo. No sólo lo cognitivo y los conocimientos son valiosos, también lo es nuestro bienestar mental, por ello, te invitamos a que contestes la prueba MMPI, misma que proporciona información sobre áreas a fortalecer para que vivas con más plenitud tu vida personal, estudiantil y social.             <br/>
                <br/><b>Completa en una sesión tu prueba haciendo clic en el botón  <i>Iniciar prueba</i>.</b>
                
               

                <div className="text-end">Tiempo estimado de duración: 90-120 minutos.<br/>
              
                </div>
                </p>
                <hr/>
              </div>
          

              <div className={"p-4"}>
               <div className="d-flex justify-content-center">


  <Link to={"/ldm/take-mmpi"} className={"btn btn-primary"}>Iniciar prueba <i className="fas fa-arrow-right"/></Link>
  </div>

 

                  </div>
                </div>
            </div>
        </div>
        <div style={{height:50}} />
        </div>
    </React.Fragment>
  )
}


MMPILDM.propTypes = {
 
}


export default (withRouter(MMPILDM))



