import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Link, withRouter } from "react-router-dom"
import { Card, CardBody} from "reactstrap"
import { Button } from "../../../components"

import List from "../../../components/Table/List"
import { searchContact, updateListView } from "../../../store/actions"
import { Loader } from "../../../utils/Configs"
import { avatar, CAMPUS, getValue } from "../../../utils/Tools"
import exportFromJSON from 'export-from-json'

const HEADERS = [ 
    {key:"url_photo", label:"" , formatter:(value, row)=>{
        return (
                <div  style={{textAlign:"center"}}>{avatar(row, 40)}</div>
            )
    }},
    {key:"matricula", label:"Matrícula"},
    {key:"full_name", label:"Nombre", formatter:(value, row)=>{
        return (
                <Link  to={"/perfil/"+row.id+"/"+row.matricula}>{value}</Link>
            )
    }},
    {key:"email", label:"Correo"},
    {key:"phone", label:"Teléfono"},
    {key:"student.generation",xlsFormatter:(value, row)=>`${value}a (${2013+value})`, label:"Generación", formatter:(value, row)=>{
        return (
                <div  style={{textAlign:"center"}}>{value}a ({2013+value})</div>
            )
    }},
    {key:"student.campus",xlsFormatter:(value, row)=>value ? ((value - 1) < CAMPUS.length ? CAMPUS[value - 1].campus : value) : "", label:"Campus", formatter:(value, row)=>{
        return (
                <div  style={{textAlign:"center"}}>{CAMPUS[value - 1].campus}</div>
            )
    }}


]

const StudentView = (props) => {

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")

  const listInfo = useSelector(state => state.contact.listData);

  const dispatch = useDispatch();

  useEffect(()=>{

    if (!listInfo){
        setLoading(true)
        dispatch(searchContact("",1,10,(data)=>{
            //console.log(data)
            setLoading(false)
            dispatch(updateListView(data))
          },()=>{},[]))
    }
  },[])

  const onChangePage = (page, nextPage) => {
      //console.log(page,nextPage)
    setLoading(true)
    dispatch(searchContact("",nextPage,10,(data)=>{
        //console.log(data)
        setLoading(false)
        dispatch(updateListView(data))
    },()=>{},[]))
  }


  const donwloadData = () => {
    const fileName = 'Líderes del Mañana - Alumnos'
    const exportType =  exportFromJSON.types.xls


    setLoading(true)
    setMessage("Descargando datos...")
    dispatch(searchContact("",1,listInfo.total,(response)=>{
        setMessage("Preparando el archivo...")

        const data = []
        response.data.map((item)=>{
            let row = {}
            HEADERS.map((header)=>{
                let value = ""
                if (header.xlsFormatter ){
                    value = header.xlsFormatter(getValue(item,header.key), item)
                }else{
                    value =getValue(item,header.key)
                }
               
                row[header.label] = value
            })
    
            data.push(row)
    
        })
        exportFromJSON({ data: data, fileName, exportType  })
        setLoading(false)
    },()=>{},[]))
    
    
    

  }

  return (
    <React.Fragment>
         
      {loading?<Loader message={message}/> :""}

      

      <div className="d-flex align-items-center">
          <h3 className="flex-fill"><i className="fas fa-users"/> Líderes del Mañana</h3>
         {listInfo ? <Button onClick={donwloadData} icon={"fas fa-download"} color={"primary"}>Descargar</Button>:null}
      </div>

        <div className={"mt-2"}/>
        <Card className={"box-shadow mt-0 pt-0"}>
                
                <CardBody className={"mt-2 pt-0"}>

      <List
        onChangePage={onChangePage}
        hasPagination={true}
        pagination={listInfo}
        data={listInfo ? listInfo.data : []}
        headers={HEADERS}
      />
      </CardBody></Card>
    </React.Fragment>
  )
}


StudentView.propTypes = {
 
}


export default (withRouter(StudentView))



