import classNames from "classnames"
import { map } from "lodash"
import React, { useEffect, useState } from "react"

import {   Link, Redirect, Route, Switch, useLocation, withRouter } from "react-router-dom"

import Dashboard from "./Views/Dashboard"

import ExpedientesView from "./Views/ExpedientesView"
import FormsView from "./Views/FormsView"
import StudentView from "./Views/StudentView"
import * as bootstrap from "bootstrap"
import ReportsView from "./Views/Reports"
import Report from "./Views/Report"
import CandidatosView from "./Views/CandidatosView"
import VideosView from "./Views/VideosView"




const menu = [
  {title:"Dashboard", icon:"fas fa-chart-pie", tab:1, path:"/data-center/dashboard"},

  {title:"Líderes del Mañana", icon:"fas fa-users", tab:2, path:"/data-center/alumnos"},
  {title:"Expedientes", icon:"fas fa-file-alt", tab:3, path:"/data-center/expedientes"},
  {title:"Formularios", icon:"fas fa-table", tab:4, path:"/data-center/forms"},
  {title:"Reportes", icon:"fas fa-table", tab:5, path:"/data-center/reports"},

]
const menu2 = [

  // {title:"Candidatos", icon:"fas fa-users", tab:6, path:"/data-center/candidatos"},
  // {title:"Videos", icon:"fas fa-play", tab:6, path:"/data-center/videos"}


]
//     "bootstrap": "^4.5.3",
const DataCenter = (props) => {

  const [loading, setLoading] = useState(false)



  const location = useLocation().pathname

  useEffect(()=>{
    console.log( bootstrap)
    if (bootstrap.Offcanvas){

      var myModalEl  = document.getElementById("offcanvasExample")

      console.log(myModalEl)
  
      bootstrap.Offcanvas.getOrCreateInstance(myModalEl)
    }

  },[bootstrap.Offcanvas])



  function renderMenu(){
    return (
        <div className="bg-white" >

<div className="fs-4 mb-3"> 
        
            
            <i className="fas fa-database"/> Data center <b>LDM</b></div>
       
          {map(menu,(section, idx)=>
                   <Link     to={section.path} as="ul" key={idx + 1}  className={classNames({"twitter-bs-wizard-nav text-decoration-none d-flex align-items-center p-2":true,  "bg-primary ":location.includes( section.path), "bg-soft-primary ":!location.includes( section.path)}) } style={{borderRadius:5, marginBottom:10}}>

          <div className={" p-1 " + (location.includes( section.path) ?"active":"") + ""}>

                <div
            
                  className={ classNames({" align-items-center":true,  active:location.includes( section.path), "text-white":location.includes( section.path) })}
                  onClick={() => {
                  }}
                  style={{display:"flex", flexDirection:"row", alignItems:"center", fontWeight:location.includes( section.path) ? "bold ":"normal"}}
                >
                  <div className={"me-2 text-decoration-none " + (section.success ?  (location.includes( section.path) ? " bg-success border-success":" border-success text-success") :"")}>
                    {section.icon? 
                    <i className={"font-size-18  " + section.icon} />:idx+1}</div>


                  <div>{section.title}</div>
                </div>
              </div> 
              </Link>
              )}
              {/* <div className="border-bottom  mb-3"> 
        
            
        <i className="fas fa-star"/> Candidatos <b>LDM 2022</b></div>
        {map(menu2,(section, idx)=>
                   <Link     to={section.path} as="ul" key={idx + 1}  className={classNames({"twitter-bs-wizard-nav text-decoration-none d-flex align-items-center p-2":true,  "bg-primary ":location.includes( section.path), "bg-soft-primary ":!location.includes( section.path)}) } style={{borderRadius:5, marginBottom:10}}>

          <div className={" p-1 " + (location.includes( section.path) ?"active":"") + ""}>

                <div
            
                  className={ classNames({" align-items-center":true,  active:location.includes( section.path), "text-white":location.includes( section.path) })}
                  onClick={() => {
                  }}
                  style={{display:"flex", flexDirection:"row", alignItems:"center", fontWeight:location.includes( section.path) ? "bold ":"normal"}}
                >
                  <div className={"me-2 text-decoration-none " + (section.success ?  (location.includes( section.path) ? " bg-success border-success":" border-success text-success") :"")}>
                    {section.icon? 
                    <i className={"font-size-18  " + section.icon} />:idx+1}</div>


                  <div>{section.title}</div>
                </div>
              </div> 
              </Link>
              )} */}
        </div>
    )
  }



  return (
    <React.Fragment>
      {loading?"":""}
      <div className="" style={{height:70}}/>
      <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div className="offcanvas-header d-flex border-bottom">
        <h2 className="flex-fill"> 
      
            
            Menu
           </h2>
 
           <button className=" btn btn-link fs-4" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
              <i className="fas fa-times" /> 
            </button> 
        </div>
        
        <div className="offcanvas-body">
        {renderMenu()}
        </div>
      </div>
      <div className="">

     
        <div>
 

          <div  className="row border-top" >

            <div className="bg-white p-3 border-end d-none col-md-2 d-md-block">
              

              {renderMenu()}
            </div>

            <div className="p-4 col-12 col-md-10 ">

           
              <div>
                
                <div>
    


                
          <button className="float-start d-md-none btn btn-primary me-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
              <i className="fas fa-bars" /> 
            </button> 
            
         



                  <Switch>
             
                  <Route exact path="/data-center/dashboard" render={Dashboard} />
                  <Route exact path="/data-center/alumnos" render={StudentView} />
                  <Route exact path="/data-center/expedientes" render={ExpedientesView} />
                  <Route exact path="/data-center/forms" render={FormsView} />
                  <Route exact path="/data-center/reports" render={ReportsView} />
                  <Route exact path="/data-center/reports/:id" render={Report} />
                  <Route exact path="/data-center/candidatos" render={CandidatosView} />
                  <Route exact path="/data-center/videos" render={VideosView} />

                  <Route exact path="" render={()=><Redirect to={"/data-center/dashboard"}/>} />
                  </Switch>

                </div>
              </div>


            </div>
  

          </div>
      
        </div>
      </div>
    </React.Fragment>
  )
}


DataCenter.propTypes = {
 
}


export default (withRouter(DataCenter))



