import React from "react"

import { ReactLiquid, liquidEngine } from "react-liquid";
import { LDMDB, Loader } from "../../utils/Configs";
import { getValue, linkify } from "../../utils/Tools";

const GLOBAL_DATA = {

}

class DivRender  extends React.Component{

    

    constructor(props) {
        super(props);
        this.state = {
            loading:false, 
            data: props.data || {}
        }
    }

    componentDidMount(){


        liquidEngine.registerFilter('set', (initial, arg1) => {
            GLOBAL_DATA[initial] = arg1
      
        })

        liquidEngine.registerFilter('get', (initial) => {
            return GLOBAL_DATA[initial]
        })
      
        const { datasources, data } = this.props;

        if (datasources && datasources.length > 0){
            this.setState({loading:true})
            datasources.map((datasource)=>{
                if (datasource.source == "firebase"){

                    /*LDMDB.database().ref(datasource.path + user.matricula).once('value').then((snapshot)=>{
                        if (snapshot.exists()) {
                            console.log(snapshot.val());
                           

                        }
                
                    });*/
                    let path = datasource.path
                    var matches = path.match(/\<(.*?)\>/)

                    if (matches && matches.length > 0) {
             
                        let submatch = matches[1]

                        path = datasource.path.replace(matches[0], getValue(data, submatch) || "")

                        
                        matches = path.match(/\<(.*?)\>/)

                        console.log(matches)
                        console.log(path)
                    }

                    

                    LDMDB.database().ref(path).on('value', (snapshot)=>{
                        if (snapshot.exists()) {
                            //console.log(snapshot.val());
                            let dat = snapshot.val()
                            this.setState({data:{...this.state.data, [datasource.name]: dat} })
                        }

                        this.setState({loading:false})

                        

                    });
            
                }
            })
            
        }
       
    }



    

    


    render(){

        
      

        const { html,} = this.props;
        const { data, loading} = this.state;


        return (
            <div {...this.props}>
             {loading ?  <Loader/>:<ReactLiquid  key={"reactliquid"} template={linkify(html?html:"")} data={data ? data :{}} html={true} />}
            
        </div>
        
        )
    }
}


export default DivRender



