
import {
  GET_ALL_FORMS_SUCCESS,
  SET_FORMS_NAMES,
  GET_ALL_EXP_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  listData:null,
  listExpData: null,
  formNames:{}
}

const form = (state = INIT_STATE, action) => {

  switch (action.type) {

    case GET_ALL_FORMS_SUCCESS:
      //console.log(action.payload)
      return {
        ...state,
        listData:JSON.parse(JSON.stringify(action.payload)), 
      }
      case GET_ALL_EXP_SUCCESS:
        //console.log(action.payload)
        return {
          ...state,
          listExpData:JSON.parse(JSON.stringify(action.payload)), 
        }
    case SET_FORMS_NAMES:
      //console.log(action.payload)
      return {
        ...state,
        formNames:JSON.parse(JSON.stringify(action.payload)), 
      }
    default:
      return state
  }
}

export default form 

