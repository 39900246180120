
import { call, put as reduxPut, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { 
  GET_ALL_FORMS, 
} from "./actionTypes"

import {
  getAllExpSuccess,
   getAllFormSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { get } from "../../../utils/backend/api_helper"

function* getAllForms({ payload: { page, size, callback, onError, exp} }) {
  try {

    let response = {}
    if (exp){
       response = yield call(get, "/forms/expedientes/"+page+"/"+size)
       yield reduxPut(getAllExpSuccess(response.data))


    }else {
       response = yield call(get, "/forms/"+page+"/"+size)
       yield reduxPut(getAllFormSuccess(response.data))


    }
    ////console.log(response)
    callback(response.data)

  } catch (error) {
    //console.log(error)
    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}



function* formSaga() {
  yield takeEvery(GET_ALL_FORMS, getAllForms)
}

export default formSaga

