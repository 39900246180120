import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"

import { Link, useParams, withRouter} from "react-router-dom"
import {
    Container,
    Card,
    CardBody,
    Spinner
} from "reactstrap"
import InfiniteScroll from "../../components/InfiniteScroll"
import Share from "../../components/Share"
import { getContactProfile} from "../../store/actions"
import { CAMPUS, CARRERAS } from "../../utils/Tools"
// import { getConvocatorias, getSolicitud, getSolicituds, postSolicitud } from
// "../../store/actions"
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import Solicitud from "./Solicitud"
import FormsStudentView from "../Admin/Views/FormsStudentView"
import classNames from "classnames"


const Perfil = (props) => {
    const { id, matricula } = useParams();

    const [loading,
        setLoading] = useState(false)
        const [fullText,
            setFullText] = useState(false)
    const [view,
            setView] = useState(1)
    const [profile,
        setProfile] = useState({student:{}})
    //const modal = useRef();
    const dispatch = useDispatch();
    const user = useSelector(state => state.profile.user);
    const contacts = useSelector(state => state.contact.dictionary);
    var hash = window.location.hash.substr(1);
    //console.log(id)

    
    
    const getUser = () => {
        setLoading(true)

        dispatch(getContactProfile(id,(data)=>{
            //console.log(data)
            ////console.log("data")
            setProfile(data)


            setLoading(false)

            if (!data.id){
                props.history.push("/comunidad-virtual")
            }
        },()=>{}, contacts))


    }

    if (profile && profile.id && profile.id != id){
        //getUser()
    }


    useEffect(() => {
   
        getUser()

    }, [id])

    useEffect(() => {
        
        console.log(hash)

        switch (hash) {
            case "expediente":
                setView(2)
                break;
            case "muro":
                setView(1)
                break;
            case "forms":
                setView(3)
                break;
            default:
                break;
        }

    }, [hash])
 

    /*const createSolicitud = (values, validData) => {
    //console.log(values, validData)

    if (validData){
      setLoading(true)
      dispatch(postSolicitud(values,
        ()=>{
          setLoading(false)
          dispatch(getSolicituds())
          modal.current.toggle()
          props.history.push("/solicitud")
        }))
    }
  }*/

  const renderView  = () =>{

    switch (view) {
        case 1:
            return (
               <>
                    {user.id == profile.id ? <Share user={user} hiddeImage/>:null}


                
                    {profile.id ?<InfiniteScroll user={profile}/>:null}


                    {profile.student.generation ? <div style={{fontSize:18, textAlign:"center"}}><VerticalTimeline>
                    <VerticalTimelineElement
                            date={<div style={{fontSize:18, textAlign:"center"}}><b>{profile.student.generation}a</b> generación</div>}
                            iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
                            icon={<i className={`fas fa-flag`} style={{fontSize:24, padding:18}}></i>}
                        />

                    </VerticalTimeline></div>:null}
                </>
            )
        case 2:
            return (
                <>
                    <Solicitud user={profile}/>
                </>
            )  
        case 3:
            return (
                <>
                    <FormsStudentView user={profile}/>
                </>
            )  
    
        default:
            break;
    }
  }
  const profileCard = () => {
    return (
        <div
        className={"mt-0"}
        style={{
        flex: 1,
        borderTopLeftRadius:5,
        borderTopRightRadius:5,
        textAlign: "center"
    }}>

         <Card
            className={"mt-0 box-shadow "}
            style={{
       
        }}>

            <CardBody
                className={"mt-0"}

                style={{
       
                padding: 0
            }}>

                <div
                    style={{
                    display: "flex",
                    alignItems: "center",
                    alignContent:"center",
                    justifyContent:"center",
              
               
                    padding: 0
                }}>
                    <div
                        
                        style={{
                            flex:1,
                            height:130,
                            borderBottom:"5px solid #f6f6f6",                                                
                            backgroundSize:"cover",
                            borderTopLeftRadius:5,
                            borderTopRightRadius:5,
                            backgroundImage:`url(${"https://tec.mx/sites/default/files/styles/header_full/public/2018-05/fotoprincialeslideresconecta.jpg?itok=YF7OfQ-p"})`}
                        }>

                        {profile && profile.url_photo
                            ? <img
                                    className="rounded-circle header-profile-user"
                                    src={profile.url_photo}
                                    style={{
                                        objectFit:"cover",
                                    transform:"translateY(30px)",
                                    minHeight: 180,
                                    minWidth: 180,
                       
                                }}
                                    alt="Header Avatar"/>
                            : 
                            <span className={"d-inline-block"} style={{}}>
                                 <span
                                    className={
                                        "avatar-title rounded-circle" +
                                        " text-primary"
                                    }
                                    style={{transform:"translateY(30px)", backgroundColor:"#fff"}}
                                    >
                                    <span
                                    className={
                                        "avatar-title rounded-circle bg-soft-primary" +
                                        " text-primary"
                                    }
                                    style={{ fontSize:88,
                                    width:180, height:180}}
                                    >
                                    {(profile.full_name || "-").charAt(0)}
                                    </span>
                                    </span>
                                    </span>
                                
                                                    
                            
                            }
                    </div>


            </div>

            <div style={{marginTop:70, padding:20}}>
                <h4>{profile.full_name}</h4>
                <h5 style={{color:"gray", marginTop:0, paddingTop:0}}>{profile.student.generation}a generación</h5>
                <hr/>
                <div style={{textAlign:"left", fontSize:15}}>
                    <div style={{color:"gray", display:"flex", alignItems:"center"}}>
                        <i className={"fas fa-at p-1"} /> 
                        <div>{profile.email}</div>
                    </div>
                    <div style={{color:"gray", flex:1, display:"flex", alignItems:"center"}}>
                        <i className={"fas fa-school p-1"}/> 
                        <div style={{flex:1}}>Campus {profile.student.campus ? CAMPUS[profile.student.campus-1].campus:null}</div>
                    </div>
                    <div style={{color:"gray", flex:1, display:"flex", alignItems:"center"}}>
                        <i className={"fas fa-graduation-cap p-1"}/> 
                        <div style={{flex:1}}>{ (CARRERAS.find((ca)=> ca.id==profile.student.degree) || {}).carrera || profile.student.degree}</div>
                    </div>


                    {user.admin ? <div style={{color:"gray", flex:1, display:"flex", alignItems:"center"}}>
                        <i className={"fas fa-phone p-1"}/> 
                        <div style={{flex:1}}>{profile.phone}</div>
                    </div>:null}

                </div>

                {profile.student.semblanza ? <React.Fragment>
                    <hr/>
                    <div style={{flex:1, fontSize:14, display:"flex", textAlign:"left", alignItems:"center"}}>
                    {profile.student.semblanza}
                    </div>

                </React.Fragment>:null}

                {profile.student.why_ldm ? <React.Fragment>
                    <hr/>
                    <div style={{color:"gray", marginBottom:10, textAlign:"left" }}>Para mi ser un Líder del Mañana...</div>
                    <div style={{flex:1, display:"flex", textAlign:"left", fontSize:16}}>
                    <i className={"fas fa-quote-left p-2 text-primary" } style={{fontSize:28}}/> 
                    {profile.student.why_ldm}
                    </div>

                </React.Fragment>:null}

            </div>
            </CardBody>
        </Card>

    </div>
    
    )
  }
  

    return (
        <React.Fragment>
                   <div className="" style={{height:70}}/>
                            <div className="d-none  d-lg-block" style={{height:53}}/>
            <div className=" mt-3">

            {loading
                ? <Spinner/>:
          
                <div className="container-fluid">

                <Container
                            className={"d-md-none"}
                            style={{
                            flex: 1
                        }}>
                                            {profileCard()}
                                            </Container>

                    <div style={{
                        display: "flex"
                    }}>
                                            <div className={"d-none d-md-block"} style={{
                                                    flex: 0.5
                                                }}>
                                            {profileCard()}
                                            </div>

                       <Container
                            className={"px-lg-4 px-xl-5"}
                            style={{
                            flex: 1
                        }}>


{profile.student.video  || profile.student.history && user.admin ?  <Card
            className={"mt-0 box-shadow mb-2 "}
            style={{
       
        }}>
<CardBody>
<h3 className="text-center border-bottom pb-2" >Mi historia</h3>
</CardBody>
            <CardBody
                className="d-flex align-items-center"

                style={{
       
          
            }}> 
            
                    {profile.student.video ? 
                    <div className="flex-fill text-center pe-2 me-2" style={{minWidth:80}}>
                        
                        <a target="_blank" rel="noreferrer" href={profile.student.video}>
                            <i className="fas fa-play-circle fs-1" />
                            <div>Ver video</div>
                        </a>
                    </div> : null}

                    {profile.student.history  ?<div className="flex-fill">
               
                        <div className="row">
                            <p className={classNames({"text-t":!fullText, "text-justify":true})}>
                                {profile.student.history  }
                            </p>
                            <button onClick={()=>setFullText(!fullText) } className="btn btn-link">{fullText ? "Ver menos":"Ver más"}</button>
                        </div>
                    </div>:null}



                </CardBody>
                </Card>:null}
                            {user.admin  ? <div style={{display:"flex", marginBottom:10, flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                                <Link to={"#muro"} className={"selectable box-shadow"} style={{flex:1, textAlign:"center", padding:10, backgroundColor:view == 1? "#1a3192":"#fff", color:view == 1? "#fff":"unset", borderRadius:5}}>Muro</Link>
                    <Link to={"#expediente"}  className={"selectable box-shadow ms-1 me-1"} style={{flex:1, textAlign:"center", padding:10, backgroundColor:view == 2? "#1a3192":"#fff", color:view == 2? "#fff":"unset",borderRadius:5}}>Expedientes</Link>
                               <Link to={"#forms"}  className={"selectable box-shadow"} style={{flex:1, textAlign:"center", padding:10, backgroundColor:view == 3? "#1a3192":"#fff", color:view == 3? "#fff":"unset", borderRadius:5}}>Solicitudes/apoyos</Link>
                                </div>:
                                
                                null}

                                {renderView()}
                            

                        </Container>


                       
                   </div>
                </div>
                  }</div>
        </React.Fragment>
    )
}

Perfil.propTypes = {}

export default(withRouter(Perfil))
