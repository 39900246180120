import React from "react"
import { FormGroup, InputGroup, Label } from "reactstrap"
import PropTypes from 'prop-types';

import BaseField from "./BaseField"

class Input extends BaseField {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || ""
        }
       
      }

      getValue = () =>{
          return this.state.value ? this.state.value  :""
      }

      setValue = (value) => {
        this.setState({value:value})
      }


    render(){
        const { name, label, type, disabled } = this.props;

        
        return (
            <FormGroup>
                <Label>{label}{this.props.required ? <span className={"invalid-feedback"} style={{display:"inline", fontSize:14}}> *</span>:null}</Label>
                <InputGroup>
                    <input
                        disabled={disabled}
                      
                        type={type}
                        value={this.state.value}
                        onChange={(event)=>this.setState({value:event.target.value})}
                        name={name}
                        className="form-control"
                    
                    />
                </InputGroup>
                {this.renderErrorMessage()}
        </FormGroup>
        
        )
    }
}

Input.propTypes =  Object.assign({}, BaseField.propTypes,   {
    type: PropTypes.string,
});

Input.defaultProps = Object.assign({}, BaseField.defaultProps, {
    type:"text"
});



export default Input