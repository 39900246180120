import React from "react"

import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId'


import BaseField from "./BaseField"

class Radio extends BaseField {

    constructor(props) {
        super(props);

        this.id  = uniqueId(this.props.name + '-')
    }

    getValue = () =>{
        return this.props.value
    }

    setValue = (value) => {

    }



    render(){
    const {value, name, label, inline} = this.props;


    return (

            <div className={inline?`d-flex justify-content-center align-items-center flex-column`:"form-check"}>
                <input
                    type="radio"
                    name={name}
                    id={this.id}
                    className="form-check-input"
                    style={{ marginRight:inline?0:"", cursor:"pointer"}}
                    checked={this.props.checked}
                    onChange={(event)=>this.props.onChange(event.target.value)}
                    value={value}
                />
                <label
                    className="form-check-label "
                    style={{fontWeight:"normal", cursor:"pointer"}}
                    htmlFor={this.id}
                >
                    <div dangerouslySetInnerHTML={{__html:label}}/>
                </label>
            </div>

     
    )
}
}
Radio.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
}

export default Radio