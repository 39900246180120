import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {  withRouter } from "react-router-dom"
import { map } from "lodash"
import {

  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledDropdown,

} from "reactstrap"
import { getValue } from "../../utils/Tools"

//Import Breadcrumb

//Import Image


//import { getProjects } from "store/actions"

const List = props => {

    const {data, headers, withMenu, menu, ifEmpty, hasPagination, pagination = {}, onChangePage=()=>{}} = props
    const {pages, page, has_prev, has_next, prev_num, next_num} = pagination || props

  useEffect(()=>{

  },[props.render])


    let arr1 = []
    let arr2 = []


    arr1 = [...Array(page > 0 ?  page : 0).keys()].reverse()
    arr2 = [...Array(pages - page  > 0 ? pages - page: 0 ).keys()]
    let s = {borderTop:"0"}
    return (
    <React.Fragment>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">

                  <Table className="table-nowrap table-centered" style={{borderSpacing:"0 4px", borderTop:"0"}}>
                    <thead style={{borderTop:"0"}}>
                      <tr style={{borderTop:"0"}}>
                        {withMenu?<th scope="col" style={{width:20}}></th>:null}

                        {map(headers, (header, index) => (
                            <th key={index} scope="col" style={{...header.headerStyle, ...s}}  {...header.className}>
                                {header.label}
                            </th>))}
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length > 0 ? map(data, (item, index) => (
                        <tr key={index}>
                            {withMenu?<td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                href="#"
                                className="card-drop"
                                tag="i"
                              >
                                <i className="mdi mdi-dots-vertical font-size-22 pl-2" />
                              </DropdownToggle>

                              <DropdownMenu right={false}>
                                {map(menu,(menuItem, idx)=>(
                                  <DropdownItem key={idx} style={{color:menuItem.color?menuItem.color:"unset"}} onClick={()=>menuItem.onClick(item)}>
                                      {menuItem.icon ? <i className={menuItem.icon  + " me-2"}></i>: null}
                                    {menuItem.label}</DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>:null}

                          {map(headers, (header, index) => (
                            <td key={index}>
                                {header.formatter? header.formatter(getValue(item,header.key),item ):getValue(item,header.key)}
                            </td>))}

                        </tr>
                      )):<tr><td colSpan={headers.length}>{ifEmpty}</td></tr>}
                    </tbody>
                  </Table>
                
                </div>
              </div>
            </Col>
          </Row>

          {hasPagination ? <div style={{display:"flex", alignContent:"center", justifyContent:"center"}}>
               <Pagination aria-label="Page navigation example">
               {has_prev ? <><PaginationItem>
                      <PaginationLink first href="#" onClick={()=>onChangePage(page, 1)} />
                    </PaginationItem>
                    <PaginationItem>
                      <PaginationLink previous href="#" onClick={()=>onChangePage(page, prev_num)}/>
                    </PaginationItem></>:null}



                    {pages > 5 ? <>
                    {arr1.length > 3 ? 
                    <PaginationItem onClick={()=>onChangePage(page,  1)}>
                    <PaginationLink href="#">
                      1
                    </PaginationLink>
                  </PaginationItem>:null
                    }
                    {arr1.length > 3 ? 
                    <PaginationItem disabled>
                    <PaginationLink href="#">
                      ...
                    </PaginationLink>
                  </PaginationItem>:null
                    }

                    {(arr1).map((obj, index) => {
                     
                      if (obj > 0 && index <= 1) {
                      return (
                        <PaginationItem key={obj} onClick={()=>onChangePage(page,  obj)}>
                          <PaginationLink href="#">
                            { obj}
                          </PaginationLink>
                        </PaginationItem>)
                        }else{
                          return null
                        }
                    }).reverse()}

                  <PaginationItem active onClick={()=>onChangePage(page, page)}>
                          <PaginationLink href="#">
                            {page}
                          </PaginationLink>
                  </PaginationItem>

                {(arr2).map((obj, index) => {
                      obj = obj + 1;
                      if (page + obj > 0 && index <= 1) {
                      return (
                        <PaginationItem key={page + obj} onClick={()=>onChangePage(page, page + obj)}>
                          <PaginationLink href="#">
                            {page + obj}
                          </PaginationLink>
                        </PaginationItem>)
                        }else{
                          return null
                        }
                    })}
                  {arr2.length > 3 ? 
                    <PaginationItem disabled>
                    <PaginationLink href="#">
                      ...
                    </PaginationLink>
                  </PaginationItem>:null
                    }

                  {arr2.length > 3 ? 
                    <PaginationItem onClick={()=>onChangePage(page,  pages)}>
                    <PaginationLink href="#">
                      {pages}
                    </PaginationLink>
                  </PaginationItem>:null
                    }
                    </>:<>{([...Array(pages).keys()]).map((obj, index) => {
                      obj = obj + 1;

                      return (
                        <PaginationItem key={obj} active={obj==page} onClick={()=>onChangePage(page, obj)}>
                          <PaginationLink href="#">
                            {obj}
                          </PaginationLink>
                        </PaginationItem>)
                        
                    })}</>}

                    {has_next ? <><PaginationItem onClick={()=>onChangePage(page, next_num)}>
                      <PaginationLink next href="#" />
                    </PaginationItem>
                    <PaginationItem onClick={()=>onChangePage(page, pages)}>
                      <PaginationLink last href="#" />
                    </PaginationItem></>:null}

                  </Pagination>
                  
                  </div>:null}
    </React.Fragment>
  )
}

List.propTypes = {
  withMenu: PropTypes.bool,
  menu:PropTypes.array, 
}


export default connect()(withRouter(List))
