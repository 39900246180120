
import React, {  useRef, useState } from "react"


import {   useHistory, withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"

import { API } from "../../utils/backend/api_helper"
import swal from "sweetalert"
import { Loader } from "../../utils/Configs"
import TMMS from "../../utils/TMMS.json"
import Form from "../../components/Form/Form"
import RadioGroup from "../../components/Fields2/RadioGroup"
import Button from "../../components/Buttons/Button"
import Header from "./Header"

const options = [
  {value:1,label:"Nada de acuerdo"},
  {value:2,label:"Algo de acuerdo"},
  {value:3,label:"Bastante de acuerdo"},
  {value:4,label:"Muy de acuerdo"},
  {value:5,label:"Totalmente de acuerdo"},
]

const TakeTMMS = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);

    const [candidato, setCandidato] = useState({})
    const history = useHistory()


  
    const submit = (values, valid) =>{
      console.log(values, valid)

      if (valid){
        setLoading(true)
        API.post(
          "/candidato/tmms",
          values,
          (response)=>{
       
            setLoading(false)
            swal({
              title:"¡Gracias!",
              text:"Esta sección ha sido completada.",
              icon:"success"
          }).then(
            ()=>{
              //window.location.href = "/candidatos/home"
              history.push( "/candidatos/home")

            }
          )

          },
          (response)=>{
            swal({
              title:"¡Error!",
              message:response,
              icon:"error"
            })
            setLoading(false)

          })
  
      }



    }


  return (
    <React.Fragment >

      {loading ? <Loader message={"Enviando..."} />:null}
      
      <div className="vh-100">
        <Header/>

        <div className="container my-4  h-100 ">
            <div className="card shadow-sm">
            <div className="card-body p-0">

              <div className="rounded-top ">
              <h3 className="fw-bold bg-primary  rounded-top text-white p-4">INTELIGENCIA EMOCIONAL Y LIDERAZGO (TMMS)</h3>
                <p className=" fs-5 fs-md-4 p-4">
                Lea cada una de las frases y responde con la opción que mas se te describa.
                </p>
                <hr/>
              </div>
          

              <div className={"p-4"}>
                  <Form onSubmit={submit}>

                      <div className="bg-body p-4 rounded mb-2"><b>TMMS</b></div>
                      {TMMS["tmms"].map((q,index)=><div key={index}>

                          <RadioGroup required inline label={(index+1)+". "+q.question} name={"q"+q.id} options={options} />
                      <hr/>
                      </div>)}

                      <div className="bg-body p-4 rounded mb-2"><b>Empatía</b></div>

                      {TMMS["empatia"].map((q,index)=><div key={index}>

                      <RadioGroup required inline label={(index+1)+". "+q.question} name={"q" +(24+q.id)} options={options} />
                      <hr/>
                      </div>)}
                      <div className="bg-body p-4 rounded mb-2"><b>Autoestima</b></div>

                      {TMMS["autoestima"].map((q,index)=><div key={index}>

                      <RadioGroup required inline label={(index+1)+". "+q.question} name={"q"+(33+q.id)} options={options} />
                      <hr/>
                      </div>)}
                      <div className="bg-body p-4 rounded mb-2"><b>Planeación y tona de decisiones</b></div>

                      {TMMS["planeacion"].map((q,index)=><div key={index}>

                      <RadioGroup required inline label={(index+1)+". "+q.question} name={"q"+(43+q.id)} options={options} />
                      <hr/>
                      </div>)}

                      <div className="mt-2 text-end">
                      <Button  type={"submit"} color={"primary fw-bold"}>Finalizar test <i className="ms-1 border-start ps-1 fas fa-arrow-right"/></Button>

                    </div>
                  </Form>
              

                  </div>
                </div>
            </div>
        </div>
        </div>
    </React.Fragment>
  )
}


TakeTMMS.propTypes = {
 
}


export default (withRouter(TakeTMMS))



