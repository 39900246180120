import { map } from "lodash"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { withRouter } from "react-router-dom"
import { getAllPostSC, getPostSCSuccess, getContact } from "../store/actions"
import Post from "./Post"


let lastDate = ""
let fkey = ""
let lastKey = ""
const pageSize = 15

let comments = {}
const InfiniteScroll = (props) => {

  const [loading, setLoading] = useState(false)
  const [comments, setComments] = useState({})
  const currentUser = useSelector(state => state.profile.user);

  //const [posts, setPosts] = useState([])
  const dispatch = useDispatch();
  const posts = useSelector(state => state.postSC.all || {}).data;
  const [likes, setLikes] = useState([]);

  const [likeModal, setLikeModal] = useState(false);
  const toggleLikeModal = () => {
    setLikeModal(!likeModal)
  };

  let user = props.user || {}

  const contacts = useSelector(state => state.contact.dictionary);


  useEffect(()=>{
    dispatch(getPostSCSuccess([]))
    //console.log(user)
    if (user && user.id){
      dispatch(getAllPostSC(1,user.id,(data)=>{
        //console.log(data)
        

      }))
    
    }else{
      dispatch(getAllPostSC(1, null,(data)=>{
        //console.log(data)
      }))
    }
   
  
  },[])

  useEffect(()=>{
    if (posts && posts.length >  0) {
      posts.map((v)=>{
        getContactDict(v.user_id)
      })

    }

  },[posts])


  const nextPage = (next) => {
 
  
  }






  const getContactDict = (id) =>{
    
    if (!contacts[id] ){
      dispatch(getContact(id,(data)=>{
        //console.log(data)
      }))
      return false
    }

    return contacts[id]

  }

  

 


  
  return (

    <React.Fragment>
         
        <div  id={"lst"}>
            {
                posts && posts.length > 0 ? map(posts,(p,index)=>{
                  ////console.log(p)
                    return <div className="mt-3" key={p.id}>
                     <Post post={p} user={user}/>
                    </div>
                }):<div style={{textAlign:"center", fontSize:16, color:"gray", marginTop:20}}>No hay publicaciones{user && user.full_name ?  " de " + user.full_name:null}</div>
            }

           
        </div>

        {
            posts && posts.length > 0 ? <div style={{display:"flex", textAlign:"right"}}>

            
            <div onClick={()=>nextPage(false)} style={{cursor:"pointer"}}><i className={"fas fa-chevron-left"}/></div>
            <div style={{width:20}}/>
            <div onClick={()=>nextPage(true)} style={{cursor:"pointer"}}><i className={"fas fa-chevron-right"}/></div>


            </div>:null}

    </React.Fragment>

  )
}


InfiniteScroll.propTypes = {
 
}


export default (withRouter(InfiniteScroll))



