import React from "react"
import { FormGroup, Label } from "reactstrap"
import PropTypes from 'prop-types';

import Radio from "../Fields/Radio";

import BaseField from "./BaseField"



class RadioGroup extends BaseField{

    constructor(props) {
        super(props);
        this.state = {
            value:props.value ||  props.default  || null,
            otherValue:""
        }


        /*this.radiosRefs  = {};
        this.props.options.map((option)=>
            this.radiosRefs[option.value] = createRef()
        )*/

    }

    getValue = () => {
        //let value = null
        /*this.props.options.map((option)=>{
            if (this.radiosRefs[option.value].current.getValue()){
                value = option.value
            }
        })*/
        
        return this.state.value
    }

    setValue = (value) => {
        let other = false
        if (value && value != ""){
            other = true
            this.props.options.map((option)=>{
                if (option.value == value){
                    other = false
                }
            })
        }


        this.setState({value:value})
        if (other){
            this.setState({otherValue:value})
        }
    }

    setOtherValue = (value) =>{
        //console.log(value)
        this.setState({otherValue:value, value:value})
    }

    onChange = (value) => {
        //console.log(value)
        this.setState({value:value})
        if (this.props.onChange){
            this.props.onChange(value)
        }
    }


    render(){
    const {name, label, options, other, otherLabel, inline} = this.props;

    return (
        <FormGroup >

            <Label style={{display: inline ? "block":""}}>
                <div dangerouslySetInnerHTML={{__html:label +(this.props.required ? "<span class='invalid-feedback' style='display: inline; font-size:15px;'>*</span>":"")}}/>
                </Label>
            <div className={`${inline ? "d-flex justify-content-center":""}`}>
                <div>
            {options && options.map((option, idx)=>
                <div  key={idx}  className={`pl-2 ${inline ? "form-check-inline":""}`}>
                    
                    <Radio 
                    inline={inline}
                    //ref={this.radiosRefs[option.value]}
                    key={idx} 
                    name={label + (idx ? "-"+idx:"")}  
                    checked={this.state.value === option.value}
                    label={option.label || option.value} 
                    value={option.value}
                    onChange={()=>this.onChange(option.value)}
            /></div>)}
            </div>
   </div>
             <div  className={"pl-2"}>
            {other ? 
                 <div className="form-check">
                 <input
                     type="radio"
                     id={label+"-other" +  "-"+999}  
                     className="form-check-input"
  
                     checked={this.state.value  === this.state.otherValue}
                     onChange={()=>this.onChange(this.state.otherValue)}
                 />
                  <label
                    className="form-check-label"
                    style={{fontWeight:"normal"}}
                    htmlFor={label+"-other" +  "-"+999}
                    >
                  {otherLabel? otherLabel : "Otro:"}{" "}<input value={this.state.otherValue} className="form-check-label" style={{fontWeight:"normal", borderWidth:0, borderBottomWidth:1}} onChange={(e)=>this.setOtherValue(e.target.value)} htmlFor={this.id}/> 
              </label>
              </div>
       
       :null  
            }
            </div>
            {this.renderErrorMessage()}
         
        </FormGroup>
    )
    }
}

RadioGroup.propTypes = {
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
}



export default RadioGroup