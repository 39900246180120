import React from "react"
import { FormGroup, InputGroup, Label } from "reactstrap"
import PropTypes from 'prop-types';

import BaseField from "./BaseField"

class Input extends BaseField {
    timer = null;
    constructor(props) {
        super(props);
        this.state = {
            value: props.value ||  props.default  || ""
        }
       
      }

     

      getValue = () =>{
          return this.state.value ? this.state.value  :""
      }



      setValue = (value) => {

        this.setState({value:value})
        this.props.onChange &&  this.props.onChange(value)
      }


    render(){
        const { name, label, type, disabled, hidden, inputProps, containerClass, leftAddon } = this.props;

      

        return (
            <FormGroup className={`${containerClass}`}>
                {hidden  ? "": <Label className="form-label"><span dangerouslySetInnerHTML={{ __html: label}}/>{this.props.required ? <span className={"invalid-feedback"} style={{display:"inline", fontSize:14}}> *</span>:null}</Label>}
                <InputGroup>
                    {leftAddon ? <span className="input-group-text">{leftAddon}</span>  :null }
                    <input
                        disabled={disabled}
                        hidden={hidden}
       
                        type={type}
                        value={this.state.value}
                        onChange={(event)=>this.setValue(event.target.value)}
                        name={name}
                        className={`form-control ${disabled?"bg-light":""}`}
                        {...inputProps}
                       
                    />
                </InputGroup>
                {this.renderErrorMessage()}
        </FormGroup>
        
        )
    }
}

Input.propTypes =  Object.assign({}, BaseField.propTypes,   {
    type: PropTypes.string,
    label: PropTypes.string,
});

Input.defaultProps = Object.assign({}, BaseField.defaultProps, {
    type:"text",
    label:""
});



export default Input