import React, { useState } from "react"
import { useSelector } from "react-redux"

import { Link, withRouter } from "react-router-dom"
import { Card, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap"
import Input from "../components/Fields2/Input"
import  Button  from "../components/Buttons/Button"
import { avatar } from "../utils/Tools"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import swal from "sweetalert"
import { LDMDB } from "../utils/Configs"
import { FirebaseDatabaseNode, FirebaseDatabaseProvider } from "@react-firebase/database"


const FormCard = (props) => {
    let item = props.item
    const formNames = useSelector(state => state.form.formNames);
    const contacts = useSelector(state => state.contact.dictionary);

    const [urlModal, setURLModal] = useState(false);
    const toggleUrlModal  = () => {
      setURLModal(!urlModal)
    }; 
  

  
  return (

    <React.Fragment>
        
             <Card className={"box-shadow p-0 flex"} style={{height:250, width:"100%"}}>
                          
                          
                        {props.expediente  ? null :<Modal centered isOpen={urlModal} toggle={toggleUrlModal}>
                                    <ModalHeader toggle={toggleUrlModal} charCode={<i className={"fa fa-times"}/>}>{formNames[item.form_id] ? formNames[item.form_id] : 
                                <Spinner/>
                              }</ModalHeader>
                          <ModalBody>
                            Link del formulario
                            <div style={{display:"flex", alignItems:"center"}}>
                                 
                            <div style={{flex:1}} ><Input readonly disabled value={`https://micasaldm.mx/forms/${item.form_id}`}/></div>
                            <CopyToClipboard text={`https://micasaldm.mx/forms/${item.form_id}`}
                                    onCopy={() => swal("¡Listo!", "Copiamos el link a tu portapapeles", "success", )}
                                  >
                                  <Button color={"primary"} style={{marginLeft:10}}><i className={"fa fa-copy"} />{"  "}Copiar</Button>
                            </CopyToClipboard>
                            </div>
                          </ModalBody>
                        </Modal>}




                          <div className={"p-2"} style={{"flex":1,flexDirection:"row", textAlign:"center", display:"flex", backgroundColor:"#f8f8f8",borderBottom:"1px solid #e1e1e1", justifyContent:"center", alignItems:"center"}}>
                          

                              
                              <h3>{formNames[item.form_id] ? formNames[item.form_id] : 
                                <Spinner/>
                              }</h3>

<FirebaseDatabaseProvider firebase={LDMDB}>   

<FirebaseDatabaseNode
      path={`forms/${item.form_id}/status/`}
      limitToFirst={1}
      > 
  {d=>{
    //console.log(d)
    if (d && d.value ){
    return (
      <div>
        {d.value}
      </div>
      )  
    }else{

      return (
        <div>
    none
        </div>
        )  
    }
    }}

  </FirebaseDatabaseNode>

  </FirebaseDatabaseProvider>
                          </div>

                         
                        
                          {props.expediente  ? null : <div style={{display:"flex", flexDirection:"row", marginBottom:1, justifyContent:"center", alignItems:"center", borderBottom:"1px solid #e1e1e1"}}>
                            
                            {contacts[item.user_id] ? <Link to={"/perfil/"+item.user_id+"/"+contacts[item.user_id].matricula} className={"p-2"} style={{flex:1}}>
                              <div style={{display:"flex",   flexDirection:"row", marginBottom:1, alignItems:"center"}}>

                              <div style={{marginRight:5}}>{avatar(contacts[item.user_id], 25, 16)}</div>
                              <div style={{flex:1,fontSize:12}}>{contacts[item.user_id].full_name}</div>
                              </div>
                            </Link>: 
                              <div className={"ms-2"} style={{display:"flex", alignItems:"center", justifyContent:"center", flex:1}}>
                                
                                <Spinner/>

                             </div>
                            }
                          </div>}
                          <div style={{display:"flex", flexDirection:"row", textAlign:"center", marginBottom:1}}>
                          {props.user ? null : <div className={"selectable p-2"} style={{flex:1}}><i className={"fas fa-pen"}/> Editar</div>}
                            <div onClick={()=>{
                                if (props.user ){
                                    props.history.push(`/forms/${item.form_id}/${props.user.id}`)
                                }else{
                                    props.history.push("/answers/"+ (props.expediente  ? "expedientes" : "forms")+"/"+item.form_id)
                                }
                                
                            }} className={"selectable p-2"} style={{flex:1}}>Respuestas</div>
                             {props.expediente || props.user  ? null :<div onClick={toggleUrlModal} className={"selectable p-2"} style={{flex:1}}><i className={"fas fa-link"}/> Link</div>}
                          </div>
          </Card>
    
    </React.Fragment>

  )
}


FormCard.propTypes = {
 
}


export default (withRouter(FormCard))



