import React, {   useRef, useState } from "react"
import { useSelector } from "react-redux"

import {  Link, withRouter } from "react-router-dom"
import { Loader } from "../../utils/Configs"
import ambiente from "../../assets/images/ambiente.jpeg"

import NewProjectModal from "../../components/NewProjectModal"






const Sociales = (props) => {

  const [loading, setLoading] = useState(false)
  const user = useSelector(state => state.profile.user);
  //const convocatorias = useSelector(state => state.convocatoria.data);
  //const solicitudes = useSelector(state => state.solicitud.data);
  const modal = useRef();











  
  
  return (
    <React.Fragment> 
      <div className="page-content mt-6">

                  {loading?<Loader message={"Cargando"}/>:""}




                  <NewProjectModal ref={modal}/>

                  <div className="row">
                    <div className="col-12 col-md-8 mb-4">
                  <div className="bg-black rounded border">
                <div className=" d-md-none rounded-top">
                    <img className="rounded-top"  style={{height:300, width:"100%", objectFit:"cover"}} src={ambiente} />
                </div>


                <div className="d-flex rounded align-items-center  ">

                    <div className="d-none d-md-block">
                        <img className="rounded-start"  style={{height:350, width:250, objectFit:"cover"}} src={ambiente} />
                    </div>

                    <div className="flex-fill text-white p-3" style={{maxHeight:350}}>
                        <h1 className="fw-bolder">Iniciativas y proyectos sociales</h1>
                        <p className="">Siendo <b>Líder del Mañana</b>, dirigido por los Objetivos de Desarrollo Sostenible, y sensible a las necesidades del mundo, estarás llevando a cabo iniciativas  para contribuir a erradicar la pobreza, facilitar el acceso a la educación, proteger al medio ambiente, entre otras causas. 
                        <br/> <br/>
                        En este espacio podrás registrar y dar seguimiento a tu iniciativas y proyectos así como conocer los proyectos de tus hermanos LDM.
                        </p> 

                                            <Link  to={"/ods"}><button className="btn btn-outline-light float-end">Conoce todas las iniciativas de tus hermanos LDM</button></Link>
           
                    </div>
                </div>
            </div>
                    </div>
                    <div className={"col-12 col-md-4 mb-4"}>

                    <div className="h-100 rounded box-shadow ">
                        <div className="text-shadow d-flex align-items-end rounded-top" style={{ background:"linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.6)), url('/objetivos.png')", backgroundPosition:"center", backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
                        <div  className="p-3 m-0 text-white fs-3 fw-bold" ><br/>Mis iniciativas y/o proyectos sociales


                        </div>
             
                    </div>
                    <div className=" d-flex flex-column justify-content-center align-items-center ">
                      <label className="text-gray mt-4">No tienes ninguna iniciativa o proyecto social</label>

                    <button onClick={()=>modal.current.toggle()} className="btn btn-primary mt-4">Registra un nuevo proyecto social</button>
                    </div>

              </div>

                    </div>
            </div>


              </div>

    </React.Fragment>
  )
}


Sociales.propTypes = {
 
}


export default (withRouter(Sociales))

