
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { Link, withRouter } from "react-router-dom"
import { Container, Card, CardBody} from "reactstrap"



const BibliotecaLDM = (props) => {

  const [loading, setLoading] = useState(false)

  //const convocatorias = useSelector(state => state.convocatoria.data);
  //const solicitudes = useSelector(state => state.solicitud.data);
  //const modal = useRef();
  const dispatch = useDispatch();

  useEffect(()=>{
    //dispatch(getConvocatorias())
    //dispatch(getSolicituds())
  },[dispatch])

  /*const createSolicitud = (values, validData) => {
    console.log(values, validData)
    
    if (validData){
      setLoading(true)
      dispatch(postSolicitud(values, 
        ()=>{
          setLoading(false)
          dispatch(getSolicituds())
          modal.current.toggle()
          props.history.push("/solicitud")
        }))
    }
  }*/
  
  return (
    <React.Fragment>
      {loading?"":""}
      <div className="page-content mt-6">
        <Container fluid>
          <h2><b>Guía Líderes del Mañana</b></h2>
          <Card className={"box-shadow"}>
            
            <CardBody style={{padding:0}}>

              <div style={{display:"flex", alignItems:"center",height:"100%", backgroundColor:"rgb(43,156,217)",borderRadius:5}}>
                <div style={{flex:1, fontSize:14, padding:60, backgroundColor:"#fff", color:"gray", borderTopLeftRadius:5, borderBottomLeftRadius:5}}>
                  <b style={{fontSize:20, color:"black"}}>¡Todo lo que necesitas saber!</b> <br/> <br/>
  
                  
                  Acompañarte y ayudarte a florecer en tu viaje de formación profesional por el Tecnológico de Monterrey es nuestra tarea. A través de las distintas áreas de apoyo el programa Líderes del Mañana buscará fortalecer tu desarrollo, bienestar físico, mental y emocional, asimismo acompañaremos tu formación como futuro líder de impacto positivo en tu entorno.

                    <br/><br/>Para disfrutar al máximo tu paso por el Tec y para conservar tu beca cada semestre, te invitamos a revisar todas las áreas de apoyo con las que cuentas para tu desarrollo, así como los reglamentos y normativas que debes conocer.                 
                      <br/>    <br/>
                      <a href={"https://s3.amazonaws.com/micasaldm.resources/libro-azul.pdf"} target={"blank"}>
<Link style={{fontSize:14}}>¡Consulta tu guía LDM!</Link><br/><br/>
</a>

                </div>
          
                <div style={{flex:1.1, height:"100%", backgroundColor:"rgb(43,156,217)", borderTopRightRadius:5, textAlign:"center", borderBottomRightRadius:5}}>
                  <div style={{textAlign:"center", height:"100%",padding:30, paddingBottom:0, fontSize:18, fontWeight:"bold", color:"#fff"}}>
                    <div>
                      Acerca de
                    </div>
                  </div>
                  <img src={"/LogoLideresAzul.jpeg"} style={{width:"100%", borderRadius:5, maxWidth:400, padding:60, paddingTop:0, paddingBottom:20}}/>
                  <div>
                  <a href={"https://s3.amazonaws.com/micasaldm.resources/libro-azul.pdf"} target={"blank"} style={{textAlign:"center", paddingBottom:50}}>
                    <span style={{border:"1px solid #fff",height:"100%", borderRadius:3, padding:10, color:"#fff"}}>
                      CONSULTA TU GUÍA LDM
                    </span>
                  </a>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
            
        </Container>
      </div>
    </React.Fragment>
  )
}


BibliotecaLDM.propTypes = {
 
}


export default (withRouter(BibliotecaLDM))



