
import React, { useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import { Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Button } from "."
import { getAllPostSC, postPostSC } from "../store/actions"
import { Loader } from "../utils/Configs"
import TextArea from "./Fields2/TextArea"
import Form from "./Form2/Form"
//import { ReactVideo } from "reactjs-media";
import { getVideoCover } from "../utils/Tools"


const Share = (props) => {
  let user = props.user
  const [loading, setLoading] = useState(false)
  const [postType, setPostType] = useState(false)
  const [coverImg, setCoverImg] = useState(false)
  const [coverImgBlob, setCoverImgBlob] = useState(false)

  const [url, setURL] = useState({})
  const previousUrl = useRef(url);

  const videoRef = useRef();

  const inputFileX = useRef(null) 
  const inputFile2 = useRef(null) 


  const dispatch = useDispatch()

  const form = useRef()
  const [file, setFile] = useState();

  const [modal, setModal] = useState(false);
  const toggle = (ty="", open=!modal) => {
    setPostType(ty)

    if (ty=="text"){
      setFile(null)
    }

    if (ty=="foto_video"){
      inputFileX.current.click();
    }

    if (ty=="file"){
      setFile(null)
      inputFile2.current.click();
    }


    setModal(open)
  };




  const postNewPost = (data, valid) => {
    if (valid){

      data["post_type"] = postType

      if (file && postType=="foto_video"){
        data["post_type"] = String(file.type).substr(0,String(file.type).search("/") ).toLowerCase() == "image" ? "image":"video"
        data["file_type"] = file.type

        if (data["post_type"] == "video"){
          data["cover"] = coverImgBlob
        }


      }else if (postType=="file"){
        data["file_type"] = file.type
      }

      //console.log(data)
      setLoading(true)
      dispatch(postPostSC(data, file, ()=>{
        setModal(false)
        dispatch(getAllPostSC(1,null ,(data)=>{
          //console.log(data)
          setLoading(false)

        },()=>setLoading(false)))

      },()=>setLoading(false)))

    }

  }

  const shareOptions = () => {
    return (
      <>
                                    <div
                                        style={{
                                        display:"flex",
                                        marginTop: 0,
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                        fontSize:14,
                                        alignItems:"center",
                                        justifyContent:"center",
                                        textAlign:"center"
                                    }}>
                                        <div onClick={()=>toggle("foto_video", false)} className={"selectable"} style={{flex:1, padding:10}}>
                                          <i className={"fas fa-image text-success"} style={{fontSize:16}}/> Foto
                                        </div>
                                        <div onClick={()=>toggle("foto_video", false)} className={"selectable"} style={{flex:1, padding:10}}>
                                          <i className={"fas fa-video text-danger"} style={{fontSize:16}}/> Video
                                        </div>
                                        <div onClick={()=>toggle("file", false)}  className={"selectable"} style={{flex:1, padding:10}}>
                                        <i className={"fas fa-paperclip text-info"} style={{fontSize:16}}/> Archivo

                                        </div>
                                        {/*true ?null:<div onClick={()=>toggle("file", false)}  className={"selectable"} style={{flex:1, padding:10}}>
                                        <i className={"fas fa-briefcase text-warning"} style={{fontSize:16}}/> Vacante

                                  </div>*/}
                                        {/*true ?null:<div  onClick={()=>toggle("pool", true)}  className={"selectable"} style={{flex:1, padding:10}}>
                                        <i className={"fas fa-poll text-warning"} style={{fontSize:16}}/> Encuesta

                                </div>*/}

                                    </div></>
    )
  }

  const modalPost = () => {
    return (
      <Modal size={"lg"} isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} charCode={<i className={"fa fa-times"}/>}>Nueva publicación</ModalHeader>
        <ModalBody>
          <div style={{display:"flex"}}>
                        {user && user.url_photo
                                                ? <img
                                                        className="rounded-circle header-profile-user"
                                                        src={user.url_photo}
                                                        style={{
                                                          objectFit:"cover",
                                                        minHeight: 80,
                                                        minWidth: 80
                                                    }}
                                                        alt="Header Avatar"/>

                                                : <span className={"d-inline-block"}>
                                                    <span
                                                        className={"avatar-title rounded-circle bg-soft-primary text-primary font-size-22"}
                                                        style={{
                                                        width: 80,
                                                        height: 80
                                                    }}>
                                                        {user
                                                            .full_name
                                                            .charAt(0)}
                                                    </span>
                                                </span>}

                                                <div style={{flex:1, paddingLeft:20}}>
                                                  <Form ref={form} onSubmit={postNewPost}>
                                                    <TextArea name={"text"} required label={"Escribe lo que quieras compartir"}/>
                                                    {file ? 
                                                    file.type && String(file.type).substr(0,String(file.type).search("/") ).toLowerCase() == "image" ? 
                                                      <img width={"100%"} style={{borderRadius:5, marginTop:10}}  src={URL.createObjectURL(file)}/> : 
                                                      file.type && String(file.type).substr(0,String(file.type).search("/") ).toLowerCase() == "video" ? 
                                                      // <ReactVideo
                                                      // ref={videoRef}
                                                      // src={url ? url: ""}
                                                      //     poster={coverImg ? URL.createObjectURL(coverImg):""}
                                                      //     primaryColor={"red"}
                                                          
                                                      //     // other props
                                                      // />
                                                      <></>
                                                      :<div style={{display:"flex"}}><div style={{flex:1}}>{file.name}</div><div onClick={()=>{setFile(null); setPostType("text")}} className={"selectable p-1"}><i className={"fa fa-times"}/></div></div>

                                                    :null
                                                    
                                                    }

                                                  </Form>

                                                  {shareOptions()}


                                                </div>
          </div>


        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>Cancelar</Button>
          <Button color="primary"  type={"submit"} onClick={()=>form.current.submit()}>Publicar</Button>{' '}
        </ModalFooter>
      </Modal>
    )
  }
 
 
  return (
    <React.Fragment>
            {loading ? <Loader message={"No cierres la ventana, estamos subiendo tu publicación...."}/>:null}
            <input type='file' id='file1' accept="image/*,video/*" onChange={(e)=>{
              //console.log(e.target.files)
              let file = null
              
              if (e.target.files && e.target.files.length > 0){
                file = e.target.files[0]
                setFile(e.target.files[0])
                setURL(URL.createObjectURL(e.target.files[0]) )
               

              }

              if (file && file.type && String(file.type).substr(0,String(file.type).search("/") ).toLowerCase() == "video"){
                getVideoCover(file, 2,(cover)=>{
                  //console.log(cover)
                  setCoverImgBlob(cover)
                  setCoverImg(cover)
                })

              }

              setModal(true)
    
            }} ref={inputFileX} style={{display: "none"}}/>
            
            <input type='file' id='file2' onChange={(e)=>{
              //console.log(e.target.files)
              let file = null
              
              if (e.target.files && e.target.files.length > 0){
                file = e.target.files[0]
                setFile(e.target.files[0])
                setURL(URL.createObjectURL(e.target.files[0]) )
               
              }

              if (file && file.type && String(file.type).substr(0,String(file.type).search("/") ).toLowerCase() == "video"){
                getVideoCover(file, 2,(cover)=>{
                  //console.log(cover)
                  setCoverImgBlob(cover)

                  setCoverImg(cover)
                })
                toggle("foto_video", true)
              }
              setModal(true)
    
                                                    }} ref={inputFile2} style={{display: "none"}}/>

      {modalPost()}

       <Card className={"box-shadow"}>
                                <CardBody
                                    style={{
                                    padding: 10
                                }}>

                                    <div
                                        style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}>

                                        {props.hiddeImage ? null: <div
                                            style={{
                                            marginRight: 10
                                        }}>
                                          {user && user.url_photo
                                                ? <img
                                                        className="rounded-circle header-profile-user"
                                                        src={user.url_photo}
                                                        style={{
                                                          objectFit:"cover",
                                                        minHeight: 50,
                                                        minWidth: 50
                                                    }}
                                                        alt="Header Avatar"/>

                                                : <span className={"d-inline-block"}>
                                                    <span
                                                        className={"avatar-title rounded-circle bg-soft-primary text-primary font-size-22"}
                                                        style={{
                                                        width: 50,
                                                        height: 50
                                                    }}>
                                                        {user
                                                            .full_name
                                                            .charAt(0)}
                                                    </span>
                                                </span>}</div>
                                        }<div
                                            style={{
                                            flex: 1
                                        }}>
                                            <div>
                                              <div
                                                onClick={()=>toggle("text")}
                                                className={"selectable"}
                                                //value={vPost}
                                                //onChange={(e) => setVPost(e.target.value)}
                                                //placeholder={}
                                                style={{
                                                  display:"flex",
                                                    borderWidth: 0,
                                                    borderRadius: 70,
                                                    padding: 10,
                                                    width: "100%",
                                                    alignItems:"center",
                                                    minHeight: 50,
                                                    paddingLeft:25,
                                                    fontSize:12,
                                                    color:"gray",
                                                    backgroundColor: "rgb(242,242,245)"
                                                }}>{"  "}¿Tienes algo que compartir? </div>
                                                </div>
                                        </div>
                                    </div>
                                    <hr style={{margin:10}}/>
                                    {shareOptions()}

                                </CardBody>
                            </Card>

     
    </React.Fragment>
  )
}


Share.propTypes = {
 
}


export default (withRouter(Share))



