
import { call, fork, put as reduxPut, take, takeEvery, takeLatest, takeLeading } from "redux-saga/effects"

// Crypto Redux States
import { 
  GET_ALL_CONTACT, 
  GET_CONTACT, 
  POST_CONTACT, 
  PUT_CONTACT, 
  DELETE_CONTACT,
  SEARCH_CONTACT,
  GET_CONTACT_PROFILE 
} from "./actionTypes"

import {
  getAllContactSuccess,
  getContactSuccess,
  putContactLoading,
  searchSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { post, put, get, del } from "../../../utils/backend/api_helper"

function* getAllContact({ payload: { page, callback, onError} }) {
  try {

    yield reduxPut(putContactLoading(true))



    const response = yield call(get, "/contact/"+page)
    ////console.log(response)
    yield reduxPut(getAllContactSuccess(response.data))
    callback(response)

    
    yield reduxPut(putContactLoading(false))

  } catch (error) {
    //console.log("ERROR")
    ////console.log(JSON.stringify(error.response))
    yield reduxPut(putContactLoading(false))

    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}


function* getContact({ payload: {id, callback, onError, contacts={}} }) {
  try {

    let response = null
    if (contacts[id]){
      response = contacts[id]
      yield reduxPut(getContactSuccess(contacts[id]))

    }else{
      response = yield call(get, "/contact/user/" + id)

      yield reduxPut(getContactSuccess(response.data))
      response = response.data

    }
    ////console.log(response)
    callback(response)
  } catch (error) {
    //console.log(error)
    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}


function* getContactProfile({ payload: {id, callback, onError, contacts={}} }) {
  try {

    let response = null
    if (contacts[id] && contacts[id].first_name){
      response = contacts[id]
      yield reduxPut(getContactSuccess(contacts[id]))

    }else{
      response = yield call(get, "/contact/user/profile/" + id)

      yield reduxPut(getContactSuccess(response.data))
      response = response.data

    }
    ////console.log(response)
    callback(response)
  } catch (error) {
    //console.log(error)
    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}


function* searchContact({ payload: {id, page, size, callback, onError, contacts={}} }) {
  try {

    let response = null

    let currentObjs = []
    let pagec = 1

    for (const [key, value] of Object.entries(contacts)) {
      ////console.log(key, value);
      if ((value.email.toLowerCase().includes(id.toLowerCase()) || value.full_name.toLowerCase().includes(id.toLowerCase())) && value.is_ldm){
        currentObjs.push(value)
        
      }

      if (currentObjs.length == size){
        pagec = pagec + 1
        if (pagec < page){
          currentObjs = []
        }
      }

      if (currentObjs.length == size){
        break;
      }
    }

    if (currentObjs.length == size){
      response = {data:currentObjs}

    }else{
      response = yield call(post, "/contact/user/search/" + page+"/"+size, {query:id})

      yield reduxPut(searchSuccess(response.data))
      response = response.data

    }
    ////console.log(response)
    callback(response)
  } catch (error) {
    //console.log(error)
    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}

function* putContact({ payload: {id, data, callback, onError} }) {
  try {
    const response = yield call(put, "/contact/user/" + id, data)
    callback(response)
  } catch (error) {

    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}

function* deleteContact({ payload: {id, callback, onError} }) {
  try {
    const response = yield call(del, "/contact/user/" + id)
    callback(response)
  } catch (error) {

    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}



function* postContact({ payload: {data, callback, onError} }) {
  try {
    const response = yield call(post, "/contact", data)
    ////console.log(response)
    callback(response)
  } catch (error) {
    ////console.log("ERROR")
    ////console.log(JSON.stringify(error.response))

    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}

const takeLatestByKeyA = (patternOrChannel, saga, ...args) => fork(function*() {
  const tasks = {};

  while (true) {
    const action = yield take(patternOrChannel)
    if (tasks[action.payload.id]) {
      //yield cancel(tasks[action.payload.id]) // cancel is no-op if the task has already terminated
    }else{
   
      console.log(action.payload.id)
  
      tasks[action.payload.id] = yield fork(saga, ...args.concat(action))
    }

  
  }

})


function* contactSaga() {
  yield takeLeading(GET_ALL_CONTACT, getAllContact)
  yield takeLatest(SEARCH_CONTACT, searchContact)
  yield takeLatestByKeyA(GET_CONTACT, getContact)
  yield takeEvery(GET_CONTACT_PROFILE, getContactProfile)
  yield takeEvery(POST_CONTACT, postContact)
  yield takeEvery(PUT_CONTACT, putContact)
  yield takeEvery(DELETE_CONTACT, deleteContact)
 
}

export default contactSaga

