import React, { useEffect, useState } from 'react';
import { Button
} from 'reactstrap';

import StackGrid from "react-stack-grid";

import { LDMDB } from '../../utils/Configs';
import { useMediaQuery } from 'react-responsive';
import JSZip from 'jszip';
import{saveAs} from 'file-saver';
import axios from 'axios';


var zip = new JSZip();

const fontStyle = {}



const Divider = ({props}) => {
    return (
        <div style={{margin:1}} {...props} />
    )
}




const Galeria = () => {

        const  [files, setFiles] = useState([
            "https://s3.us-east-2.amazonaws.com/ae.resources/A01704848@itesm.mx/8a95febb-05fd-4b40-a413-87766c47cb94-pp.jpg",
            "https://s3.amazonaws.com/micasaldm.resources/libertad@tec.mx/fad77ec8-a6c8-4351-beb0-b089a8f0dfa7-pp.jpg",
            "https://s3.amazonaws.com/micasaldm.resources/graciela.arroyo@tec.mx/aedaf196-742c-40f1-8067-bf60ee378674-pp.jpg"

        ])
        const  [loading, setLoading] = useState(false)

        useEffect(() => {
           setLoading(true)
            var query = LDMDB
                .database()
                .ref("answers/AX87D84DWS/");
              
            const listener = query.on("value", (snapshot) => {
                //console.log(snapshot)
                if (snapshot) {
                    let data = []
                    
                    snapshot.forEach(function (d) {
                        if (d.key) {
                            //console.log("The " + d.key + " data is " + d.val());
                            data.push((d.val()["form_compromiso"] || {}).compromiso)
                        }
                    });
                    //console.log(data)
                    //setFiles(data)
                    setLoading(false)

                }
            });
    
                
            return listener
        }, [])


        const download = () =>{
            let count = 0
            files.forEach(function(url, index){

                var filename = "filename";
                // loading a file and add it in a zip file
                axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob', // Important
                  }).then((response) =>  {
                    
                    zip.file(filename, response.data, {binary:true});
                    count++;

                    if (count == files.length) {
                    var zipFile = zip.generate({type: "blob"});
                    saveAs(zipFile, "dddd.zip");
                    }
                });

              });
              
          
        }

        let mod600 = useMediaQuery({ query: `(min-width: 800px)` })
        let mod800 = useMediaQuery({ query: `(min-width: 1000px)` }) 
        return (
            <React.Fragment>
            

            <div className="account-pages">

                <div>
            <div style={{marginBottom:"128px"}}>
                <StackGrid  gutterWidth={1} gutterHeight={1}
                           columnWidth={mod800 ?'33.33%': mod600? "50%": '100%'  }
                        
                >
                    {files.map((item, key) =>
                        <div key={key}>

                            <img
                                className="header-profile-user"
                                src={encodeURI(item)}
                                style={{
                                height: 100,
                                objectFit:"cover",
                                width: "100%"
                            }}
                            alt="Header Avatar"/>
                           
                         </div>
                    )}
                </StackGrid>
                <Button onClick={download}>ss</Button>

            </div>
         </div> 
                       </div>
                       </React.Fragment>
        );

}

export default Galeria