
import React from "react"


import {  withRouter } from "react-router-dom"
import { Container} from "reactstrap"




const Vivencia = (props) => {
 
  return (
    <React.Fragment>
      
      <div className="page-content mt-6">
        <Container fluid>

        <iframe src="https://sites.google.com/tec.mx/programas-de-acompaamiento/programas-de-acompa%C3%B1amiento/mentoring-exatec?authuser=0"/>
            
        </Container>
      </div>
    </React.Fragment>
  )
}


Vivencia.propTypes = {
 
}


export default (withRouter(Vivencia))



