import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { loginError, loginSuccess, logoutUserSuccess } from "./actions"


import {isBrowser} from 'react-device-detect';
import { get, post } from "../../../utils/backend/api_helper";
import MSAuth from "../../../utils/MSAuth";
import { profileError, profileSuccess } from "../profile/actions";
import { LDMDB } from "../../../utils/Configs";

//Include Both Helper File with needed methods
import "firebase/database";
import "firebase/auth";

function* loginUser({ payload: { user, onSuccess, onError, onPermissionGranted, exatec, candidato } }) {
  let values =  {
    email: user.email,
    matricula: user.matricula,
    pin: user.pin,
    password: user.password,
    type: user.type,
    access_token: user.access_token
  }
  try {
    const response = yield call(post, candidato ? "auth/user/login/candidato" : (exatec ? "auth/user/login/exatec": "auth/user/login"), values)
    //console.log("x SUCCESS")
    console.log(response)
    if(response.success && !response.request_permission && response.user){
      console.log(response)
      localStorage.setItem("authToken",response.auth_token );
      yield put(loginSuccess(response.auth_token))

      //localStorage.setItem("profile", JSON.stringify(response.user))
      response.user.fbToken = response.fb_token
      response.user.fbUser = response.fb_user

      //console.log(response.fb_token)
      LDMDB.auth().signInWithCustomToken(response.fb_token)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        //console.log(user)
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        //console.log(errorMessage)
        // ...
      });

      yield put(profileSuccess(response.user))
      onSuccess(response)

    }else{
      //console.log("2 SUCCESS")
        if (response.request_permission){
            if (isBrowser){
                //onError(response.message)
                MSAuth.getPermissions(onPermissionGranted)
            }else{
                onError(response.message + ". Es necesario ingresar desde el navegador de una computadora. "  )
            }
        }else{
            onError(response.message)
        }
    }
    
    //localStorage.setItem("authToken", response.token)


    ////console.log("SUCCESS")
  } catch (error) {

    ////console.log("ERROR")
    //console.log(JSON.stringify(error.response))

    if (error && error.response && error.response.data && error.response.data.message)
      onError(error.response.data.message)
    else
      onError(null)
  }
}

function* logoutUser({ payload: { history } }) {
  try {
  

    const response = yield call(get,"auth/logout")
    
    localStorage.removeItem("authToken")
    localStorage.removeItem("profile")

    yield put(logoutUserSuccess(response))
    yield put(profileError())
    
    history.push("/login")
  } catch (error) {
  
    localStorage.removeItem("authToken")
    localStorage.removeItem("profile")
    yield put(logoutUserSuccess(error))
    yield put(profileError())
    history.push("/login")
    yield put(loginError(null))
  }
}



function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
