
import React, { useEffect, useRef, useState } from "react"


import {  Link, useHistory, withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import Form from "../../components/Form/Form"
import Button from "../../components/Buttons/Button"

import { API } from "../../utils/backend/api_helper"
import swal from "sweetalert"
import { Loader } from "../../utils/Configs"
import Upload from "../../components/Fields2/Upload"
import Header from "./Header"



const AdvanceInsight = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);
    const [progress, setProgress] = useState(-1)

    const [candidato, setCandidato] = useState({})

    const history = useHistory()
    let files = 0

    useEffect(()=>{
      setLoading(true)
      API.get(
          "/candidato",
          (resp)=>{
              setCandidato(resp.data)
              setLoading(false)



          },
          (resp)=>{
              swal({
                  title:"¡Error!",
                  text:resp,
                  icon:"error"
              })
              setLoading(false)

          }
      )
  },[])

  const completeUpload = (fileData, totalFiles) => {
    API.post(
      "/candidato/complete-upload",
      {url:fileData.upload_url.split("?")[0],name:fileData.name,bucket:fileData.bucket },
      ()=>{
        files = files +1
        if (files == totalFiles){
          swal({
            title:"¡Gracias!",
            text:"Esta sección ha sido completada.",
            icon:"success"
          }).then(
            ()=>{
              //window.location.href = "/candidatos/home"
              history.push( "/candidatos/home")
            }
          )
        }
      
      },
      (response)=>{
        swal({
          title:"¡Error en el documento!",
          message:response,
          icon:"error"
        })
      })
  }

  const upload = (url, file, fileData, totalFiles) => {
    API.S3put(
      url,
      file,
      (response)=>{
        console.log(response)
        completeUpload(fileData, totalFiles)
    },(response)=>{
      swal({
        title:"¡Error al subir el documento!",
        message:response,
        icon:"error"
      })
    },(progressEvent)=>{
      let percentComplete = progressEvent.loaded / progressEvent.total
      percentComplete = percentComplete * 100;
      setProgress(percentComplete)
    })
  }


  
    const submit = (values, valid) =>{
      console.log(values, valid)

      if (valid){
        setLoading(true)
        let files = []
        for (const [key, value] of Object.entries(values)) {
          if (value && value.fileValue){
            files.push(key)
          }
        }
        API.post(
          "/candidato/upload-file",
          {files},
          (response)=>{
            setLoading(false)
            response.data.map((uploadFileData)=>{
              upload(uploadFileData.upload_url, values[uploadFileData.name].fileValue, uploadFileData, response.data.length)
            })
          },
          (response)=>{
            swal({
              title:"¡Error!",
              message:response,
              icon:"error"
            })
            setLoading(false)

          })
  
      }



    }


  return (
    <React.Fragment >

      {loading ? <Loader message={"Enviando..."} />:null}
      
      <div className="vh-100">
      <Header/>

        <div className="container mt-4  h-100 ">
            <div className="card shadow-sm">
            <div className="card-body p-0">

              <div className="rounded-top ">
              <h3 className="fw-bold bg-primary  rounded-top text-white p-4">PRUEBA DE EVALUACIÓN ADVANCED INSIGHTS</h3>
                <p className=" fs-5 fs-md-4 p-4">
                Con el deseo de que conozcas mejor tus capacidades y competencias, te invitamos a contestar la prueba Advanced Insights. Sigue las indicaciones que se muestran a continuación:<br/><br/>
<ol>
<li>Accede a la dirección electrónica: <a rel="noreferrer" href="https://profiles.innermetrix.com/VO/c1fc504d/es" target="_blank">https://profiles.innermetrix.com/VO/c1fc504d/es</a></li>
<li>Especifica tu nombre completo y <b>el correo con el que te registraste en el sistema de preselección de Líderes del Mañana.</b></li>
<li>Responde las 3 pruebas en una sesión.</li>
</ol>
Al terminar de contestar las pruebas, llegarán a tu correo tus resultados. Te invitamos a leerlos pues te ayudarán a conocerte mejor y consérvalos ya que los usaremos más adelante.
<br/><br/>
<div className="alert alert-warning" role="alert">
4.	Sube aqui el documento con los resultados que recibiste por correo.
</div>

                <div className="text-end">Tiempo estimado de duración: 30-45 minutos.<br/>
                Contacto para dudas: Jorge Méndez <a href="mailto:jorge@tec.mx">jorge@tec.mx</a><br/>
                </div>
                </p>
                <hr/>
              </div>
          

              <div className={"p-4"}>
              {candidato.AI== "COMPLETA"  ? <div className="text-center">

<h3 className="mb-5"><i className="fas fs-1 text-success fa-check-circle"/><br/>¡Gracias!<br/>Esta sección ya esta completa.</h3>

<div className="d-flex justify-content-center">
<Link to="/candidatos/home" className={"btn btn-outline-primary"}>Regresar</Link>

<Button onClick={()=>setCandidato({})}  className={"ms-2"} color={"primary"}>Volver a subir mis resultados</Button>
</div>
</div>: <Form  onSubmit={submit}>
                    <div className="row">
                  
                      <div className={""}>
                      <Upload maxSize={10} progress={progress} required name={"AI"}  label={"Sube aqui tus resultados"}/>

                      </div>

                    </div>
                    {progress >= 0 ? null:<div className="mt-2 d-flex justify-content-end">
                      <Link to="/candidatos/home" className={"btn btn-outline-primary me-2"}>Regresar</Link>

                      <Button  type={"submit"} color={"primary fw-bold"}>Continuar <i className="ms-1 border-start ps-1 fas fa-arrow-right"/></Button>

                    </div>}
                  </Form>}

                  </div>
                </div>
            </div>
            <div style={{height:50}} />
        </div>
        </div>
    </React.Fragment>
  )
}


AdvanceInsight.propTypes = {
 
}


export default (withRouter(AdvanceInsight))



