import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {  useParams, withRouter } from "react-router-dom"
import { Container } from "reactstrap"


import { getContactProfile } from "../../store/actions"
import Solicitud from "./Solicitud"

const FormView = (props) => {

    const { id, user_id } = useParams();
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();
    const user = useSelector(state => state.profile.user);

    const contacts = useSelector(state => state.contact.dictionary);
    const [profile, setProfile] = useState({student:{}})

    


      const getUser = () => {
        

        dispatch(getContactProfile(user_id,(data)=>{
            //console.log(data)
            ////console.log("data")
            setProfile(data)
            setLoading(false)
        },()=>{}, contacts))


    }

    useEffect(() => {
        if (user_id){
          setLoading(true)
          getUser()
        }else{
          setProfile(null)
          setLoading(false)
        }


    }, [])
  
  return (

    <React.Fragment>
          {loading
                ? ""
                : ""}
            <div className="page-content">
                <div>
                <Container fluid >
               
                {!loading
                ? <Solicitud formID={id} user={profile}/>:null}
        </Container>
        </div>
        </div>

    </React.Fragment>

  )
}


FormView.propTypes = {
 
}


export default (withRouter(FormView))



