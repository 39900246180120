import { all, fork } from "redux-saga/effects"
//import drugPLMSaga from "./data/drugs_plm/saga"
import authSaga from "./auth/login/saga"
import profileSaga from "./auth/profile/saga"


//public

import LayoutSaga from "./layout/saga"
import contactSaga from "./data/contact/saga"
import postSCSaga from "./data/postSC/saga"
import formSaga from "./data/forms/saga"

//import solicitudSaga from "./data/solicitud/saga"
//import convocatoriaSaga from "./data/convocatoria/saga"

export default function* rootSaga() {
  yield all([
    //public
    LayoutSaga(),
    //fork(convocatoriaSaga),
    fork(authSaga),

    fork(profileSaga),
    fork(postSCSaga),
    fork(formSaga),
    
    fork(contactSaga)
  ])
}
