
import React, {  useRef, useState } from "react"


import {   useHistory, withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"

import { API } from "../../utils/backend/api_helper"
import swal from "sweetalert"
import { Loader } from "../../utils/Configs"
import mmpi_questions from "../../utils/MMPI.json"
import Form from "../../components/Form/Form"
import RadioGroup from "../../components/Fields2/RadioGroup"
import Button from "../../components/Buttons/Button"
import Header from "./Header"



const TakeMMPI = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);

    const [candidato, setCandidato] = useState({})
    const history = useHistory()


  
    const submit = (values, valid) =>{
      console.log(values, valid)

      if (valid){
        setLoading(true)
        API.post(
          "/candidato/mmpi",
          values,
          (response)=>{
       
            setLoading(false)
            swal({
              title:"¡Gracias!",
              text:"Esta sección ha sido completada.",
              icon:"success"
          }).then(
            ()=>{
              //window.location.href = "/candidatos/home"
              history.push( "/candidatos/home")

            }
          )

          },
          (response)=>{
            swal({
              title:"¡Error!",
              message:response,
              icon:"error"
            })
            setLoading(false)

          })
  
      }



    }


  return (
    <React.Fragment >

      {loading ? <Loader message={"Enviando..."} />:null}
      
      <div className="vh-100">
        <Header/>

        <div className="container my-4  h-100 ">
            <div className="card shadow-sm">
            <div className="card-body p-0">

              <div className="rounded-top ">
              <h3 className="fw-bold bg-primary  rounded-top text-white p-4">PRUEBA DE BIENESTAR INTEGRAL (MMPI)</h3>
                <p className=" fs-5 fs-md-4 p-4">
                Lea cada una de las frases y decida si es Verdadera o Falsa en referencia a usted.
                </p>
                <hr/>
              </div>
          

              <div className={"p-4"}>
                  <Form onSubmit={submit}>
                      {mmpi_questions.map((q,index)=><div key={index}>

                          <RadioGroup required inline label={(index+1)+". "+q.question} name={"q"+q.id} options={[{value:"T", label:"VERDADERO"}, {value:"F", label:"FALSO"}]} />
                      <hr/>
                      </div>)}

                      <div className="mt-2 text-end">
                      <Button  type={"submit"} color={"primary fw-bold"}>Finalizar test <i className="ms-1 border-start ps-1 fas fa-arrow-right"/></Button>

                    </div>
                  </Form>
              

                  </div>
                </div>
            </div>
        </div>
        </div>
    </React.Fragment>
  )
}


TakeMMPI.propTypes = {
 
}


export default (withRouter(TakeMMPI))



