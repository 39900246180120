
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { useParams, withRouter } from "react-router-dom"
import { Container, Card, CardBody} from "reactstrap"
import { Button } from "../../components"
import { LDMDB } from "../../utils/Configs"
import { linkify } from "../../utils/Tools"



const Curso = (props) => {
  const { id, course_name } = useParams();

  const [loading, setLoading] = useState(false)
  const [course, setCourse] = useState({})
  //const convocatorias = useSelector(state => state.convocatoria.data);
  //const solicitudes = useSelector(state => state.solicitud.data);
  //const modal = useRef();
  const dispatch = useDispatch();
  const imgRef = React.useRef()

  const [maxHeight, setMaxHeight] = useState("100%")

  //window.open(curso.src, "DescriptiveWindowName","resizable,scrollbars,status")

  useEffect(() => {
    setLoading(true)
    let cleanup = LDMDB.database().ref("cursos/"+course_name+"/").on("value", function(snapshot) {
      //console.log(snapshot);
      if (snapshot && snapshot.exists()){
        setCourse(snapshot.val())

      }
      
    setLoading(false)
    })
    return () => {
      cleanup()
    }
  }, [course_name])

  useEffect(()=>{

    if (imgRef.current && imgRef.current.clientHeight != maxHeight){
      setMaxHeight(imgRef.current.clientHeight)

    

    }

    if (!imgRef.current || (imgRef.current && !imgRef.current.clientHeight)){
      setMaxHeight("100%")
    }


  })

  const onImgLoad = ({ target: img }) => {
    setMaxHeight(img.height,)
  };

  /*const createSolicitud = (values, validData) => {
    console.log(values, validData)
    
    if (validData){
      setLoading(true)
      dispatch(postSolicitud(values, 
        ()=>{
          setLoading(false)
          dispatch(getSolicituds())
          modal.current.toggle()
          props.history.push("/solicitud")
        }))
    }
  }*/
  
  return (
    <React.Fragment>
      {loading?"":""}
      <div className="page-content mt-6">
        <Container fluid>

          <h2><b>{course.name}</b></h2>
          <Card className={"box-shadow"}>
            
            <CardBody style={{padding:0}}>

            <div className={" d-lg-none"}  style={{flex:1}}>
                    <img src={course.portada} style={{width:"100%", borderRadius:5}}/>
                </div>


              <div style={{display:"flex"}}>

             

                <div style={{flex:1,  padding:40, maxHeight:maxHeight, overflow:"scroll"}}>
                  
                  
                 <div dangerouslySetInnerHTML={{ __html: linkify(course.descripcion ? course.descripcion :"")}}/>  <br/> <br/>
                  
                  {course.materiales ? <hr/>:null}
                  {course.materiales ? 

                    <div style={{color:"grey", marginBottom:10}}>
                      Materiales del curso<br/>
                      {course.materiales.map((l, key)=><a key={key} rel="noreferrer"  href={l.link} target={"_blank"}><i className={"fas fa-link"}/> {l.name} <br/></a>)}
                    
                    </div>
                    
                    :null}


                     <hr/>
                    <div style={{color:"grey", marginBottom:10}}>
                      Videos<br/>
                      {course.grabaciones && course.grabaciones.map((l, key)=><a key={key} rel="noreferrer"  href={l.link} target={"_blank"}><i className={"fas fa-play"}/> {l.name} <br/></a>)}
                      {course.grabaciones ?     null: <div style={{textAlign:"center"}}>No hay videos</div>}
                    </div>

                    <hr/>

                    <Button onClick={()=>{
                      window.open(course.src, "DescriptiveWindowName","resizable,scrollbars,status")
                    }} color={"primary"} block>Iniciar curso</Button>
                </div>
        
                <div className={"flex-shrink-0 d-none d-lg-block "} style={{flex:1.5}}>
                  <img onLoad={onImgLoad} ref={imgRef} src={course.portada} style={{width:"100%", borderBottomRightRadius:5, borderTopRightRadius:5 }}/>
                </div>


       
              </div>
              
            </CardBody>
          </Card>
            
        </Container>
      </div>
    </React.Fragment>
  )
}


Curso.propTypes = {
 
}


export default (withRouter(Curso))



