import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Spinner } from "reactstrap"
import { deletePostSC, getAllPostSC, getContact } from "../store/actions"
import { LDMDB } from "../utils/Configs"
// import { ReactVideo } from "reactjs-media";
import { FirebaseDatabaseNode, FirebaseDatabaseProvider } from "@react-firebase/database"
import Input from "../components/Fields2/Input"
import  Button  from "../components/Buttons/Button"
import { avatar, linkify, validateYouTubeUrl } from "../utils/Tools"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import moment from "moment"
import {  Popover, PopoverBody } from 'reactstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import swal from "sweetalert"
import { useMediaQuery } from "react-responsive"


const Post = (props) => {

  const post = props.post
  const [sharePopover, setSharePopover] = useState(false);

  const currentUser = useSelector(state => state.profile.user);
  const [likes, setLikes] = useState([]);

  const [inputComment, setInputComment] = useState("");

  const dispatch = useDispatch();
  let user = props.user || {}
  const contacts = useSelector(state => state.contact.dictionary);

  const [urlModal, setURLModal] = useState(false);
  const toggleUrlModal  = () => {
    setURLModal(!urlModal)
  }; 

  const [likeModal, setLikeModal] = useState(false);
  const toggleLikeModal = () => {
    setLikeModal(!likeModal)
  };

 


  const [comments, setComments] = useState([]);

  useEffect(() => {
    let cleanup = LDMDB.database().ref("comunidad_virtual/post/"+post.id+"/comments/").on("value", function(snapshot) {
      //console.log(snapshot);
      if (snapshot) {
        let comments = []
        snapshot.forEach(function (d) {
            if (d.key) {
              let value = d.val()
                //console.log("The " + d.key + " data is " + value);
                if (value && value.user_id){
                  getContactDict(value.user_id)
                }

                comments.push(value)

            }
        });
        comments = comments.sort((a,b)=> a.created_at-b.created_at)

        setComments(comments)
    }
    })
    return () => {
      cleanup()
    }
  }, [])





  const deletePost = (id) =>{
    dispatch(deletePostSC(id,(data)=>{
      dispatch(getAllPostSC(1,user.id,(data)=>{
        //console.log(data)
      }))
    }))
  }

  const userHeader = (c, post) =>{
    if (!c){
      return null
    }

    return (
      <div
          
  

          style={{
          display: "flex",
          alignItems: "center",
          margin:0,
          padding:0

      }}>
          <div>
              {c && c.url_photo
                  ? <img
                          className="rounded-circle header-profile-user"
                          src={c.url_photo}
                          style={{
                            objectFit:"cover",
                          height: 40,
                          width: 40
                      }}
                          alt="Header Avatar"/>

                  : <span className={"d-inline-block"}>
                            
<span
                          className={"avatar-title rounded-circle bg-soft-primary text-primary font-size-22"}
                          style={{
                          width: 40,
                          height: 40
                      }}>
                          {c
                              .full_name
                              .charAt(0)}
                      </span>     
                  </span>
}
          </div>

          <div
              style={{
              marginLeft: 10,
              fontSize: 12,
              flex:1
          }}>
              <div><Link to={"/perfil/"+c.id+"/"+c.matricula}>{c.full_name}</Link> compartió una publicación</div>
              <div
                  style={{
                  color: "gray"
              }}>
                <i className={"fa fa-clock"}/> {post.created_at}
              </div>
          </div>

          {c.id == currentUser.id ? <div onClick={()=>deletePost(post.id)} className={"selectable"}><i className={"fas fa-trash p-1"}/></div>:null}
      </div>
 

    )
  }


  const getContactDict = (id) =>{
    
    if (!contacts[id] ){
      dispatch(getContact(id,(data)=>{
        //console.log(data)
      }))
      return false
    }

    return contacts[id]

  }





  const renderPost = (post) => {

    //console.log("Youtube: ", validateYouTubeUrl(post.text) )
    let youtubeVideo = validateYouTubeUrl(post.text)
    if (youtubeVideo){
      post.post_type = "youtube"
    }

    return (
      <div style={{marginTop:10}}>
        <div dangerouslySetInnerHTML={{ __html: linkify(post.text)}}/>
  
        {post.post_type == "image" ? 
          <div>
              <img width={"100%"} style={{borderRadius:5, marginTop:10}}  src={post.resource_url}/>
          </div>
          :post.post_type == "video"?
          // <ReactVideo
  
          // src={post.resource_url}
          //     poster={post.cover_url}
          //     primaryColor={"red"}
              
          //     // other props
          // />
          <></>:post.post_type == "file"?
           <>{post.resource_url && post.resource_url.includes(".pdf") ?  
           
           <div style={{textAlign:"center"}}> <object style={{marginTop:10}} data={post.resource_url} type="application/pdf" width="100%" height="250px">
                 
       </object>
       <a href={post.resource_url} target={"blank"}>Ver archivo</a>
       </div>
       :<div style={{textAlign:"center"}}> 
          <a href={post.resource_url} target={"blank"}>Ver archivo compartido</a></div>}</>:
          post.post_type == "youtube"?
          <iframe
              style={{border:"1px solid #f5f5f5"}}
              frameBorder="0"
              width={"100%"}
              height={300}
              src={'https://www.youtube.com/embed/' + youtubeVideo + '?autoplay=0'}
              primaryColor={"red"}
              
              // other props
          />:null
        }
      </div>
    )
  }


  const likePost = (post, like=true) => {
    if (like){
    LDMDB.database().ref("comunidad_virtual/post/"+post.id+"/likes/l"+currentUser.id ).set({
      user_id: currentUser.id,
      like:true
    },()=>{
  });
}else{
  LDMDB.database().ref("comunidad_virtual/post/"+post.id+"/likes/l"+currentUser.id ).set({
    user_id: currentUser.id,
    like:false
  },()=>{
});
}
  }

  const commentPost = () => {
      if (inputComment && inputComment != ""){
      LDMDB.database().ref("comunidad_virtual/post/"+post.id+"/comments/").push({
        user_id: currentUser.id,
        comment:inputComment,
        created_at:moment.now()
      },()=>{
        setInputComment("")
    });
  }

  }

  const renderLikes= (post) => {
    return (
      <div style={{marginTop:10}}>

              <Modal isOpen={likeModal} toggle={toggleLikeModal}>
                        <ModalHeader toggle={toggleLikeModal} charCode={<i className={"fa fa-times"}/>}>Me gusta</ModalHeader>
                  <ModalBody style={{maxHeight:500, overflowY:"scroll"}}>
                    {
                       
                        likes.map((v, index)=> {
                        return (
                          <div  key={index}>


                            {contacts[v.user_id] ? 
                            <Link to={"/perfil/"+v.user_id+"/"+contacts[v.user_id].matricula} className={"selectable p-1"} style={{display:"flex", alignItems:"center"}}>
                              <div>{avatar(contacts[v.user_id])}</div>
                              <div className={"ms-2"} style={{flex:1}}>{contacts[v.user_id].full_name}</div>
                              
                            </Link>:
                            <div  style={{textAlign:"center"}}><Spinner/></div>}
                            </div>
                          )
      
                      })
                    }

                  </ModalBody>
              </Modal>
              <div style={{display:"flex"}}>
            <FirebaseDatabaseProvider firebase={LDMDB}>
            <FirebaseDatabaseNode
                                    path={"comunidad_virtual/post/"+post.id+"/likes/"}
                                    limitToFirst={10000}
                                    // orderByKey
                                    
                                    > 
                                    
                                      {(d)=>{
                                        //console.log(d)
                                            if (d && d.value && d.value){
                                              let counter = 0
                                              //console.log( d.value)
                                              let likes = []
                                              Object.entries(d.value).forEach(([key, value]) => {
                                                counter += value.like ? 1:0
                                                likes.push(value)
                                         
                                             });
                                            
                                                
                                                return (
                                                  <div>
                                                    
                                                    {counter > 0 ? <div className={"selectable text-primary"} onClick={()=>{
                                                       setLikes(likes)
                                                        likes.map((v)=>{
                                                          getContactDict(v.user_id) 
                                                        })



                                                       toggleLikeModal()
                                                    }}>{counter} me gusta</div>:null}
                                                    
                                                  </div>
                                                )
                                            }else{
                                              return <div></div>
                                            }
                                      }}
                                    </FirebaseDatabaseNode>
                                    </FirebaseDatabaseProvider>
        
                                                  { comments.length > 0 ? <div style={{marginLeft:10}}>
                                                    
                                                    {comments.length  > 0 ? <div  onClick={()=>{
                                                       
                                                    }}>{comments.length } comentario{comments.length  > 1 ? "s":""}</div>:null}
                                                    
                                                  </div>: <div></div>}
                                                 
                                    
              </div>
              
      </div>
    )
    
  }


  const shareOptions = () => {

    const uriWa = `${post.text}...\n\n_Revisa esta publicación completa en https://micasaldm.mx/post/${post.id}_`
    const uri = `${post.text}...\n\nRevisa esta publicación completa en https://micasaldm.mx/post/${post.id}`

    return (
      <React.Fragment>

              <Modal centered isOpen={urlModal} toggle={toggleUrlModal}>
                    <ModalHeader toggle={toggleUrlModal} charCode={<i className={"fa fa-times"}/>}>Vinculo de la publicación</ModalHeader>
          <ModalBody style={{display:"flex", alignItems:"center"}}>
            <div style={{flex:1}} ><Input readonly disabled value={`https://micasaldm.mx/post/${post.id}`}/></div>
            <CopyToClipboard text={`https://micasaldm.mx/post/${post.id}`}
                    onCopy={() => swal("¡Listo!", "Copiamos el vinculo a tu portapapeles", "success", )}
                  >
                  <Button color={"primary"} style={{marginLeft:10}}><i className={"fa fa-copy"} />{"  "}Copiar</Button>
            </CopyToClipboard>

          </ModalBody>
        </Modal>

   
      <div id={"share_option_post_"+post.id} onClick={()=>setSharePopover(!sharePopover)}className={"selectable"} style={{flex:1, padding:3}}>
      <i className={"fas fa-share"} style={{fontSize:14, color:"gray"}}/> Compartir

      </div>
    
        <Popover placement="top" className={"p-0 box-shadow"} target={"share_option_post_"+post.id} isOpen={sharePopover} toggle={()=>setSharePopover(!sharePopover)}>
              <PopoverBody className={"px-1 py-2 font-size-12"}>
                
                  <a href={encodeURI("https://wa.me/?text=" + uriWa)} target={"_blank"} className={"selectable px-2 py-1"} style={{flex:1, color:"unset", display:"flex", alignItems:"center"}} rel="noreferrer">
                    <i className={"fab fa-whatsapp"} style={{fontSize:14, color:"gray"}}/>
                    <div className={"ms-1"} style={{flex:1}}>{"   "}Compartir por WhatsApp</div>
                  </a>
                  <a href={encodeURI("mailto:?body=" + uri)}  className={"selectable px-2 py-1"} style={{flex:1, color:"unset", display:"flex", alignItems:"center"}}>
                    <i className={"fas fa-envelope"} style={{fontSize:14, color:"gray"}}/>
                    <div className={"ms-1"} style={{flex:1}}>{"   "}Compartir por correo</div>
                  </a>
                 
                  <div onClick={()=>{toggleUrlModal();setSharePopover(!sharePopover);}} className={"selectable px-2 py-1"} style={{flex:1, color:"unset", display:"flex", alignItems:"center"}}>
                    <i className={"fas fa-link"} style={{fontSize:14, color:"gray"}}/>
                    <div className={"ms-1"} style={{flex:1}}>{"   "}Link de la publicación</div>
                  </div>
              
              </PopoverBody>
        </Popover>
        
    
      </React.Fragment>
    )
  }

  let smallS = useMediaQuery({ query: `(min-width: 450)` })
  return (


        <React.Fragment>
             <Card className={"box-shadow"}>
                        <CardBody style={{textAlign:"left", padding:10}}>
                           {contacts[post.user_id]? <> {userHeader(contacts[post.user_id], post)}
                            
                            {renderPost(post)}

                            {renderLikes(post)}

                            <hr className={"mt-3 mb-0"}/>
                                    <div
                                        style={{
                                        display:"flex",
                                        marginTop: 5,
                                        paddingBottom: 0,
                                        marginBottom: 0,
                                        fontSize:12,
                                        alignItems:"center",
                                        justifyContent:"center",
                                        textAlign:"center"
                                    }}>
                                      
                                      <FirebaseDatabaseProvider firebase={LDMDB}>   

                                      <FirebaseDatabaseNode
                                            path={"comunidad_virtual/post/"+post.id+"/"}
                                            limitToFirst={10}
                                            > 
                                        {d=>{
                                          //console.log(d)
                                          if (d && d.value && d.value.likes && d.value.likes["l"+currentUser.id]&&  d.value.likes["l"+currentUser.id].like ){
                                          return (
                                            <div  onClick={()=>likePost(post, false)} className={"selectable"} style={{flex:1, padding:3}}>

                                            <div className={"text-primary"}><i className={"fas fa-thumbs-up"} style={{fontSize:14}}/> Me gusta</div>
                                            </div>
                                            )  
                                          }else{

                                            return (
                                              <div  onClick={()=>likePost(post)} className={"selectable"} style={{flex:1, padding:3}}>
                                              <div ><i className={"fas fa-thumbs-up"} style={{fontSize:14, color:"gray"}}/> Me gusta</div>
                                              </div>
                                              )  
                                          }
                                          }}

                                        </FirebaseDatabaseNode>
                               
                                        </FirebaseDatabaseProvider>

                                        <Link to={`/post/${post.id}`} className={"selectable"} style={{flex:1, padding:3}}>
                                        <i className={"fas fa-comment-alt"} style={{fontSize:14, color:"gray"}}/> Comentar

                                        </Link>

                                      {shareOptions()}
                                       

                                    </div>

                                    {comments && comments.length > 0 ? 
                                    <>
                                     <hr style={{margin:5}}/>
                                    {comments.map((c, index)=><div key={index}>
                                     
                                      {contacts[c.user_id]? <div style={{display:"flex", marginTop:10}}> 

                                        {avatar(contacts[c.user_id],40)}

                                        <div style={{backgroundColor:"rgb(242, 242, 245)", marginLeft:10, borderRadius:20, flex:1, padding:5, paddingLeft:20, paddingRight:20}}>
                                          <Link to={"/perfil/"+contacts[c.user_id].id+"/"+contacts[c.user_id].matricula}>{contacts[c.user_id].full_name}</Link><br/>
                                          <span>{c.comment}</span></div>
                                      
                                      </div>:null}
                                      

                                    </div>)}</>:null}
                                    
                                    <hr style={{margin:5}}/>
                                    <div style={{display:"flex", alignItems:"center", marginTop:10}}>
                                                    <input placeholder={"Escribe un comentario..."} onChange={(e)=>setInputComment(e.target.value)} className={"outline-none"} style={{flex:1, borderRadius:100, minHeight:40, minWidth:0, borderWidth:0, backgroundColor:"rgb(242, 242, 245)", padding:5, paddingLeft:20, paddingRight:20, marginRight:10}}/>
                                                    <Button onClick={commentPost} color={"primary"} style={{borderRadius:"100px"}} text={ smallS ? <i className={"fa fa-paper-plane"}></i> : "Comentar"}/>

                                                  </div>
                                    
                                    </>:
                                    <div style={{textAlign:"center"}}><Spinner color={"#1a3192"}/></div>}

                                    

                             
                                        
                        </CardBody>
                    </Card>
        </React.Fragment>
  )
}


Post.propTypes = {
 
}


export default (withRouter(Post))



