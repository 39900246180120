import React from "react"
import { FormGroup, InputGroup, Label } from "reactstrap"
// import RichTextEditor from 'react-rte';

import BaseField from "./BaseField"

class RichText extends BaseField {

    constructor(props) {
        super(props);
        this.state = {
          value: props.value ||  props.default  //|| RichTextEditor.createEmptyValue()
        }
       
      }

      getValue = () =>{
          
          return this.state.value ? this.state.value.toString('html')  :""
      }

      setValue = (value) => {
        this.setState({value:value})
      }

      onChange = (value) => {
        this.setState({value});
      };


    render(){
        const {  label, containerClassName, style } = this.props;

        
        return (
            <FormGroup className={`${containerClassName}`}>
                <Label>{label}{this.props.required ? <span className={"invalid-feedback"} style={{display:"inline", fontSize:14}}> *</span>:null}</Label>
                <InputGroup>
{/*                
                  <RichTextEditor
                  {...this.props}
                  className="w-100"
                  value={this.state.value}
                  onChange={this.onChange}
                  /> */}
                  <></>
                </InputGroup>
                {this.renderErrorMessage()} 
        </FormGroup>
        
        )
    }
}

RichText.propTypes =  Object.assign({}, BaseField.propTypes,   {
 
});

RichText.defaultProps = Object.assign({}, BaseField.defaultProps, {
  disabled:false
});


export default RichText