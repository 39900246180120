import React from "react"
import { logoutUser } from "../store/actions"
import firebase from "firebase/app";
import "firebase/database";



export const APP_NAME = "Tecnológico de Monterrey"
export const APP_SLOGAN = "Líderes del Mañana"

export const ON_SEARCH = (value) => {}
export const DISPLAY_SEARCH = false;
export const LAYOUT_OPTIONS = true;

export const ON_LOGOUT = (dispatch, componetProps) => {
    dispatch(logoutUser(componetProps.history))
}

export const NAVBAR_MENU_ADMIN = [
  
]

export const NAVBAR_MENU = [
    {icon:"fas fa-home", label:"", to:"/comunidad-virtual"},
    //{icon:"fas fa-users", label:"Comunidad virtual", to:"/comunidad-virtual"},
    {icon:"fas fa-file-alt", label:"Mi expediente", to:"/expediente"},
    // {icon:"fas fa-book-open", label:"Guía LDM", to:"/biblioteca-ldm"},
    // {icon:"fas fa-child", label:"Conserva tu beca", to:"/conserva-tu-beca"},

    {icon:"fas fa-film", label:"Videoteca", to:"/videoteca"},

    //{icon:"fas fa-hiking", label:"Liderazgo Positivo", to:"/liderazgo-positivo"},


    //{icon:"fas fa-spa", label:"Bienestar", to:"/bienestar"},
    //Internacionalización
    {icon:"fas fa-briefcase", label:"Vinculación profesional", to:"/vinculacion"},
    // {icon:"fas fa-address-card", label:"Contactos", to:"/contacto"},
    // {icon:"fas fa-hands-helping", label:"Iniciativas y proyectos sociales", to:"/sociales"},
    {icon:"fas fa-hands-helping", label:"Servicio becario", href:"https://bit.ly/serviciobecario-ldm-AD2023"},

]

export const DEFAULT_LAYOUT = {
    layoutType: "horizontal",
    layoutWidth: "fluid",
    leftSideBarTheme: "light",
    leftSideBarType: "default",
    topbarTheme: "light",
    isPreloader: false,
    showRightSidebar: false,
    isMobile: false,
    showSidebar: true,
    leftMenu: false,
  }

  export  const FIREBASE_CONFIG = {
    apiKey: "AIzaSyDYRDwu8YqpOeWsD2W8jEHmJBbhXmrY7fQ",
    authDomain: "lideres-del-mana-1582836347293.firebaseapp.com",
    projectId: "lideres-del-mana-1582836347293",
    storageBucket: "lideres-del-mana-1582836347293.appspot.com",
    messagingSenderId: "800247259147",
    appId: "1:800247259147:web:76895239a494f45fbde70e",
    measurementId: "G-54007ZJXVH",
    databaseURL: "https://lideres-del-mana-1582836347293-default-rtdb.firebaseio.com/"
  };
  
  export const LDMDB2 = firebase.initializeApp(FIREBASE_CONFIG, "COMITES");


  export  const FIREBASE_CONFIG_LDMDB = {
    apiKey: "AIzaSyDYRDwu8YqpOeWsD2W8jEHmJBbhXmrY7fQ",
    authDomain: "lideres-del-mana-1582836347293.firebaseapp.com",
    projectId: "lideres-del-mana-1582836347293",
    storageBucket: "lideres-del-mana-1582836347293.appspot.com",
    messagingSenderId: "800247259147",
    appId: "1:800247259147:web:76895239a494f45fbde70e",
    measurementId: "G-54007ZJXVH",
    databaseURL: "https://ldmdb.firebaseio.com/"
  };

  export const LDMDB = firebase.initializeApp(FIREBASE_CONFIG_LDMDB);

  export const  Loader = ({message}) => {
    return (
      <div id="preloader" style={{display:"block"}}>
      <div id="status" style={{display:"block"}}>
        <div className="spinner-chase">
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
        </div>
      </div>
      <div className={"text-center"} style={{top:"50%", position:"absolute", marginTop:50, width:"100%"}}>{message}</div>

    </div>
    )
  }
