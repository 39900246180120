
import React, { useRef, useState } from "react"
import logo_tec from "../../assets/images/logo-t.png"


import {  withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { API } from "../../utils/backend/api_helper"




const Header = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);
    const [candidato, setCandidato] = useState({})

  
  


  return (
    <React.Fragment>

      <React.Fragment>
        <div className="row align-items-center  px-1 border-bottom bg-white shadow-sm">
            <div className="col-3"><img className="img-fluid"  src={logo_tec}/></div>
            <div className="col-6 text-center"><h3>Sistema de candidatos Líderes del Mañana</h3></div>
            <div className="col-3 justify-content-end text-end d-flex align-items-center">
                
                <div className="me-2 d-none d-sm-block"><i className="fas fa-user me-2"/>{currentUser.full_name} ({currentUser.email})</div>
                <button onClick={()=>{
                   localStorage.removeItem("authToken")
                   localStorage.removeItem("profile")
                   API.get("/auth/logout",()=>{
                       window.location.reload()
                   },()=>{
                    window.location.reload()
                })
            }} className="btn btn-danger text-white">Cerrar sesión</button></div>
        </div>

     
        </React.Fragment>
    </React.Fragment>
  )
}


Header.propTypes = {
 
}


export default (withRouter(Header))



