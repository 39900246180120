
import {
    GET_ALL_CONTACT,
    GET_CONTACT,
    POST_CONTACT,
    PUT_CONTACT,
    DELETE_CONTACT,
    GET_ALL_CONTACT_SUCCESS,
    GET_CONTACT_SUCCESS,
    PUT_CONTACT_LOADING,
    SEARCH_CONTACT,
    GET_CONTACT_PROFILE,
    SEARCH_SUCCESS,
    UPDATE_LIST_VIEW
  } from "./actionTypes"

  export const getAllContact = (page=1,callback=()=>{}, onError=()=>{}) => ({
    type: GET_ALL_CONTACT,
    payload: {callback, onError, page},
  })

  export const getContact = (id="", callback=()=>{}, onError=()=>{}, contacts={}) => ({
    type: GET_CONTACT,
    payload: {id, callback, onError, contacts},
  })

  export const updateListView = (data) => ({
    type: UPDATE_LIST_VIEW,
    payload: {data},
  })

  export const getContactProfile = (id="", callback=()=>{}, onError=()=>{}, contacts={}) => ({
    type: GET_CONTACT_PROFILE,
    payload: {id, callback, onError, contacts},
  })

  export const searchContact = (id="",page=1,size=5, callback=()=>{}, onError=()=>{}, contacts={}) => ({
    type: SEARCH_CONTACT,
    payload: {id, page, size, callback, onError, contacts},
  })

  export const deleteContact = (id="", callback=()=>{}, onError=()=>{}) => ({
    type: DELETE_CONTACT,
    payload: {id, callback, onError},
  })

  export const postContact = (data={}, callback=()=>{}, onError=()=>{}) => ({
    type: POST_CONTACT,
    payload: {data, callback, onError},
  })

  export const putContact = (id="",data={}, callback=()=>{}, onError=()=>{}) => ({
    type: PUT_CONTACT,
    payload: {id, data, callback, onError},
  })

  export const getAllContactSuccess = data => ({
    type: GET_ALL_CONTACT_SUCCESS,
    payload: data,
  })

  export const searchSuccess = data => ({
    type: SEARCH_SUCCESS,
    payload: data,
  })

  
  export const getContactSuccess = data => ({
    type: GET_CONTACT_SUCCESS,
    payload: data,
  })
  

  export const putContactLoading = data => ({
    type: PUT_CONTACT_LOADING,
    payload: data,
  })
