
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import { Container} from "reactstrap"

let COORDINACION = [
  [
    {name:"ABRAHAM REA", puesto: "ATRACCIÓN Y SELECCIÓN DE ALUMNOS", email:"bram@tec.mx", phone:"818-053-8651", photo:"https://s3.amazonaws.com/micasaldm.resources//coordinacion/bram.jpeg"},
    {name:"MARIANA ORTEGA", puesto: "SEGUIMIENTO ACADÉMICO Y SOCIOEMOCIONAL", email:"marianaortega@tec.mx", phone:"811-544-5907", photo:"https://s3.amazonaws.com/micasaldm.resources//coordinacion/mariana.jpeg"},
    {name:"ELIZABETH GUEVARA", puesto: "CULTURA FILANTRÓPICA Y COMPROMISO DE RECIPROCIDAD", email:"e.guevara@tec.mx", phone:"813-403-1333", photo:"https://s3.amazonaws.com/micasaldm.resources//coordinacion/lizzy.jpeg"},
    {name:"JORGE MÉNDEZ", puesto: "APOYO ESTUDIANTIL Y SISTEMAS", email:"jorge@tec.mx", phone:"812-350-8688", photo:"https://s3.amazonaws.com/micasaldm.resources//coordinacion/jorge.jpeg"}

  ],
  [
    {name:"LIBERTAD BERNAL", puesto: "INTERNACIONALIZACIÓN Y VINCULACIÓN PROFESIONAL", email:"libertad@tec.mx", phone:"662-223-2867", photo:"https://s3.amazonaws.com/micasaldm.resources//coordinacion/liber.jpeg"},
    {name:"GRACIELA ARROYO", puesto: "ENRIQUECIMIENTO A LA VIVENCIA", email:"graciela.arroyo@tec.mx", phone:"811-879-0207", photo:"https://s3.amazonaws.com/micasaldm.resources//coordinacion/grace.jpeg"},
    {name:"ROSE ÁLVAREZ", puesto: "ADMINISTRACIÓN Y GESTIÓN", email:"rose.alvarez@tec.mx", phone:"811-798-1495", photo:"https://s3.amazonaws.com/micasaldm.resources//coordinacion/rose.jpeg"},
    // {name:"LAURA RUIZ", puesto: "DIRECTORA DEL PROGRAMA LÍDERES DEL MAÑANA", email:"laruiz@tec.mx", phone:"811-544-5298", photo:"https://s3.amazonaws.com/micasaldm.resources//coordinacion/laruiz.jpeg"}

  ]
]



const Contactos = (props) => {

  const [loading, setLoading] = useState(false)

  //const convocatorias = useSelector(state => state.convocatoria.data);
  //const solicitudes = useSelector(state => state.solicitud.data);
  //const modal = useRef();
  const dispatch = useDispatch();

  useEffect(()=>{
    //dispatch(getConvocatorias())
    //dispatch(getSolicituds())
  },[dispatch])

  /*const createSolicitud = (values, validData) => {
    console.log(values, validData)
    
    if (validData){
      setLoading(true)
      dispatch(postSolicitud(values, 
        ()=>{
          setLoading(false)
          dispatch(getSolicituds())
          modal.current.toggle()
          props.history.push("/solicitud")
        }))
    }
  }*/
  
  return (
    <React.Fragment>
      {loading?"":""}
      <div className="page-content mt-6">
        <Container>
          <div className="fs-2 fw-bold">
          Coordinación Nacional del Programa Líderes del Mañana
          </div>
          <div className="row ">
            {COORDINACION[0].map((c, index)=>
            <div key={index} className="col-12 col-md-6 col-lg-3 d-flex flex-column  p-2 border rounded shadow bg-white border">


             
<div className="row">
              <div className="col-6  rounded ">
                 <img width={"100%"} className="img-fluid rounded" src={c.photo}/>
                 
              </div>

              <div className="d-flex flex-column text-break col-6 pt-2">
                <div className="fw-bold  bg-primary text-white ps-1 pe-1">{c.name}</div>
                <label style={{fontSize:10}}>{c.puesto}</label>
                <div className="d-none mt-auto  d-md-block">
                <div style={{fontSize:10}}>{c.phone}</div>
                <div style={{fontSize:10}}>{c.email}</div>
                </div>
                <div className="row mt-auto d-md-none">
                <div >{c.phone}</div>
                <div>{c.email}</div>
                  <div className="col p-2  text-center selectable">
                    <i className="fas fa-phone"/>
                  </div>

                  <div className="col p-2  text-center selectable">
                    <i className="fas fa-envelope"/>
                  </div>
                </div>
              
              </div>
</div>
              <div className="row text-center mt-auto d-none d-md-flex">
                  <a href={`tel:${c.phone}`} className="col p-2 selectable">
                    <i className="fas fa-phone"/>
                  </a>

                  <a href={`mailto:${c.email}`} className="col p-2 selectable">
                    <i className="fas fa-envelope"/>
                  </a>
                </div>
            </div>)}

          </div>
          <div className="row ">
            {COORDINACION[1].map((c, index)=>
            <div key={index} className="col-12 col-md-6 col-lg-3 d-flex flex-column  p-2 border rounded shadow bg-white border">


             
<div className="row">
              <div className="col-6  rounded ">
                 <img width={"100%"} className="img-fluid rounded" src={c.photo}/>
                 
              </div>

              <div className="d-flex flex-column text-break col-6 pt-2">
                <div className="fw-bold bg-primary text-white ps-1 pe-1">{c.name}</div>
                <label className="f" style={{fontSize:10}}>{c.puesto}</label>
<div className="mt-auto d-none  d-md-block">
                <div style={{fontSize:10}}>{c.phone}</div>
                <div style={{fontSize:10}}>{c.email}</div>
                </div>
                <div className="row  mt-auto d-md-none">
                <div >{c.phone}</div>
                <div >{c.email}</div>
                  <div className="col p-2 text-center selectable">
                    <i className="fas fa-phone"/>
                  </div>

                  <div className="col p-2 text-center selectable">
                    <i className="fas fa-envelope"/>
                  </div>
                </div>
              
              </div>
</div>
              <div className="row text-center mt-auto d-none d-md-flex">
                  <a href={`tel:${c.phone}`} className="col p-2 selectable">
                    <i className="fas fa-phone"/>
                  </a>

                  <a href={`mailto:${c.email}`} className="col p-2 selectable">
                    <i className="fas fa-envelope"/>
                  </a>
                </div>
            </div>)}

          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}




export default (withRouter(Contactos))



