import './App.css';
import PropTypes from 'prop-types'
import React, { Suspense } from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
//import { FirebaseDatabaseProvider } from "@react-firebase/database";

// Import Routes all
import { userRoutes, authRoutes, adminRoutes, candidatosRoutes, candidatosAdminRoutes} from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
// import VerticalLayout from "./components/VerticalLayout/"
// import HorizontalLayout from "./components/HorizontalLayout/"
// import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import { HorizontalLayout, VerticalLayout, NonLayout } from './components';
//import HorizontalLayout from './components/Layout/HorizontalLayout';

import { getProfile } from "./store/actions"
import { NAVBAR_MENU, NAVBAR_MENU_ADMIN } from './utils/Configs';


class App extends React.Component{

  componentDidMount(){
    this.props.getProfile(this.props.history)
  }


  getLayout = () => {
    let layoutCls = VerticalLayout

    switch (this.props.layout.layoutType) {
      case "horizontal":
        return HorizontalLayout

      default:
        return VerticalLayout

    }

  }

  loader = ({message}) => {
    return (
      <div id="preloader" style={{display:"block"}}>
      <div id="status" style={{display:"block"}}>
        <div className="spinner-chase">
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
          <div className="chase-dot"/>
        </div>
      </div>
      <div className={"text-center"} style={{top:"50%", position:"absolute", marginTop:50, width:"100%"}}>{message}</div>

    </div>
    )
  }

  render(){
    const Layout = this.getLayout()
    //Layout.props.userName = this.props.user.full_name
    const Loader = this.loader
    return (



      <React.Fragment>
            <Suspense fallback={<Loader message={"Cargando..."}/>}>
      <Router>
        <Switch>

          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              marginNeg={route.marginNeg}
              layout={NonLayout}
              component={route.component}
              key={"auth"+idx}
            />
          ))}

        {candidatosRoutes.map((route, idx) => (
             <Authmiddleware
             path={route.path}
             authRequired
             candidato
             authError={this.props.authError}
            redirectRoute={"/candidatos/login"}
             omitLayout={route.omitLayout}
             authSuccess={this.props.authSuccess}
             loader={()=><Loader message={"Validando crendenciales..."} />}
             layout={NonLayout}
             component={route.component}
             key={"candidato"+idx}
             exact
           />
          ))}

        {candidatosAdminRoutes.map((route, idx) => (
             <Authmiddleware
             path={route.path}
             authRequired
             candidato
             candidatoAdmin
             authError={this.props.authError}
              redirectRoute={"/candidatos/admin/login"}
             omitLayout={route.omitLayout}
             authSuccess={this.props.authSuccess}
             loader={()=><Loader message={"Validando crendenciales..."} />}
             layout={NonLayout}
             component={route.component}
             key={"candidato"+idx}
             exact
           />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              authRequired
              authError={this.props.authError}
              layoutProps={{
                navMenu: NAVBAR_MENU
              }}
              omitLayout={route.omitLayout}
              authSuccess={this.props.authSuccess}
              loader={()=><Loader message={"Validando crendenciales..."} />}
              layout={Layout}
              component={route.component}
              key={"user"+idx}
              exact
            />
          ))}

        {adminRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              authRequired
              layoutProps={{
                navMenu: NAVBAR_MENU_ADMIN
              }}
              authError={this.props.authError}
              authSuccess={this.props.authSuccess}
              admin={true}
              loader={()=><Loader message={"Validando crendenciales..."} />}
              layout={Layout}
              component={route.component}
              key={"admin"+idx}
              exact
            />
          ))}
          
        </Switch>
      </Router>
      </Suspense>
    </React.Fragment>

    );
  }
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = (state) => ({ 
  authLoading: state.profile.loading,
  authSuccess: state.profile.success,
  authError: state.profile.error,
  layout: state.Layout,
  user: state.profile.user
})

export default withRouter(
  connect(mapStateToProps, { getProfile })(App)
)
