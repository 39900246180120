import React, { useEffect, useState } from 'react';


import logo1 from "../../assets/images/logo-dark1.png"
import logo2 from "../../assets/images/logo-dark2.png"

import { useMediaQuery } from 'react-responsive';
import Select from '../../components/Fields2/Select';
import { Loader } from '../../utils/Configs';

const fontStyle = {}


const folders = [

    {label:"30/JUNIO/2022", value:"JUNIO+30", imgs:91, prefix:"seleccion-ldm-grad-ver22-"},
    {label:"01/JULIO/2022", value:"JULIO+01", imgs:96, prefix:"ldm-grad-ver22-1jul-"},
    {label:"14/DICIEMBRE/2022", value:"life-14-dic-22", imgs:101, prefix:"ldm_grad_inv22_dic14-"},
    {label:"15/DICIEMBRE/2022", value:"15-dic-22", imgs:122, prefix:"ldm_grad_inv22_dic15-"},
    {label:"16/DICIEMBRE/2022", value:"16-dic-22", imgs:65, prefix:"ldm_grad_inv22_dic16-"},

]
const campus =
    [{label:"Monterrey", value:"MTY"}]

    


const Divider = ({props}) => {
    return (
        <div style={{margin:1}} {...props} />
    )
}


function download(url, name="foto.jpg") {
    
    const a = document.createElement("a");
        a.href = url;
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();
}

const MuroFotos = () => {
    const [loading, setLoading] = useState(false)
    const [videos, setVideos] = useState([])
    const [galeria, setGaleria] = useState("all")

    const [foto, setFoto] = useState("")
        useEffect(()=>{
            const baseurl = "https://s3.amazonaws.com/micasaldm.resources/graduados"
            let total = []

            setLoading(true)
            folders.map((folder)=>{
                if ((folder.value == galeria || galeria == "all") && folder.imgs) {
                    let files = folder.imgs
                    let counter = 1
                    while(counter <= files){
                        total.push(`${baseurl}/${folder.value}/${folder.prefix}${counter}.jpg`)
                        counter = counter + 1

                    }
                }
            })
            setTimeout(()=>{
                setLoading(false)
            }
            , 3000)


            setVideos(total)



        },[galeria])


        let mod600 = useMediaQuery({ query: `(min-width: 800px)` })
        let mod800 = useMediaQuery({ query: `(min-width: 1000px)` }) 
        return (
            <React.Fragment>
                {loading ? <Loader message={"Cargando..."}/> : null}
                   <div className="p-2 pt-3 d-md-none" style={{backgroundColor:"rgb(4,14,75)"}}>
                <div style={{display:"flex", flexDirection:"row", alignContent:"center", alignItems:"center"}}>
                    <div style={{flex:1,textAlign:"start"}}>
                        <img src={logo1} alt="" height={45} si/>
                    </div>
                
                    <div  style={{flex:1, textAlign:"end", justifyContent:"end"}}>
                    <img src={logo2} alt="" height={40}/>
                    </div>
                </div>
            </div>
            <div className="p-2 pt-3 pb-3" style={{backgroundColor:"rgb(4,14,75)"}}>
                <div style={{display:"flex", flexDirection:"row", alignContent:"center", alignItems:"center"}}>
                    <div className='d-none d-md-block' style={{flex:1,textAlign:"center"}}>
                        <img src={logo1} alt="" height={45}/>
                    </div>
                    <div  style={{flex:2,textAlign:"center"}}>
                            <span className='gold-text'>Galería EXATEC LDM<br/></span>
  
                            <span className=' glow cursiva'>¡El Tec siempre será tu casa!</span>

                    </div>
                    <div className='d-none d-md-block' style={{flex:1, textAlign:"center"}}>
                    <img src={logo2} alt="" height={50}/>
                    </div>
                </div>
            </div>

            <div className="account-pages">
<div className='p-2 row'>
   
    <Select className="w-100" onChange={setGaleria} default={folders[folders.length - 1].value} label="Fecha de graduación:" containerClassName="row col-12 col-md-6"  options={folders} />
    <Select className="w-100" default="MTY" label="Campus" containerClassName="row col-12 col-md-6"  options={campus} />

</div>
                <div>
            <div style={{marginBottom:"128px"}}>
            <div className='myGallery'
      >
        {videos.map((video, index)=>{
            const arr = [200, 300, 220, 350, 400]
                    function random(mn, mx) {
                        return Math.random() * (mx - mn) + mn;
                    }
                     
            return (
               
                
                <img id={`img-${index}`} onLoad={()=>document.getElementById(`img-${index}`).classList.remove("active")} className={"active"} key={index} data-bs-toggle="modal"  data-bs-target="#exampleModal" onClick={()=>setFoto(video)} loading="lazy" src={video} width={"100%"} style={{objectFit:"cover"}}>
          
                    </img>
   
            )
        })}
  
      </div>
                 
            </div>
   
                       </div> 
                       </div>
                       <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl">
    <div className="modal-content bg-dark-transparent" style={{backgroundColor:"black"}}>

      <div className="text-white d-flex  align-items-center">
        <div className='text-white flex-fill ps-3'><i className='fas fa-image'/>{" "}{foto.split("/")[foto.split("/").length - 1]}</div>
        <a href={foto} target={"_blank"} download={foto} rel="noreferrer"className='btn text-white fs-4' ><i className='fas fa-download'/></a>
        <button type="button" className="btn text-white fs-3" data-bs-dismiss="modal" aria-label="Close"><i className='fas fa-times'/></button>
      </div>
      <div className="modal-body">
        <img src={foto} height={"100%"} className={"img-fluid"}/>
      </div>
 
    </div>
  </div>
</div>
                       </React.Fragment>
        );

}

export default MuroFotos