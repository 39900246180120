import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import {  withRouter } from "react-router-dom"
import { Card, CardBody} from "reactstrap"
import { Button } from "../../../components"

import List from "../../../components/Table/List"
import { LDMDB, Loader } from "../../../utils/Configs"
import exportFromJSON from 'export-from-json'
import { API } from "../../../utils/backend/api_helper"
import { CAMPUS_2 as CAMPUS, getValue } from "../../../utils/Tools"
import Header from "../../Candidatos/Header"



const statusFormatter = (value, row)=><div className={"text-center"}>{value ? <i className="fas fa-check text-success"/>:<i className="fas fa-times text-danger"/>}</div>
const xlsStatusFormatter = (value, row)=>`${value ? "COMPLETO":"INCOMPLETO"}`


const defaultFormatter = (v)=>{
     return Array.isArray(v) ? v.join(' / ') : (v ? v : "") ;
}

const HEADERS = [
    {
        "label": "Completo",
        "key": "q1",
        formatter:statusFormatter,
        xlsFormatter:xlsStatusFormatter
    },
    {
        "label": "Matricula (Sobrecargado)",
        "key": "matricula",
        formatter: defaultFormatter
    },
    {
        "label": "Nombre (Sobrecargado)",
        "key": "full_name",
        formatter: defaultFormatter
    },
    {
        "label": "Email (Sobrecargado)",
        "key": "email",
        formatter: defaultFormatter
    },
    {
        "label": "Campus (Sobrecargado)",
        "key": "campus",
        formatter: defaultFormatter
    },
    {
        "label": "Expectativas",
        "key": "expectativas",
        formatter: defaultFormatter
    },
    {
        "label": "Logro 1",
        "key": "l1",
        formatter: defaultFormatter
    },
    {
        "label": "Logro 2",
        "key": "l2",
        formatter: defaultFormatter
    },
    {
        "label": "Logro 3",
        "key": "l3",
        formatter: defaultFormatter
    },
    {
        "label": "Logro 4",
        "key": "l4",
        formatter: defaultFormatter
    },
    {
        "label": "Logro 5",
        "key": "l5",
        formatter: defaultFormatter
    },
    {
        "label": "Logro 6",
        "key": "l6",
        formatter: defaultFormatter
    },
    {
        "label": "Logro 7",
        "key": "l7",
        formatter: defaultFormatter
    },
    {
        "label": "Logro 8",
        "key": "l8",
        formatter: defaultFormatter
    },
    {
        "label": "Logro 9",
        "key": "l9",
        formatter: defaultFormatter
    },
    {
        "label": "Logro 10",
        "key": "l10",
        formatter: defaultFormatter
    }
]
const LogrosView = (props) => {

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [campus, setCampus] = useState("all")

  const [listInfo, setlistInfo] = useState({items:[]})

  const dispatch = useDispatch();

  const getCandidatos = (page, size, campus="all") => {
    setLoading(true)



    API.get(
      `/candidato/p/${page}/${size}/${campus}`, 
      (response)=>{
        console.log(response)
        let count = 0
        response.data.items.map((d, index)=>{
          var query = LDMDB
          .database()
          .ref("answers/"+"logros-24"+ "/" + "candidato-"+d.id +"/"+"logros"+"/").once('value').then(v=>{
            console.log(v.val())
            count =  count + 1;
            response.data.items[index] = {...response.data.items[index], ...v.val()}
            if (count ==  response.data.items.length){
              setlistInfo(response.data)
              setLoading(false)
            }
   
          });
    


        })

        

      },
      (response)=>{
        setLoading(false)
      }
    )


    
  }

  useEffect(()=>{
    getCandidatos(1,25)
   
  },[])

  const onChangePage = (page, nextPage) => {
      //console.log(page,nextPage)
      getCandidatos(nextPage, 25, campus)

  }

  const onChangeCampus = (e) => {
    setCampus(e.target.value)
    setTimeout(()=>{
      getCandidatos(listInfo.page, 25, e.target.value)

    },500)

}


  const donwloadData = () => {
    const fileName = `Candidatos Comité Nacional 2022${campus == "all" ? "":" - " + campus} (Cuestionario de Vulnerabilidad)`
    const exportType =  exportFromJSON.types.xls
    const data = []

    setLoading(true)
    setMessage("Descargando datos...")



    API.get(
      `/candidato/p/1/10000/${campus}`, 
      (response)=>{
        console.log(response)
        let count = 0
        response.data.items.map((d, index)=>{
          var query = LDMDB
          .database()
          .ref("answers/"+"logros-24"+ "/" + "candidato-"+d.id +"/"+"logros"+"/").once('value').then(v=>{
            console.log(v.val())
            count =  count + 1;
            response.data.items[index] = {...response.data.items[index], ...v.val()}
            if (count ==  response.data.items.length){
              console.log(response.data)

                response.data.items.map(((item)=>{

                    let row = {}
                    console.log("22")
                    HEADERS.map((header)=>{
                        let value = ""
            
                        if (header.xlsFormatter ){
                            value = header.xlsFormatter(getValue(item, header.key), item)
                        }else{
                            value = header.formatter(getValue(item, header.key), item)
                        }
                   
                        let label = header.label
   
      
                        // if (row[label]){
                        //     console.log(row[label])
                        //     let c = 1
                        //     let newlabel = label + " " + c
            
                        //     while(row[newlabel]){
                        //         c = c + 1;
                        //         newlabel = label + " " +c
                        //     }
                        //     label = newlabel
                        // }
                        
                        row[label] = value
                        
                
            
                    })
                    data.push(row)
                }))
                exportFromJSON({ data: data, fileName, exportType})

                setLoading(false)
            }
   
          });
    


        })

        

      },
      (response)=>{
        setLoading(false)
      }
    )

 
   
    

  }

  return (
    <div >
         <Header/>
         <div className="container-fluid mt-4">
         
      {loading?<Loader message={message}/> :""}

      

      <div className="d-flex align-items-center">
          <div className="flex-fill"><h3><i className="fas fa-star me-2"/>Logros LDM</h3>
          <div>
          <select onChange={onChangeCampus} className="form-select" aria-label="Campus" defaultValue={"all"}>
            <option value="all">Todos los campus</option>
            
            {CAMPUS.map((v, key)=>(
              <option key={key} value={v.campus}>{v.campus}</option>
            ))}
          </select>
          </div>
          </div>
          <div className="ms-3">
         {listInfo ? <Button onClick={donwloadData}  icon={"fas fa-download"} color={"primary"}>Descargar</Button>:null}
         </div>
      </div>

        <div className={"mt-2"}/>
        <Card className={"box-shadow mt-0 pt-0"}>
                
                <CardBody className={"mt-2 pt-0"}>

      <List
        onChangePage={onChangePage}
        hasPagination={true}
        pagination={listInfo}
        data={listInfo ? listInfo.items : []}
        headers={HEADERS}
      />
      </CardBody></Card>
      </div>
    </div>
  )
}


LogrosView.propTypes = {
 
}


export default (withRouter(LogrosView))



