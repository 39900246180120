
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import { Loader } from "../../utils/Configs"
import StackGrid from "react-stack-grid";
import { useMediaQuery } from "react-responsive";
import { API } from "../../utils/backend/api_helper";



const Videoteca = (props) => {

  const [loading, setLoading] = useState(false)

  const [page, setPage] = useState(1)
  const [videos, setVideos] = useState([])
  let mod600 = useMediaQuery({ query: `(min-width: 800px)` })
  let mod800 = useMediaQuery({ query: `(min-width: 1000px)` }) 

  //const convocatorias = useSelector(state => state.convocatoria.data);
  //const solicitudes = useSelector(state => state.solicitud.data);
  //const modal = useRef();
  const dispatch = useDispatch();

  useEffect(()=>{
    setLoading(true)
    API.get(`/videoteca/pag/${page}/10`,
    (response)=>{
      setLoading(false)
      setVideos(response.data.items)
    },
    ()=>{
      setLoading(false)
    })
    
  },[page])

  /*const createSolicitud = (values, validData) => {
    console.log(values, validData)
    
    if (validData){
      setLoading(true)
      dispatch(postSolicitud(values, 
        ()=>{
          setLoading(false)
          dispatch(getSolicituds())
          modal.current.toggle()
          props.history.push("/solicitud")
        }))
    }
  }*/

  const selectVideo = (item) =>{

    switch (item.video_type) {
      case "FACEBOOK":
        return (<iframe className={"align-items-center justify-content-center"} src={`https://www.facebook.com/plugins/video.php?href=${item.url}&show_text=false&height=280&appId`} width="100%" height="280" scrolling="no" style={{border:"none", overflow:"hidden"}} frameBorder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>)

      case "YOUTUBE":
        return (<iframe width="100%" height="280" src={item.url} frameBorder="0" allowFullScreen="true" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" scrolling="no" style={{border:"none", overflow:"hidden"}}></iframe>)
     
      case "DRIVE":
        return (<iframe src={item.url} width="100%" height="280" frameBorder="0" allowFullScreen="true" allow="autoplay" scrolling="no" style={{border:"none", overflow:"hidden"}}></iframe>)
      default:
        return "Video no soportado"
    
    }

  }
 
  return (
    <React.Fragment>
            <div className="page-content mt-6">

      {loading?<Loader message={"Cargando"}/>:""}
      <h2><i className={"fas fa-film"}/> <b> Videoteca</b></h2>

      <StackGrid  gutterWidth={3} gutterHeight={2} 
                           columnWidth={mod800 ?'33.33%': mod600? "50%": '100%'  }
                        
                          >
                    {videos.map((item, key) =>
                        <div key={key}>
                          <div style={{backgroundColor:"black"}}>


          {selectVideo(item)}
          
      </div>

      </div>
           )}
</StackGrid>
      </div>
    </React.Fragment>
  )
}


Videoteca.propTypes = {
 
}


export default (withRouter(Videoteca))



