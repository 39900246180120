
import React, {   useRef, useState } from "react"

import {  Link } from "react-router-dom"

import {   useHistory, withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"

// import { API } from "../../utils/backend/api_helper"
// import swal from "sweetalert"
import { LDMDB, Loader } from "../../utils/Configs"

import Form from "../../components/Form/Form"
import Button from "../../components/Buttons/Button"
import Header from "./Header"
import RadioGroup from "../../components/Fields2/RadioGroup"
import Select from "../../components/Fields2/Select"
import Input from "../../components/Fields2/Input"
import CheckboxGroup from "../../components/Fields2/CheckboxGroup"
import {  toNumber } from "lodash"
import MaskInput from "../../components/Fields2/MaskInput"
import {  CAMPUS_2, ESTADOS } from "../../utils/Tools"
import TextArea from "../../components/Fields2/TextArea"

const GRADO_ESTUDIO = [
  {value:"Primaria pública", label:"Primaria pública"},
  {value:"Primaria privada", label:"Primaria privada"},
  {value:"Secundaria pública", label:"Secundaria pública"},
  {value:"Secundaria privada", label:"Secundaria privada"},
  {value:"Preparatoria o Bachillerato pública", label:"Preparatoria o Bachillerato pública"},
  {value:"Preparatoria o Bachillerato privada", label:"Preparatoria o Bachillerato privada"},

  {value:"Normal Superior", label:"Normal Superior"},
  {value:"Profesional (Licenciatura / Ingeniería) pública", label:"Profesional pública"},
  {value:"Profesional (Licenciatura / Ingeniería) privada", label:"Profesional privada"},

  {value:"Posgrado", label:"Posgrado"},
  {value:"Ninguno", label:"Ninguno"}
]

const GRADO_ESTUDIO_2 = [
  {value:"Normal Superior", label:"Normal Superior"},
  {value:"Profesional (Licenciatura / Ingeniería) pública", label:"Profesional pública"},
  {value:"Profesional (Licenciatura / Ingeniería) privada", label:"Profesional privada"},
  {value:"Posgrado", label:"Posgrado"},
  {value:"Ninguno", label:"Ninguno"}
]

const OCUPACION = [
  {value:"Empleo remunerado", label:"Empleo remunerado"},
  {value:"Negocio propio", label:"Negocio propio"},
  {value:"Trabajo en el campo (tierras propias)", label:"Trabajo en el campo (tierras propias)"},
  {value:"Trabajo en el campo (tierras no propias)", label:"Trabajo en el campo (tierras no propias)"},
  {value:"Vetas de productos por catalogo", label:"Vetas de productos por catalogo"},
  {value:"No trabaja", label:"No trabaja"}

]

const SI_NO = [
  {label:"Sí", value:"Sí"},
  {label:"No", value:"No"}
]

const APOYOS = [
  {label:"Becas educativas", value:"Becas educativas"},
  {label:"Pensión para adultos mayores", value:"Pensión para adultos mayores"},
  {label:"Apoyos para el campo", value:"Apoyos para el campo"},
  {label:"Apoyos para madres solteras", value:"Apoyos para madres solteras"},
  {label:"Microcréditos", value:"Microcréditos"},
  {label:"Remesas (dinero que les mande alguien del extrajero)", value:"Remesas"},
  {label:"Ninguno", value:"Ninguno"},

]

const PRESTACIONES = [
  {label:"Servicio médico", value:"Servicio médico"},
  {label:"Sistema de Ahorro para el Retiro (SAR) o AFORE", value:"Sistema de Ahorro para el Retiro (SAR) o AFORE"},
  {label:"Incapacidad laboral con goce de sueldo", value:"Incapacidad laboral con goce de sueldo"},
  {label:"Jubliación o pensión", value:"Jubliación o pensión"},

  {label:"Ninguno", value:"Ninguno"},

]

const TIPO_OCUPACION = [
  {label:"Servicios domésticos", value:"Servicios domésticos"},
  {label:"Actividades el campo (agrícolas, ganaderas, etc.)", value:"Actividades el campo (agrícolas, ganaderas, etc.)"},
  {label:"Obrero", value:"Obrero"},
  {label:"Actividades adminstrativas", value:"Actividades adminstrativas"},
  {label:"Empleado del gobierno", value:"Empleado del gobierno"},
  {label:"Comerciante", value:"Comerciante"},
  {value:"Empleado", label:"Empleado"},
  {label:"Empresarios", value:"Empresarios"},
  {label:"Funcionario público (puesto directivo)", value:"Funcionario público (puesto directivo)"},
  {label:"Gerentes", value:"Gerentes"},
  {label:"No aplica", value:"No aplica"}

]

const TIPO_VIVIENDA = [
  {label:"Propia", value:"Propia"},
  {label:"Rentada", value:"Rentada"},
  {label:"Prestada", value:"Prestada"},

]

const NUMBERS = [
  {label:"1", value:"1"},
  {label:"2", value:"2"},
  {label:"3", value:"3"},
  {label:"4", value:"4"},
  {label:"5", value:"5"},
  {label:"6", value:"6"},
  {label:"7", value:"7"},
  {label:"8", value:"8"},
  {label:"9", value:"9"},
  {label:"10", value:"10"},
  {label:"11", value:"11"},
  {label:"12", value:"12"},
  {label:"13", value:"13"},
  {label:"14", value:"14"},
  {label:"15 o más", value:"15 o más"},
]

const NUMBERS2 = [
  {label:"1", value:"1"},
  {label:"2", value:"2"},
  {label:"3", value:"3"},
  {label:"4", value:"4"},
  {label:"5", value:"5"},
  {label:"6 o más", value:"6 o más"},
]

const CARCASA = [
  {label:"Piso firme de cemento o con recubrimiento (laminado, mosaico, madera);", value:"Piso firme de cemento o con recubrimiento (laminado, mosaico, madera);"},
  {label:"Techos de losa de concreto o viguetas con bovedilla, madera, terrado con viguería, lámina metálica, de asbesto, palma, teja, o de calidad superior;", value:"Techos de losa de concreto o viguetas con bovedilla, madera, terrado con viguería, lámina metálica, de asbesto, palma, teja, o de calidad superior;"},
  {label:"Muros de tabique, ladrillo, block, piedra, concreto, madera, adobe, o de calidad superior", value:"Muros de tabique, ladrillo, block, piedra, concreto, madera, adobe, o de calidad superior"},


]


const SERVICIOS = [
  {label:"Agua entubada dentro de la vivienda o fuera de la vivienda, pero dentro del terreno", value:"Agua entubada dentro de la vivienda o fuera de la vivienda, pero dentro del terreno"},
  {label:"Drenaje conectado a la red pública o a una fosa séptica", value:"Drenaje conectado a la red pública o a una fosa séptica"},
  {label:"Electricidad obtenida del servicio público, de panel solar o de otra fuente, planta particular", value:"Electricidad obtenida del servicio público, de panel solar o de otra fuente, planta particular"},
  {label:"Principal combustible para cocina es GAS NATURAL", value:"Principal combustible para cocina es GAS NATURAL"},
  {label:"Principal combustible para cocina es GAS LP", value:"Principal combustible para cocina es GAS LP"},
  {label:"Principal combustible para cocina es ELECTRICIDAD", value:"Principal combustible para cocina es ELECTRICIDAD"},
  {label:"Principal combustible para cocina es LEÑA o CARBÓN", value:"Principal combustible para cocina es LEÑA o CARBÓN"},


]

const FORANEO = [
  {label:"Foráneo, el campus está en otra ciudad diferente a mi ciudad de origen", value:"Foráneo, el campus está en otra ciudad diferente a mi ciudad de origen"},
  {label:"Local, el campus está en la ciudad donde vivo", value:"Local, el campus está en la ciudad donde vivo"},

]

const ROOMIES = [
  {label:"Mis padres", value:"Mis padres"},
  {label:"Familiares", value:"Familiares"},
  {label:"Amigos/Compañeros de cuarto", value:"Amigos/Compañeros de cuarto"},
  {label:"Viviré Sol@", value:"Viviré Sol@"},
]

const INGRESOSIND = [
  {label:"Mis padres o tutor", value:"Mis padres o tutor"},
  {label:"Familia externa (tíos, abuelos, entre otros)", value:"Familia externa (tíos, abuelos, entre otros)"},
  {label:"Patrocinador empresarial", value:"Patrocinador empresarial"},
  {label:"Apoyo gubernamental", value:"Apoyo gubernamental"},
  {label:"Trabajo en tiempos libres", value:"Trabajo en tiempos libres"},
  {label:"Apoyo de vida de redes filantrópicas (organizaciones civiles, fundaciones o sociedad civil)", value:"Apoyo de vida de redes filantrópicas (organizaciones civiles, fundaciones o sociedad civil)"},


]

const COMIDAS = [
  {label:"Mi casa, se cocina todos los días", value:"Mi casa, se cocina todos los días"},
  {label:"Mi casa, compramos comida", value:"Mi casa, compramos comida"},
  {label:"Fuera de mi casa en algún local de comida", value:"Fuera de mi casa en algún local de comida"},
  {label:"En mi escuela", value:"En mi escuela"},
]

const ULTIMOSMESES = [
  {label:"Considero que comí bien / comí lo suficiente", value:"Considero que comí bien / comí lo suficiente"},
  {label:"Tuve una alimentación basada en muy poca variedad de alimentos", value:"Tuve una alimentación basada en muy poca variedad de alimentos"},
  {label:"Dejé de desayunar, comer o cenar", value:"Dejé de desayunar, comer o cenar"},
  {label:"Comí menos de lo que pienso que debo comer", value:"Comí menos de lo que pienso que debo comer"},
  {label:"Me quedé sin comida", value:"Me quedé sin comida."},
  {label:"Sentí hambre pero no comí", value:"Sentí hambre pero no comí"},
  {label:"Comí una vez al día o dejé de comer todo un día", value:"Comí una vez al día o dejé de comer todo un día"},
]

const TRANSPORTE = [
  {label:"Transporte público (metro, camión, metrobus, entre otros)", value:"Transporte público (metro, camión, metrobus, entre otros)"},
  {label:"Vehículo particular (familiar o propio)", value:"Vehículo particular (familiar o propio)"},
  {label:"Bicicleta", value:"Bicicleta"},
  {label:"Caminando", value:"Caminando"},

]

const TIEMPOTRANSPORTE = [
  {label:"Menos de 15 minutos", value:"Menos de 15 minutos"},
  {label:"De 15 a 30 minutos", value:"De 15 a 30 minutos"},
  {label:"De 30 minutos a 1 hora", value:"De 30 minutos a 1 hora"},
  {label:"De 1 a 2 horas", value:"De 1 a 2 horas"},
  {label:"Más de 2 horas", value:"Más de 2 horas"},

]

const ENDONDETRABAJAS = [
  {label:"Trabajo en campus", value:"Trabajo en campus"},
  {label:"Prácticas profesionales en el exterior", value:"Prácticas profesionales en el exterior"},
  {label:"Otro trabajo no relacionado con mi profesión", value:"Otro trabajo no relacionado con mi profesión"},
  {label:"Negocio familiar", value:"Negocio familiar"}
]

const  SEGUROS = [
  {label:"IMSS", value:"IMSS"},
  {label:"ISSSTE", value:"ISSSTE"},
  {label:"Seguro popular", value:"Seguro popular"},
  {label:"Seguro privado de gastos médicos menores", value:"Seguro privado de gastos médicos menores"},
  {label:"SGMM (por mamá o papá)", value:"SGMM (por mamá o papá)"},
  {label:"No cuento con uno", value:"No cuento con uno"},

]

const EAST = ESTADOS.map((v)=>{ return {"label":v.name,"value":v.name} })

const totalDict = {}
const totalDic2 = {}
const TakeLogros = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);
 
    const [hermanos, setHermanos] = useState(false)
    const [empleo, setEmpleo] = useState(false)
    const [beca, setBeca] = useState(false)
    const [hermanosUni, setHermanosUni] = useState(false)

    const [values, setValues] = useState({})
    const history = useHistory()


    const calcTotal = (key, value) => {
      let tot = 0
      
      totalDict[key] = value

      Object.keys(totalDict).map((k)=>{
 
        tot = tot + toNumber(totalDict[k])
        //values[k] =  toNumber(totalDict[k]).toFixed(2)

      })

      values["gtotal"] = tot.toFixed(2)
      
      const values2 = JSON.parse(JSON.stringify(values))

      form.current.fill(values2)
    }

    const calcTotal2 = (key, value) => {
      let tot = 0
      
      totalDic2[key] = value

      Object.keys(totalDic2).map((k)=>{
 
        tot = tot + toNumber(totalDic2[k])
        //values[k] =  toNumber(totalDict[k]).toFixed(2)

      })

      values["gitotal"] = tot.toFixed(2)
      
      const values2 = JSON.parse(JSON.stringify(values))

      form.current.fill(values2)
    }
  
    const submit = (values, valid) =>{

      let arr = []
      Object.keys(values).map((k)=>{
        arr.push(values[k])
      })
      console.log(arr)
      if (valid){
        setLoading(true)
        LDMDB.database().ref("answers/"+"logros-24"+ "/" + "candidato-"+currentUser.id +"/"+"logros"+"/" ).set(values,()=>{
          setLoading(false)
          window.location.href = "/candidatos/home"
    
        
        });
  
      }



    }


  return (
    <React.Fragment >

      {loading ? <Loader message={"Enviando..."} />:null}
      
      <div className="vh-100">
        <Header/>

        <div className="container my-4  h-100 ">
            <div className="card shadow-sm">
            <div className="card-body p-0">

              <div className="rounded-top ">
              <h3 className="fw-bold bg-primary  rounded-top text-white p-4"> Logros y Expectativas a Futuro</h3>
                <p className=" fs-5 fs-md-4 p-4">
                Para continuar con la evaluación de tu perfil, solicitamos que nos brinde detalles sobre sus logros y expectativas a futuro según las siguientes indicaciones:   
                <hr/>
                <div class="container">
        <h3>Logros</h3>
        <p>Describa entre 5 y 10 logros significativos que haya tenido en su vida académica, personal, deportiva, artística, o cualquier otro ámbito relevante. Cada logro debe tener un máximo de 275 caracteres, incluyendo espacios y puntuación.</p>
        
        <h4>Ejemplos de Logros</h4>
        <div class="examples">
            <ul>
                <li>Gan&eacute; el primer lugar en la Feria de Ciencias de mi escuela por un experimento sobre energ&iacute;a renovable.</li>
                <li>Fui capit&aacute;n del equipo de baloncesto y lider&eacute; al equipo a ganar el campeonato estatal.</li>
                <li>Inici&eacute; una campa&ntilde;a de reciclaje en mi escuela que redujo el uso de pl&aacute;stico en un 30%.</li>
                <li>Organic&eacute; un evento ben&eacute;fico para recaudar fondos para una fundaci&oacute;n infantil, logrando recaudar m&aacute;s de $5,000.</li>
                <li>Escrib&iacute; y publiqu&eacute; un libro de poes&iacute;a que fue bien recibido por mis compa&ntilde;eros de clase y profesores.</li>
            </ul>
        </div>

        <p><strong>Indicación para los Candidatos:</strong> Asegúrese de incluir detalles concretos y resultados tangibles. Si tiene logros relacionados con actividades extracurriculares, liderazgo, servicio comunitario o creatividad, también son válidos.</p>
    </div>

    <div class="container">
        <h3>Expectativas a Futuro</h3>
        <p>Describa sus expectativas y metas para el futuro, con un límite máximo de 450 caracteres, incluyendo espacios y puntuación.</p>
      
        <h4>Ejemplos de Expectativas a Futuro</h4>
        <div class="examples">
            <ul>
                <li>Aspiro a estudiar ingeniería y crear soluciones innovadoras para problemas ambientales. Planeo seguir desarrollando mis habilidades de liderazgo para guiar a otros en iniciativas ecológicas.</li>
                <li>Quiero convertirme en médico para ayudar a personas necesitadas. Mi objetivo es estudiar medicina en una universidad de prestigio y especializarme en cirugía.</li>
                <li>Sueño con ser un líder empresarial y emprendedor. Planeo obtener un MBA y fundar mi propia empresa de tecnología para crear empleo y contribuir al desarrollo económico de mi comunidad.</li>
            </ul>
        </div>

        <p><strong>Indicación para los Candidatos:</strong> Presente sus objetivos a largo plazo, destacando sus ambiciones y cómo espera lograrlas. Sea realista pero ambicioso, mostrando su visión para el futuro y su compromiso con el crecimiento personal y profesional.</p>
    </div> 
    <hr/>
      

      <p>Con estas indicaciones, esperamos obtener una visión clara de sus logros hasta ahora y sus planes para el futuro. Esto nos ayudará a tomar una decisión informada sobre su candidatura para la beca. Si tiene alguna pregunta, no dude en comunicarse con nosotros. ¡Buena suerte!</p>
              
                </p>
              
              </div>
          

              <div className={"p-4"}>
                  <Form onChange={setValues}  ref={form}  onSubmit={submit}>
                      <h4>Logros</h4>
                      <Input label={"Logro 1"} required name="l1" />
                      <Input label={"Logro 2"} required name="l2" />
                      <Input label={"Logro 3"} required name="l3" />
                      <Input label={"Logro 4"} required name="l4" />
                      <Input label={"Logro 5"}  required name="l5" />
                      <Input label={"Logro 6"}  name="l6" />
                      <Input label={"Logro 7"}  name="l7" />
                      <Input label={"Logro 8"}  name="l8" />
                      <Input label={"Logro 9"}  name="l9" />
                      <Input label={"Logro 10"}  name="l10" />

                      <TextArea label={"Mis Expectativas a Furuto"} required name="expectativas" />

                      <div className="d-flex justify-content-center">

                        <Link to="/candidatos/home" >
                        <Button className="me-2" color={"secondary"}>Regresar</Button></Link>
                        <Button  type={"submit"} color={"primary"}>Finalizar <i className="ms-1 border-start ps-1 fas fa-arrow-right"/></Button>
                      </div>
                  </Form>
              

                  </div>
                </div>
            </div>
        </div>
        </div>
    </React.Fragment>
  )
}


TakeLogros.propTypes = {
  
}


export default (withRouter(TakeLogros))



