
import {
    GET_ALL_FORMS,
    GET_ALL_FORMS_SUCCESS,
    SET_FORMS_NAMES,
    GET_ALL_EXP_SUCCESS
  } from "./actionTypes"

  export const getAllForms = (page=1, size=10,callback=()=>{}, onError=()=>{}) => ({
    type: GET_ALL_FORMS,
    payload: {callback, onError, page, size, exp:false},
  })
  export const getAllExpedientes = (page=1, size=10,callback=()=>{}, onError=()=>{}) => ({
    type: GET_ALL_FORMS,
    payload: {callback, onError, page, size, exp:true},
  })
  
  export const getAllFormSuccess = data => ({
    type: GET_ALL_FORMS_SUCCESS,
    payload: data,
  })

  export const getAllExpSuccess = data => ({
    type: GET_ALL_EXP_SUCCESS,
    payload: data,
  })


  export const setFormsNames = data => ({
    type: SET_FORMS_NAMES,
    payload: data,
  })

