import { takeEvery, put, call } from "redux-saga/effects"

// Login Redux States
import { GET_PROFILE, PUT_PROFILE, PUT_EXATEC, PUT_FILES } from "./actionTypes"
import { profileSuccess, profileError } from "./actions"

import { get, put as putHTTP, postFile } from "../../../utils/backend/api_helper"
import "firebase/database";
import "firebase/auth";
import { LDMDB } from "../../../utils/Configs";

function* fetchProfile() {
  try {
    const response = yield call(get, "auth/validate-token")
    console.log(response)
    if(response.success){
      //localStorage.setItem("user", JSON.stringify(response.user))
      response.user.fbToken = response.fb_token
      response.user.fbUser = response.fb_user
  
      LDMDB.auth().signInWithCustomToken(response.fb_token)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        //console.log(user)
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        //console.log(errorMessage)
        // ...
      });
      
      yield put(profileSuccess(response.user))
    }else{
      localStorage.removeItem("auth_token")
      localStorage.removeItem("user")
      yield put(profileError(response.message))
    }


  } catch (error) {
    yield put(profileError(error))
  }
}

function* putProfile({ payload: {id, data, callback, onError} }) {
  //console.log(data)
  try {
    const response = yield call(putHTTP, "user/" + id, data)
    callback(response)
  } catch (error) {
    //console.log(error)
    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}

function* putFiles({ payload: {id, files, callback, onError} }) {
  //console.log(files)
  try {


    var formData = new FormData();
    
    for (const [key, value] of Object.entries(files)) {
      //console.log(key, value)
      formData.append(key, value);
    }
    //console.log(formData)
    const response = yield call(postFile, "/user/" + id + "/files", formData)
    callback(response)
  } catch (error) {
    //console.log(error)
    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}

function* putExatec({ payload: {id, data, callback, onError} }) {
  //console.log(data)
  try {
    const response = yield call(putHTTP, "user/exatec/" + id, data)

    yield put(profileSuccess(response.data))
    callback(response.data)
  } catch (error) {
    //console.log(error)
    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}


function* profileSaga() {
  yield takeEvery(GET_PROFILE, fetchProfile)
  yield takeEvery(PUT_PROFILE, putProfile)
  yield takeEvery(PUT_EXATEC, putExatec)
  yield takeEvery(PUT_FILES, putFiles)

}

export default profileSaga
