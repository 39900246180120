
import React, {  useRef,forwardRef, useImperativeHandle, createRef, useEffect } from "react"


const VALID_FIELDS = [
    "Input",
    "TimePicker",
    "DatePicker",
    "CheckboxGroup",
    "Checkbox",
    "Radio",
    "RadioGroup",
    "Select",
    "MaskInput",
    "Upload",
    "TextArea",
    "Div"
]

const RULES = [
    "length"
]

const Form = (props, ref) => {


    let form = useRef()
    let fields = {};

    let content = []

    useEffect(()=>{
        fill(props.initialValues)

        },[])


    const field = (field, key) => {
        //console.log(field)
        if (field && field.type){
            //console.log(field)
            const FieldComponent = field.type
            //console.log(field)
            if (field.props && field.props.name && field.props.formfield === "true"){
                fields[field.props.name] = createRef()
            }

            if (field.props.name ){
                content.push({"label": field.props.label, "key":field.props.name })
            }


            return <FieldComponent ref={fields[field.props.name]} key={key} {...field.props}/>
        }else{
 
            return field
        }
    }

    
    
    const layout = (arr, key) =>{
     

        if (arr && Array.isArray(arr)){
            return <>
                    {arr.map((element, id)=>{
                        return layout(element, id)
                    }) } </> 
    
        }else if (arr && arr.props && arr.props.children && Array.isArray(arr.props.children)){
           
                //console.log(arr.type.name)
                let Wrapper = arr.type
                return <Wrapper key={key} {...arr.props}>
                    {arr.props.children.map((element, id)=>{
                        return layout(element, id)
                    }) } </Wrapper>  
      
    
        } else if (arr && arr.props && arr.props.children){
                //console.log(arr.type.name)
                let Wrapper = arr.type

                if (VALID_FIELDS.includes(Wrapper.name) && field.props.name){
                    fields[field.props.name] = createRef()
                }

                return <Wrapper key={key}  {...arr.props}>
                    {[arr.props.children].map((element, id)=>{

                        if (element && element.type){
                            if (!VALID_FIELDS.includes(element.type.name)){
                                
                                return layout(element, id)
                            }
                        }

                        return field(element, id)


                    }) } </Wrapper>  
    
        }else{
            return field(arr, key)
    
        }


    }    


    const fill = (data) => {
        //console.log(form)


        if (data){

     

            let keys = Object.keys(data)

            for(let i = 0; i < keys.length; i++){
                if (fields[keys[i]]){
                    fields[keys[i]].current.setValue(data[keys[i]])
                }
            }

            
        }

        return  null

    }

    const reset = () =>{
        let keys = Object.keys(fields)
        for(let i = 0; i < keys.length; i++){
            fields[keys[i]].current.setValue("")
        }
    }
    
    const validateData  = (name, value, field) => {

        if (field.isRequired()){
            //console.log(name, value, field)
            if (value === undefined  || value === null || value === ""){
               
                field.displayError(true)
                return false
            }
        }

        if (field.getValidations()){
            let validations = field.getValidations()

            for(let i = 0; i < validations.length; i++){
                let validation = validations[i]
                switch (validation.type) {
                    case RULES[0]:
                        if (value.length < validation.min || value.length > validation.max){
                            field.displayError(true,validation.message )
                            return false
                        }
                        break;
                    default:
                        break;
                }

            }

        }

        return true
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        //console.log(fields)
        console.log(content)
        let data = {}
        let validData = true;
        let keys = Object.keys(fields)

        for(let i = 0; i < keys.length; i++){
            data[keys[i]] = fields[keys[i]].current.getValue();
            fields[keys[i]].current.displayError(false);
            validData = (validateData(keys[i], data[keys[i]], fields[keys[i]].current) && validData);
            //console.log(keys[i])
        }
        //console.log(data)

        if (props.onSubmit){
            props.onSubmit(data, validData)
        }

        return {data:data, validData:validData}
    }

    const submit = () => {
    
        return handleSubmit(new Event("submit"));
    }

    useImperativeHandle(ref, () => ({
        fill,
        submit,
        reset
    }));

    //console.log(props.children)
    //layout(props.children)

    //console.log(fields)
    content = []
    return (
        <form ref={f => (form = f)} onSubmit={handleSubmit} onReset={reset}>
            {props.children ? layout(props.children) : null}
  
        </form>
     
    )
};

export default forwardRef(Form)