import React from "react"
import uniqueId from 'lodash/uniqueId'

import BaseField from "./BaseField"
import DivRender from "./Div";



class Checkbox extends BaseField {

    constructor(props) {
        super(props);
        this.state = {
            checked: false
        }
        this.id  = uniqueId(this.props.name + '-')
    }

    getValue = () =>{
        return this.state.checked
    }

    setValue = (value) => {
      this.setState({checked:value})
    }

    check = (checked) =>{
        this.setState({checked:checked})
        if (this.props.onChange){
            this.props.onChange(checked)
        }
    }

    render(){
    const { name, value } = this.props;
    

    return (
        <div {...this.props}>
            <div className="form-check mb-1">
                <input
                    name={name}
                    type="checkbox"
                    className="form-check-input"
                    id={this.id}
                    checked={this.state.checked}
                    onChange={(event)=>{event.stopPropagation(); this.check(event.target.checked)}}
                    value={value}
                />
                <label
                    className="form-check-label"
                    htmlFor={this.id}
                    style={{fontWeight:"normal"}}
                >
                    <DivRender html={this.props.label}/> 
                </label>
            </div>
      </div>
     
    )}
}

export default Checkbox