import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import {  withRouter } from "react-router-dom"
import { Card, CardBody} from "reactstrap"
import { Button } from "../../../components"

import List from "../../../components/Table/List"
import { Loader, LDMDB } from "../../../utils/Configs"
import exportFromJSON from 'export-from-json'
import { API } from "../../../utils/backend/api_helper"
import { CAMPUS_2 as CAMPUS, getValue } from "../../../utils/Tools"
import Header from "../../Candidatos/Header"
import { Link } from "react-router-dom"

const formaterURL = (value, row, xlsx) => {

  if (xlsx){
    return value ? value:""
  }

  return (
   <div>{value ? <a  href={value} target="_blank" rel="noreferrer">
      Ver <i className="fas fa-external-link-alt"/>
    </a>: "Sin archivo"}</div>
  )

}
const formatterTMMS = (value, row, xlsx) => {
  if (xlsx){
    return value ? "COMPLETA":"INCOMPLETA"
  }
  return (
    <div className={ "text-center p-1 rounded text-white " + (value ? "bg-success" : "bg-warning")}>
      {value ? "COMPLETA":"INCOMPLETA"}
    </div>
  )

}

const formatter = (value, row, xlsx) => {
  if (xlsx){
    return value ? value :""
  }
  return (
    <div className={ "text-center p-1 rounded text-white " + (value =="COMPLETA" ? "bg-success" : "bg-warning")}>
      {value}
    </div>
  )

}




const HEADERS = [ 
    

    {key:"matricula", label:"Matrícula"},
    {key:"full_name", label:"Nombre"},
    {key:"email", label:"Correo"},
    {key:"phone", label:"Teléfono"},

    {key:"WA", label:"WhatsApp", formatter:formatter},
    {key:"AI", label:"Advance Insights", formatter:formatter},
    {key:"ACCUTEST", label:"Accutest", formatter:formatter},
    {key:"INGLES", label:"Pruebas de inglés", formatter:formatter},
    {key:"VIDEO", label:"Video", formatter:formatter},
    {key:"MMPI", label:"MMPI", formatter:formatter},
    {key:"ENTREVISTA_STATUS", label:"ENTREVISTA", formatter:formatter},
    {key:"tmms_test.percepcion_emocional", label:"TMMS", formatter:formatterTMMS},
    {key:"VULNERABILIDAD", label:"Cuestionario de vulnerabilidad", formatter:formatter},
    {key:"LOGROS", label:"Logros", formatter:formatter},
    { key:"HMS", label:"HMS", formatter:formatter},

    
    {key:"campus", label:"Campus"},
    {key:"url_ai", label:"Evidencia Advance Insights", formatter: formaterURL},
    {key:"url_accutest", label:"Evidencia Accutest", formatter: formaterURL},
    {key:"listening", label:"Resultado Listening"},
    {key:"vocabulary", label:"Resultado Vocabulary"},
    {key:"grammar", label:"Resultado Grammar"},
    {key:"reading", label:"Resultado Reading"},
    {key:"url_video", label:"Liga al video", formatter: formaterURL},
    {key:"evaluacion_video", label:"Evaluación del video"},
    {key:"star_video", label:"Video destacado", formatter:(value)=>{ return value? "Sí":"No" }  },
    {key:"comentarios_video", label:"Comentarios del video"},
    {key:"evaluador", label:"Evaluador del video"},

    {key:"entrevistador", label:"Entrevistador en campus"},
    {key:'mmpi_tests.resultado.AE_PI', label:'V.Alteración emocional/problemas internalizados(AE/PI)'},
{key:'mmpi_tests.resultado.AP', label:'V.Alteración del pensamiento(AP)'},
{key:'mmpi_tests.resultado.AC_PE', label:'V.Alteraciones de la conducta/problemas externalizados(AC/PE)'},
{key:'mmpi_tests.resultado.CRd', label:'V.Desmoralización(CRd)'},
{key:'mmpi_tests.resultado.CR1', label:'V.Quejas somáticas(CR1)'},
{key:'mmpi_tests.resultado.CR2', label:'V.Disminución de emociones positivas(CR2)'},
{key:'mmpi_tests.resultado.CR3', label:'V.Cinismo(CR3)'},
{key:'mmpi_tests.resultado.CR4', label:'V.Conducta antisocial(CR4)'},
{key:'mmpi_tests.resultado.CR6', label:'V.Ideas de persecución(CR6)'},
{key:'mmpi_tests.resultado.CR7', label:'V.Emociones negativas disfuncionales(CR7)'},
{key:'mmpi_tests.resultado.CR8', label:'V.Experiencias aberrantes(CR8)'},
{key:'mmpi_tests.resultado.CR9', label:'V.Activación hipomaniaca(CR9)'},
{key:'mmpi_tests.resultado.MAL', label:'V.Malestar(MAL)'},
{key:'mmpi_tests.resultado.QGI', label:'V.Quejas gastrointestinales(QGI)'},
{key:'mmpi_tests.resultado.QDC', label:'V.Quejas de dolor de cabeza(QDC)'},
{key:'mmpi_tests.resultado.QNEU', label:'V.Quejas neurológicas(QNEU)'},
{key:'mmpi_tests.resultado.QCO', label:'V.Quejas cognitivas(QCO)'},
{key:'mmpi_tests.resultado.ISU', label:'V.Ideación suicida/deseo de muerte(ISU)'},
{key:'mmpi_tests.resultado.Im_D', label:'V.Impotencia/desesperanza(Im/D)'},
{key:'mmpi_tests.resultado.DSM', label:'V.Desconfianza de sí mismo(DSM)'},
{key:'mmpi_tests.resultado.INE', label:'V.Ineficacia(INE)'},
{key:'mmpi_tests.resultado.P_E', label:'V.Preocupación/estrés(P/E)'},
{key:'mmpi_tests.resultado.ANS', label:'V.Ansiedad(ANS)'},
{key:'mmpi_tests.resultado.TEN', label:'V.Tendencia al enojo(TEN)'},
{key:'mmpi_tests.resultado.LCM', label:'V.Limitaciones conductuales por miedos(LCM)'},
{key:'mmpi_tests.resultado.MEM', label:'V.Miedos específicos múltiples(MEM)'},
{key:'mmpi_tests.resultado.PCIJ', label:'V.Problemas de conducta infanto-juveniles(PCIJ)'},
{key:'mmpi_tests.resultado.ABS', label:'V.Abuso de sustancias(ABS)'},
{key:'mmpi_tests.resultado.AG', label:'V.Agresión(AG)'},
{key:'mmpi_tests.resultado.EUF', label:'V.Euforia(EUF)'},
{key:'mmpi_tests.resultado.PFA', label:'V.Problemas familiares (PFA)'},
{key:'mmpi_tests.resultado.PIP', label:'V.Pasividad interpersonal (PIP)'},
{key:'mmpi_tests.resultado.ESO', label:'V.Evitación social(ESO)'},
{key:'mmpi_tests.resultado.TIM', label:'V.Timidez(TIM)'},
{key:'mmpi_tests.resultado.DES', label:'V.Desapego(DES)'},
{key:'mmpi_tests.resultado.AGGR_r', label:'V.Agresividadr-revisada(AGGR-r)'},
{key:'mmpi_tests.resultado.PSYC_r', label:'V.Psicoticismo-revisada(PSYC-r)'},
{key:'mmpi_tests.resultado.DISC_r', label:'V.Impulsividad-revisada(DISC-r)'},
{key:'mmpi_tests.resultado.NEGE_r', label:'V.Neuroticismo/emocionalidad negativa-revisada(NEGE-r)'},
{key:'mmpi_tests.resultado.INTR_r', label:'V.Introversión/disminución de emociones positivas-revisada(INTR-r)'},
    {key:'mmpi_tests.resultado.AE_PI_RIESGO', label:'Alteración emocional/problemas internalizados(AE/PI)'},
    {key:'mmpi_tests.resultado.AP_RIESGO', label:'Alteración del pensamiento(AP)'},
    {key:'mmpi_tests.resultado.AC_PE_RIESGO', label:'Alteraciones de la conducta/problemas externalizados(AC/PE)'},
    {key:'mmpi_tests.resultado.CRd_RIESGO', label:'Desmoralización(CRd)'},
    {key:'mmpi_tests.resultado.CR1_RIESGO', label:'Quejas somáticas(CR1)'},
    {key:'mmpi_tests.resultado.CR2_RIESGO', label:'Disminución de emociones positivas(CR2)'},
    {key:'mmpi_tests.resultado.CR3_RIESGO', label:'Cinismo(CR3)'},
    {key:'mmpi_tests.resultado.CR4_RIESGO', label:'Conducta antisocial(CR4)'},
    {key:'mmpi_tests.resultado.CR6_RIESGO', label:'Ideas de persecución(CR6)'},
    {key:'mmpi_tests.resultado.CR7_RIESGO', label:'Emociones negativas disfuncionales(CR7)'},
    {key:'mmpi_tests.resultado.CR8_RIESGO', label:'Experiencias aberrantes(CR8)'},
    {key:'mmpi_tests.resultado.CR9_RIESGO', label:'Activación hipomaniaca(CR9)'},
    {key:'mmpi_tests.resultado.MAL_RIESGO', label:'Malestar(MAL)'},
    {key:'mmpi_tests.resultado.QGI_RIESGO', label:'Quejas gastrointestinales(QGI)'},
    {key:'mmpi_tests.resultado.QDC_RIESGO', label:'Quejas de dolor de cabeza(QDC)'},
    {key:'mmpi_tests.resultado.QNEU_RIESGO', label:'Quejas neurológicas(QNEU)'},
    {key:'mmpi_tests.resultado.QCO_RIESGO', label:'Quejas cognitivas(QCO)'},
    {key:'mmpi_tests.resultado.ISU_RIESGO', label:'Ideación suicida/deseo de muerte(ISU)'},
    {key:'mmpi_tests.resultado.Im_D_RIESGO', label:'Impotencia/desesperanza(Im/D)'},
    {key:'mmpi_tests.resultado.DSM_RIESGO', label:'Desconfianza de sí mismo(DSM)'},
    {key:'mmpi_tests.resultado.INE_RIESGO', label:'Ineficacia(INE)'},
    {key:'mmpi_tests.resultado.P_E_RIESGO', label:'Preocupación/estrés(P/E)'},
    {key:'mmpi_tests.resultado.ANS_RIESGO', label:'Ansiedad(ANS)'},
    {key:'mmpi_tests.resultado.TEN_RIESGO', label:'Tendencia al enojo(TEN)'},
    {key:'mmpi_tests.resultado.LCM_RIESGO', label:'Limitaciones conductuales por miedos(LCM)'},
    {key:'mmpi_tests.resultado.MEM_RIESGO', label:'Miedos específicos múltiples(MEM)'},
    {key:'mmpi_tests.resultado.PCIJ_RIESGO', label:'Problemas de conducta infanto-juveniles(PCIJ)'},
    {key:'mmpi_tests.resultado.ABS_RIESGO', label:'Abuso de sustancias(ABS)'},
    {key:'mmpi_tests.resultado.AG_RIESGO', label:'Agresión(AG)'},
    {key:'mmpi_tests.resultado.EUF_RIESGO', label:'Euforia(EUF)'},
    {key:'mmpi_tests.resultado.PFA_RIESGO', label:'Problemas familiares (PFA)'},
    {key:'mmpi_tests.resultado.PIP_RIESGO', label:'Pasividad interpersonal (PIP)'},
    {key:'mmpi_tests.resultado.ESO_RIESGO', label:'Evitación social(ESO)'},
    {key:'mmpi_tests.resultado.TIM_RIESGO', label:'Timidez(TIM)'},
    {key:'mmpi_tests.resultado.DES_RIESGO', label:'Desapego(DES)'},
    {key:'mmpi_tests.resultado.AGGR_r_RIESGO', label:'Agresividadr-revisada(AGGR-r)'},
    {key:'mmpi_tests.resultado.PSYC_r_RIESGO', label:'Psicoticismo-revisada(PSYC-r)'},
    {key:'mmpi_tests.resultado.DISC_r_RIESGO', label:'Impulsividad-revisada(DISC-r)'},
    {key:'mmpi_tests.resultado.NEGE_r_RIESGO', label:'Neuroticismo/emocionalidad negativa-revisada(NEGE-r)'},
    {key:'mmpi_tests.resultado.INTR_r_RIESGO', label:'Introversión/disminución de emociones positivas-revisada(INTR-r)'},
    {key:'mmpi_tests.resultado.GENERAL', label:'Riesgo general'},
    {key:'tmms_test.percepcion_emocional', label:'Atención a las emociones'},
    {key:'tmms_test.compresion_emocional', label:'Claridad emocional '},
    {key:'tmms_test.regulacion_emocional', label:'Reparación emocional '},
    {key:'tmms_test.empatia_afectiva', label:'Empatía afectiva'},
    {key:'tmms_test.empatia_cognitiva', label:'Empatía cognitiva'},
    {key:'tmms_test.empatia_global', label:'Empatía global'},
    {key:'tmms_test.autoestima', label:'Autoestima'},
    {key:'tmms_test.planeacion', label:'Planeación y Toma de Decisiones'},
    {key:'tmms_test.percepcion_emocional', label:'Nivel de desarrollo. Atención a las emociones', formatter:(value)=>{ return !value ? "" : value >= 34? "EN PROGRESO":value >= 23? "AVANZADO" :"INICIAL"}},
    {key:'tmms_test.compresion_emocional', label:'Nivel de desarrollo. Claridad emocional', formatter:(value)=>{ return !value ? "" :value >= 35? "AVANZADO":value >= 25? "EN PROGRESO" :"INICIAL"}},
    {key:'tmms_test.regulacion_emocional', label:'Nivel de desarrollo. Reparación emocional', formatter:(value)=>{ return !value ? "" :value >= 36? "AVANZADO":value >= 25? "EN PROGRESO" :"INICIAL"}},
    {key:'tmms_test.empatia_global', label:'Nivel de desarrollo. Empatía', formatter:(value)=>{ return !value ? "" :value >= 38? "AVANZADO":value >= 30? "EN PROGRESO" :"INICIAL"}},
    {key:'tmms_test.autoestima', label:'Nivel de desarrollo. Autoestima', formatter:(value)=>{ return !value ? "" :value >= 41? "AVANZADO":value >= 31? "EN PROGRESO" :"INICIAL"}},
    {key:'tmms_test.planeacion', label:'Nivel de desarrollo. Planeación y Toma de Decisiones', formatter:(value)=>{ return !value ? "" :value >= 31? "AVANZADO":value >= 25? "EN PROGRESO" :"INICIAL"}},



]

const CandidatosView = (props) => {

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [campus, setCampus] = useState("all")

  const [listInfo, setlistInfo] = useState({items:[]})

  const dispatch = useDispatch();

  const getCandidatos = (page, size, campus="all") => {
    setLoading(true)



    API.get(
      `/candidato/p/${page}/${size}/${campus}`, 
      (response)=>{
 
        let count = 0;
        response.data.items.map((item,index)=>{

          LDMDB
          .database()
          .ref("answers/"+"vulnerabilidad-23"+ "/" + "candidato-"+item.id +"/"+"vulnerabilidad"+"/q1/").once('value').then(v=>{
            response.data.items[index]["VULNERABILIDAD"] = v.val() ? "COMPLETA":"INCOMPLETA"
            
            LDMDB
            .database()
            .ref("answers/"+"logros-24"+ "/" + "candidato-"+item.id +"/"+"logros"+"/").once('value').then(v=>{
                console.log(v.val())
    
                response.data.items[index]["LOGROS"] = v.val() ? "COMPLETA":"INCOMPLETA"

                  count = count + 1;
                  if (count == response.data.items.length){
                    console.log(response)
                    setlistInfo(response.data)
                    setLoading(false)
        
                  }
            
            
            })
        


            
          })
      
          
       


        })

      
      },
      (response)=>{
        setLoading(false)
      }
    )


    
  }

  useEffect(()=>{
    getCandidatos(1,25)
   
  },[])

  const onChangePage = (page, nextPage) => {
      //console.log(page,nextPage)
      getCandidatos(nextPage, 25, campus)

  }

  const onChangeCampus = (e) => {
    setCampus(e.target.value)
    setTimeout(()=>{
      getCandidatos(listInfo.page, 25, e.target.value)

    },500)

}


  const donwloadData = () => {
    const fileName = `Candidatos Comité Nacional 2022${campus == "all" ? "":" - " + campus}`
    const exportType =  exportFromJSON.types.xls


    setLoading(true)
    setMessage("Descargando datos...")
 
    API.get(
      `/candidato/get-all/${campus}`, 
      (response)=>{
        console.log(response)
        let data = []
        let count = 0

        response.data.map((item, index)=>{

          
          LDMDB
          .database()
          .ref("answers/"+"vulnerabilidad-23"+ "/" + "candidato-"+item.id +"/"+"vulnerabilidad"+"/q1/").once('value').then(v=>{
            response.data[index]["VULNERABILIDAD"] = v.val() ? "COMPLETA":"INCOMPLETA"



            LDMDB
            .database()
            .ref("answers/"+"logros-24"+ "/" + "candidato-"+item.id +"/"+"logros"+"/").once('value').then(v=>{
                console.log(v.val())
    
                response.data[index]["LOGROS"] = v.val() ? "COMPLETA":"INCOMPLETA"

                  count = count + 1;
                  if (count == response.data.length){

                    console.log(response)
      
                    response.data.map(((item)=>{
      
                      let row = {}
                      HEADERS.map((header)=>{
                          let value = ""
                          if (header.xlsFormatter ){
                              value = header.xlsFormatter(getValue(item,header.key), item,true)
                          }else{
                              if (header.formatter){
                                value = header.formatter(getValue(item,header.key), item, true)
          
                              }else{
                                value = getValue(item,header.key)
                                value = value ? value:""
                              }
                              
                          }
                         
                          row[header.label] = value
                      })
              
                      data.push(row)
                  }))
                    exportFromJSON({ data: data, fileName, exportType})
      
                  setLoading(false)
                    
                    
                
                    
                  }
            
            
            })


         



            
          })
    
        })
        //exportFromJSON({ data: data, fileName, exportType  })

        setLoading(false)

      },
      (response)=>{
        setLoading(false)
      }
    )
    
    

  }

  return (
    <div >
         <Header/>
         <div className="container mt-4">
         
      {loading?<Loader message={message}/> :""}

      

      <div className="d-flex align-items-center">
          <div className="flex-fill"><h3><i className="fas fa-star me-2"/>Candidatos LDM</h3>
          <div>
          <select onChange={onChangeCampus} className="form-select" aria-label="Campus" defaultValue={"all"}>
            <option value="all">Todos los campus</option>
            
            {CAMPUS.map((v, key)=>(
              <option key={key} value={v.campus}>{v.campus}</option>
            ))}
          </select>
          </div>
          </div>
          <div className="ms-3">
         {listInfo ? <Link to="/candidatos/vulnerabilidad"><Button color={"warning"}>Ir a Cuestionarios de Vulnerabilidad</Button></Link>:null}

         
         </div>
         <div className="ms-3">
         {listInfo ? <Button onClick={donwloadData} icon={"fas fa-download"} color={"primary"}>Descargar</Button>:null}

         
         </div>
      </div>

        <div className={"mt-2"}/>
        <Card className={"box-shadow mt-0 pt-0"}>
                
                <CardBody className={"mt-2 pt-0"}>

      <List
        onChangePage={onChangePage}
        hasPagination={true}
        pagination={listInfo}
        data={listInfo ? listInfo.items : []}
        headers={HEADERS}
      />
      </CardBody></Card>
      </div>
    </div>
  )
}


CandidatosView.propTypes = {
 
}


export default (withRouter(CandidatosView))



