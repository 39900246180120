import React from "react"
import { Redirect } from "react-router-dom"
import DataCenter from "../pages/Admin/DataCenter"
import FormAnswers from "../pages/Admin/FormAnswers"
import CandidatosView from "../pages/Admin/Views/CandidatosView"
import VideosEvaluadosView from "../pages/Admin/Views/VideosEvaluadosView"
import VideosView from "../pages/Admin/Views/VideosView"
import ExatecLogin from "../pages/Authentication/ExatecLogin"
import Galeria from "../pages/Authentication/Galeria"
//import Game from "../pages/Authentication/Game"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import MuroGraduados from "../pages/Authentication/MuroGraduados"
// import Navidad from "../pages/Authentication/Navidad"
import Accutest from "../pages/Candidatos/Accutest"
import AdvanceInsight from "../pages/Candidatos/AdvanceInsight"
import Entrevista from "../pages/Candidatos/Entrevista"
import HMS from "../pages/Candidatos/HMS"
import Home from "../pages/Candidatos/Home"
import Ingles from "../pages/Candidatos/Ingles"
import LoginCandidato from "../pages/Candidatos/Login"
import LoginAdmin from "../pages/Candidatos/LoginAdmin"
import MMPI from "../pages/Candidatos/MMPI"
import TakeMMPI from "../pages/Candidatos/TakeMMPI"
import TakeTMMS from "../pages/Candidatos/TakeTMMS"
import TMMS from "../pages/Candidatos/TMMS"
import VerificationCode from "../pages/Candidatos/VerificationCode"
import Video from "../pages/Candidatos/Video"
import WhatsApp from "../pages/Candidatos/WhatsApp"
// import Registro from "../pages/Authentication/Registro"
// import Vote from "../pages/Authentication/Vote"
// import Voting from "../pages/Authentication/Voting"
import BibliotecaLDM from "../pages/Main/BibliotecaLDM"
import Bienestar from "../pages/Main/Bienestar"
import ComunidadVirtual from "../pages/Main/ComunidadVirtual"
import ConservaTuBeca from "../pages/Main/ConservaTuBeca"
import Curso from "../pages/Main/Curso"
import Expediente from "../pages/Main/Expediente"
import ExtFormView from "../pages/Main/ExtFormView"
import FormView from "../pages/Main/FormView"


//Pages
//import Home from "../pages/Main/Home"
//import LaL from "../pages/Main/LaL"
import LiderazgoPositivo from "../pages/Main/LiderazgoPositivo"
import Perfil from "../pages/Main/Perfil"
import PostView from "../pages/Main/PostView"
import ProgramaFormacion2021 from "../pages/Main/ProgramaFormacion2021"
import Project from "../pages/Main/Project"
import Sociales from "../pages/Main/Sociales"
import Videoteca from "../pages/Main/Videoteca"
import Vinculacion from "../pages/Main/Vinculacion"
import Vivencia from "../pages/Main/Vivencia"
// import ControlVote from "../pages/Authentication/ControlVote"
import Contactos from "../pages/Main/Contactos"
import MuroFotos from "../pages/Authentication/MuroFotos"
import MuroGratitud from "../pages/Authentication/MuroGratitud"
import TakeSES from "../pages/Candidatos/TakeSES"
import VulnerabilidadView from "../pages/Admin/Views/VulnerabilidadView"
import MMPILDM from "../pages/Main/MMPI"
import TakeMMPILDM from "../pages/Main/TakeMMPI"
import TakeLogros from "../pages/Candidatos/TakeLogros"
import LogrosView from "../pages/Admin/Views/LogrosView"

const userRoutes = [
  //Utility
  //{ path: "/home", exact: true, component: Home },
  { path: "/home", exact: true, component: () => <Redirect to="/comunidad-virtual" /> },

  { path: "/expediente", exact: true, component: Expediente },
  { path: "/vinculacion", exact: true, component: Vinculacion },
  { path: "/sociales", exact: true, component: Sociales },
  { path: "/sociales/:course_name", exact: true, component: Project },

  { path: "/liderazgo-positivo", exact: true, component: LiderazgoPositivo },
  { path: "/bienestar", exact: true, component: Bienestar },
  { path: "/biblioteca-ldm", exact: true, component: BibliotecaLDM },
  { path: "/programa-formacion-y-desarrollo-2021", exact: true, component: ProgramaFormacion2021 },
  { path: "/comunidad-virtual", exact: true, component: ComunidadVirtual },
  { path: "/conserva-tu-beca", exact: true, component: ConservaTuBeca },
  { path: "/videoteca", exact: true, component: Videoteca },
  { path: "/vivencia", exact: true, component: Vivencia },
  { path: "/contacto", exact: true, component: Contactos },


  { path: "/perfil/:id/:matricula", exact: true, component: Perfil },
  { path: "/post/:id/", exact: true, component: PostView },
  { path: "/forms/:id/", exact: true, component: FormView },
  { path: "/form/:id/", exact: true, component: FormView },

  { path: "/ext/forms/:id/", exact: true, component: ExtFormView, omitLayout:true },
  
  { path: "/curso/liderazgo-positivo", exact: true, component: LiderazgoPositivo },
  { path: "/curso/:course_name", exact: true, component: Curso },

  { path: "/curso/:course_name", exact: true, component: Curso },
  { path: "/mmpi", exact: true, component: MMPILDM },
  { path: "/ldm/take-mmpi", exact: true, component: TakeMMPILDM },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/home" /> },
]

const candidatosRoutes = [
  { path: "/candidatos/home", exact: true, component: () => <Home/> },
  { path: "/candidatos/whatsapp", exact: true, component: () => <WhatsApp/> },
  { path: "/candidatos/verification", exact: true, component: () => <VerificationCode/> },
  { path: "/candidatos/advance-insights", exact: true, component: () => <AdvanceInsight/> },
  { path: "/candidatos/accutest", exact: true, component: () => <Accutest/> },
  { path: "/candidatos/ingles", exact: true, component: () => <Ingles/> },
  { path: "/candidatos/video", exact: true, component: () => <Video/> },
  { path: "/candidatos/mmpi", exact: true, component: () => <MMPI/> },
  { path: "/candidatos/entrevista", exact: true, component: () => <Entrevista/> },
  { path: "/candidatos/hms", exact: true, component: () => <HMS/> },
  { path: "/candidatos/tmms", exact: true, component: () => <TMMS/> },
  { path: "/candidatos/take-ses", exact: true, component: () => <TakeSES/> },
  { path: "/candidatos/take-logros", exact: true, component: () => <TakeLogros/> },

  { path: "/candidatos/take-mmpi", exact: true, component: () => <TakeMMPI/> },
  { path: "/candidatos/take-tmms", exact: true, component: () => <TakeTMMS/> },
  { path: "/candidatos", exact: true,  component:() => <Redirect to="/candidatos/home" />  },
]

const candidatosAdminRoutes = [
  { path: "/candidatos/all", exact: true, component: () => <CandidatosView/> },
  { path: "/candidatos/videos", exact: true, component: () => <VideosView/> },
  { path: "/candidatos/videos/evaluados", exact: true, component: () => <VideosEvaluadosView/> },
  { path: "/candidatos/vulnerabilidad", exact: true, component: () => <VulnerabilidadView/> },
  { path: "/candidatos/logros", exact: true, component: () => <LogrosView/> },

]

const adminRoutes = [
  //Utility
  { path: "/data-center", exact: true, component: DataCenter },
  { path: "/data-center/:any", exact: true, component: DataCenter },
  { path: "/data-center/:any/:any", exact: true, component: DataCenter },

  { path: "/answers/:type/:id", exact: true, component: FormAnswers },
  { path: "/galeria", exact: true, component: Galeria },
  { path: "/form/:id/:user_id", exact: true, component: FormView },
  { path: "/forms/:id/:user_id", exact: true, component: FormView },
]

const authRoutes = [
  { path: "/login/exatec", exact: true, component:  ExatecLogin},

  { path: "/login", exact: true, component: Login },
  // { path: "/navidad", exact: true, component: Navidad },
  { path: "/candidatos/login", exact: true, component: LoginCandidato },
  { path: "/candidatos/admin/login", exact: true, component: LoginAdmin },

  //{ path: "/lal", exact: true, component: LaL },
  { path: "/graduados-julio-22", exact: true, component: MuroFotos },
  { path: "/galeria-exatec-ldm", exact: true, component: MuroFotos },
  { path: "/muro-gratitud", exact: true, component: MuroGratitud },
  { path: "/muro-graduados", exact: true, component: MuroGraduados },
  { path: "/public-view/:type/:id", exact: true, component: FormAnswers },
  { path: "/pb/cursos/:course_name", exact: true, component: Curso, marginNeg:true },

  // { path: "/distincion/registro/:name", exact: true, component: Registro },

  // { path: "/control-vote/:name", exact: true, component: ControlVote },

  // { path: "/vote/:name", exact: true, component: Vote },
  // //{ path: "/game/:name", exact: true, component: Game },

  // { path: "/distincion/:name", exact: true, component: Voting },



]

export { userRoutes, authRoutes, adminRoutes, candidatosRoutes, candidatosAdminRoutes }
