
import React, { useEffect, useRef, useState } from "react"


import {  Link, useHistory, withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import Form from "../../components/Form/Form"
import Button from "../../components/Buttons/Button"

import { API } from "../../utils/backend/api_helper"
import swal from "sweetalert"
import { Loader } from "../../utils/Configs"
import Upload from "../../components/Fields2/Upload"
import Header from "./Header"



const HMS = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);
    

    const [candidato, setCandidato] = useState({})
    const history = useHistory()
    const [progress, setProgress] = useState(-1)
    let files = 0;

    const completeUpload = (fileData, totalFiles) => {
      API.post(
        "/candidato/complete-upload",
        {url:fileData.upload_url.split("?")[0],name:fileData.name,bucket:fileData.bucket },
        ()=>{
          files = files +1
          if (files == totalFiles){
            swal({
              title:"¡Gracias!",
              text:"Esta sección ha sido completada.",
              icon:"success"
            }).then(
              ()=>{
                //window.location.href = "/candidatos/home"
                history.push( "/candidatos/home")
              }
            )
          }
        
        },
        (response)=>{
          swal({
            title:"¡Error en el documento!",
            message:response,
            icon:"error"
          })
        })
    }
  
    const upload = (url, file, fileData, totalFiles) => {
      API.S3put(
        url,
        file,
        (response)=>{
          console.log(response)
          completeUpload(fileData, totalFiles)
      },(response)=>{
        swal({
          title:"¡Error al subir el documento!",
          message:response,
          icon:"error"
        })
      },(progressEvent)=>{
        let percentComplete = progressEvent.loaded / progressEvent.total
        percentComplete = percentComplete * 100;
        setProgress(percentComplete)
      })
    }
    
      const submit = (values, valid) =>{
        console.log(values, valid)
  
        if (valid){
          setLoading(true)
          let files = []
          for (const [key, value] of Object.entries(values)) {
            if (value && value.fileValue){
              files.push(key)
            }
          }
          API.post(
            "/candidato/upload-file",
            {files},
            (response)=>{
              setLoading(false)
              response.data.map((uploadFileData)=>{
                upload(uploadFileData.upload_url, values[uploadFileData.name].fileValue, uploadFileData, response.data.length)
              })
            },
            (response)=>{
              swal({
                title:"¡Error!",
                message:response,
                icon:"error"
              })
              setLoading(false)
  
            })
    
        }
  
  
  
      }

    useEffect(()=>{
      setLoading(true)
      API.get(
          "/candidato",
          (resp)=>{
              setCandidato(resp.data)
              setLoading(false)



          },
          (resp)=>{
              swal({
                  title:"¡Error!",
                  text:resp,
                  icon:"error"
              })
              setLoading(false)

          }
      )
  },[])


  


  return (
    <React.Fragment >

      {loading ? <Loader message={"Enviando..."} />:null}
      
      <div className="vh-100">
     <Header/>

        <div className="container mt-4  h-100 ">
            <div className="card shadow-sm">
            <div className="card-body p-0">

              <div className="rounded-top ">
              <h3 className="fw-bold bg-primary  rounded-top text-white p-4">HEALTHY MINDS STUDY (HMS)</h3>
                <p className=" fs-5 fs-md-4 p-4">
                Tu salud y bienestar son elementos importantes para el desarrollo de habilidades y conocimientos que te ayudarán a tener éxito durante tu estancia en la institución, y en la vida. Para nosotros es vital conocer cómo podemos apoyarte a fortalecer y desarrollar las diferentes dimensiones que contribuyen a tu florecimiento humano.
    <br/><br/>
                Responde este cuestionario siguiendo estas instrucciones:
                <ol>
                <li>Accede a la dirección electrónica: <a rel="noreferrer" href="https://itesm.co1.qualtrics.com/jfe/form/SV_bQ1k9hVIDpFFMXQ" target="_blank">https://itesm.co1.qualtrics.com/jfe/form/SV_bQ1k9hVIDpFFMXQ</a></li>
                <li>Especifica tu nombre completo y el correo con el que te registraste en el sistema de preselección de Líderes del Mañana.</li>
   
                <li>Responde todas las preguntas en una sesión.
Al finalizar, verás un mensaje de confirmación de que la prueba fue realizada exitosamente. </li>
                </ol>
                Al finalizar, verás un mensaje de confirmación de que la prueba fue realizada exitosamente. <b><u>Toma una foto o una captura de pantalla a ese mensaje de confirmación.</u></b>
                <br/><br/>
                <div className="alert alert-warning" role="alert">
                4.	Sube aquí la foto o captura de pantalla.
</div>
<br/>
                <div className="text-end">Tiempo estimado de duración: 60-90 minutos.<br/>
                Contacto para dudas: Mariana Ortega <a href="mailto:marianaortega@tec.mx">marianaortega@tec.mx</a><br/>
                </div>
                </p>
                <hr/>
              </div>
          

              <div className={"p-4"}>
              {candidato.HMS == "COMPLETA" ? <div className="text-center">

<h3 className="mb-5"><i className="fas fs-1 text-success fa-check-circle"/><br/>¡Gracias!<br/>Esta sección ya esta completa.</h3>

<div className="d-flex justify-content-center">
<Link to="/candidatos/home" className={"btn btn-outline-primary"}>Regresar</Link>

<Button onClick={()=>setCandidato({})}  className={"ms-2"} color={"primary"}>Volver a subir pantalla de confirmación</Button>
</div>
</div>: <Form  onSubmit={submit}>
                    <div className="row">
                  
                      <div className={""}>
                      <Upload maxSize={10} progress={progress} required name={"HMS"}  label={"Sube aqui la foto o captura de pantalla"}/>

                      </div>

                    </div>
                    {progress >= 0 ? null:<div className="mt-2 d-flex justify-content-end">
                      <Link to="/candidatos/home" className={"btn btn-outline-primary me-2"}>Regresar</Link>

                      <Button  type={"submit"} color={"primary fw-bold"}>Continuar <i className="ms-1 border-start ps-1 fas fa-arrow-right"/></Button>

                    </div>}
                  </Form>}

                  </div>
                </div>
            </div>
            <div style={{height:50}} />
        </div>

        </div>
    </React.Fragment>
  )
}


HMS.propTypes = {
 
}


export default (withRouter(HMS))



