import React, { useEffect, useState } from "react";

import logo1 from "../../assets/images/logo-dark1.png";
import logo2 from "../../assets/images/logo-dark2.png";

import { useMediaQuery } from "react-responsive";

const fontStyle = {};

const Divider = ({ props }) => {
  return <div style={{ margin: 1 }} {...props} />;
};

const videos = [
  {
    nombre: "Aleida Machorro",
    periodo: "1er generación",
    matricula: "",
    contenido: "",
    carrera_id: "IFI",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:
      "https://s3.us-east-2.amazonaws.com/muro.graduados/mg_aleida_machorro.png",
    video_url:
      "https://drive.google.com/file/d/1SxpfM2c_iLnLULQzcQkDFX0FKoZt0zgu/preview",
    carrera: "Ingeniero Fisico Industrial",
    campus_id: 18,
  },

  {
    nombre: "Alejandro Quintero",
    matricula: "",
    periodo: "2da generación",
    contenido: "",
    carrera_id: "LAF",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:
      "https://s3.us-east-2.amazonaws.com/muro.graduados/mg_alejandro_quintero_v2.png",
    video_url:
      "https://drive.google.com/file/d/1eUX0zn6ia1JEsYRT1mzn9JYQ6lKjG9Fw/preview",
    carrera: "Licenciado en Administración Financiera",
    campus_id: 18,
  },

  {
    nombre: "Angel Briones",
    matricula: "",
    periodo: "2da genración",
    contenido: "",
    carrera_id: "INT",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:
      "https://s3.us-east-2.amazonaws.com/muro.graduados/mg_angel_briones_v2.png",
    video_url:
      "https://drive.google.com/file/d/1RLUUNaNnqgBv8N1WTo6DrFCnYO9h8Vm1/preview",
    carrera: "Ingeniero en Negocios y Tecnologías de la Información",
    campus_id: 18,
  },

  {
    nombre: "Cynthia Castillo",
    matricula: "",
    periodo: "1er generación",
    contenido: "",
    carrera_id: "IMA",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:
      "https://s3.us-east-2.amazonaws.com/muro.graduados/mg_cynthia+castillo.png",
    video_url:
      "https://drive.google.com/file/d/1LBtdheqr0QYMIR4qMsvSCGFy0Ov6uWq9/preview",
    carrera: "Ingeniero Mecánico Administrador",
    campus_id: 18,
  },

  {
    nombre: "Iván Vázquez",
    matricula: "",
    periodo: "1er generación",
    contenido: "",
    carrera_id: "ITC",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:
      "https://s3.us-east-2.amazonaws.com/muro.graduados/mg_ivan_vazquez.png",
    video_url:
      "https://drive.google.com/file/d/1gdiYAiIbdaG4DOFi38dRlRZD0BeBfYcR/preview",
    carrera: "Ingeniero en Tecnologías Computacionales",
    campus_id: 18,
  },

  {
    nombre: "Jorge Ortega",
    matricula: "",
    periodo: "2da gneración",
    contenido: "",
    carrera_id: "",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:
      "https://s3.us-east-2.amazonaws.com/muro.graduados/mg_jorge_ortega_v2.png",
    video_url:
      "https://drive.google.com/file/d/1zrAK65XGI0IRTzLr5WSUEhnYgb2b7j3a/preview",
    carrera: "Ingeniero Químico en Procesos Ssutentables",
    campus_id: 18,
  },

  {
    nombre: "Liliana Orta",
    matricula: "",
    periodo: "1er generación",
    contenido: "",
    carrera_id: "LIN",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:
      "https://s3.us-east-2.amazonaws.com/muro.graduados/mg_liliana_orta_v2.png",
    video_url:
      "https://drive.google.com/file/d/1ZugUpT_9OaiyEuWNdrgGYGpePgM93H2L/preview",
    carrera: "Licenciado en Negocios Internacionales",
    campus_id: 18,
  },

  {
    nombre: "Mario Rodríguez",
    matricula: "",
    periodo: "2da generación",
    contenido: "",
    carrera_id: "INCQ",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:
      "https://s3.us-east-2.amazonaws.com/muro.graduados/mg_mario_rodriguez_v2.png",
    video_url:
      "https://drive.google.com/file/d/1Y-LhobxIz6BHW6yl-M6b378rN5JN9uF6/preview",
    carrera: "Ingeniero en Nanotecnología y Ciencias Químicas",
    campus_id: 18,
  },

  {
    nombre: "Oscar Laureano",
    matricula: "",
    periodo: "2da generación",
    contenido: "",
    carrera_id: "ITC",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:
      "https://s3.us-east-2.amazonaws.com/muro.graduados/mg_oscar_laureano_v2.png",
    video_url:
      "https://drive.google.com/file/d/1OV5VuXpr6tnt2Q0OkKGIA4oRwtXEM8VJ/preview",
    carrera: "Ingeniero en Tecnologías Computacionales",
    campus_id: 18,
  },

  {
    nombre: "Ricardo Ortiz",
    matricula: "",
    periodo: "1er generación",
    contenido: "",
    carrera_id: "",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:
      "https://s3.us-east-2.amazonaws.com/muro.graduados/mg_ricardo_ortiz_v2.png",
    video_url:
      "https://drive.google.com/file/d/1EDuGMlTb8uVO178a0cwrOKBaxCACEMxp/preview",
    carrera: "Licenciado en Administración y Estrategia de Negocios",
    campus_id: 18,
  },

  {
    nombre: "Sandra Reyes",
    matricula: "",
    periodo: "1er generación",
    contenido: "",
    carrera_id: "IBT",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:
      "https://s3.us-east-2.amazonaws.com/muro.graduados/mg_sandra_reyes.png",
    video_url:
      "https://drive.google.com/file/d/1grbgYUjb12JPDRD26vI3HhDaGLRbl3E3/preview",
    carrera: "Ingeniero en Biotecnología",
    campus_id: 18,
  },

  {
    nombre: "Victor Caballero",
    matricula: "",
    periodo: "1er generación",
    contenido: "",
    carrera_id: "IQA",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:
      "https://s3.us-east-2.amazonaws.com/muro.graduados/mg_victor_caballero.png",
    video_url:
      " https://drive.google.com/file/d/1Qk3tFzalLg4tlYY-KZ9kObzGTr1973IZ/preview",
    carrera: "Ingeniero Químico Administrador",
    campus_id: 18,
  },
];

const videos2 = [{
    nombre: 'José Manuel García Obezo',
    contenido: '',
    carrera_id: 'ISC',
    campus: 'Guadalajara',
    contenido_breve: 'Ser líder del mañana es para mi una gran oportunidad, pues tenemos la posibilidad de estudiar en una de las mejores universidades y al mismo tiempo contribuir a nuestra sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00227193/c3c1d8a5-8c8a-4ceb-80e9-c75e00a569e0.mp4',
    carrera: 'ISC',
    campus_id: 'Guadalajara',    gen: '4a generación',
  },
{
    nombre: 'Lizbeth Ortiz López',
    contenido: '',
    carrera_id: 'ISC',
    campus: 'Guadalajara',
    contenido_breve: 'Líderes del mañana representa una oportunidad de vida nueva que me ayuda a explotar mis habilidades dentro de una institución como lo es el Tec, que me ayuda a explorar nuevos caminos y abrirme nuevos rumbos en mi vida profesional.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00227346/8d867844-9a78-4e86-b20e-c3457f88224b.mp4',
    carrera: 'ISC',
    campus_id: 'Guadalajara',    gen: '5a generación',
  },
{
    nombre: 'Lizbeth Guadalupe Campas Ruiz',
    contenido: '',
    carrera_id: 'LAE',
    campus: 'Guadalajara',
    contenido_breve: 'Para mi ser líder del mañana representa un gran honor y orgullo, que me pone la piel chinita de sólo recordar cuando me dieron la noticia. Sin embargo así como representa felicidad representa un compromiso conmigo, con el tec de Monterey y con mi país.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00227357/a34acb07-a6f9-4830-aeb4-dbaf78185eac.mp4',
    carrera: 'LAE',
    campus_id: 'Guadalajara',    gen: '5a generación',
  },
{
    nombre: 'José Miguel Beltrán Cinco',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Monterrey',
    contenido_breve: 'Para mi, ser un líder del mañana significa una oportunidad. Una oportunidad para crecer, aprender, salir adelante y ayudar a otros. Darme cuenta de que todas las habilidades que gano las puedo poner a la disposición de los demás es mi razón de seguir.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00227714/6e338eaf-4d1c-4766-a66b-24061f957a96.mp4',
    carrera: 'ITC',
    campus_id: 'Ciudad Obregón',    gen: '7a generación',
  },
{
    nombre: 'Lizbeth Aide Cortés Vega',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Monterrey',
    contenido_breve: 'Ser parte del programa líderes del mañana es un gran honor y me siento muy agradecida por ello. Dicho agradecimiento lo debo transformar en compromiso por aprender lo más que pueda y posteriormente beneficiar a mi país y entorno.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00344511/6e3b39bb-f2b8-4967-acdc-63a8027febc8.mp4',
    carrera: 'IBT',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Jorge Ernesto Ocampo Mora',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Monterrey',
    contenido_breve: 'Ser Líder del Mañana es para mi una oportunidad no solo cumplir mis sueños de estudiar en el Tec sino el privilegio de pertenecer a una comunidad de jóvenes con la misma visión de transformación social en México',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00513681/0ac7372b-dfd9-4990-9a4d-7a1acc741eea.mp4',
    carrera: 'MC',
    campus_id: 'Monterrey',    gen: '3a generación',
  },
{
    nombre: 'Verónica Liliana Godínez Gómez',
    contenido: '',
    carrera_id: 'IMD',
    campus: 'Guadalajara',
    contenido_breve: 'Ser un líder del mañana ha sido un sueños cumplido, pero específicamente una oportunidad de transformar la vida de los demás, al mismo tiempo que transformo la mía con las diferentes herramientas que el programa me ha ofrecido.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00573141/5f3f977f-5cb8-4dff-80e0-52741495aef2.mp4',
    carrera: 'IMD',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Arely Teresita Hernández Caudillo',
    contenido: '',
    carrera_id: 'NEG',
    campus: 'León',
    contenido_breve: 'Es la mayor oportunidad que me han podido brindar tanto el Tec como los donadores. Ser un LDM es un gran orgullo por el hecho de poder continuar con mis estudios profesionales y un enorme sentido de reciprocidad con mi comunidad. Es gratitud y esperanza.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00573142/954988bd-a2cb-492a-8f39-805c80b96429.mp4',
    carrera: 'NEG',
    campus_id: 'León',    gen: '7a generación',
  },
{
    nombre: 'Paola Gabriela Mejía Almada',
    contenido: '',
    carrera_id: 'IFI',
    campus: 'Monterrey',
    contenido_breve: 'Creo que es un orgullo formar parte de esta distinción que nos permite cumplir nuestros sueños. Y que ademas me dieron la oportunidad de estudiar en la mejor universidad de Mexico.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00819063/9824fc1a-2c88-4863-b962-c2a6ed5998c1.mp4',
    carrera: 'IFI',
    campus_id: 'Monterrey',    gen: '2a generación',
  },
{
    nombre: 'Carlos Augusto Muñoz Escobar',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Ciudad de México',
    contenido_breve: 'Ser un Líder del Mañana es ser un agente de cambio en mi comunidad, un ser humano altruista, compasivo y generoso, empático con las problemáticas de mi México, consciente de mi poder transformador por mis acciones, innovador y visionario de la justicia.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00819203/d8e2c8d1-05bb-4888-a2da-132274565ccc.mp4',
    carrera: 'MC',
    campus_id: 'Ciudad de México',    gen: '2a generación',
  },
{
    nombre: 'Cynthia Nahomi Solís Angulo',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Monterrey',
    contenido_breve: 'Para mi ser un líder del mañana representa un honor y un compromiso gigante, a como pasan los años siendo un líder de mañana el titulo va cobrando mas peso y me es mas fácil visualizar la manera en la que yo quiero ayudar en un futuro. Gracias',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00820868/398ed3fd-b08b-49be-9f49-a0957eab7ff2.mp4',
    carrera: 'MC',
    campus_id: 'Monterrey',    gen: '3a generación',
  },
{
    nombre: 'Mónica Granados Ruiz',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Monterrey',
    contenido_breve: 'La oportunidad de cumplir nuestros sueños e impactar positivamente, junto con otros Líderes del Mañana, en nuestro entorno.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00821267/a482828e-8eb8-488c-9b92-933bdc4a7bd6.mp4',
    carrera: 'MC',
    campus_id: 'Monterrey',    gen: '3a generación',
  },
{
    nombre: 'Edgar Alejandro Rodríguez Gallegos',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Monterrey',
    contenido_breve: 'Es salir adelante pese a las circunstancias y vivir de forma correcta. No es necesario llamar la atención ni anunciar tu bondad; con el simple hecho de hacer las cosas bien y tratar a todos con dignidad es suficiente parta actuar como un Líder del Mañana.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00822028/614ae369-7fcb-45ea-8039-c9d4bf9dcf96.mp4',
    carrera: 'IMT',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Mayté Nayeli Ramírez Santillán',
    contenido: '',
    carrera_id: 'LRI',
    campus: 'Monterrey',
    contenido_breve: 'Para mí ser una Líder del Mañana es la mejor oportunidad de mi vida. Gracias a este maravilloso programa día con día me esfuerzo para crecer como persona y profesionista para ofrecer lo mejor de mí a la sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00822158/1fecc84e-aef2-4bea-901d-851608d3483c.mp4',
    carrera: 'LRI',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Liliana Margarita González Valdez',
    contenido: '',
    carrera_id: 'LEC',
    campus: 'Monterrey',
    contenido_breve: 'Ser Líder del Mañana es ser un agente de cambio, comprometerte con el futuro de las generaciones y de la propia para vivir en un entorno que brinde las mismas, o aun mas, oportunidades a los jovenes. Significa ser ejemplo y actuar con ejemplos.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/A00822230/files/f9e20b77-e25e-43d7-86cb-6e84b93f59d6.mp4',
    carrera: 'LEC',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Karla Esbeidy Zamora Ferniza',
    contenido: '',
    carrera_id: 'IID',
    campus: 'Monterrey',
    contenido_breve: 'Para mí, ser un Líder del manana es sin duda, un priveligio y un enorme compromiso con la sociedad y con mi país, de prepararme y poner mi talento al servicio de los demás para generar un impacto positivo en México.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00822776/cfe55c86-ce7d-4016-a592-8bd8a3ffa1f7.mov',
    carrera: 'IID',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Adriana Salinas Blancas',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Monterrey',
    contenido_breve: 'Líderes del Mañana es el mejor ejemplo de fe, amor y servicio por los demás; el saber que existen personas que aún sin conocerte están dispuestas a apostar por tus sueños es uno de los mejores regalos que alguien puede recibir, gracias por elegirme.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00823527/42025b86-1aef-4474-b8ea-44cacad47d88.mp4',
    carrera: 'MC',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Tábatha Sánchez Tejedor',
    contenido: '',
    carrera_id: 'LEC',
    campus: 'Monterrey',
    contenido_breve: 'En una plataforma que permite un cambio social y que lo hace a través de la mejor forma que es la educación.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00823720/c2cb7254-dbe1-4f10-9ba6-588c965d79e6.mp4',
    carrera: 'LEC',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Felipe de Jesús Sauceda López',
    contenido: '',
    carrera_id: 'IID',
    campus: 'Monterrey',
    contenido_breve: 'Es recibir una señal motivadora de que existen personas con una gran esperanza en los jóvenes. Por otro lado, ser Líder del Mañana es seguir el sueño de ayudar a México y querer ser un pilar para las personas. Ser líder es una oportunidad y un honor.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00823744/decbe5ed-ade7-48e1-98dc-d454402bd4d4.mp4',
    carrera: 'IID',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Karol García Estrada',
    contenido: '',
    carrera_id: 'LRI',
    campus: 'Monterrey',
    contenido_breve: 'Es un compromiso con la sociedad y con la institución que me otorga esta oportunidad. Ser un Líder es un recordatorio de que los límites no existen mientras tengas la meta en la mira.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00824404/43a86b85-3cdd-4a7e-ab30-17945333f35a.mp4',
    carrera: 'LRI',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Arturo Efrén Jiménez Garibaldy',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Ciudad de México',
    contenido_breve: 'Significa participar en el programa mas importante de transformación social.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00824428/1e928d88-075e-4341-8bd6-d722c504cfde.',
    carrera: 'ITC',
    campus_id: 'Ciudad de México',    gen: '5a generación',
  },
{
    nombre: 'Cheyra Lizeth Dávila Pérez',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Monterrey',
    contenido_breve: 'Ser un líder del mañana, además de representar un orgullo, es un compromiso con mi persona pero sobre todo con la sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00824501/cb4006ca-9069-4cf9-ae9e-2dac75c24b0a.mp4',
    carrera: 'IBT',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Lizeth Alondra Rodarte Martínez',
    contenido: '',
    carrera_id: 'LDI',
    campus: 'Monterrey',
    contenido_breve: 'Gracias a la oportunidad de ser líder del mañana, me estoy preparando y adquiriendo las herramientas necesarias para contribuir en la responsabilidad de reducir brechas sociales y generar cambios positivos en la sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00824576/922a670d-b4cb-49ba-aece-dbd497be7f3f.mp4',
    carrera: 'LDI',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Daniela Monserrat Chávez Nájera',
    contenido: '',
    carrera_id: 'IID',
    campus: 'Monterrey',
    contenido_breve: 'Para mí ser un líder del mañana es llegar a ser ese ciudadano ejemplar que logre ser el verdadero cambio que México necesita, además de impactar en la vida de más personas e impulsar a nuevos estudiantes',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00824599/de287372-9de6-4523-8100-3a350f3e2df1.mov',
    carrera: 'IID',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Erik Paul García Pérez',
    contenido: '',
    carrera_id: 'IC',
    campus: 'Monterrey',
    contenido_breve: 'Para mi representa una oportunidad de cambiar vidas y poder generar oportunidades para otros estudiantes o personas con situaciones difíciles.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00824663/bfd44443-0d58-4c6b-b35d-4191591f3195.mp4',
    carrera: 'IC',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Bryan Gerardo Calderón Castellanos',
    contenido: '',
    carrera_id: 'IC',
    campus: 'Monterrey',
    contenido_breve: 'Ser un Líder del Mañana representa una gran responsabilidad y un compromiso con la sociedad. Ser un líder implica tomar decisiones oportunas, ser capaz de gestionar equipos de trabajo e inspirar a los demás.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00824679/c5a06906-cafa-4279-8f7f-114f1f4a9c63.mp4',
    carrera: 'IC',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Alexis Geovanni Ramírez De la Rosa',
    contenido: '',
    carrera_id: 'LLE',
    campus: 'Monterrey',
    contenido_breve: 'Ser un líder del mañana es llevar a tu comunidad a donde sea que vayas, y siempre estar en la búsqueda de nuevas y mejores formas de apoyarles y de poner tus habilidades a su servicio.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00824723/e4d25922-9715-472e-8b68-37c8ae28145e.mp4',
    carrera: 'LLE',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Renatta Yanire González Escalera',
    contenido: '',
    carrera_id: 'INCQ',
    campus: 'Monterrey',
    contenido_breve: 'Compromiso con la comunidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00824795/28cec69a-f92f-4651-9876-51890e14240a.mov',
    carrera: 'INCQ',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Daniel Hernández Gallegos',
    contenido: '',
    carrera_id: 'IMD',
    campus: 'Monterrey',
    contenido_breve: 'Un estudiante del tecnológico de Monterrey con compromiso con la escuela y su comunidad de buscar un mejor mañana para su comunidad',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00824836/ddd3bd7c-2847-421f-a3d3-64219427d8a2.MOV',
    carrera: 'IMD',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Maximiliano Contreras Serrano',
    contenido: '',
    carrera_id: 'LAE',
    campus: 'Monterrey',
    contenido_breve: 'Siento que es una forma de potenciar lo que puedo dar a mi comunidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00825377/7c2b037e-4e7d-4fe3-adf4-6a89219da7ea.mov',
    carrera: 'LAE',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Alondra Lizeth Vázquez Alvarado',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Monterrey',
    contenido_breve: 'Para mí, ser un Líder del Mañana es un compromiso, con quienes hacen lo hacen posible, así como con la comunidad, pues para mí es importante aprovechar esta oportunidad para ayudar a más personas con lo aprendido.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00825383/2926f4e3-7db5-462b-b535-c560e97c51a8.mp4',
    carrera: 'MC',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Miguel Gabriel Olvera López',
    contenido: '',
    carrera_id: 'IID',
    campus: 'Monterrey',
    contenido_breve: 'Ser un Líder del Mañana significa ser una persona que inspira a los demas a seguir sus sueños, apoyandolos de la forma en que se pueda, haciendo un mundo mejor con las acciones personales o profesionales que realizamos.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00825482/214b9aea-2a09-40bb-a5b5-92f767acc6ca.mp4',
    carrera: 'IID',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Lucero Jaqueline Torres Vásquez',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Monterrey',
    contenido_breve: 'Ser un Líder del Mañana es portar el compromiso de que en mis acciones siempre procuraré buscar la manera de apoyar a mi comunidad, país e incluso al mundo; innovando, creando y soñando.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00825573/ee4ffad9-56c1-4b55-9049-fd3bc2820fd1.mp4',
    carrera: 'LIN',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Diana Cristina Andrade Damián',
    contenido: '',
    carrera_id: 'IMD',
    campus: 'Monterrey',
    contenido_breve: 'Ser un líder del mañana para mí significa representar a mi comunidad, ser un agente de cambio y un ejemplo para las personas a mi alrededor. Siempre trato de inspirar a otras personas a través de mis acciones, y de impactar positivamente en las personas.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00825592/d493ea60-043e-46fa-a4e5-4c281a2229cb.mp4',
    carrera: 'IMD',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Angélica Aguilar López',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Monterrey',
    contenido_breve: 'El ser líder del mañana consiste en un compromiso y una responsabilidad, implica saber que los donantes creyeron en ti y en tus capacidades para salir adelante. Consiste en ser recíproco y ayudar a otros.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00825685/ff480fb5-2e67-432c-8d6b-ae2f7dd29c31.mov',
    carrera: 'MC',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Isaac Alberto Escobedo Correa',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Monterrey',
    contenido_breve: 'Ser Líder del Mañana representa poner el corazón en todo lo que haces, impactando positivamente en tu vida y en la de las personas que te rodean.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00825785/c70ea739-8123-4deb-9fe0-0426779d228f.mp4',
    carrera: 'IIS',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Elías de Jesús Solórzano López',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Monterrey',
    contenido_breve: 'Es, para mi, un privilegio y un compromiso de gran transcendencia. Estoy orgulloso de serlo; también soy consciente de la responsabilidad que conlleva, formarme académica y socialmente y poder brindar un bien a la sociedad mexicana.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00825859/25005b6a-88ff-41b8-a737-a707130b39ea.mp4',
    carrera: 'MC',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Keyra Naomi Carranza Sánchez',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Monterrey',
    contenido_breve: 'Ser un Líder del Mañana para mí significa tener presente el compromiso de ser un agente de cambio, de compartir e inspirar a los que te rodean para que JUNTOS logremos crear el mundo que todos merecemos. Somos guías, mentores y apoyo para el que lo necesi',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00825925/14cd3a20-482a-47d9-ab80-d4d95f9fe601.mp4',
    carrera: 'LIN',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Marcela Barajas González',
    contenido: '',
    carrera_id: 'INT',
    campus: 'Monterrey',
    contenido_breve: 'Ser un líder del mañana es tomar cada oportunidad que se presente para hacer de México un mejor país.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00825958/9badbde9-ee84-4a85-8c54-7d279ec5b998.mov',
    carrera: 'INT',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Lizeth Alvarado Ramírez',
    contenido: '',
    carrera_id: 'INCQ',
    campus: 'Monterrey',
    contenido_breve: 'Para mí significa ser un ejemplo de oportunidad y factor de cambio en México. Es una manera de transmitir que la disciplina, trabajo duro y el amor por el servicio siempre será recompensado, en este caso estudiar la carrera de tus sueños.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00826013/54859436-5781-4465-bfad-0a46db4842f3.mp4',
    carrera: 'INCQ',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Cecilio Armengol García',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Monterrey',
    contenido_breve: 'Es un compromiso muy grande con mi persona, la universidad y con las generaciones futuras. Implica tener metas claras que vayan siempre con ideales de armonía hacía mi país y comunidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00826205/3e6ad714-0bac-4f45-8618-d33ff643d01f.mp4',
    carrera: 'MC',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Ilse Denisse Valladares Pacheco',
    contenido: '',
    carrera_id: 'IID',
    campus: 'Monterrey',
    contenido_breve: 'Ser un Líder del Mañana significa una oportunidad única para crecer y desarrollarme como persona y profesionista pero lo mejor es que esta beca no solo impacta mi vida personal sino también es una oportunidad para mi familia.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00826268/dab6e767-7e6e-4f4a-a36e-395c28473360.mp4',
    carrera: 'IID',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Kendy Azucena Rojo Hernández',
    contenido: '',
    carrera_id: 'IBQ',
    campus: 'Monterrey',
    contenido_breve: 'Ser parte de Líderes del Mañana significa tener a mi alcance la oportunidad de cumplir mis metas que se alínean a transformar mi entorno y causar un gran impacto positivo en la sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00827532/bbfcc6f0-0b00-4987-823a-304ea0f12edb.mp4',
    carrera: 'IBQ',
    campus_id: 'Monterrey',    gen: '6a generación',
  },
{
    nombre: 'Emiliano Guajardo Gloria',
    contenido: '',
    carrera_id: 'IFI',
    campus: 'Monterrey',
    contenido_breve: 'Significa ser un agente que impulsa la transformación de su comunidad',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00827563/1586eb50-df02-4b63-88f3-c2bb7518a8a4.mp4',
    carrera: 'IFI',
    campus_id: 'Monterrey',    gen: '6a generación',
  },
{
    nombre: 'Omar Eliazid Rangel Pérez',
    contenido: '',
    carrera_id: 'ESC',
    campus: 'Monterrey',
    contenido_breve: 'Es la puerta a un nuevo mundo lleno de oportunidades, retos y experiencias. Es el momento de nutrirnos para potencializar al universo que nos vio nacer, fungir cómo representantes de la humanidad para ampliar los marcos de la entrada al bienestar común.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00827967/2d7ccbc0-7cd7-40ae-82a8-eef200938c18.mp4',
    carrera: 'ESC',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Eunise Stefania Viera Molina',
    contenido: '',
    carrera_id: 'LP',
    campus: 'Monterrey',
    contenido_breve: 'Lo mejor que me ha pasado, gracias a este programa tengo una comunidad hermosa a mi alrededor.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00830305/91463161-7024-430a-94e2-e142b69772ae.mp4',
    carrera: 'LP',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'David Alejandro Caballero Medina',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Monterrey',
    contenido_breve: 'Ser Líder del Mañana representa una oportunidad de crecimiento, de optimismo para alcanzar mis metas. Para mí es un reto en el cual, si bien podrías no plantearte expectativas, por lo menos tener esperanza para la solución de desafíos a corto plazo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00830571/8640294a-9b03-49fb-9b73-54f52f8975cc.mp4',
    carrera: 'IMT',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Hermann Raúl Herrera Medina',
    contenido: '',
    carrera_id: 'LEC',
    campus: 'Monterrey',
    contenido_breve: 'Ser Líder para mi es formar parte de una comunidad exitosa, con objetivos centrados en dejar este mundo mejor de lo que lo encontramos, desde los profesores hasta nosotros mismos, estoy entusiasmado por tanto talento con el que hoy me rodeo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00831215/7305d250-1836-4709-8507-cacdcb242903.mp4',
    carrera: 'LEC',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Eduardo Antonio Melendres Luna',
    contenido: '',
    carrera_id: 'IMD',
    campus: 'Monterrey',
    contenido_breve: 'Ser un Líder del mañana es solo el primer paso para ser una persona ejemplar en un futuro, los lideres del mañana se nos ha encomendado una misión la cual es engrandecer a nuestro pías con nuestras buenas acciones y con ese compromiso de reciprocidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/A00831290/files/438e2245-b3a4-45c9-9914-c0bc7cb9b7cd.mp4',
    carrera: 'IMD',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Brittany Lizbeth Elizondo Villarreal',
    contenido: '',
    carrera_id: 'ESC',
    campus: 'Monterrey',
    contenido_breve: 'Ser parte de Líderes del Mañana para mí significa pertenecer a una familia en la que todos tenemos la visión de ser agentes de cambio y tener la oportunidad de seguirme desarrollando, tanto personal como profesionalmente en Líderes del Mañana y en el Tec.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00831352/ec08bb59-e13a-413c-96e0-06cdef3dc9cb.mp4',
    carrera: 'ESC',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Elida Lisset Rodríguez García',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Monterrey',
    contenido_breve: 'Para mí ser Líder del Mañana es una oportunidad de desarrollar aún más mi potencial en favor de mi comunidad y mi país, siempre aportando de manera positiva según mis capacidades y gustos.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00831598/5878c03d-d633-450c-8eb6-1a797bd1be86.mp4',
    carrera: 'ITC',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Luis Arturo Flores Asomoza',
    contenido: '',
    carrera_id: 'INCQ',
    campus: 'Ciudad de México',
    contenido_breve: 'Es saber que tienes una nueva familia con la cual intentar mejorar al mundo. Significa que tienes una chispa grandiosa, la cual tu nueva familia te guía y te ayuda para que entre todos, brindemos parte de nuestra chispa y hagamos brillar al mundo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00831636/4647e960-02c0-4f0b-be4c-95771b1c56d1.mp4',
    carrera: 'INCQ',
    campus_id: 'Ciudad de México',    gen: '7a generación',
  },
{
    nombre: 'Carlos Alonso Moreno Alcantar',
    contenido: '',
    carrera_id: 'IBQ',
    campus: 'Monterrey',
    contenido_breve: 'Ser Líder del Mañana es la mayor bendición que me ha dado la vida y considero que es una gran oportunidad para desarrollar mis habilidades al máximo y con ellas crear el cambio en la sociedad, haciendo el bien para todos.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00831671/c06a9a28-2007-44ee-b2d8-6c1bcc7d0eb9.mp4',
    carrera: 'IBQ',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Giselle Sabbagh Debernardi',
    contenido: '',
    carrera_id: 'ICI',
    campus: 'Monterrey',
    contenido_breve: 'Ser Líder del Mañana me da la oportunidad de potenciar en mi y en los demás el talento que tenemos y el brillo que nos caracteriza para poder lograr un cambio en cualquier ámbito, porque un líder no crea seguidores, crea más líderes.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00831817/c3af8767-b02b-408f-8514-4bf5b44d8ba2.mp4',
    carrera: 'ICI',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Celso Francisco Escalante Flores',
    contenido: '',
    carrera_id: 'LCPF',
    campus: 'León',
    contenido_breve: 'Ser un líder del mañana es una puerta que se me ha abierto en la cual mis miedos y mis limitaciones se ven diminutas, en donde sé que tengo las capacidades y habilidades para lograr lo que me proponga y una oportunidad para poder dejar huella.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00832023/c8b25ed9-e979-4582-8333-666e2a61fee1.mp4',
    carrera: 'LCPF',
    campus_id: 'León',    gen: '7a generación',
  },
{
    nombre: 'Dana Paola Rosete Gómez',
    contenido: '',
    carrera_id: 'IMD',
    campus: 'Monterrey',
    contenido_breve: 'Es una gran oportunidad de crecimiento y aprendizaje, además de una manera de crear lazos para seguir cambiando el mundo, apoyando comunidades, cuidando el ambiente. Líderes del mañana es parte de mi familia y estoy orgullosa de ser parte de este proyecto',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00832064/efc6ca3a-0cbc-4707-97ad-6962300649dd.mp4',
    carrera: 'IMD',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Mauricio Antonio Torres Márquez',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Monterrey',
    contenido_breve: 'Significa estar consciente de que dentro de nosotros está la capacidad y el deseo de cambiar todo aquello que puede mejorarse y convertirse en algo más',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A00832073/2e882410-1d6f-4923-ba1d-59a0405128c3.mp4',
    carrera: 'IMT',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Ana Paula González Lázaro',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Ciudad de México',
    contenido_breve: 'Es una nueva oportunidad todos los días. Una oportunidad de luchar por el cambio en mi comunidad y país. De ayudar de los demás, y ayudarme a mí misma a crecer y ser mejor persona, así como continuar con mis estudios. Y de impulsar los sueños de los demás',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01025554/66891cfa-5478-4714-8b79-b6fa17f2924a.mp4',
    carrera: 'MC',
    campus_id: 'Ciudad de México',    gen: '7a generación',
  },
{
    nombre: 'Daniel Salazar Páez',
    contenido: '',
    carrera_id: 'LEF',
    campus: 'Santa Fe',
    contenido_breve: 'Es un orgullo y un gran logro formar parte del programa, pues me ha abierto muchas puertas para mi desarrollo personal, académico y profesional.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01025833/95e453ae-5533-41de-a9e0-a296bcd4a51e.mp4',
    carrera: 'LEF',
    campus_id: 'Santa Fe',    gen: '4a generación',
  },
{
    nombre: 'Enrique Orduña Ávila',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Ciudad de México',
    contenido_breve: 'Ser líder del mañana para mí significa tener la misión de no rendirse ante los problemas que atañen al mundo, significa llevar esa responsabilidad de querer un México y un mundo mejor en el cual cada uno de nosotros trabajemos para todos.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01027318/8dc2fc58-c456-4dc3-bd65-b551bfb7a65a.mp4',
    carrera: 'ITC',
    campus_id: 'Ciudad de México',    gen: '5a generación',
  },
{
    nombre: 'Ricardo Mendoza Villa',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Morelia',
    contenido_breve: 'Desde que aplique para la beca lo pensé, y hoy lo confirmo. Ser un líder del mañana es la capacidad de soñar y creer que podemos lograr metas. Es desarrollarse en muchos ámbitos de la vida, pero sobre todo. SER UN LÍDER DEL MAÑANA ES UNA OPORTUNIDAD',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01067076/da70fe38-3304-4a16-a717-8111218c43cb.mp4',
    carrera: 'IMT',
    campus_id: 'Morelia',    gen: '3a generación',
  },
{
    nombre: 'Mayra Andrea Bucio Suárez',
    contenido: '',
    carrera_id: 'IMD',
    campus: 'Monterrey',
    contenido_breve: 'Es pasión, compromiso y amor por por compartir e inspirar a otros a ser su mejor versión para juntos generar una sociedad responsable con nuestro entorno. Ser consiente del poder que tenemos con cada una de nuestras acciones y usarlo para crear algo bueno',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01067249/fd9037d6-a68f-4466-adaa-ac4f1457e3ef.mp4',
    carrera: 'IMD',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Luis Fernando Aguilera Hurtado',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Monterrey',
    contenido_breve: 'Ser un Líder del Mañana representa dar un ejemplo de que las cosas pueden cambiar cuando empezamos primero por cambiar nuestra mentalidad; sin importar tus condiciones económicas tú puedes ser el cambio que necesita tu comunidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01067403/d9be2d79-43be-493e-bcb9-cd3f224ab513.mp4',
    carrera: 'IMT',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Fernando Pérez Romero',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Morelia',
    contenido_breve: 'Ser un líder del mañana para mi es ser parte de una gran familia. Somos cientos y tenemos un compromiso con nosotros mismos y con la sociedad debido a todo lo que nos han dado y por lo que nos han reconocido',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01067465/e1e74962-49b6-4b82-8859-4a95518be805.mp4',
    carrera: 'LIN',
    campus_id: 'Morelia',    gen: '5a generación',
  },
{
    nombre: 'Juan Guillermo Camacho Solorio',
    contenido: '',
    carrera_id: 'IIT',
    campus: 'Querétaro',
    contenido_breve: 'Para mi ser un Líder del Mañana representa esperanza, pero sobre todo una gran transformación, pues la reciprocidad es algo que nos distingue y estoy seguro que con el conocimiento que nos brinda el Tec de Monterrey haremos grandes cambios en México.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01067689/7c8217f3-6dd2-4c19-b5d8-02e101bdfdf3.mp4',
    carrera: 'IIT',
    campus_id: 'Morelia',    gen: '7a generación',
  },
{
    nombre: 'Yulyet Alessandra Zavala Rangel',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Toluca',
    contenido_breve: 'Ser Líder del Mañana es sinónimo de ser una persona con valores, creencias y metas encaminadas hacia mejorar el mundo, comenzando por nosotros mismos y nuestro alrededor. La reciprocidad y el sentido humano forman una parte elemental de cualquier LDM.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01067988/1313dca5-9fcc-497d-afd8-7bb11d38d174.mp4',
    carrera: 'IBT',
    campus_id: 'Morelia',    gen: '7a generación',
  },
{
    nombre: 'Nomar Jotziel Colmenero Núñez',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Monterrey',
    contenido_breve: 'Ser un líder del mañana es tener esta responsabilidad social ligada de la gratitud, en generar oportunidades a las personas con capacidades destacables pero con carencias que impidan su desarrollo y de esta manera llevar a cabo una formación colectiva.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01114649/175c4302-fb80-4cab-8ee8-67743806ea32.txt',
    carrera: 'IMT',
    campus_id: 'Querétaro',    gen: '5a generación',
  },
{
    nombre: 'Manuel Guadalupe Gil Jacobo',
    contenido: '',
    carrera_id: 'LAE',
    campus: 'Monterrey',
    contenido_breve: 'Ser un Líder del Mañana es la dualidad entre recibir una oportunidad que cambio mi vida y asumir la respondabilidad que esta oportunidad conlleva. Para mí, ser es ser el representante de la esperanza que alguien vio en mi persona para cambiar a México.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01114863/456f1667-ea67-48de-8bc8-c94760d45eaf.mp4',
    carrera: 'LAE',
    campus_id: 'Monterrey',    gen: '3a generación',
  },
{
    nombre: 'Ángel José Solís Domínguez',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Monterrey',
    contenido_breve: 'Líderes del Mañana es una oportunidad invaluable que nos impulsa a continuar luchando por alcanzar nuestros sueños y en el camino impactar a nuestras familias, a nuestras comunidades y generar un cambio positivo en nuestros entornos.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01174157/5c40ae8f-34a8-4c9b-b97c-c5d6d1684cae.mp4',
    carrera: 'IMT',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Camila Cárdenas Aguilar',
    contenido: '',
    carrera_id: 'LMC',
    campus: 'Querétaro',
    contenido_breve: 'Para mí ser líder del mañana, es ser un ganador de la lotería, por la oportunidad que representa, sin embargo es equivalente al nivel de compromiso que conlleva, ya que, por compromiso moral debemos crear oportunidades que se equiparen a la nuestra.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01174194/558d5120-cf00-45d3-9566-00e9f20e3393.mp4',
    carrera: 'LMC',
    campus_id: 'Querétaro',    gen: '5a generación',
  },
{
    nombre: 'Celia Lizeth Morales Vázquez',
    contenido: '',
    carrera_id: 'LRI',
    campus: 'Ciudad de México',
    contenido_breve: 'Es poder crecer, aprender, salir adelante, estar un paso más cerca de lograr mis metas y a la vez tener la oportunidad de ayudar a los demás, de generar un cambio que nos beneficie a todos, es lo más importante y valioso que tengo después de madre.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01174208/4a5f20f0-9797-4283-bdc1-103d115d9472.mp4',
    carrera: 'LRI',
    campus_id: 'Ciudad de México',    gen: '5a generación',
  },
{
    nombre: 'Andrea Jazmín Guzmán Cruz',
    contenido: '',
    carrera_id: 'NEG',
    campus: 'Monterrey',
    contenido_breve: 'Significa ser cool y hacerse notar, siempre estar dispuestos a ayudar a México aportando nuestro granito de arena a la comunidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01174724/459b88ed-778a-4fee-a3c2-d42dfca667dd.mp4',
    carrera: 'NEG',
    campus_id: 'Chiapas',    gen: '7a generación',
  },
{
    nombre: 'Johan Leonardo Méndez Trujillo',
    contenido: '',
    carrera_id: 'NEG',
    campus: 'Monterrey',
    contenido_breve: 'Ser un LDM es una gran oportunidad de crecimiento y cambio en todos los niveles que beneficie a la sociedad y sobre todo te genere un autoconocimiento que permita ayudar a todo el mundo',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01174725/696d07d5-9958-4a7b-b36b-25a40f2c073d.mp4',
    carrera: 'NEG',
    campus_id: 'Chiapas',    gen: '7a generación',
  },
{
    nombre: 'Paola Cerecedo Cruz',
    contenido: '',
    carrera_id: 'IIA',
    campus: 'Querétaro',
    contenido_breve: 'Ser día con día un agente de cambio en todos los aspectos, es actuar con responsabilidad, honestidad, reciprocidad e inteligencia emocional, manteniendo siempre una visión de mejora y crecimiento',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01208040/ef81732f-4884-4ef7-ad0c-09c04f57e537.mp4',
    carrera: 'IIA',
    campus_id: 'Querétaro',    gen: '3a generación',
  },
{
    nombre: 'Mariana Yunuen Moreno Becerril',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Querétaro',
    contenido_breve: 'Ser un Líder del Mañana es inspirar a los demás, es tener el deseo de aplicar sus conocimientos para beneficiar a su entorno, es estar enfocado en buscar soluciones, es ser sencillo, tenaz, comprometido y generoso, es un líder cambiará el futuro de México',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01209117/81ad349e-a368-4488-a587-a1253d9b6918.mp4',
    carrera: 'IBT',
    campus_id: 'Querétaro',    gen: '5a generación',
  },
{
    nombre: 'Ingrid Shaiany Chan Topete',
    contenido: '',
    carrera_id: 'ITE',
    campus: 'Guadalajara',
    contenido_breve: 'Para mi ser Líder del mañana es una gran responsabilidad de utilizar el conocimiento que me fue dado para cumplir el propósito por el cual estoy en esta vida. Ser Líder del mañana es estar totalmente agradecida por cada persona involucrada en cambiar vida',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01227694/c2361484-5a2b-45ba-8087-e2ab8e998eb3.mp4',
    carrera: 'ITE',
    campus_id: 'Guadalajara',    gen: '4a generación',
  },
{
    nombre: 'Andrea Reyna Casas',
    contenido: '',
    carrera_id: 'ARQ',
    campus: 'Laguna',
    contenido_breve: 'Para mí ser un líder del mañana significa una responsabilidad directa con la sociedad que me rodea, con el compromiso de crear un México mejor para todos y un mundo donde podamos avanzar juntos.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01235154/ace1e601-c42d-4e4b-9f99-36cc292f9c99.mp4',
    carrera: 'ARQ',
    campus_id: 'Laguna',    gen: '3a generación',
  },
{
    nombre: 'Monserrat Romo Molina',
    contenido: '',
    carrera_id: 'LDI',
    campus: 'Laguna',
    contenido_breve: 'Ser líder del mañana es una gran oportunidad que me ha cambiado la vida completamente y un compromiso para mejorar mi entorno',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01235425/e1df6296-7e4c-42cb-b321-215c1cdb20c8.MOV',
    carrera: 'LDI',
    campus_id: 'Laguna',    gen: '4a generación',
  },
{
    nombre: 'Alan Giovanny Ríos Orrante',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Laguna',
    contenido_breve: 'Representa un compromiso con la sociedad, en el Tec de Monterrey nos dan las herramientas para salir adelante y nosotros tenemos la responsabilidad social de tenderle la mano a todos en nuestro entorno.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01235601/b4533664-4cb4-4752-bdb3-807ff55aa0f3.mp4',
    carrera: 'IMT',
    campus_id: 'Laguna',    gen: '5a generación',
  },
{
    nombre: 'Luis Manuel Pérez Sánchez',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Laguna',
    contenido_breve: 'Una persona con comprometida a ayudar a la sociedad y que siemrpre está buscando formas de innovar para poder beneficiar a las personas que lo roedan. Alguien dedicado con mucho optimismo y esperanza en el futuro, que siempre está dispuesto a ayudar.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01235687/e1aa7484-f1e3-4cfd-8343-3bd8a9416230.mp4',
    carrera: 'IMT',
    campus_id: 'Laguna',    gen: '5a generación',
  },
{
    nombre: 'Marian Lizbeth Ramírez González',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Laguna',
    contenido_breve: 'Para mi ser un líder del mañana representa ser un agente del cambio, una persona que aprovecha las oportunidades que se le han dado, no solo para el mismo si no para quienes lo rodean. Siempre tratando de encontrar nuevas areas de oportunidad para mejorar',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01235717/b4875663-5a4a-4ef8-90f6-2b81402651db.mp4',
    carrera: 'IMT',
    campus_id: 'Laguna',    gen: '5a generación',
  },
{
    nombre: 'Christopher Wan-Yat Dorado Woo',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Laguna',
    contenido_breve: 'Un líder del mañana es el ejemplo de que a pesar de las adversidades se pueden cumplir las metas. Ser un líder del mañana es hacer lo que crees correcto en todo momento, es salir adelante sólo por ti mismo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01235737/9b349469-272a-4841-973c-37e0353f0f05.mp4',
    carrera: 'IMT',
    campus_id: 'Laguna',    gen: '5a generación',
  },
{
    nombre: 'Darian Muñoz Del Río',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Laguna',
    contenido_breve: 'Ser un líder del mañana para mi e un gran honor y a la vez una gran responsabilidad, es ser un ejemplo de superación y una inspiración para aquellos chicos que deseen estudiar y ser mejores, que deseen ser personas íntegras.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01235739/f2a9f5ac-0fa9-4015-b02a-13ff01bcddb2.mp4',
    carrera: 'LAF',
    campus_id: 'Laguna',    gen: '5a generación',
  },
{
    nombre: 'Manuel Antonio Hernández De la O',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Laguna',
    contenido_breve: 'Es ser un agente de cambio en la sociedad, una persona que impulsa a otras y a si mismo a ser mejor cada día.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/A01235764/files/2231d9dd-3fbf-4f39-9215-2f9468bbf2d8.mp4',
    carrera: 'IMT',
    campus_id: 'Laguna',    gen: '5a generación',
  },
{
    nombre: 'Litzy Cristina Casas Muñoz',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Laguna',
    contenido_breve: 'Es ser un líder hoy, ver por el bien común de nuestra sociedad, ser un agente de cambio y contribuir con acciones para generar un impacto positivo en el país, que logre brindar mejores condiciones de vida a las próximas generaciones.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01235903/e61c441b-3e1c-40ba-a4e5-db3d3ab0f15c.mov',
    carrera: 'LIN',
    campus_id: 'Laguna',    gen: '7a generación',
  },
{
    nombre: 'Armando Galaviz Esparza',
    contenido: '',
    carrera_id: 'NEG',
    campus: 'Laguna',
    contenido_breve: 'Una oportunidad para demostrar que existe mucho talento en nuestro país que no florece por falta de oportunidad y un compromiso, tanto con mi comunidad como para aquellos que confiaron en mí y en el programa,para demostrar que no se equivocaron en confiar',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01236063/e92c24b7-3174-4f19-a378-9a77259a2dee.mp4',
    carrera: 'NEG',
    campus_id: 'Laguna',    gen: '7a generación',
  },
{
    nombre: 'Arnulfo Alejandro Morales Guzmán',
    contenido: '',
    carrera_id: 'IIT',
    campus: 'Monterrey',
    contenido_breve: 'Es representar a miles de jóvenes de mi país y buscar una transformación social. El ser un líder del mañana te llena de la búsqueda por mejorar al país para un futuro más sostenible y lleno de valores. La distinción promueve dar un voto de confianza.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01236064/356d4975-a662-4a06-8d04-635e8bb35acf.mp4',
    carrera: 'IIT',
    campus_id: 'Laguna',    gen: '7a generación',
  },
{
    nombre: 'Leslie Elizabeth Herrera Guerrero',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Monterrey',
    contenido_breve: 'Para mi ser una Líder del mañana es tener la voz y el poder de contribuir a nuestro país, es formar parte de una red de personas interesadas en mejorar las condiciones de los demás. De la mano de la filantropía y la solidaridad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01236100/80bf877f-1e66-4c13-9967-473ca19d7419.mp4',
    carrera: 'IBT',
    campus_id: 'Laguna',    gen: '7a generación',
  },
{
    nombre: 'Luis Ángel Gramillo Estrada',
    contenido: '',
    carrera_id: 'IFI',
    campus: 'Monterrey',
    contenido_breve: 'Es una persona que dispone su ser, sus habilidades, conocimientos y competencias en pro de la humanidad, es aquella persona que busca un bien común y está dispuesto a luchar contra sus propios demonios para encontrar su bienestar y el de su pueblo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01236136/6d47a088-19a7-4e8f-b381-2da6eb6ae410.mp4',
    carrera: 'IFI',
    campus_id: 'Laguna',    gen: '7a generación',
  },
{
    nombre: 'Karen Lizbeth Estévez Sánchez',
    contenido: '',
    carrera_id: 'IID',
    campus: 'Monterrey',
    contenido_breve: 'Para mí ser un líder del mañana es asumir un compromiso no solo con la beca y el Tec, sino con tu país, de que harás cosas positivas y de gran impacto, que transformen tu vida y lo que es más importante; la vida de otros para bien.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01246288/a8b7e76a-43d5-4ddf-b001-5b29fac4d4bd.mp4',
    carrera: 'IID',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Marco Antonio López Chávez',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Chihuahua',
    contenido_breve: 'Ser Líder del Mañana es tanto una responsabilidad como un honor ya que tienes la capacidad y las herramientas para cambiar ideales para cambiar el mundo en el que vivimos, no solo en un aspecto económico sino social y humano mediante la empatía.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01246401/df0fc63f-a289-4050-ad1a-a352b952ccba.mp4',
    carrera: 'IMT',
    campus_id: 'Chihuahua',    gen: '5a generación',
  },
{
    nombre: 'Raúl Adrián Delgado Rodríguez',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Querétaro',
    contenido_breve: 'Es un honor y orgullo formar parte de una familia extraordinaria que destaca dentro de la comunidad TEC por su compromiso social y sobre todo, porque nos une un compromiso: el hacer un impacto positivo en la sociedad y el sentido de reciprocidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01246414/6f34fd8b-3783-4d20-b070-1640e071e852.mp4',
    carrera: 'IMT',
    campus_id: 'Querétaro',    gen: '5a generación',
  },
{
    nombre: 'Angélica Alemán Cabrales',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Chihuahua',
    contenido_breve: 'Ser líder del mañana ha sido una oportunidad para que mi vida cambiará y para seguir creciendo como persona. ser estudiante, compañera y mexicana. Es la oportunidad de cambiar a mi país y dejar huella en él y en quienes lo estamos formando.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01246666/bba1bf4d-3c03-4866-b146-7da60084731d.mp4',
    carrera: 'ITC',
    campus_id: 'Ciudad Juárez',    gen: '7a generación',
  },
{
    nombre: 'Jesús Antonio Vázquez Ceja',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Sonora Norte',
    contenido_breve: 'Es un orgullo, ya que muchas personas confían en tu futuro y en lo que puedas devolver a la sociedad con ello',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01251980/1861c2d1-aa11-4eea-8672-db557a991ae7.mp4',
    carrera: 'IMT',
    campus_id: 'Sonora Norte',    gen: '4a generación',
  },
{
    nombre: 'Melissa Sofía Meza Sánchez',
    contenido: '',
    carrera_id: 'LDI',
    campus: 'Sonora Norte',
    contenido_breve: 'Para mi ser un líder del Mañana, es una oportunidad y un compromiso por mejorar mi entrono y mi vida. Es aquello que me permite seguir soñando y trabajando por las cosas que considero que valen la pena en el mundo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01252594/76b90df8-6693-41f8-8404-57338a35b732.mp4',
    carrera: 'LDI',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Jesús Abraham Haros Madrid',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Monterrey',
    contenido_breve: 'Para mí, significa ser un agente de cambio en mi comunidad, significa estar comprometido para mejorar mi entorno, crear oportunidades y abrir camino para nuevos retos. Significa usar mis conocimientos técnicos para resolver problemas sociales.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01252642/a4f5052b-40cb-40b1-a695-81092b3c4db7.mov',
    carrera: 'ITC',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Ana Cristina Franco Cota',
    contenido: '',
    carrera_id: 'LMC',
    campus: 'Sonora Norte',
    contenido_breve: 'La importancia de ser Líder del Mañana es muy emocionante y retador, ya que considero que es la oportunidad para poder desarrollar todas mis habilidades. Siento que es un cambio en todos los sentidos, desde la educación hasta tu reacción hacia problemas.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01252648/b2072394-c65d-45d1-99f6-32e4479bc519.mp4',
    carrera: 'LMC',
    campus_id: 'Sonora Norte',    gen: '5a generación',
  },
{
    nombre: 'Luis Alfonso Santa Cruz Hernández',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Sonora Norte',
    contenido_breve: 'Es una enorme oportunidad que se me ha dado y se trata de ser un elemento activo en la comunidad, emprendedor, y que devuelve lo que se le ha dado.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01252753/5ffa4eae-28ae-4881-999c-bb03a519c012.mp4',
    carrera: 'IMT',
    campus_id: 'Sonora Norte',    gen: '5a generación',
  },
{
    nombre: 'Delia Gabriela Benavides Pérez',
    contenido: '',
    carrera_id: 'LRI',
    campus: 'Monterrey',
    contenido_breve: 'Significa una oportunidad que me cambió totalmente la vida, estoy muy orgullosa de ser parte de este grupo de personas tan especiales. También significa retarme a mi misma dia a día para ser mejor y hacerle honor al titulo de lider del mañana',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01252769/559e5a84-a861-44ac-97b3-8277156c7dda.mov',
    carrera: 'LRI',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'María Regina Meza Sánchez',
    contenido: '',
    carrera_id: 'CIS',
    campus: 'Sonora Norte',
    contenido_breve: 'Para mi ser líder del mañana significa ser un agente de cambio en tu comunidad y entorno, representa una oportunidad para lograr mis sueños y motivar a otros jóvenes; sin duda alguna va más allá de una beca, pues trasciende a todos los ámbitos de mi vida.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01253535/f975b953-d853-4911-ae36-861d846094ae.mp4',
    carrera: 'CIS',
    campus_id: 'Sonora Norte',    gen: '7a generación',
  },
{
    nombre: 'Ana Alicia Quijada Sánchez',
    contenido: '',
    carrera_id: 'ICI',
    campus: 'Monterrey',
    contenido_breve: 'Es aquel que inspira a otras personas a hacer un cambio en su comunidad, es un joven perseverante, responsable, resiliente, solidario, empático, que día a día lucha por lograr cada una de las metas que se propone, alguien siempre dispuesto a ayudar.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01253539/b7f97f4e-6804-4658-a749-f9ff986f3fae.mp4',
    carrera: 'ICI',
    campus_id: 'Sonora Norte',    gen: '7a generación',
  },
{
    nombre: 'Daniela Hernandez Vazquez',
    contenido: '',
    carrera_id: 'ESC',
    campus: 'Monterrey',
    contenido_breve: 'Ser Líder del Mañana significa estar consciente de la responsabilidad que llevo de poner en alto el nombre de la institución y de mi país. Significa tener mentalidad de transformación y disposición al cambio, y tener la oportunidad de cumplir mis metas.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01253825/de6c3ed6-2e41-49b1-a2b8-638cf0ffec76.mp4',
    carrera: 'ESC',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Dafne Abigail Carranza Maldonado',
    contenido: '',
    carrera_id: 'LCPF',
    campus: 'Hidalgo',
    contenido_breve: 'Para mí ser un Líder del Mañana significa ser un agente de cambio para la sociedad y buscar prepararte con las mejores herramientas para poder beneficiar a quienes te rodean y que tal vez no cuentan con las mismas oportunidades que tú..',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01274217/17178ea8-0c4f-43aa-8eda-fd374944b9bf.MOV',
    carrera: 'LCPF',
    campus_id: 'Hidalgo',    gen: '5a generación',
  },
{
    nombre: 'Diana Itzel Salgado Vargas',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Hidalgo',
    contenido_breve: 'Para mí ser Líder del Mañana ha representado una de las oportunidades más grandes de mi vida. Ser LDM es ser parte una red comprometida con el hoy y con el futuro, con la transformación, el esfuerzo y el compromiso.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01274818/6fcf43f8-ca3e-4b21-acc0-bea731a6c01a.mp4',
    carrera: 'IIS',
    campus_id: 'Hidalgo',    gen: '3a generación',
  },
{
    nombre: 'Dafne Estephania Galván Valadez',
    contenido: '',
    carrera_id: 'IDS',
    campus: 'Monterrey',
    contenido_breve: 'Para mí este programa es una gran oportunidad que representa mucho compromiso y agradecimiento.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/A01275135/files/1a4c50ef-84c3-40d2-80bc-3bb2407a7c7b.mp4',
    carrera: 'IDS',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Ana Bertha Adame Durán',
    contenido: '',
    carrera_id: 'LAE',
    campus: 'Hidalgo',
    contenido_breve: 'Ser líder del mañana es un orgullo y una responsabilidad. El haber tenido la oportunidad de estudiar en la mejor escuela privada de México me ayudó a crecer como persona y a ver el mundo de una manera distinta, fortaleció mi deseo de cambiar a México.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01275396/e6ff1da5-d655-43bd-bad2-1d7cc8f47ebb.mp4',
    carrera: 'LAE',
    campus_id: 'Hidalgo',    gen: '4a generación',
  },
{
    nombre: 'Félix Arturo Hinojosa Reyna',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Monterrey',
    contenido_breve: 'Ser líder del mañana, significa tener el poder de generar un impacto positivo en mi comunidad, generando soluciones innovadoras a problemáticas sociales.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01275838/f4458203-148e-47c9-becd-25955f03ff0c.MOV',
    carrera: 'ITC',
    campus_id: 'Hidalgo',    gen: '5a generación',
  },
{
    nombre: 'Alberto Estrada Guerrero',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Monterrey',
    contenido_breve: 'Significa tener la responsabilidad de hacer el cambio que nuestro país necesita, sobre poder tomar acción y de esta forma ser un ejemplo para las próximas generaciones.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01276671/38d57ea4-adf2-4a1e-99f2-e74e70b8dffe.mp4',
    carrera: 'ITC',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Jairo Barrera Hernández',
    contenido: '',
    carrera_id: 'ICT',
    campus: 'Guadalajara',
    contenido_breve: 'Es una oportunidad de adquirir conocimientos y habilidades los cuales me serán de ayuda para llevar a cabo mi compromiso de generar un cambio en mi comunidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01276795/8b0c0c4e-a5f0-4643-a035-5c293ee3c518.mp4',
    carrera: 'ICT',
    campus_id: 'Hidalgo',    gen: '7a generación',
  },
{
    nombre: 'Elliott Bradford López Montiel',
    contenido: '',
    carrera_id: 'ISD',
    campus: 'Puebla',
    contenido_breve: 'Significa ser parte de un sistema de valores enfocados al empoderamiento personal a traves de la sociedad, recobocer que en uno reside la oportunidad y obligación de usar nuestras habilidades para hacer del entorno que nos rodea algo mejor.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01327179/8688a823-7b1a-4a68-90fe-cb9384026c0c.mp4',
    carrera: 'ISD',
    campus_id: 'Puebla',    gen: '3a generación',
  },
{
    nombre: 'Lisa Isabel Pimentel Suárez',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Ciudad de México',
    contenido_breve: 'Al pasar del tiempo entiendo que es un compromiso que nace desde el amor y la reciprocidad, es una promesa de bienestar propio y comunitario, es una semilla que florecerá en otras personas, es revolucionar tu sistema de creencias y seguir soñando.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01334434/52f1625b-e058-4c0a-b419-4e59ae720eb0.mov',
    carrera: 'MC',
    campus_id: 'Ciudad de México',    gen: '3a generación',
  },
{
    nombre: 'Gerardo Díaz Santander',
    contenido: '',
    carrera_id: 'IMA',
    campus: 'Puebla',
    contenido_breve: 'Ser un agente de cambio, demostrar que con mis habilidades puedo hacer algo que cause un beneficio a gran escala.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01339156/dc7b9ffd-a96a-4cae-8999-10de93856505.mp4',
    carrera: 'IMA',
    campus_id: 'Puebla',    gen: '4a generación',
  },
{
    nombre: 'Jorge Emiliano Báez Herrera',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Monterrey',
    contenido_breve: 'Para mi es una oportunidad de poder dar ese gran salto a cumplir mis sueños, una oportunidad de demostrarle a los jóvenes que alcanzar tus metas es posible, pero sobre todo es ese gran trampolín que me llevará a en un futuro poder apoyar a más jóvenes.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01351083/e77aa718-11d6-4a65-b71e-901c9418cd97.mp4',
    carrera: 'MC',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Yessica Vidal Castellanos',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Toluca',
    contenido_breve: 'Para ser un líder del mañana es la mejor oportunidad que se me ha presentado para poder apoyar a mi familia, amigos, pueblo, estado, con mi granito de arena para poder vivir en una comunidad mejor, apoyar a personas que realmente lo necesitan.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01366760/a41b5d7c-dd2b-48df-b2b7-620f22edf415.mp4',
    carrera: 'IIS',
    campus_id: 'Toluca',    gen: '3a generación',
  },
{
    nombre: 'Ángel Leonardo Méndez Camacho',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Toluca',
    contenido_breve: 'La oportunidad de crecimiento personal y profesional que permite tener herramientas e ideas para construir un México mejor. Lideres del mañana significa el cambio que nuestro país requiere para salir adelante de manera constante.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01367029/8ea39a97-3ded-4bec-b5d9-12a95ab20e26.MOV',
    carrera: 'IBT',
    campus_id: 'Toluca',    gen: '3a generación',
  },
{
    nombre: 'Alejandro Gabriel Hernández',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Toluca',
    contenido_breve: 'La evidencia de que creer en el talento e invertir en la educación puede cambiar nuestra sociedad. Pero a su vez la hipoteca social que tengo con mi país y el compromiso de corresponder todo lo recibido para continuar potenciando este cambio.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01367757/6cf345b6-0492-4464-9e27-1286f19954fa.mov',
    carrera: 'IIS',
    campus_id: 'Toluca',    gen: '4a generación',
  },
{
    nombre: 'Juan Leyva González',
    contenido: '',
    carrera_id: 'ISC',
    campus: 'Toluca',
    contenido_breve: 'Ser líder del mañana representa, esperanza indudablemente y un compromiso hermoso con muchas personas maravillosas que día a día hacen esto posible. Tener esta oportunidad a lado de nuestra familia es una flama de esperanza que quema el cuerpo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01368023/5f722202-fac5-4350-b484-c8f056efef15.mp4',
    carrera: 'ISC',
    campus_id: 'Toluca',    gen: '4a generación',
  },
{
    nombre: 'Diana Francisco Cantor',
    contenido: '',
    carrera_id: 'LCPF',
    campus: 'Toluca',
    contenido_breve: 'Es una oportunidad para hacer el cambio que nuestra sociedad necesita, es luchar por la educación. Buscar con base a nuestra profesión ayudar al prójimo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01368212/646715bb-1b05-40d3-a5cf-383d69ca28a3.m4v',
    carrera: 'LCPF',
    campus_id: 'Toluca',    gen: '4a generación',
  },
{
    nombre: 'Daisy María Díaz Sánchez',
    contenido: '',
    carrera_id: 'LCMD',
    campus: 'Toluca',
    contenido_breve: 'Ser líder del mañana es todo para mi. Así como implicó una gran oportunidad, también considero que es una responsabilidad por cambiar mi entorno y un compromiso de reciprocidad con mi universidad, mi familia y con los jóvenes mexicanos.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01368628/d10a45e6-d325-44d6-b809-65fee7af6d77.3gp',
    carrera: 'LCMD',
    campus_id: 'Toluca',    gen: '5a generación',
  },
{
    nombre: 'Dulce María Trejo Ayala',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Monterrey',
    contenido_breve: 'Ser lider del mañana es un título del cual me siento orgullosa, pues implica formar parte de una gran familia que busca y se esfuerza por lograr el desarrollo positivo de nuestro país, dejar una huella positiva en la sociedad y transformarla.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01368853/2881bba8-8111-4cc2-a55c-a79ae7b9fa36.mp4',
    carrera: 'IBT',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'María Elena López Hernández',
    contenido: '',
    carrera_id: 'LCPF',
    campus: 'Toluca',
    contenido_breve: 'Ser líder del mañana para mi significa una gran oportunidad para poder cambiar mi vida y la de mi familia, pero también representa una gran responsabilidad porque tengo en claro que así como me apoyaron a mi, yo debo ayudar a los demás.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01369109/4a862ead-5953-4396-bde8-2db0f6c5aa28.mp4',
    carrera: 'LCPF',
    campus_id: 'Toluca',    gen: '5a generación',
  },
{
    nombre: 'Rocío Acastenco Hernández',
    contenido: '',
    carrera_id: 'LCMD',
    campus: 'Ciudad de México',
    contenido_breve: 'Es tener como objetivo buscar la mejor versión de nuestro país, entorno, familia y propia.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01369110/9d1a3654-d9fc-4b71-b0ec-72e9520ddc68.3gp',
    carrera: 'LCMD',
    campus_id: 'Toluca',    gen: '5a generación',
  },
{
    nombre: 'Cristina Cadena José',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Toluca',
    contenido_breve: 'Para mí ser un Líder del Mañana representa un conjunto de posibilidades infinitas para ser una mejor persona, una excelente profesional y un agente de cambio que vele por el progreso de su comunidad, nación y del mundo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01369238/381919fa-fc77-45d6-946b-134fb483f8c3.mp4',
    carrera: 'LIN',
    campus_id: 'Toluca',    gen: '5a generación',
  },
{
    nombre: 'Christian Emmanuel Durán Bonilla',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Monterrey',
    contenido_breve: 'Líderes del Mañana significa el poder pertenecer a una familia comprometida por su entorno social, por su país y por el cambio social, desde el punto de vista solidario, empático, pero sobre todo humano, el poder mejorar la calidad de vida de los demás.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01369955/2719c14a-5746-41f3-8eb4-86d585ad2642.mp4',
    carrera: 'IMT',
    campus_id: 'Toluca',    gen: '6a generación',
  },
{
    nombre: 'Jessica Rubí Pérez San Lázaro',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Estado de México',
    contenido_breve: 'Ser líder del mañana es un compromiso tanto conmigo misma como con las personas que me rodean; formar parte de este programa me motiva a ser una mejor versión de mí. Es un orgullo poder ser parte de un programa tan noble y tan inspirador.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01377611/eb1927b3-bb47-4860-92ea-b063ea382990.mov',
    carrera: 'IMT',
    campus_id: 'Estado de México',    gen: '5a generación',
  },
{
    nombre: 'Isela Guadalupe Valdés Silva',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Saltillo',
    contenido_breve: 'Para mi ser líder del mañana es ser una persona comprometida y responsable con los demás ya que somos quienes vamos a generar ese cambio en nuestro país y quienes daremos difusión por medio de nuestras vivencias y oportunidades para que este programa crezca',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01382849/79a7d3b5-a40c-4541-b7af-64accc11830d.mp4',
    carrera: 'IIS',
    campus_id: 'Saltillo',    gen: '3a generación',
  },
{
    nombre: 'Emmanuel Cisneros Alfaro',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Saltillo',
    contenido_breve: 'Para mi ser un líder del mañana es ser alguien comprometido a mejorar su entorno social a través de los conocimientos y habilidades aprendidas, además de ser un motor de cambio que corresponda al compromiso de reciprocidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01383174/e39c8a4f-2efe-4ff2-ae5a-172c710aaad4.mp4',
    carrera: 'IIS',
    campus_id: 'Saltillo',    gen: '4a generación',
  },
{
    nombre: 'Brandon Alejandro Bernal Torres',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Saltillo',
    contenido_breve: 'Ser líder representa la oportunidad de formar parte de una comunidad e institución que te puede dar todas las herramientas y vínculos para lograr tus sueños, también una responsabilidad de tal vez no cambiar México por ti solo si no de poner tu granito.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01383238/0a3c123a-9f0e-4b97-835e-bfe19819903a.mp4',
    carrera: 'IMT',
    campus_id: 'Saltillo',    gen: '4a generación',
  },
{
    nombre: 'Lilieth Mendoza Galván',
    contenido: '',
    carrera_id: 'LAE',
    campus: 'Saltillo',
    contenido_breve: 'Ser un líder del mañana para mi implica una gran responsabilidad social porque no solo significa regresar un poco a la sociedad lo que me han brindando, sino también somos la inspiración de otros jóvenes.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01383520/0d795586-feb6-4432-b5d4-84e12ab68ec0.mp4',
    carrera: 'LAE',
    campus_id: 'Saltillo',    gen: '5a generación',
  },
{
    nombre: 'Mariel Pamela Gómez Aguilar',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Saltillo',
    contenido_breve: 'Ser un factor de cambio para nuestro país y una persona sumamente resiliente, así mismo tener el compromiso de brindar herramientas a la sociedad para que esta tenga mejores oportunidades.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01383549/f6f32bcb-532a-4372-8a2f-6431cd5e20c2.mp4',
    carrera: 'IMT',
    campus_id: 'Saltillo',    gen: '5a generación',
  },
{
    nombre: 'Karen Monserrat Rodríguez García',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Saltillo',
    contenido_breve: 'Ser líder del mañana es para mí una gran responsabilidad que se me fue dada, la porto con orgullo porque sé que hay alguien confiando en mí, para ser un líder de cambio.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01383568/4180123b-3132-4113-a86a-7a35252dd354.mp4',
    carrera: 'LIN',
    campus_id: 'Saltillo',    gen: '5a generación',
  },
{
    nombre: 'Miguel Alejandro Escobar González',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Monterrey',
    contenido_breve: 'Para mi ser LDM es una gran oportunidad y a su vez una gran responsabilidad, ya que el formar parte de este programa cambió totalmente mi vida, me ha permitido encaminarme aún más y de mejora manera a cumplir mis sueños.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01411716/b5ca5765-752b-4b84-9ed8-ef6391d62a46.mp4',
    carrera: 'IMT',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Patricia Janeth Fernández Santillán',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Monterrey',
    contenido_breve: 'Es la mejor oportunidad de mi vida para demostrar mi potencial, ayudar a otros y contar acerca de lo que este programa ha hecho en mi vida, es un motivo de orgullo y ha sido totalmente la oportunidad de que mi vida gire positivamente.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01411738/443c9706-e0fa-4585-9780-855ae9d3d48e.mp4',
    carrera: 'LAF',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Jennifer Arlene Castañeda Cruz',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Tampico',
    contenido_breve: 'Para mi ser líder del mañana es una gran oportunidad para poder cumplir mi sueño de ser una gran profesionista y retribuirlo en la sociedad para poder generar el verdadero cambio en las problemáticas sociales que existen en la actualidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01411965/d9b1c186-8567-4d7c-aa11-61bee3c8ddbe.mp4',
    carrera: 'IIS',
    campus_id: 'Tampico',    gen: '5a generación',
  },
{
    nombre: 'Carlos Yael Hernández Alcalá',
    contenido: '',
    carrera_id: 'LAE',
    campus: 'Monterrey',
    contenido_breve: 'Ser líder del mañana es un gran privilegio y compromiso, no solamente con la institución, sino también con la sociedad en general. La gente puso mucho esperanza en nosotros, y ahora nos toca a nosotros esparcir esa esperanza y transformarla en un cambio.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01412016/5b72e6c3-e2f0-43c4-bb05-1a779870ea0b.mp4',
    carrera: 'LAE',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Marco Leonardo Valencia Martínez',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Monterrey',
    contenido_breve: 'Para mí ser un Líder del Mañana significa contar con un gran privilegio y cargar con una gran responsabilidad de mejorar a México para que como yo más jóvenes puedan tener una mejor educación y una mejor vida.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01412017/6565641e-4c8e-49c6-a350-5a0ed6bee207.mp4',
    carrera: 'LAF',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Elsa Alejandra Sosa Brambila',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Tampico',
    contenido_breve: 'Para mí ser un líder de mañana, es la oportunidad de pertenecer a una generación de cambio que desea mejorar e impulsar el desarrollo de México, poniendo a disposición de los demás mis talentos y conocimientos para el beneficio de la sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01412020/abf2a65a-6a56-4139-8a0a-8a1b87a7bba5.mp4',
    carrera: 'IMT',
    campus_id: 'Tampico',    gen: '5a generación',
  },
{
    nombre: 'María del Carmen Rodarte Nieto',
    contenido: '',
    carrera_id: 'IFI',
    campus: 'Monterrey',
    contenido_breve: 'Para mi ser líder del mañana es tener la oportunidad de conocer y vivir nuevas situaciones que nunca había tenido y ademas de que me ha hecho conocer personas grandiosas, que me han enseñado y cambiado mi forma de ver el mundo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01412021/6cc6b733-74dc-4659-9b9f-1aae3d03cf0b.mp4',
    carrera: 'IFI',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'María Guadalupe Sánchez Ibarra',
    contenido: '',
    carrera_id: 'LCPF',
    campus: 'Ciudad de México',
    contenido_breve: 'Es la oportunidad de abrir una puerta para empoderar a los demás.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01412022/09844466-1dfd-4f64-9b33-d96daf3c4e3b.mp4',
    carrera: 'LCPF',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Eduardo Jair Hernández Gómez',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Monterrey',
    contenido_breve: 'Para mi ser un líder del mañana es poder ser un agente de cambio en nuestra sociedad, poder ser un ejemplo a seguir liderando con el ejemplo, y poder ayudar a más personas con nuestras acciones.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01412492/dee527e9-c966-444f-a3ca-a616884df1ea.mp4',
    carrera: 'ITC',
    campus_id: 'Tampico',    gen: '7a generación',
  },
{
    nombre: 'Elizabeth Nieto Garduño',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Puebla',
    contenido_breve: 'Es ser acreedor a una oportunidad, una la cual nos dio los medios y herramientas para realmente explotar nuestro talento y ser agentes de cambio en nuestro entorno, siempre con una mira social y buscar como retribuir a nuestra sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01422810/1f26953e-e594-4e54-bda4-6dd9a523cc7b.mp4',
    carrera: 'IBT',
    campus_id: 'Puebla',    gen: '3a generación',
  },
{
    nombre: 'Idalia Marisela Alvarado Molina',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Cuernavaca',
    contenido_breve: 'Ser un líder del mañana es esperanza, pero también acción. Es ser consciente que en nuestras manos esta el construir un mejor futuro para las generaciones que vienen, pero también el crear una mejor realidad para nuestro presente.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01423259/80577abb-d779-4885-bec8-f9c545a67545.mp4',
    carrera: 'LIN',
    campus_id: 'Cuernavaca',    gen: '4a generación',
  },
{
    nombre: 'Pedro Pablo Ortiz López',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Monterrey',
    contenido_breve: 'Para mi ser Líder del Mañana es ser una persona comprometida, dispuesta a realizar un verdadero cambio por los que mas ama sin dejar de lado a todos los que le roden, una persona que desea el bien común, que da y recibe amor, pero sobre cualquier cosa da.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01423260/d6a026f2-cea7-4a45-a755-f78025a31e5d.mp4',
    carrera: 'LIN',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Cristian Iván Bravo Contreras',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Cuernavaca',
    contenido_breve: 'Significa tener la oportunidad de cambiar tu entorno, ser un líder del mañana representa ser el ejemplo para tu comunidad así como para utilizar tus conocimientos en pro del mundo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01423382/fd27f2f0-3024-42b4-ad8e-781104a53756.mp4',
    carrera: 'ITC',
    campus_id: 'Cuernavaca',    gen: '4a generación',
  },
{
    nombre: 'Ricardo André Flores Cornelio',
    contenido: '',
    carrera_id: 'LCMD',
    campus: 'Puebla',
    contenido_breve: 'Es la oportunidad de demostrarle al mundo todo lo que podemos hacer con la confianza que han puesto en nosotros.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01423440/afac7c03-cdf4-479f-87c5-98b180cc594e.mp4',
    carrera: 'LCMD',
    campus_id: 'Puebla',    gen: '6a generación',
  },
{
    nombre: 'Cesar Cerecero Lopez',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Cuernavaca',
    contenido_breve: 'Para mi ser un líder del mañana significa ser esa persona que conoce y le preocupa las necesidades de su comunidad, y su vez tiene la capacidad de generar un cambio que impacte positivamente en ella.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01423709/8a41f06a-c017-4f6a-bce8-ff6113745df1.mp4',
    carrera: 'IMT',
    campus_id: 'Cuernavaca',    gen: '5a generación',
  },
{
    nombre: 'María Guadalupe Gama Cruz',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Cuernavaca',
    contenido_breve: 'Para mí ser una líder del mañana significa estar consciente del presente y de las problemáticas que existen y conforman mi realidad actual, además de ser capaz de proyectar una visión acerca del futuro y proponer soluciones a estas problemáticas.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01423870/84e737c9-d9e8-4324-80cb-bc439a3ab4bc.mp4',
    carrera: 'IMT',
    campus_id: 'Cuernavaca',    gen: '5a generación',
  },
{
    nombre: 'Erik Josías González Lucas',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Monterrey',
    contenido_breve: 'Para mí ser Líder del Mañana es ser testimonio, una carta viva que habla de esperanza, y de una oportunidad de superación, para mis compañeros de clases, para otros jóvenes fuera del ámbito estudiantil, para mi familia y para todo aquel que me conozca.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01424067/6bd6b2e2-7d37-4e30-8990-0670f783ea7c.mp4',
    carrera: 'ITC',
    campus_id: 'Monterrey',    gen: '6a generación',
  },
{
    nombre: 'Alan Fabián Romero Zúñiga',
    contenido: '',
    carrera_id: 'IC',
    campus: 'Monterrey',
    contenido_breve: 'Ser lider del mañana es una oportinidad unica para poder cumplir mis sueños y metas,una familia unica de personas encaminadas a hacer grandes cambios.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01424152/4a0011a0-3a57-4e99-b89d-048224cb09ff.mp4',
    carrera: 'IC',
    campus_id: 'Monterrey',    gen: '6a generación',
  },
{
    nombre: 'Sergio Daniel Agüero Ramírez',
    contenido: '',
    carrera_id: 'AMC',
    campus: 'Monterrey',
    contenido_breve: 'Para mí ser un Líder del Mañana significa tener una gran oportunidad de crecimiento personal y profesional para poder desarrollar mis proyectos, devolviendo así a la sociedad todo el apoyo que se me ha brindado para poder llegar hasta aquí.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01424185/de7bee0f-e57d-449e-97e4-8cd334a6ace6.mp4',
    carrera: 'AMC',
    campus_id: 'Monterrey',    gen: '6a generación',
  },
{
    nombre: 'Jatziri Meylim Ortiz Trujillo',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Cuernavaca',
    contenido_breve: 'Ser líder del mañana significa poder potencializar mis habilidades y liderazgo en busca de mejorar la calidad de vida de mi comunidad desde mis posibilidades y postura.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01424528/a441cc2f-ecdc-4b83-9c45-fbe1964f8feb.mp4',
    carrera: 'LAF',
    campus_id: 'Cuernavaca',    gen: '7a generación',
  },
{
    nombre: 'Korina Yeizel Medellín Bañuelos',
    contenido: '',
    carrera_id: 'NEG',
    campus: 'Monterrey',
    contenido_breve: 'La mas grande bendición que Dios me ha ofrecido. Una oportunidad diaria para ser mejor, que me recuerda el potencial que tengo, lo duro que tengo que trabajar por mis metas, y lo comprometida que estoy con retribuir lo tengo. Es un sueño hecho realidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01540325/84edc00b-fa7d-425a-9144-a8d13bd9bf3e.mov',
    carrera: 'NEG',
    campus_id: 'Monterrey',    gen: '6a generación',
  },
{
    nombre: 'Jorge Luis Becerra Rojas',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Aguascalientes',
    contenido_breve: 'Es el reconocimiento más importante que tengo hasta ahora, significa tener la responsabilidad de cambiar el rumbo de mi comunidad, de mi estado y de mi país, siempre accionando de forma correcta siguiendo los valores que esta institución me ha brindado.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01540691/455a1e54-3031-4332-8cfa-e074db931ae7.mp4',
    carrera: 'IIS',
    campus_id: 'Aguascalientes',    gen: '3a generación',
  },
{
    nombre: 'Alexis Josefath Cardona Campos',
    contenido: '',
    carrera_id: 'LAE',
    campus: 'Aguascalientes',
    contenido_breve: 'Para mí, ser líder del mañana es ser un agente de cambio y líder de opinión en mi comunidad, es buscar un bien común en sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01540800/d3b92825-5ff0-417d-a727-7e991dc2f8ca.mp4',
    carrera: 'LAE',
    campus_id: 'Aguascalientes',    gen: '4a generación',
  },
{
    nombre: 'Efraín Alemán Álvarez',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Aguascalientes',
    contenido_breve: 'Aquella persona que se capacita en todo ámbito, para el día de mañana poder ayudar a tener un planeta mejor',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01540802/308057dc-d2c8-423e-b34b-ea40a19a4d07.mp4',
    carrera: 'IIS',
    campus_id: 'Aguascalientes',    gen: '4a generación',
  },
{
    nombre: 'Fernanda Janeth Mitre Lozano',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'San Luis Potosí',
    contenido_breve: 'Ser un Líder del Mañana para mí representa transformación, a lo largo de estos 4 años he pasado por distintos retos que me han apoyado en mi crecimiento personal y profesional, además han fortalecido mis sueños que en un momento parecían imposibles.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01540805/50e80d30-3e81-44db-8df0-a219d635f75f.mp4',
    carrera: 'LAF',
    campus_id: 'San Luis Potosí',    gen: '4a generación',
  },
{
    nombre: 'Luisa Itzel Mares Lozano',
    contenido: '',
    carrera_id: 'LAE',
    campus: 'Aguascalientes',
    contenido_breve: 'Significa representar a una generación de jóvenes comprometidos con el bienestar social de nuestra comunidad, a crear soluciones a las problemáticas diarias que acomplejan a los más necesitados y a impulsar el talento de los jóvenes emprendedores.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01541086/cecb6637-5ad9-42de-af8d-de19d65d540f.mp4',
    carrera: 'LAE',
    campus_id: 'Aguascalientes',    gen: '5a generación',
  },
{
    nombre: 'Diana Paola De la Cruz Morales',
    contenido: '',
    carrera_id: 'LEM',
    campus: 'Monterrey',
    contenido_breve: 'Sin duda es uno de los logros más importantes para mí, ya que día a día es un constante recordatorio de mis esfuerzos y como estos se pueden ver recompensados. Ha sido una herramienta que sin duda me ha ayudado a lograr y descubrir nuevas metas.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01541094/804f3797-90be-4710-bbeb-9079cb24b3a0.mp4',
    carrera: 'LEM',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Eduardo Chacón Ontiveros',
    contenido: '',
    carrera_id: 'IIT',
    campus: 'Zacatecas',
    contenido_breve: 'Ser Líder del mañana es lo más importante en mi vida, pues al recibir esta oportunidad, estoy forjando mi futuro de la mejor manera. Ser líder es un gran compromiso para mi, pues mi deseo es crecer y ser un buen ejemplo para muchas personas.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01541571/72225430-1057-4409-a97a-65e84c6807df.MOV',
    carrera: 'IIT',
    campus_id: 'Zacatecas',    gen: '7a generación',
  },
{
    nombre: 'Dionisia Tzitzihua Cuaquehua',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Monterrey',
    contenido_breve: 'Ser líder del mañana es un compromiso conmigo misma y con la sociedad para aportar valor y poner mi talento al servicio de los demás. Por otro lado, tener esta distinción es un regalo que me motiva cada día para ser mejor.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01552079/f4bd56e8-b614-4c9c-99a3-85b52ff6e9f7.mp4',
    carrera: 'IIS',
    campus_id: 'Monterrey',    gen: '3a generación',
  },
{
    nombre: 'Diego González Herrera',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Guadalajara',
    contenido_breve: 'Es una oportunidad de una en un millón que tuve la fortuna de obtener. Ser Líder del Mañana significó un cambio total en cómo me percibía a mí y mi futuro, tanto el profesional como el personal. Esta beca es un medio con el que podré cumplir mis metas.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01552183/0c31d002-79a7-4114-ba10-eb3fa5a10270. mp4',
    carrera: 'LIN',
    campus_id: 'Guadalajara',    gen: '4a generación',
  },
{
    nombre: 'María José Morales Pérez',
    contenido: '',
    carrera_id: 'LAE',
    campus: 'Querétaro',
    contenido_breve: 'Es la oportunidad y bendición más grande que he tenido, la prueba de que los sueños si se vuelven realidad. Es una responsabilidad de regresar a la sociedad todo lo que el TEC y los bienhechores me han proporcionado, es un honor, un mérito y un orgullo',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01552380/d754f8d1-6350-4dcd-bae7-a1e246e01880.MOV',
    carrera: 'LAE',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Andrés Romero Navarro',
    contenido: '',
    carrera_id: 'IA',
    campus: 'Querétaro',
    contenido_breve: 'Es ser una persona que escucha, que vive los problemas y necesidades de una población tan golpeada durante generaciones, es una persona que se involucra activamente y vive día a día los sueños, luchas, ilusiones y deseos de miles de jóvenes y niños',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01552455/27ee001c-5ff2-4684-a959-fc1b7d010054.mp4',
    carrera: 'IA',
    campus_id: 'Querétaro',    gen: '5a generación',
  },
{
    nombre: 'Beatriz Romero Navarro',
    contenido: '',
    carrera_id: 'IBQ',
    campus: 'Querétaro',
    contenido_breve: 'Significa una forma de impactar a la sociedad mexicana mediante mi preparación. Pero mas allá que una oportunidad estudiantil, representa una manera de mejorar a mi patria, buscando contribuir en la transformación de las comunidades que la integran.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01552639/36da5b8b-cbdc-400f-8c11-b8b7723ea89d.mp4',
    carrera: 'IBQ',
    campus_id: 'Central de Veracruz',    gen: '7a generación',
  },
{
    nombre: 'Brianda Gisela Tena Cornejo',
    contenido: '',
    carrera_id: 'LCDE',
    campus: 'Chihuahua',
    contenido_breve: 'Ser Líder del Mañana es una oportunidad para crecer personal y profesionalmente, así como para potencializar y mejorar tus conocimientos y habilidades y ponerlos al servicio de la comunidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01561543/622e0336-8bb4-4b8d-8200-8e3027ce0168.mp4',
    carrera: 'LCDE',
    campus_id: 'Chihuahua',    gen: '3a generación',
  },
{
    nombre: 'Mariana Rodríguez Pérez',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Chihuahua',
    contenido_breve: 'Representar un cambio para mi comunidad y mi país',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01561661/92dcfbf9-c381-40d5-90dd-68d0d03afe2f.MOV',
    carrera: 'IBT',
    campus_id: 'Ciudad de México',    gen: '4a generación',
  },
{
    nombre: 'Rebeca Carta Jurado',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Chihuahua',
    contenido_breve: 'Es un honor ser galardonado con esta. beca, que implica responsabilidad, excelencia y mucho trabajo, pero lo que nos distingue de los demás alumnos, es que cambian nuestra vida para que nosotros cambiemos otras vidas.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01561708/d9dbde3b-add3-48d9-8309-8b05f327b025.mp4',
    carrera: 'IBT',
    campus_id: 'Chihuahua',    gen: '4a generación',
  },
{
    nombre: 'Laura Gabriela Velázquez Varela',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Chihuahua',
    contenido_breve: 'Es la oportunidad que me ha permitido crecer como persona y al mismo tiempo profesionalmente, es una ventana que me abrió los ojos a lo grande que es el mundo y la variedad de oportunidades que tenemos para lograr nuestras metas.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01561760/41f7085d-06cc-4d65-a6b9-618835425c5b.mp4',
    carrera: 'LIN',
    campus_id: 'Chihuahua',    gen: '4a generación',
  },
{
    nombre: 'Alejandro Hahn Gallegos',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Chihuahua',
    contenido_breve: 'Para mí, ser Lider del mañana significa tener la oportunidad de superarme y tener la oportunidad de generar un impacto positivo en la sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01561774/1f79f20c-e90d-4e29-aae3-1441890b3f82.mp4',
    carrera: 'ITC',
    campus_id: 'Chihuahua',    gen: '4a generación',
  },
{
    nombre: 'Adrián Anchondo Alvídrez',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Monterrey',
    contenido_breve: 'Líderes del Mañana es la conclusión de un gran número de esfuerzos que desencadena el cambio que México necesita. Falta mucho por recorrer y aprender, pero ser un Líder del Mañana significa tener las herramientas para transformar un contexto vivido.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01561790/71d8b60e-49e5-4ab6-9507-b0dad6345e17.mp4',
    carrera: 'MC',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Ximena Cedillo Torres',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Chihuahua',
    contenido_breve: 'Ser una Líder del Mañana es representar a un grupo de jóvenes que, además de ser talentosos, estamos comprometidos con ser agentes de cambio, confiando en que la educación superior es una de las mejores herramientas para transformar México.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01561874/c1308a29-28ee-41ce-bcbd-fc449b22e38c.mp4',
    carrera: 'IIS',
    campus_id: 'Chihuahua',    gen: '5a generación',
  },
{
    nombre: 'Jesús Daniel Martínez Torres',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Chihuahua',
    contenido_breve: 'Ser un líder del mañana es ser una persona que quiere transformar su entorno buscando mejores oportunidades para cada uno, también por medio del conocimiento brindar herramientas para apoyar a los que lo necesiten de ellos.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01561989/ccc72dab-bd94-4695-890b-cb4945179b43.mp4',
    carrera: 'LAF',
    campus_id: 'Chihuahua',    gen: '5a generación',
  },
{
    nombre: 'Laura Daniela García Quiroz',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Guadalajara',
    contenido_breve: 'Es todo en mi vida. Es un gran giro y una enorme oportunidad. Significa fuerza, perseverancia y honor; es un reconocimiento a mi familia por lo mucho que hemos trabajado y sobre todo es un agradecimiento a aquellos que me ayudaron.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01562009/a3bc222e-89f6-4016-ba0a-26f203b9435d.mp4',
    carrera: 'MC',
    campus_id: 'Guadalajara',    gen: '5a generación',
  },
{
    nombre: 'Perla Lizeth Armendáriz Estrada',
    contenido: '',
    carrera_id: 'NEG',
    campus: 'Chihuahua',
    contenido_breve: 'Es tener la oportunidad de desarrollar todo mi potencial y ponerlo al servicio de la sociedad. Es ser un agente de cambio, un individuo consciente de su entorno, de sus raíces y de la necesidad de mejora. Pero también es ser parte de una gran familia.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01562334/8bf60c6f-54d2-49b4-a197-4a2701fd1a03.mp4',
    carrera: 'NEG',
    campus_id: 'Chihuahua',    gen: '7a generación',
  },
{
    nombre: 'Guadalupe Gricel Rodríguez Vega',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Chihuahua',
    contenido_breve: 'Ser un líder del mañana significa para mi no solo un gran orgullo sino una gran responsabilidad ya que esta distinción esta llena de grandes oportunidades para lograr nuestros sueños y lograr tener un mejor México.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01562436/661beb11-7e83-41e0-8bf9-8ad5b515429a.mp4',
    carrera: 'LIN',
    campus_id: 'Chihuahua',    gen: '7a generación',
  },
{
    nombre: 'Victoria Lucero Robles',
    contenido: '',
    carrera_id: 'ICT',
    campus: 'Monterrey',
    contenido_breve: 'Es una de las mejores oportunidades que se me ha brindado en la vida, es un un apoyo para seguir creciendo tanto en lo profesional como en lo personal y es mucha valentía, de querer salir adelante y nunca rendirse.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01562440/b2a1de4b-c9fe-4644-b46b-5ce965ef7782.mp4',
    carrera: 'ICT',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Doris Elizabeth Velázquez Andrade',
    contenido: '',
    carrera_id: 'IIT',
    campus: 'Chihuahua',
    contenido_breve: 'Para mi ser líder del mañana es una oportunidad de cambiar el rumbo de mi vida, es una oportunidad de compartir a la sociedad de lo mucho que se me ha dado.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01562474/93503136-fa9c-4ea5-9857-bd16f790220d.mp4',
    carrera: 'IIT',
    campus_id: 'Chihuahua',    gen: '7a generación',
  },
{
    nombre: 'Miguel Gutiérrez Caraveo',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Chihuahua',
    contenido_breve: 'El ser un líder del mañana es una distinción y reconocimiento por mi entrega y dedicación no solo en la escuela sino fuera de ella en beneficio de mi comunidad, pero también es un compromiso a seguir desarrollando un mayor sentido humano y filantropía.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01562501/c5aa24db-cd1d-4395-ad9c-40bee624e1f6.mp4',
    carrera: 'IMT',
    campus_id: 'Chihuahua',    gen: '7a generación',
  },
{
    nombre: 'Raúl Salvador Chávez Ramírez',
    contenido: '',
    carrera_id: 'ICI',
    campus: 'Monterrey',
    contenido_breve: 'Para mi ser líder del mañana es toda una forma de vida, es buscar crecer día a día para buscar un futuro mejor para nuestro mundo y nuestra sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01562526/5cbf106d-3793-443c-a50f-ad7e05af911c.mov',
    carrera: 'ICI',
    campus_id: 'Chihuahua',    gen: '7a generación',
  },
{
    nombre: 'Lizeth Michelle Martínez Alvarez',
    contenido: '',
    carrera_id: 'IFI',
    campus: 'Monterrey',
    contenido_breve: 'Es una oportunidad enorme para aprender cosas nuevas tanto académica como personalmente ycrecer con el apoyo del Tecnológico de Monterrey. Lo que más agradezco de esta beca son lo mucho que amplió mi perspectiva y la nueva forma de ver el mundo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01611457/7c362660-1351-4881-88f2-b8ef029004e5.mp4',
    carrera: 'IFI',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Humberto Uquillas Martínez',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'San Luis Potosí',
    contenido_breve: 'Es una oportunidad de crecer como persona y profesionista, un orgullo y una responsabilidad para formarme y utilizar el conocimiento y las habilidades que adquiera para apoyar a mi comunidad. Ser líder, es ser un medio para el cambio de mi país.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01611773/fc52b7cf-bfaa-4790-a5bf-1a1cf10f3665.mp4',
    carrera: 'IMT',
    campus_id: 'San Luis Potosí',    gen: '5a generación',
  },
{
    nombre: 'Andrea Sánchez Gómez',
    contenido: '',
    carrera_id: 'NEG',
    campus: 'San Luis Potosí',
    contenido_breve: 'Ser Líder del Mañana es formar parte de una gran familia que trabaja día con día para lograr transformar nuestro México; una familia que me impulsa a alcanzar mis sueños y a convertirme en esa persona que quiero ser en un futuro.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01612881/2da47c71-30f9-412b-8499-476747a41eb8.mp4',
    carrera: 'NEG',
    campus_id: 'San Luis Potosí',    gen: '7a generación',
  },
{
    nombre: 'José Sebastián Jasso Castillo',
    contenido: '',
    carrera_id: 'IFI',
    campus: 'Monterrey',
    contenido_breve: 'Es dar seguimiento al compromiso que tengo conmigo y con la sociedad de trabajar por la creación de un México más incluyente, armonioso y científico.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01612901/9ff31475-dbd0-40d4-b584-f4a27c8fcba2.mp4',
    carrera: 'IFI',
    campus_id: 'San Luis Potosí',    gen: '7a generación',
  },
{
    nombre: 'Juan Carlos Martínez Zacarías',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Monterrey',
    contenido_breve: 'Es ser una persona a la cual se le dio la confianza para cumplir sus sueños; metas encaminadas a una mejora tanto personal como profesional que finalmente culminará en cambios positivos en pequeñas comunidades, en el país e, inclusive, en el mundo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01612967/fdf25b66-9eb0-46cb-bac2-4d57aae0ad90.mp4',
    carrera: 'ITC',
    campus_id: 'San Luis Potosí',    gen: '7a generación',
  },
{
    nombre: 'Shalom Zurisadai Carmona Gallegos',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Monterrey',
    contenido_breve: 'Para mi ser una líder del mañana es tener acceso a educación y a redes de apoyo que puedan potencializar nuestra carrera profesional y proyectos con el objetivo de crear mejores condiciones sociales para las generaciones futuras.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01620374/e73e435b-763e-4ed0-9a25-1a52370b044d.mp4',
    carrera: 'IMT',
    campus_id: 'Monterrey',    gen: '6a generación',
  },
{
    nombre: 'Juan Pablo Torres Velázquez',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Guadalajara',
    contenido_breve: 'Es ser un agende de cambio para la sociedad y retribuir el privilegio que se nos otorgó a partir de acciones que generen cambio y movimientos a favor de toda la sociedad',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01625064/cee88ead-bca7-4ade-9f05-2654f8b38f07.mp4',
    carrera: 'LAF',
    campus_id: 'Guadalajara',    gen: '4a generación',
  },
{
    nombre: 'Emmanuel Odilón Ruvalcaba Tejeda',
    contenido: '',
    carrera_id: 'ARQ',
    campus: 'Monterrey',
    contenido_breve: 'Es agradecimiento pero sobre todo mucha responsabilidad para hacer las cosas lo mejor posible en beneficio personal y social',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01625077/f09afc38-6ae5-4b6f-a4ec-38d1aa2cd1cd.mp4',
    carrera: 'ARQ',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Tania Jaqueline López Esparza',
    contenido: '',
    carrera_id: 'INCQ',
    campus: 'Monterrey',
    contenido_breve: 'Para mí ha sido más que una beca; este programa ha sido el impulso que me ha permitido seguir adelante a pesar de tantos golpes recibidos sobre todo en el último año, por ejemplo, el fallecimiento de mi padre, todo esto gracias al apoyo recibido en LDM',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01625312/d78d5f89-1db6-4afb-a9fe-abf595511411.mp4',
    carrera: 'INCQ',
    campus_id: 'Monterrey',    gen: '6a generación',
  },
{
    nombre: 'Yulenny Mariel Cisneros Prudencio',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Guadalajara',
    contenido_breve: 'Es un compromiso conmigo misma y con la población mexicana, de devolver un poco de las oportunidades que se me han brindado a aquellos que aún no cuentan con ellas. Es un gran orgullo ser parte de este programa.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01633916/ada4336a-cb1c-4000-9db1-459d6bbfbaac.mov',
    carrera: 'MC',
    campus_id: 'Guadalajara',    gen: '4a generación',
  },
{
    nombre: 'Mirta Valeria Guzmán Flores',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Guadalajara',
    contenido_breve: 'Ser líder del mañana es querer cambiar al mundo y tener la oportunidad de hacerlo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01633919/fb9aa6dc-330a-4ae8-8d9a-c2a8530633b7.mp4',
    carrera: 'LIN',
    campus_id: 'Guadalajara',    gen: '4a generación',
  },
{
    nombre: 'Claudia Martínez Sepúlveda',
    contenido: '',
    carrera_id: 'IMD',
    campus: 'Guadalajara',
    contenido_breve: 'Ser un Líder del Mañana significa oportunidad y responsabilidad. Tenemos la oportunidad de estudiar una carrera y la responsabilidad de trabajar para construir un mejor país y una mejor sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01633920/9d09cfe5-44de-4b04-8f78-ef3bf68beb93.mp4',
    carrera: 'IMD',
    campus_id: 'Guadalajara',    gen: '4a generación',
  },
{
    nombre: 'Víctor Castillo Madrigal',
    contenido: '',
    carrera_id: 'IMD',
    campus: 'Guadalajara',
    contenido_breve: 'Se trata de ser un ejemplo a seguir y alguien dispuesto a servir a los demás y a las comunidad mediante el aprendizaje y la tecnología',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01634397/7b735972-92e4-41dd-b1a0-38815f7baa1a.mp4',
    carrera: 'IMD',
    campus_id: 'Guadalajara',    gen: '4a generación',
  },
{
    nombre: 'Jesús Castillo Madrigal',
    contenido: '',
    carrera_id: 'IMD',
    campus: 'Guadalajara',
    contenido_breve: 'Ser un líder del mañana es ser parte de una comunidad llena de personas que tienen la capacidad para cambiar a México.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01634414/85a01fb3-a12a-420d-8305-9e6b39e69f18.mp4',
    carrera: 'IMD',
    campus_id: 'Guadalajara',    gen: '4a generación',
  },
{
    nombre: 'Manuel Esaú Tamayo Gómez',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Guadalajara',
    contenido_breve: 'Un compromiso y un regalo de la Vida. Un cambio para regresar a través del principio de reciprocidad. Un sueño que exige trabajo pero nos da la esperanza del futuro.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01634491/d0aaeb11-386f-403f-a8f3-da1ea10daff8.MOV',
    carrera: 'MC',
    campus_id: 'Guadalajara',    gen: '4a generación',
  },
{
    nombre: 'Diana Patricia Vázquez Contreras',
    contenido: '',
    carrera_id: 'IMD',
    campus: 'Guadalajara',
    contenido_breve: 'Es una gran oportunidad para completar mis estudios superiores y que me permite poder ayudar a mi comunidad en alguna de las problemáticas presentes; Ser líder del mañana es formar parte de un grupo de personas dispuestas a ayudar a la sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01634957/42f96009-c910-4778-8294-f3aa097f9151.mp4',
    carrera: 'IMD',
    campus_id: 'Guadalajara',    gen: '4a generación',
  },
{
    nombre: 'Evelyn Morfín Hernández',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Guadalajara',
    contenido_breve: 'Un honor formar parte de una familia llena de personas talentosas, comprometidas con el futuro de México.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01635075/c2bd557f-bd6b-4d54-87b4-51fbfa3f2c9b.MOV',
    carrera: 'LAF',
    campus_id: 'Guadalajara',    gen: '5a generación',
  },
{
    nombre: 'Diego Eduardo Aguilar García',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Guadalajara',
    contenido_breve: 'El programa Líderes del Mañana ha representado para mí la increíble oportunidad de cumplir mis sueños, aún cuando la situación económica de mi familia los colocaba tan lejos. Ha cambiado mi vida drásticamente y me ha permitido conocer mi vocación.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01635111/8e1bd227-feba-4572-a9dd-ad4faad74fea.mp4',
    carrera: 'MC',
    campus_id: 'Guadalajara',    gen: '4a generación',
  },
{
    nombre: 'Ian Donnet Jiménez Saldaña',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Monterrey',
    contenido_breve: 'Tener oportunidades todos los días de crecer, desarrollarte y sobre todo, construir un mejor México de la mano de una increíble familia que saca lo mejor de ti.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01635178/297e9d49-2536-417f-a305-4f7647c00f0f.mp4',
    carrera: 'IMT',
    campus_id: 'Aguascalientes',    gen: '7a generación',
  },
{
    nombre: 'Ari Ramón Valenzuela Navarro',
    contenido: '',
    carrera_id: 'ISC',
    campus: 'Guadalajara',
    contenido_breve: 'Para mí es tener la responsabilidad y el compromiso de ser resiliente, de dar el máximo en tus actividades, y de ir mejorando día con día, con el propósito de generar un cambio y dar de vuelta a la comunidad todo el apoyo que se nos ha dado',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01635584/cd61e1b6-e046-4bd5-a4c8-9dfc7d8f7271.mp4',
    carrera: 'ISC',
    campus_id: 'Guadalajara',    gen: '5a generación',
  },
{
    nombre: 'Alejandro Longino Hernández García',
    contenido: '',
    carrera_id: 'LAD',
    campus: 'Guadalajara',
    contenido_breve: 'Es tener la posibilidad de aportar nuestro grano de arena en aras de mejorar nuestra sociedad, la oportunidad de crecer como persona, de estudiar en una de las mejores universidades del país, y de representar una serie de valores que debemos transmitir.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01635847/203e06de-2a23-40cf-99f6-86503d545fcb.mp4',
    carrera: 'LAD',
    campus_id: 'Guadalajara',    gen: '5a generación',
  },
{
    nombre: 'Jorge Arturo Mencías XX',
    contenido: '',
    carrera_id: 'IFI',
    campus: 'Monterrey',
    contenido_breve: 'Ser un lider del mañana es esforzarse para lograr un gran desarrollo personal, obtener los conocimientos y herramientas necesarias para poder llegar a ser un agente de cambio en la sociedad el día de mañana y así ayudar a la mejora del mundo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01635904/d13c26a9-73cb-4804-a029-a3f1cd982870.mp4',
    carrera: 'IFI',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Adrian Fernández Garcia',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Guadalajara',
    contenido_breve: 'Para mí es poder hacer un cambio significativo en nuestro entorno a partir de nuestras vivencias y amplio esfuerzo que hemos aplicado.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01636351/6dc0ec29-fb29-4031-871a-d89aacc89b20.mp4',
    carrera: 'IMT',
    campus_id: 'Guadalajara',    gen: '5a generación',
  },
{
    nombre: 'Vanessa Jiménez Bahena',
    contenido: '',
    carrera_id: 'LCDE',
    campus: 'Guadalajara',
    contenido_breve: 'Para mí ser Líder del Mañana significa ser un agente de cambio, ser una persona que constantemente está buscando la forma de mejorar para después poder ayudar a los demás. Una persona persona resiliente que enfrenta la adversidad para poder sobresalir.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01636633/9cf14e56-e4a4-499c-8490-cef4c1d5e59a.MOV',
    carrera: 'LCDE',
    campus_id: 'Guadalajara',    gen: '5a generación',
  },
{
    nombre: 'Stephanie Elizabeth Jimenez Ramirez',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Guadalajara',
    contenido_breve: 'Un sueño hecho realidad, el Tec ha transformado mi vida positivamente, me ha hecho ampliar mis horizontes y oportunidades de éxito en la vida.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01636665/7e199c3b-1c18-4269-90be-1f33b026debe.mp4',
    carrera: 'MC',
    campus_id: 'Guadalajara',    gen: '5a generación',
  },
{
    nombre: 'Jorge Negrete Ibarra',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Guadalajara',
    contenido_breve: 'Es la mejor oportunidad que he tenido en la vida para sobresalir y crecer tanto personal como académicamente, Líderes del Mañana es sinónimo de responsabilidad, esfuerzo y compromiso con nosotros mismos y quienes nos rodean.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01636813/d11a0221-cb6f-4f23-a668-00cc7ba40134.mp4',
    carrera: 'MC',
    campus_id: 'Guadalajara',    gen: '5a generación',
  },
{
    nombre: 'Azul Citlalli Del Real Bañuelos',
    contenido: '',
    carrera_id: 'IME',
    campus: 'Monterrey',
    contenido_breve: 'Ser un líder del mañana, es ser una persona capaz de superarse a si misma, con ideales que ayuden al desarrollo de las comunidades y de las personas a nuestro alrededor, buscando ante todo generar cambios positivos en la sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01637906/dafdb893-d98c-427d-a0fa-fcebae7f5224.mp4',
    carrera: 'IME',
    campus_id: 'Monterrey',    gen: '6a generación',
  },
{
    nombre: 'Esmeralda Michelle Razura Ruiz',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Guadalajara',
    contenido_breve: 'Ser Líder del Mañana para mí significa tener la oportunidad de cambiar a México y el mundo, y así dejar un mejor futuro a las generaciones que vienen',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01638150/c5cf191a-673e-442b-83d0-5c3da6f66a6f.mp4',
    carrera: 'MC',
    campus_id: 'Guadalajara',    gen: '6a generación',
  },
{
    nombre: 'Adolfo López Jiménez',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Guadalajara',
    contenido_breve: 'Es formar parte de una gran familia de personas distinguidas por sus cualidades, como el liderazgo, la responsabilidad, constancia, disciplina, empatía y muchas más. Además de buscar brindar la misma oportunidad a todas las personas que nos sea posible.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01638382/aa1a03a3-4380-43bd-988d-53ee6bc216df.mp4',
    carrera: 'IMT',
    campus_id: 'Guadalajara',    gen: '6a generación',
  },
{
    nombre: 'Aarón Gómez Casillas',
    contenido: '',
    carrera_id: 'IIT',
    campus: 'Guadalajara',
    contenido_breve: 'Es poder tener una oportunidad en tu vida en base a todo el esfuerzo que has logrado dar años anteriores para poder salir adelante, es tener una segunda o tercera familia. Además es sentirte orgulloso de formar parte de una familia tan hermosa y savia.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01639239/dadf7ad6-303a-4ba9-9b92-bf48efa6a8e0.mp4',
    carrera: 'IIT',
    campus_id: 'Guadalajara',    gen: '7a generación',
  },
{
    nombre: 'Elena Jacqueline Funes Avila',
    contenido: '',
    carrera_id: 'AMC',
    campus: 'Monterrey',
    contenido_breve: 'Para mí ser una líder del mañana es un total orgullo, no sólo porque se nos da la oportunidad de dar un giro completo a nuestra vida y plantearnos grandes aspiraciones, sino también por la gran red de apoyo que se ha formado entre cada uno de nosotros.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01639258/ff39c7de-5c96-429d-be9e-06f3ed85f75a.mp4',
    carrera: 'AMC',
    campus_id: 'Guadalajara',    gen: '7a generación',
  },
{
    nombre: 'Benjamín Álvarez Yépez',
    contenido: '',
    carrera_id: 'IMD',
    campus: 'Guadalajara',
    contenido_breve: 'En un mundo de contrastes y finalidades, un líder es aquel que analiza en pro de lo mejor, prevé escenarios, da soluciones e incentiva movimientos capaces de transformar ideas y con ello al entorno, ser líder es aquel que reúne el conocimiento y el valor.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01639669/e1474023-a3e2-48f8-b67b-f1d1f6834ff5.MOV',
    carrera: 'IMD',
    campus_id: 'Guadalajara',    gen: '7a generación',
  },
{
    nombre: 'Ximena Aquino Pérez',
    contenido: '',
    carrera_id: 'ISD',
    campus: 'Guadalajara',
    contenido_breve: 'Significa tener la capacidad de lograr un cambio y de inspirar a que muchos otros luchen por sus sueños desarrollando habilidades sociales, innovadoras y de liderazgo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01639678/3560978a-d981-4024-b489-538e2eda577a.mp4',
    carrera: 'ISD',
    campus_id: 'Guadalajara',    gen: '7a generación',
  },
{
    nombre: 'Lesli Robles Zárate',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Guadalajara',
    contenido_breve: 'Ser Líder del mañana es la oportunidad de aprovechar mi potencial para ser factor de cambio.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01639891/07fa77db-7259-4948-99a8-4234f0a80776.mp4',
    carrera: 'MC',
    campus_id: 'Guadalajara',    gen: '7a generación',
  },
{
    nombre: 'Diana Jahel Martínez Castillo',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Guadalajara',
    contenido_breve: 'Es del alguien que utiliza el liderazgo para impactar positivamente en los ambientes donde se desenvuelve',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01639940/d29902bf-0550-4fbe-b349-864323e02845.mp4',
    carrera: 'IMT',
    campus_id: 'Guadalajara',    gen: '7a generación',
  },
{
    nombre: 'Uriel Pavel Morales Sánchez',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Guadalajara',
    contenido_breve: 'Para mi más que una beca es un compromiso, es un incentivo para poder encaminar y explotar mis habilidades y ponerlas al servicio de los demás para bien, es un orgullo, un símbolo de disciplina, capacidad, reciprocidad, y disposición a ayudar.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01640065/8bce20d2-31b5-4b8f-99b8-5930682de89e.mp4',
    carrera: 'IMT',
    campus_id: 'Guadalajara',    gen: '7a generación',
  },
{
    nombre: 'Brandon Josué Magaña Mendoza',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Guadalajara',
    contenido_breve: 'El ser un Líder del Mañana, representa para mí, más que una oportunidad para continuar con mis estudios profesionales, sobre todo es un compromiso que tengo con mi comunidad para generar cambios positivos y retribuir lo que ellos me han dado.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01640162/b74099ac-055c-4a6d-8f08-84d2660cfdab.mp4',
    carrera: 'ITC',
    campus_id: 'Guadalajara',    gen: '7a generación',
  },
{
    nombre: 'María Magdalena Olvera Sánchez',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Ciudad de México',
    contenido_breve: 'Significa demostrar que los sueños son un motor importante y que no estamos solos, es decir, siempre habrá alguien que necesite de nuestra ayuda.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01653569/35489ab0-517b-4e08-a14e-4ea0e048ae24.mp4',
    carrera: 'IBT',
    campus_id: 'Ciudad de México',    gen: '4a generación',
  },
{
    nombre: 'Sinai Adriana Mendoza Galeana',
    contenido: '',
    carrera_id: 'IID',
    campus: 'Monterrey',
    contenido_breve: 'Luego de cuatro años de pertenecer a este increíble proyecto, miro atrás, y hoy sé que Líderes del Mañana me ha cambiado la vida. Me dio las herramientas para crecer y abrazar siempre el compromiso de regresar a la sociedad todo lo que se me ha dado.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01655250/e9a6d2a8-856e-4654-8d6a-d333c127e806.mp4',
    carrera: 'IID',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Arely Elizabeth Nataren Hidalgo',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Ciudad de México',
    contenido_breve: 'Ser lider del mañana es una oportunidad única en la vida de un joven, para mi es ese puente entre solo soñar los sueños y realmente cumplirlos, hoy estoy un poquito más cerca de ser lo que siempre soñé gracias a este programa.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01655276/0326dd6a-a984-4ac3-9391-7817bf277b23.mp4',
    carrera: 'MC',
    campus_id: 'Ciudad de México',    gen: '4a generación',
  },
{
    nombre: 'Kristell Atziry Bahena Molina',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Ciudad de México',
    contenido_breve: 'Ser un líder del mañana es mi mayor y más bonito compromiso con la sociedad y con mi gente para retribuir de manera entusiasta todo lo que me ha sido otorgado con esta oportunidad de estudiar mi carrera profesional en el Tec.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01655292/03fb41f0-2a36-49db-88f1-1e2c36fc822b.mp4',
    carrera: 'IBT',
    campus_id: 'Ciudad de México',    gen: '4a generación',
  },
{
    nombre: 'Jorge Noceda Crispín',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Ciudad de México',
    contenido_breve: 'Para mi ser líder del mañana significa ser un ejemplo de la búsqueda constante de crecimiento positivo en mí mismo y los demás, de sacrificio, empatía, amor, esfuerzo máximo y progreso social para el bienestar común.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01655612/eaee2521-448f-41a3-ba01-adec6de3523a.mp4',
    carrera: 'MC',
    campus_id: 'Ciudad de México',    gen: '5a generación',
  },
{
    nombre: 'Silvia Paola Barrera Pérez',
    contenido: '',
    carrera_id: 'LAE',
    campus: 'Guadalajara',
    contenido_breve: 'Para mi, el ser Líder del Mañana significa ser parte de una comunidad transformadora que busca siempre la mejora continua en su entorno, así mismo, es para mi una gran responsabilidad con las personas que hacen que el programa sea posible.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01655710/524125e7-e921-4ba9-b471-3ef1e89a8152.mp4',
    carrera: 'LAE',
    campus_id: 'Hidalgo',    gen: '5a generación',
  },
{
    nombre: 'Aarón Castillo Cervantes',
    contenido: '',
    carrera_id: 'INT',
    campus: 'Santa Fe',
    contenido_breve: 'Ser un Líder del Mañana, representa un gran logro, pero sobre todo una gran responsabilidad, no solo con la institución, sino con la sociedad. Desde mi punto de vista el objetivo primordial es poder aportar y compartir con los quienes te rodean los conoci',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01656213/168459c1-97ec-4aa4-993b-ccbcaf4fbd5f.mp4',
    carrera: 'INT',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Alejandro Soriano Gonzaga',
    contenido: '',
    carrera_id: 'IID',
    campus: 'Ciudad de México',
    contenido_breve: 'Ser un líder del mañana para mi es saber que puedo cumplir mis metas, que detrás de cada logro, esfuerzo, y sueño hay una red de apoyo de grandes personas que saben que los jóvenes podemos ser agentes de cambio, para México, para el mundo y la sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01656519/4e447265-e6cc-408c-8b0a-a91c830c0dcd.mp4',
    carrera: 'IID',
    campus_id: 'Ciudad de México',    gen: '5a generación',
  },
{
    nombre: 'Sarahí Vigueras González',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Querétaro',
    contenido_breve: 'Es la oportunidad de perseguir mis sueños, educándome en la mejor universidad de México. Más que una beca por concepto de colegiatura significa una comunidad que me acompaña en esta etapa de mi vida para alcanzar el máximo de mi potencial.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01656933/6c02f01b-f689-4030-99c4-244121fe56a9.mov',
    carrera: 'LIN',
    campus_id: 'Morelia',    gen: '5a generación',
  },
{
    nombre: 'Adriana Inés López Sánchez',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Ciudad de México',
    contenido_breve: 'Ser un líder del mañana no es solo estudiar una carrera en la mejor universidad de México, sino también ser preparado en ella para contribuir a nuestro país y sociedad, resolviendo problemáticas que se afrontan en la actualidad, aportando a su desarrollo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01656937/0593a814-c2c3-4ed8-9e49-f0a42feea649.mp4',
    carrera: 'ITC',
    campus_id: 'Ciudad de México',    gen: '5a generación',
  },
{
    nombre: 'Andrea Miranda Cárdenas',
    contenido: '',
    carrera_id: 'LPS',
    campus: 'Ciudad de México',
    contenido_breve: 'Ser Líder del Mañana es tener la oportunidad de cumplir mi sueño de poder ayudar a potencializar la salud mental y el bienestar poblacional; además de favorecer la reciprocidad para que más jóvenes puedan alcanzar sus objetivos.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01658643/24bee88b-5c84-4a72-b6b1-7e2a6e272016.mp4',
    carrera: 'LPS',
    campus_id: 'Ciudad de México',    gen: '6a generación',
  },
{
    nombre: 'Axel Reyes Molina',
    contenido: '',
    carrera_id: 'INCQ',
    campus: 'Monterrey',
    contenido_breve: 'Ser líder del mañana es aprovechar mi potencial y desarrollar nuevas habilidades en un plataforma de excelencia con el fin de ser una persona capaz de generar un impacto positivo en mi entorno desde mi trinchera y al mismo tiempo inspirar a los demás',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01658714/1df9ba79-7d69-436f-a85a-95ce8715feea.mp4',
    carrera: 'INCQ',
    campus_id: 'Monterrey',    gen: '6a generación',
  },
{
    nombre: 'Marian Alejandra Durán Romero',
    contenido: '',
    carrera_id: 'IQP',
    campus: 'Ciudad de México',
    contenido_breve: 'Es una oportunidad única, que me permite desarrollarme no solo académicamente, también personalmente. Además ser Líder del Mañana, significa ser parte de una gran comunidad alrededor de todo México y del mundo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/A01659769/files/2d3e046a-a6ce-46d6-914d-607be1256ff1.mp4',
    carrera: 'IQP',
    campus_id: 'Ciudad de México',    gen: '7a generación',
  },
{
    nombre: 'Leslie Ravizé Hernández',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Ciudad de México',
    contenido_breve: 'Significa ser parte de un movimiento nacional de cambio',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01659862/54ce489a-cb7b-45a6-b490-4f703b758de3.mp4',
    carrera: 'MC',
    campus_id: 'Ciudad de México',    gen: '7a generación',
  },
{
    nombre: 'Jorge Quiroz Tello',
    contenido: '',
    carrera_id: 'IIT',
    campus: 'Ciudad de México',
    contenido_breve: 'Para mi ser un líder del mañana significa tener la oportunidad de construir mi futuro, de perseguir mis sueños y de salir adelante junto con mi familia y mi sociedad. Ser líder representa un compromiso y responsabilidad con mi comunidad y mi país.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01660141/5b2bb8a6-af00-4ea0-bc84-d52b9ab75a81.mp4',
    carrera: 'IIT',
    campus_id: 'Ciudad de México',    gen: '7a generación',
  },
{
    nombre: 'Víctor Julián Flores Ávila',
    contenido: '',
    carrera_id: 'IDS',
    campus: 'Ciudad de México',
    contenido_breve: 'El compromiso de dar mi 100% a cada instante y en cualquier lugar, aprovechando la calidad educativa y programas del Tec de Monterrey. La oportunidad de recibir educación de calidad, creando un futuro próspero y de mejorar la vida de mi familia y mi país',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01660159/767caf8c-a5c0-4bc3-b2e4-ff11591a904b.mp4',
    carrera: 'IDS',
    campus_id: 'Ciudad de México',    gen: '7a generación',
  },
{
    nombre: 'Wendy Guadalupe Muñoz Rodríguez',
    contenido: '',
    carrera_id: 'MC',
    campus: 'Ciudad de México',
    contenido_breve: 'Tanto una oportunidad como una responsabilidad, pues todas las personas que estamos involucradas en el programa tenemos un compromiso con el desarrollo de México, sobre todo aportando desde nuestras habilidades, experiencias, formación y sentido humano.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01660191/33e23ef9-c42b-42da-a609-c6a45b26fa90.MOV',
    carrera: 'MC',
    campus_id: 'Ciudad de México',    gen: '7a generación',
  },
{
    nombre: 'Marcela Arcos Caballero',
    contenido: '',
    carrera_id: 'ISC',
    campus: 'Querétaro',
    contenido_breve: 'Es la mayor oportunidad que me ha dado la vida y es un gran privilegio contar con toda la comunidad de líderes del mañana comprometidos por la sociedad',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/A01703191/files/78296a02-98d1-42b5-8350-e538a356fe95.mp4',
    carrera: 'ISC',
    campus_id: 'Querétaro',    gen: '4a generación',
  },
{
    nombre: 'Isis Renata Reyes Rosales',
    contenido: '',
    carrera_id: 'IMA',
    campus: 'Querétaro',
    contenido_breve: 'Un agente de cambio continuo con todos y todas las que nos rodean, significa transformar no solo el presente, sino el futuro permitiendo el desarrollo y crecimiento juntos',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01703368/23832dfd-01b9-4905-9ad9-df79c8196306.mp4',
    carrera: 'IMA',
    campus_id: 'Querétaro',    gen: '7a generación',
  },
{
    nombre: 'Luis Enrique Cano Arias',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Querétaro',
    contenido_breve: 'Es la oportunidad que tenemos de contribuir a la mejora de nuestro país y de esta manera, fomentar la esperanza en futuras generaciones. Significa ser parte de una familia que comparte ideales para ayudar de manera positiva a la sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01703453/a5dab99d-e8e7-4099-bcbb-7076e10f4d96.mp4',
    carrera: 'IMT',
    campus_id: 'Querétaro',    gen: '4a generación',
  },
{
    nombre: 'Rodrigo Marcial Álvarez',
    contenido: '',
    carrera_id: 'LCMD',
    campus: 'Querétaro',
    contenido_breve: 'Significa cumplir un sueño porque es una oportunidad que llegó en el momento adecuado. Gracias a Líderes del Mañana he conocido gente maravillosa, he pulido mis habilidades y he podido colaborar en mejorar la vida de muchas personas.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01703465/452457f2-5a4a-4252-a8ca-fc43f0e88a20.mp4',
    carrera: 'LCMD',
    campus_id: 'Querétaro',    gen: '4a generación',
  },
{
    nombre: 'Marisol Gala López',
    contenido: '',
    carrera_id: 'ARQ',
    campus: 'Querétaro',
    contenido_breve: 'Ser LDM implica para mi un gran orgullo y conlleva una gran responsabilidad social. Agradezco a la vida todas las oportunidades y espero en un futuro poder regresar una parte a las siguiente generación de alumnos, tal como el Tec lo ha hecho conmigo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01703649/5c83c102-843c-4b33-8e0f-90461b09963d.mp4',
    carrera: 'ARQ',
    campus_id: 'Querétaro',    gen: '4a generación',
  },
{
    nombre: 'Gustavo Hernández Reyes',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Querétaro',
    contenido_breve: 'Ser Líder del mañana es el compromiso con la sociedad de poder crecer juntos, de poder hacer con pequeñas acciones un mejor país y que podo a poco podamos aportar algo al futuro de México de tal manera que todos podamos siempre ser mejores.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01703719/b62dd68b-4c4d-4476-80f4-fa2c5dbff368.mp4',
    carrera: 'IIS',
    campus_id: 'Querétaro',    gen: '4a generación',
  },
{
    nombre: 'Joshua Asarell Martínez González',
    contenido: '',
    carrera_id: 'ISD',
    campus: 'Querétaro',
    contenido_breve: 'Ser líder del mañana además de ser una gran bendición, es ser un agente de cambio en el entorno que lo rodea, es ser una persona que se preocupa por la sociedad, que busca innovar y ser sabio ante las circunstancias que se presenten, es un visionari@.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01703723/4442d08d-b68c-422e-80cc-2b96bafa5dd7.mp4',
    carrera: 'ISD',
    campus_id: 'Querétaro',    gen: '4a generación',
  },
{
    nombre: 'Sarahí José Aguilar',
    contenido: '',
    carrera_id: 'ISD',
    campus: 'Querétaro',
    contenido_breve: 'Un líder del mañana es una persona que busca oportunidades ante las adversidades y que las toma y hace hasta lo imposible por no dejarlas ir.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01705020/b456b57b-4dff-4ada-9762-3f7399f9d541.mp4',
    carrera: 'ISD',
    campus_id: 'Querétaro',    gen: '5a generación',
  },
{
    nombre: 'Daniel Iván Balderas Ponce',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Querétaro',
    contenido_breve: 'Es un gran oportunidad que me permite crecer y salir adelante. Es un compromiso conmigo mismo, con mi familia, mi comunidad y mi país, por esforzarme para dar siempre lo mejor y en la medida de lo posible y desde mi área de trabajo apoyar a la sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01705132/4a77b953-915b-42f4-bbd0-07b42d5d3bb2.mp4',
    carrera: 'LIN',
    campus_id: 'Querétaro',    gen: '5a generación',
  },
{
    nombre: 'Said Guadalupe Ortigoza Trujillo',
    contenido: '',
    carrera_id: 'ICT',
    campus: 'Querétaro',
    contenido_breve: 'Para mi, ser Líder del Mañana representa la oportunidad de convertirme en un líder que inspire a mi generación y a las generaciones por venir, y crear un futuro más incluyente, justo y con más oportunidades para todos y todas',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01707430/336f06ff-b0a2-4705-864d-bd45b8b3e404.MOV',
    carrera: 'ICT',
    campus_id: 'Querétaro',    gen: '7a generación',
  },
{
    nombre: 'Michelle Álvarez Martínez',
    contenido: '',
    carrera_id: 'INCQ',
    campus: 'Monterrey',
    contenido_breve: 'Ser Líder del mañana significa ser esa esperanza, motivación, herramienta y guía de otros que ayuda al país y a las personas a trascender. Es ser el alma y corazón del progreso porque eliminamos las barreras y buscamos siempre tener un mejor futuro.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01707546/9eabaacb-273b-41b5-80a4-12e8cb1192d0.mp4',
    carrera: 'INCQ',
    campus_id: 'Querétaro',    gen: '7a generación',
  },
{
    nombre: 'Randall Martín García Reyes',
    contenido: '',
    carrera_id: 'IIT',
    campus: 'Querétaro',
    contenido_breve: 'Ser un líder del mañana para mi significa ser un ejemplo para las nuevas generaciones como agente de cambio en México el cual les motive con la realización de los proyectos que tengan y significa tener un compromiso con la sociedad por esta oportunidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01707595/0d78797d-ec7c-494d-8244-2ef558651f50.mp4',
    carrera: 'IIT',
    campus_id: 'Querétaro',    gen: '7a generación',
  },
{
    nombre: 'Diego Axel Márquez Heredia',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Querétaro',
    contenido_breve: 'Es un regalo de Dios que si bien me ha dado mucho me hace acreedor a una responsabilidad y una serie de oportunidades de poder generar un impacto y regresar un poco de lo que he recibido, es esencial aprovechar las herramientas y ser signo de servicio.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01707739/8a302cc3-e46b-4d01-bb98-6c2160dca30c.mp4',
    carrera: 'IIS',
    campus_id: 'Querétaro',    gen: '7a generación',
  },
{
    nombre: 'Jesús Eduardo Flores Cruz',
    contenido: '',
    carrera_id: 'IDS',
    campus: 'Querétaro',
    contenido_breve: 'Para mí ser un Líder del mañana es una oportunidad de dejar un México Mejor de cómo lo encontré, una oportunidad de poder dar a quienes fueron parte de mi formación como persona y como profesionista y a otros jóvenes como yo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01707776/150f9ff0-ca76-4346-a3c9-08a2641c5d4d.mp4',
    carrera: 'IDS',
    campus_id: 'Querétaro',    gen: '7a generación',
  },
{
    nombre: 'Jordi Noel Dorantes Dávila',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Querétaro',
    contenido_breve: 'Es una oportunidad para desarrollar mis cualidades académicas y personales poniendo en práctica mis valores, mi capacidad para trabajar en equipo y liderar, considerándome uno de los agentes de cambio a favor del desarrollo humano.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01707895/6c54f321-cb69-400d-b037-a8c6e014682f.mp4',
    carrera: 'IMT',
    campus_id: 'Querétaro',    gen: '7a generación',
  },
{
    nombre: 'Rocío Morales Tepox',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Puebla',
    contenido_breve: 'Es ser una persona muy afortunada y con un compromiso y responsabilidad hacia mi comunidad y hacia mi país. Significa formar parte de esa fuerza de empuje que mueve hacia adelante a la sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01730899/3d41afff-135c-4334-b896-3c10676942c7.mp4',
    carrera: 'IMT',
    campus_id: 'Puebla',    gen: '3a generación',
  },
{
    nombre: 'Mariana Caamal Coria',
    contenido: '',
    carrera_id: 'LDI',
    campus: 'Monterrey',
    contenido_breve: 'Ser líder del mañana significa para mí ser parte de una gran familia donde comparto sueños y metas con más de 1300 personas que sé que como yo, trabajan todos los días para construir un mundo mejor.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01730983/4f04a055-1ce4-4bc2-8d32-e2d05de9b914.mp4',
    carrera: 'LDI',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Jesús Romero Rodríguez',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Puebla',
    contenido_breve: 'Es un camino para cambiar a México, un camino lleno de retos, aprendizajes, amigos, profesores, alumnos, comunidades, oportunidades, un camino lleno de progresos que llevan a México a un lugar mejor.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01731669/f6bedb04-fe14-40d1-82ef-540d457f78bf.mp4',
    carrera: 'LAF',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Jesús David Razo Bermúdez',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Puebla',
    contenido_breve: 'Es una oportunidad que cambió totalmente mi vida. Me ha dado la oportunidad de impactar mi entorno y de cumplir mis sueños como lo es otorgar becas. Es un compromiso de reciprocidad muy grande con México por confiar en mí y apoyarme para salir adelante.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01731680/7a461ea9-2958-4821-b442-2920e6d39a8f.mp4',
    carrera: 'IIS',
    campus_id: 'Puebla',    gen: '4a generación',
  },
{
    nombre: 'Jonatan Emanuel Salinas Ávila',
    contenido: '',
    carrera_id: 'ISD',
    campus: 'Puebla',
    contenido_breve: 'Significa sueños, oportunidades y compromiso. LDM cumplió mi sueño de estudiar en el Tec; es una oportunidad sin igual para potenciar mis habilidades y tener una preparación de excelencia; y es un compromiso de poner mis talentos al servicio de los demás.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01731815/2d3fca09-d0c0-4165-9767-dd876edc05bb.mov',
    carrera: 'ISD',
    campus_id: 'Puebla',    gen: '4a generación',
  },
{
    nombre: 'Irving Emmanuel Villavicencio Camas',
    contenido: '',
    carrera_id: 'LDP',
    campus: 'Puebla',
    contenido_breve: 'En mi opinión un líder del mañana es una persona que no busca el merito propio, es un individuo que busca superar sus adversidades para el beneficio común, es una persona que sirve como ejemplo de resiliencia para los demás.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01732025/27b6c9cf-881c-4c1a-8019-84c9db99bdf0.mp4',
    carrera: 'LDP',
    campus_id: 'Puebla',    gen: '4a generación',
  },
{
    nombre: 'Luis Fernando Pérez Hernández',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Puebla',
    contenido_breve: 'Ser Líder del Mañana significa una gran oportunidad y privilegio, considero que la educación es un medio fundamental para poder realizar un cambio dentro de nuestra sociedad, ser parte de este programa implica un aprendizaje más haya del académico.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01732083/2dc21c57-0053-42cc-bef8-0196f0432864.mp4',
    carrera: 'IBT',
    campus_id: 'Puebla',    gen: '4a generación',
  },
{
    nombre: 'Brenda Carvajal Huerta',
    contenido: '',
    carrera_id: 'IID',
    campus: 'Puebla',
    contenido_breve: 'Ser líder del mañana es ser esa semilla que germinará para hacer de México un México lleno de oportunidades.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01732089/681a4a15-ae14-4c4d-854c-cce843f5a1bc.mp4',
    carrera: 'IID',
    campus_id: 'Puebla',    gen: '4a generación',
  },
{
    nombre: 'Pedro Samuel González Rodríguez',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Puebla',
    contenido_breve: 'Es una oportunidad de poder hacer un cambio de forma generacional, aportando mi conocimientos, experiencias y habilidades aprendidas en el carrera para poder aportar un mejor estilo de vida para todo el país.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01732105/3026ceed-aa2f-4661-a3d1-916a7da7f7c7.mp4',
    carrera: 'IMT',
    campus_id: 'Puebla',    gen: '4a generación',
  },
{
    nombre: 'Luis Alejandro Enríquez Solís',
    contenido: '',
    carrera_id: 'ISD',
    campus: 'Monterrey',
    contenido_breve: 'Es un privilegio y honor garrafal. Me siento feliz y bendecido por tener esta gran oportunidad. Ser líder del mañana significa para mí, estar comprometido con un cambio positivo en el entorno, y si soñamos en grande, para el país.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01732166/55438b53-6620-4e7e-aa87-0d389bb076a2.mp4',
    carrera: 'ISD',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Miguel Guevara Pacheco',
    contenido: '',
    carrera_id: 'IC',
    campus: 'Querétaro',
    contenido_breve: 'Ser líder del mañana ha sido la oportunidad más increíble que la vida me ha dado, para mi es muy importante poder generar un cambio en mi entorno a través de lo que hago y creo que esta oportunidad me ayudará a lograrlo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01732542/f54f68df-e8d0-4b8b-b20d-fce236c70de9.mp4',
    carrera: 'IC',
    campus_id: 'Querétaro',    gen: '5a generación',
  },
{
    nombre: 'Gerardo Humberto Herrera Quiroz',
    contenido: '',
    carrera_id: 'ARQ',
    campus: 'Puebla',
    contenido_breve: 'Para mi es una oportunidad de cambio no solo personal sino para todos. Si yo puedo aportar algo para el bien común estoy beneficiándome a mi también y creando un mejor lugar. Es inspirador, retador y gratificante poder llamarme líder del mañana',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01732746/fc7033a1-1b1d-4d0e-9162-3291d1e89e62.mp4',
    carrera: 'ARQ',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Sherlyn Quetzal López Hernández',
    contenido: '',
    carrera_id: 'ISD',
    campus: 'Puebla',
    contenido_breve: 'Ser Líder del Mañana es ser un ser inspirador que sea comprometido con lograr un cambio significativo en su entorno social de manera positiva.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01732822/beeedc04-6a9e-4ca9-89f2-ebfd53f74611.mp4',
    carrera: 'ISD',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Vanesa Ortega Rodríguez',
    contenido: '',
    carrera_id: 'ARQ',
    campus: 'Puebla',
    contenido_breve: 'Para mí, ser líder del mañana significa un compromiso con mi país y mi comunidad, mediante el cual contribuyo como agente de cambio para su beneficio, enfocando mi preparación profesional en atender los conflictos existentes en la realidad social.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01732890/f0c25c71-d72f-4589-b5e0-76588fd00433.mp4',
    carrera: 'ARQ',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Irán Yexalen Benítez Calzada',
    contenido: '',
    carrera_id: 'LEF',
    campus: 'Puebla',
    contenido_breve: 'Para mí, representa la oportunidad de ser un agente de cambio, no solo en mi comunidad, sino en mi país. Pertenecer a esta gran familia es un motor que me impulsa a dar lo mejor de mí día con día para generar soluciones y combatir problemas sociales.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01732930/3cfef7b1-89a6-4a1a-9d6f-1ad0d647880d.mp4',
    carrera: 'LEF',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Ximena Molina Herrera',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Puebla',
    contenido_breve: 'Ser Líder del Mañana es una oportunidad de poder realizar un cambio en la sociedad a través de contribuciones en labores sociales. Asimismo también me parece una gran responsabilidad de contribuir con un buen ejemplo para la sociedad a través de acciones.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01732932/bfea34b0-22fe-48c0-b41c-fd19685b7cf4.mp4',
    carrera: 'LIN',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Carlos Armando Posada Rodríguez',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Monterrey',
    contenido_breve: 'Para mi ser un Líder del Mañana es ser una persona de impacto en la comunidad. Significa ser una persona con actitud, que nunca se rinde a pesar de la adversidad. Es creer en ti y en el potencial de todos. Es agradecer y ser responsable del futuro próximo',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01732941/00dd72cc-ac19-460f-8318-dff767dd3250.mp4',
    carrera: 'LAF',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Luis Ángel Torres Mora',
    contenido: '',
    carrera_id: 'IMI',
    campus: 'Puebla',
    contenido_breve: 'Es una locura, muchas personas me ven con gran admiración pero no me siento especial. El crédito yo se lo daría a mi familia. Pero, en pocas palabras, LDM es una oportunidad que cambió mi vida y algún día yo cambiaré la vida de los demás.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01733113/4154c258-2a9b-4c17-941e-0f343297fe3a.mp4',
    carrera: 'IMI',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Hefziba Brindis Cuapantecatl',
    contenido: '',
    carrera_id: 'LAD',
    campus: 'Puebla',
    contenido_breve: 'Es tener la responsabilidad y el compromiso de ayudar a crecer a otros y a nosotros mismos, al tiempo que se atienden ciertos problemas sociales.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01733190/419a803f-ad99-4ab1-adcd-0f11a100dd99.mp4',
    carrera: 'LAD',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Miguel Ángel Leobardo Vega Ríos',
    contenido: '',
    carrera_id: 'LDP',
    campus: 'Puebla',
    contenido_breve: 'Ser Líder del Mañana es el anhelo de superación que siempre he tenido por aportar un impacto positivo a mi comunidad y a la vez, significa el compromiso que asumo con responsabilidad de día con día prepararme profesionalmente.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01733225/174abc6c-7819-4277-bec6-2982ab962b57.mp4',
    carrera: 'LDP',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Alan Alberto Cruz García',
    contenido: '',
    carrera_id: 'ISD',
    campus: 'Puebla',
    contenido_breve: 'Es una herramienta, un impulso y una inspiración para nuestro camino de cumplir nuestros sueños, mediante la institución privada más importante del país. Siendo un factor de cambio en todo sentido para formar un México mejor, impulsando a más jóvenes.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01733377/29754788-8153-43d7-b46f-e21205839cb8.mp4',
    carrera: 'ISD',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Montserrat Nava Cuahutle',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Puebla',
    contenido_breve: 'Es un honor y un orgullo pertenecer a un grupo de jóvenes a los que nos brindan la confianza de formar un México mejor. Representa un enorme compromiso con mi país, bajo un apoyo sincero y real del cual he tomado la mejor de las inspiraciones.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01733391/afd1ddc1-afbe-41df-a69f-ff09bc6be03e.mp4',
    carrera: 'IIS',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Natalia Pérez Cruz',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Puebla',
    contenido_breve: 'Ser un líder del mañana es una oportunidad de ser el cambio que quiero ver en mi sociedad, representa la culminación de muchos años de esfuerzo y al mismo tiempo el inicio de una serie de eventos enfocados a poder crear una diferencia en el mundo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01733443/384eccb0-cbfc-4a6d-8384-e5518f3e18c3.mp4',
    carrera: 'IIS',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Alexander Karu Alcántara Contreras',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Puebla',
    contenido_breve: 'Un Líder del Mañana es alguien que ha demostrado un resiliencia enorme en su vida, ya que a pesar de sus situaciones previas siempre busca una solución. Es alguien que se esfuerza contra pronóstico y que crea oportunidades de crecimiento donde no las hay.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01733451/fdc70798-262e-4c94-aed5-00cbb9669ac2.mp4',
    carrera: 'IBT',
    campus_id: 'Puebla',    gen: '5a generación',
  },
{
    nombre: 'Fernanda Casados Prior',
    contenido: '',
    carrera_id: 'IDS',
    campus: 'Monterrey',
    contenido_breve: 'Ser lider del mañana es un privilegio y una responsabilidad social conmigo misma y con la comunidad, es un símbolo de mi constancia y del camino que he recorrido para seguir perseverando e impulsarme a lograr mis metas y cumplir mi compromiso social.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01733739/0ad19a43-54b4-498a-9438-2f85f1eca18e.MOV',
    carrera: 'IDS',
    campus_id: 'Puebla',    gen: '6a generación',
  },
{
    nombre: 'Luis Alberto Mirón Toruño',
    contenido: '',
    carrera_id: 'NEG',
    campus: 'Puebla',
    contenido_breve: 'Significa pertenecer a una comunidad increíble de personas que están unidas por su historia y sus aptitudes y buscan alcanzar un propósito superior: un mejor México.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01735489/eed9223e-f0ee-4f55-b2f8-2c3c0196ea2b.mp4',
    carrera: 'NEG',
    campus_id: 'Puebla',    gen: '7a generación',
  },
{
    nombre: 'José Eduardo Barrientos Castillo',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Puebla',
    contenido_breve: 'Es ser un agente de cambio que pueda generar un impacto en la sociedad en la que vive y en la que le gustaría que las siguientes generaciones vivan, es luchar por crear un futuro que de pequeños, solo se veía en sueños, es cambiar y transformar el mundo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01735530/99fef7b1-ce95-435d-902a-b57100d548b3.mp4',
    carrera: 'LAF',
    campus_id: 'Puebla',    gen: '7a generación',
  },
{
    nombre: 'Sandra Muñoz Coyotecatl',
    contenido: '',
    carrera_id: 'IBQ',
    campus: 'Puebla',
    contenido_breve: 'Es una oportunidad para seguir apoyando a quienes más lo necesitan. Es ser parte de una gran comunidad en donde compartimos muchas cosas en común, como aspecto principal: el querer un México más justo y con las mismas oportunidades para todos.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01735548/0cd1e439-37ff-4e15-9618-ddd9f36c40e9.mov',
    carrera: 'IBQ',
    campus_id: 'Puebla',    gen: '7a generación',
  },
{
    nombre: 'Karla Guadalupe Romero Pérez',
    contenido: '',
    carrera_id: 'IC',
    campus: 'Puebla',
    contenido_breve: 'Es una gran responsabilidad, es tener las herramientas necesarias para lograr un cambio significativo en nuestro entorno, donde nuestras ideas son escuchadas y podemos ayudar a que más jóvenes talentosos cumplan sus sueños.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01735550/5913771c-5d9b-45c2-b79d-038ea267e2a9.mp4',
    carrera: 'IC',
    campus_id: 'Puebla',    gen: '7a generación',
  },
{
    nombre: 'Mayra Sayonara Muñoz Sosa',
    contenido: '',
    carrera_id: 'LED',
    campus: 'Puebla',
    contenido_breve: 'Para mi es sentirme en familia, es una llave para abrir muchas puertas hacia miles de oportunidades de crecimiento. Es ser un agente de cambio que tiene la gran oportunidad de colaborar con más líderes a lo largo y ancho del país para mejorar a Mexico.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01735551/80a307e1-e69f-42db-b5db-731228f7d2b5.MOV',
    carrera: 'LED',
    campus_id: 'Puebla',    gen: '7a generación',
  },
{
    nombre: 'Humberto Franco Félix',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Sinaloa',
    contenido_breve: 'Es tener el compromiso de siempre dar lo mejor de mi, de estar en constante búsqueda de hacer cambios positivos y de motivar a otros a que también los hagan.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01740334/4f710565-8647-4524-badf-1b072d961cba.mp4',
    carrera: 'IIS',
    campus_id: 'Sinaloa',    gen: '4a generación',
  },
{
    nombre: 'Jesús Manuel Figueroa Castillo',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Monterrey',
    contenido_breve: 'Es orgullo, formar parte de una gran familia que tiene como objetivo llevar cambio a su comunidad, estado y país.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01740549/79a82e81-7481-4a45-838d-a04b711d402d.mp4',
    carrera: 'IMT',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'José Mario Flores Beltrán',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Sinaloa',
    contenido_breve: 'Es un compromiso enorme, así como una oportunidad excelente para desarrollar y descubrir mis talentos a la par que puedo formar parte de una gran comunidad de jóvenes de todo el país que buscan un impacto social.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01740889/7572f00c-114f-4204-8c0e-17f94ea5417e.mp4',
    carrera: 'LAF',
    campus_id: 'Sinaloa',    gen: '5a generación',
  },
{
    nombre: 'Audrey Sofía Nava Utrera',
    contenido: '',
    carrera_id: 'ISD',
    campus: 'Querétaro',
    contenido_breve: 'Ser líder el mañana para mi es ser un agente de cambio, es ser la esperanza de un México mejor, donde la línea entre en rico y el pobre no importe para definir el quien soy y para donde vamos. Es ser alguien con mucha resiliencia en todos lados.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01740902/ca206a1c-5ba0-4578-a8ea-3c9c93177f00.mp4',
    carrera: 'ISD',
    campus_id: 'Querétaro',    gen: '5a generación',
  },
{
    nombre: 'Francys Verónica Corvera García',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Monterrey',
    contenido_breve: 'Para mi significa el potencial de hacer las cosas realidad: Pensar fuera de la caja, tener iniciativa, poder comunicarte con las personas y lograr ir mas allá.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01740906/1a7655f6-0ab4-4532-aad3-c91abe6cc9d1.',
    carrera: 'IMT',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Jayri Diana Rodríguez Vega',
    contenido: '',
    carrera_id: 'IID',
    campus: 'Querétaro',
    contenido_breve: 'Ser Líder del Mañana es una oportunidad de crecer y trascender, este programa ha sido una familia que me brinda las herramientas para cumplir mis sueños, para ser mejor persona y lograr ser un agente de cambio en mi entorno',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01740910/cb5799d5-7b0c-421d-94e1-2a7dc8cb5448.mp4',
    carrera: 'IID',
    campus_id: 'Querétaro',    gen: '5a generación',
  },
{
    nombre: 'María Fernanda Vega Quintero',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Sinaloa',
    contenido_breve: 'Para mi ser líder del mañana significa una gran oportunidad que me da todas las herramientas necesarias para crecer y desarrollarme profesionalmente, que me alienta a dar lo mejor de mi para compartir con los demás y ayudar a forjar una mejor sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01740920/8d1ec8af-9d15-47db-9412-42e05bf40a59.mp4',
    carrera: 'LAF',
    campus_id: 'Sinaloa',    gen: '5a generación',
  },
{
    nombre: 'Nadia Karime Gutiérrez Cabanillas',
    contenido: '',
    carrera_id: 'IBN',
    campus: 'Sinaloa',
    contenido_breve: 'Es la oportunidad de estar en el mejor lugar para potenciar habilidades y descubrir talentos, rodeado de las mejores personas, inspiradoras y con muchos aprendizajes, desarrollando proyectos de impacto social.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01740948/a018bb3b-bd28-411c-935b-465c6652d79a.mp4',
    carrera: 'IBN',
    campus_id: 'Sinaloa',    gen: '5a generación',
  },
{
    nombre: 'Marco Vinicio Mancinas López',
    contenido: '',
    carrera_id: 'IMT',
    campus: 'Monterrey',
    contenido_breve: 'Ser líder del mañana es una oportunidad, porque siempre hay gente con algo importante que decir, pero estas personas no siempre tienen la oportunidad de ser escuchadas y al agrupar a las mejores mentes de México, el programa logra cambiar ese panorama.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01740950/b41d57f4-8e0a-4a25-a61c-9cd82eff5062.mp4',
    carrera: 'IMT',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Carlos Daniel Ceballos Rubio',
    contenido: '',
    carrera_id: 'IBN',
    campus: 'Sinaloa',
    contenido_breve: 'Una oportunidad para prepararme y lograr ser un agente de camino en beneficio de mi comunidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01740951/bca3d9f9-98a2-4b52-96ab-0edfb1c86c22.mp4',
    carrera: 'IBN',
    campus_id: 'Sinaloa',    gen: '5a generación',
  },
{
    nombre: 'Jesús Gabriel Leal Leal',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Sinaloa',
    contenido_breve: 'Ser un líder del mañana representa para mi la mejor oportunidad que tengo en esta vida para desarrollar todas mis habilidades, aprender y dominar nuevas. Representa ese compromiso de reciprocidad para poder ayudar a más jóvenes mexicanos.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01740989/c8bc899e-e808-495a-abcb-47d16deaafad.mp4',
    carrera: 'IIS',
    campus_id: 'Sinaloa',    gen: '5a generación',
  },
{
    nombre: 'Odette Gehoana Espinoza Espinoza',
    contenido: '',
    carrera_id: 'IBN',
    campus: 'Sinaloa',
    contenido_breve: 'Para mi el pertenecer a esta gran familia que es lideres del mañana, en la cual todos compartimos nuestros objetivos, metas o intereses comunes por mejorar nuestra persona y el país significa felicidad y compromiso.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01740990/1b81da38-ae5a-4045-8a1e-dfa5606523cc.mp4',
    carrera: 'IBN',
    campus_id: 'Sinaloa',    gen: '5a generación',
  },
{
    nombre: 'Fernando García Meza',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Monterrey',
    contenido_breve: 'Ser un líder de mañana representa el ser una generación encargada de mejorar el rumbo de nuestro país, ser jóvenes determinantes, líderes y con una gran capacidad de resiliencia, que pondrán el nombre de México en lo más alto.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01741094/e27e0fef-419e-4347-9cba-dcfd0c3cbad7.mp4',
    carrera: 'LAF',
    campus_id: 'Monterrey',    gen: '6a generación',
  },
{
    nombre: 'Francisco Ojeda López',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Sinaloa',
    contenido_breve: 'Es una oportunidad que no se presenta dos veces en la vida, la cual me ha ayudado a superarme como persona día tras día',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01741323/845f8f23-c1e5-44e9-96aa-e7e1adc72ca2.mp4',
    carrera: 'LAF',
    campus_id: 'Sinaloa',    gen: '6a generación',
  },
{
    nombre: 'Francisco Guadalupe Juárez Gastélum',
    contenido: '',
    carrera_id: 'IIS',
    campus: 'Monterrey',
    contenido_breve: 'Ser Líder del Mañana, para mi, es despertar cada dia, y ser la mejor versión de mi, estar construyendo constantemente mi personalidad y con base a todos los aprendizajes, aptitides y valores adoptados a lo largo de mi vida, ayudar a los demás.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01741707/b456c532-7654-44c9-8a10-2ab900843503.mp4',
    carrera: 'IIS',
    campus_id: 'Sinaloa',    gen: '7a generación',
  },
{
    nombre: 'Jorge Alberto Figueroa Castillo',
    contenido: '',
    carrera_id: 'ARQ',
    campus: 'Monterrey',
    contenido_breve: 'Para mi ser un líder del mañana significa ser un persona comprometida no solamente con su comunidad, sino con todo nuestro país. Es ser ese joven que a pesar de su situación económica trabaja día tras día para ser ese agente de cambio que México necesita.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01741875/23460490-0848-41b1-8952-f24140a2007c.mov',
    carrera: 'ARQ',
    campus_id: 'Sinaloa',    gen: '7a generación',
  },
{
    nombre: 'Emmanuel Castro Trinidad',
    contenido: '',
    carrera_id: 'INCQ',
    campus: 'Sinaloa',
    contenido_breve: 'Un Lider del Mañana es alguien dispuesto a ser el futuro de nuestra nacion. Ser un Lider, significa usar todo en nuestro poder para generar un cambio, inspirar personas y ser la razon por la que cada dia, el cielo en Mexico es mas azul.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01741907/571689da-d57c-494b-867e-941a603d02c7.mp4',
    carrera: 'INCQ',
    campus_id: 'Sinaloa',    gen: '7a generación',
  },
{
    nombre: 'Estefanía Ruelas Angulo',
    contenido: '',
    carrera_id: 'LAF',
    campus: 'Monterrey',
    contenido_breve: 'Formar parte de la transformación positiva que este país esta viviendo. El compromiso de inspirar a cada vez mas jóvenes a que se sumen a la agencia de cambio, así como de aprovechar todas las oportunidades para crecer y ser mejor.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01741908/02bd5780-c52c-43ce-b8b8-b615a89321bd.mp4',
    carrera: 'LAF',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Xchel Yair Melo Huitrón',
    contenido: '',
    carrera_id: 'LED',
    campus: 'Estado de México',
    contenido_breve: 'Líderes del Mañana es una oportunidad de esperanza para todos aquellos chicos con gran potencial para cambiar a México y al mundo. Sin duda, esta beca representa el claro ejemplo de superación personal y de que los sueños están para cumplirse.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01745493/d35bd408-7d8b-40ec-881f-26d75073d06f.mp4',
    carrera: 'LED',
    campus_id: 'Estado de México',    gen: '4a generación',
  },
{
    nombre: 'Jesús Eduardo Trejo Chávez',
    contenido: '',
    carrera_id: 'IQA',
    campus: 'Estado de México',
    contenido_breve: 'Ser un líder del mañana es uno de los logras más grandes que he tenido. El hecho de estar estudiando gracias al esfuerzo de mucha gente es un honor y, a la vez, una responsabilidad. La comunidad que se ha formado es increíble y agradezco fomar parte.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01745965/3f71d939-7197-49ab-ba50-d563e376bb58.mp4',
    carrera: 'IQA',
    campus_id: 'Estado de México',    gen: '4a generación',
  },
{
    nombre: 'Edgar Alexis González Amador',
    contenido: '',
    carrera_id: 'ISD',
    campus: 'Estado de México',
    contenido_breve: 'Un líder del mañana busca edificar. No importa con que conocimientos, ni quien sea su apoyo. El liderazgo se trata de influir a tu alrededor, y lo que necesitamos en un líder del mañana es que haga esto para edificar y en gran manera.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01746540/50c79d8a-69e1-4315-a8de-b978df55dc53.mp4',
    carrera: 'ISD',
    campus_id: 'Estado de México',    gen: '4a generación',
  },
{
    nombre: 'Karla Mariana García Camargo',
    contenido: '',
    carrera_id: 'LCMD',
    campus: 'Estado de México',
    contenido_breve: 'Para mi, ser líder del Mañana es ser una persona que ayuda a los demás, aporta a su comunidad y que tiene un compromiso con sigo mismo y su entorno en aprovechar la oportunidad que nos brinda el Tec de Monterrey para poder ser mejores personas.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01746542/9074c41d-fda4-4570-a1dd-2f994905666a.mp4',
    carrera: 'LCMD',
    campus_id: 'Estado de México',    gen: '4a generación',
  },
{
    nombre: 'Héctor Irwin Martínez Martínez',
    contenido: '',
    carrera_id: 'IFI',
    campus: 'Monterrey',
    contenido_breve: 'Una transformación social que emerge del talento y la meritocracia, para germinar en cada uno de sus agentes de cambio, desde alumnos beneficiaros de la beca, donantes, colaboradores y familiares. Construimos en el día a día, lo que mañana nos definirá.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01746543/3b006523-f32e-421f-bd13-ebebe802096b.mp4',
    carrera: 'IFI',
    campus_id: 'Monterrey',    gen: '4a generación',
  },
{
    nombre: 'Alexis Ricardo Piña Vargas',
    contenido: '',
    carrera_id: 'ISD',
    campus: 'Estado de México',
    contenido_breve: 'Para mi un Lider del Mañana es una gente de cambio que tiene empatia y comprension por el entorno y por el contexto de cada una de las personas para tratar de cambiar en beneficio de los demas el mundo en el que vivimos, promoviendo los mejores valores.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01746551/bc4a8773-de5f-439b-ab02-10c8828a379c.mp4',
    carrera: 'ISD',
    campus_id: 'Estado de México',    gen: '4a generación',
  },
{
    nombre: 'Omar Alejandro Vilchis Argüelles',
    contenido: '',
    carrera_id: 'LEM',
    campus: 'Estado de México',
    contenido_breve: 'Es ser consciente de las problemáticas sociales de nuestro país y trabajar en buscar soluciones en conjunto con la sociedad',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01747085/d53a8c66-4d07-461e-94f1-af38daa3fc86.mp4',
    carrera: 'LEM',
    campus_id: 'Estado de México',    gen: '5a generación',
  },
{
    nombre: 'Marco Alan Moreno León',
    contenido: '',
    carrera_id: 'ISD',
    campus: 'Estado de México',
    contenido_breve: 'Una persona líder que dentro de sus objetivos de vida está el impactar a nuestra sociedad. El desarrollo de proyectos sociales es el método en el cual podemos llegar de manera directa a todos estos lugares en donde se puede contribuir.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01747315/2cd47e8f-6a88-4aff-92ea-ebd8f1d41296.mp4',
    carrera: 'ISD',
    campus_id: 'Estado de México',    gen: '5a generación',
  },
{
    nombre: 'Jocelyn Zavala Torres',
    contenido: '',
    carrera_id: 'LCMD',
    campus: 'Estado de México',
    contenido_breve: 'Es la oportunidad de convertirme en mi mejor versión y cumplir mis sueños: trabajar en la creación de una sociedad más diversa y equitativa a través de la comunicación social y retribuir a mi comunidad lo que he sido tan privilegiada de recibir.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01747690/f2fbe0de-87a5-4f12-87b8-5c28b08a6eeb.mov',
    carrera: 'LCMD',
    campus_id: 'Estado de México',    gen: '5a generación',
  },
{
    nombre: 'Iván Alberto Cruz González',
    contenido: '',
    carrera_id: 'LCDE',
    campus: 'Estado de México',
    contenido_breve: 'Ser un Líder del Mañana es el desarrollar día a día mis habilidades de liderazgo (activo o pasivo) en todos los entornos en los que me desenvuelvo; es guiar con el ejemplo, tener fuertes convicciones, conocer, dejar actuar y preocuparme por mis equipos.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01748173/129fe97b-3b84-40e4-a896-24adecd447ad.mp4',
    carrera: 'LCDE',
    campus_id: 'Estado de México',    gen: '5a generación',
  },
{
    nombre: 'Ricardo Bazán Román',
    contenido: '',
    carrera_id: 'IQA',
    campus: 'Monterrey',
    contenido_breve: 'Para mi ser líder del mañana es un agente de cambio, que entiende la problemática actual de su entorno y que se está preparando para poder en el futuro arreglarla o mejorarla, de igual forma ser un líder es una forma de vivir, soñar, ser y crecer.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01748307/da44b530-cf21-40c8-a4a9-72e93ecc5850.mp4',
    carrera: 'IQA',
    campus_id: 'Monterrey',    gen: '5a generación',
  },
{
    nombre: 'Adriana Elideth Gil García',
    contenido: '',
    carrera_id: 'LRI',
    campus: 'Estado de México',
    contenido_breve: 'Para mí, ser un Líder del mañana es un sueño hecho realidad. Describir lo mucho que significa para mí se vuelve casi imposible, pero entre las palabras que florecen en mi mente al intentarlo, aparecen compromiso, esperanza, esfuerzo, metas, cambio...',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01748623/8f413cbc-6f27-49e9-9be4-25db3e7f7082.mp4',
    carrera: 'LRI',
    campus_id: 'Estado de México',    gen: '5a generación',
  },
{
    nombre: 'Laura Adamary Monforte Cervantes',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Estado de México',
    contenido_breve: 'Es una oportunidad para seguir aprendiendo y aprovechando todas las oportunidades que el programa y el Tec me brindan para seguir desarrollando mis habilidades y conocimientos para poder ayudar a distintas comunidades en todo el país.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01750149/fc2c38d2-3846-4fb2-8198-211be84c241a.mp4',
    carrera: 'LIN',
    campus_id: 'Estado de México',    gen: '6a generación',
  },
{
    nombre: 'Mauricio Mosqueda Brizuela',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Estado de México',
    contenido_breve: 'Ser un Líder del Mañana significa más que una beca en el Tec de Monterrey. Es ser un agente de cambio con la capacidad de trabajar para construir un mejor futuro y un mejor México.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01751661/53bed9e5-28d3-4e90-b9f3-c6bbe99fa656.mp4',
    carrera: 'IBT',
    campus_id: 'Estado de México',    gen: '7a generación',
  },
{
    nombre: 'Diana Lizbeth Villafranco Alvarado',
    contenido: '',
    carrera_id: 'ARQ',
    campus: 'Estado de México',
    contenido_breve: 'para mi el ser un lider del mañana es un copromiso y un honor enorme para mi comunidad pues pienso devolver cada una de las oportunidades que a mi me dieron y mas de la forma en la que a mi me sea posible, ser un ajente de cambio, un lider del mañana.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01751686/e2cb8d22-1bd7-42fe-825b-da2a0554e9aa.mp4',
    carrera: 'ARQ',
    campus_id: 'Estado de México',    gen: '7a generación',
  },
{
    nombre: 'Michelle Denisse Mendizábal Velázquez',
    contenido: '',
    carrera_id: 'LED',
    campus: 'Ciudad de México',
    contenido_breve: 'Para mí significa tener una oportunidad enorme para convertirte en la mejor versión de ti, académica y personal. Poder desarrollarte con las mejores herramientas institucionales, y así servir como guía para futuros líderes que construirán un mejor país.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01752058/341b92f2-02a5-4dc2-a734-c19f8e1c60e6.MOV',
    carrera: 'LED',
    campus_id: 'Ciudad de México',    gen: '7a generación',
  },
{
    nombre: 'Camila Andrea Martínez Montes',
    contenido: '',
    carrera_id: 'IME',
    campus: 'Estado de México',
    contenido_breve: 'La oportunidad de ser un agente de cambio integral a través de la cimentación de principios, estrategias y conocimientos solidos para crear trascendencia en acciones que reivindiquen el cambio espontaneo a permanente',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01752066/70beda95-ee2e-453a-a536-118849c9995b.mp4',
    carrera: 'IME',
    campus_id: 'Estado de México',    gen: '7a generación',
  },
{
    nombre: 'Arely Anguiano Rodríguez',
    contenido: '',
    carrera_id: 'IBT',
    campus: 'Monterrey',
    contenido_breve: 'Representa estar más cerca de cumplir mi más grande sueño: dejar huella en la vida de las personas a través del conocimiento que adquiera con mi profesión para generar un impacto positivo en la sociedad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01752068/d8bff00c-ec15-4fe2-a504-95d79adac679.mp4',
    carrera: 'IBT',
    campus_id: 'Estado de México',    gen: '7a generación',
  },
{
    nombre: 'Mariana Regina Escudero Valencia',
    contenido: '',
    carrera_id: 'IIT',
    campus: 'Estado de México',
    contenido_breve: 'Ser un líder del mañana es ser parte de un grupo de personas que tienen pasiones similares a las tuyas por querer efectuar un cambio, ser líder es un compromiso con mi comunidad y con mi país por un cambio.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01752203/4371c58e-24c5-41eb-ab79-e03ab537d7f1.mp4',
    carrera: 'IIT',
    campus_id: 'Estado de México',    gen: '7a generación',
  },
{
    nombre: 'Ivonne Stefany Piña Vargas',
    contenido: '',
    carrera_id: 'LAD',
    campus: 'Estado de México',
    contenido_breve: 'Es una persona comprometida con su entorno que busca constantemente transformar y crear un impacto social para mejorar la vida de las personas que lo rodean, esto a través de sus habilidades y conocimientos adquiridos a base de experiencias.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01752784/0d9eb111-ff69-4469-bda3-0951bbaf55e7.mp4',
    carrera: 'LAD',
    campus_id: 'Estado de México',    gen: '7a generación',
  },
{
    nombre: 'Luis Humberto Romero Pérez',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Estado de México',
    contenido_breve: 'Una serie de oportunidades que ya han cambiado mi vida, formar parte de una comunidad de personas que quieren mejorar nuestro país.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01752789/499aeb84-1429-4265-a1e6-b85b8e40d381.mp4',
    carrera: 'ITC',
    campus_id: 'Estado de México',    gen: '7a generación',
  },
{
    nombre: 'Dafne Alexandra Ramírez Marín',
    contenido: '',
    carrera_id: 'NEG',
    campus: 'Monterrey',
    contenido_breve: 'Ser un líder del mañana es la oportunidad de vida que me permite obtener las herramientas diferenciadoras, seguir siendo un agente de cambio y así mismo continuar generando el cambio positivo que México necesita.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01752807/90f26571-1dc7-4a72-8d93-4664be4cf6d1.mp4',
    carrera: 'NEG',
    campus_id: 'Estado de México',    gen: '7a generación',
  },
{
    nombre: 'Karla Susana Cruz Razo',
    contenido: '',
    carrera_id: 'LIN',
    campus: 'Estado de México',
    contenido_breve: 'Significa utilizar todos los recursos, habilidades y energía con la que cuento para impactar la vida de las personas, para ayudarlos a cumplir sus sueños a pesar de las circunstancias. Significa dar todo de ti para dar todo de ti.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01752938/ca557d4b-7b94-4c81-99e9-94400dd9bc1b.mp4',
    carrera: 'LIN',
    campus_id: 'Estado de México',    gen: '7a generación',
  },
{
    nombre: 'Ximena Matías Mercado',
    contenido: '',
    carrera_id: 'INCQ',
    campus: 'Monterrey',
    contenido_breve: 'Significa ser responsable de guiar y ayudar al florecimiento propio y de las demás personas con el fin de transformar nuestro país y el mundo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01752950/06bcbece-2ae7-460c-a1ba-ce74ca02e522.mp4',
    carrera: 'INCQ',
    campus_id: 'Monterrey',    gen: '7a generación',
  },
{
    nombre: 'Frida Cano Falcón',
    contenido: '',
    carrera_id: 'ISD',
    campus: 'Estado de México',
    contenido_breve: 'Para mí ser LDM significa ser consciente de la responsabilidad social y tener el valor y voluntad para asumirla, así como tener la capacidad para poder conmover a los demás a ser empáticos y unirse a realizar proyectos con impacto social.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01752953/570d0d96-c7cb-4c0d-a669-bf8615aaf1b5.mp4',
    carrera: 'ISD',
    campus_id: 'Estado de México',    gen: '7a generación',
  },
{
    nombre: 'Emily Grettel Morales Hernández',
    contenido: '',
    carrera_id: 'ITC',
    campus: 'Puebla',
    contenido_breve: 'Líder del Mañana, es ser un agente de cambio no solo en nuestra sociedad, sino que debemos trabajar en nosotros mismos en nuestro sentido humano, esto para poder cumplir con el compromiso que tenemos al ser parte de Líderes del Mañana.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01769234/b2624b62-1763-4786-acc5-2516f5a9cf0f.mp4',
    carrera: 'ITC',
    campus_id: 'Toluca',    gen: '6a generación',
  },
{
    nombre: 'José Luis Arizmendi Bernal',
    contenido: '',
    carrera_id: 'IA',
    campus: 'Querétaro',
    contenido_breve: 'Todo gran poder, conlleva una enorme responsabilidad. LDM es para mí, más que un grupo de compañeros, es una familia que se ha unido con el mismo objetivo: construir el México con el que soñamos, a través del compromiso y la reciprocidad.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01769933/a1375095-709f-4dc7-92ed-8f67a263f41c.mp4',
    carrera: 'IA',
    campus_id: 'Toluca',    gen: '7a generación',
  },
{
    nombre: 'Ingrid Andrea Sarahí Vargas Cervantes',
    contenido: '',
    carrera_id: 'ESC',
    campus: 'Ciudad de México',
    contenido_breve: 'Ser un Líder del Mañana es tener la oportunidad de estudiar en una gran escuela, de pertenecer a una familia de jóvenes interesados por hacer de México un lugar mejor, y de poder formarnos continuamente en materia de liderazgo y emprendimiento.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A01781461/6af42065-a308-4b3c-b2e3-26712046de59.mp4',
    carrera: 'ESC',
    campus_id: 'Santa Fe',    gen: '7a generación',
  },
{
    nombre: 'Ángel Agustín Cantoral Sánchez',
    contenido: '',
    carrera_id: 'IMI',
    campus: 'Puebla',
    contenido_breve: 'Para mi significa ser una persona con un compromiso enorme y a la vez capaz de ver por su comunidad, por su sociedad y atender las áreas de oportunidad qué puedan existir, para resolver los problemas de nuestro país, o incluso, del mundo.',
    video_url:
      'https://s3.amazonaws.com/micasaldm.resources/forms/UNLOCATED/A07144621/bdf9407f-5788-4e02-a3fb-9b4ea3cc2a0a.mp4',
    carrera: 'IMI',
    campus_id: 'Puebla',    gen: '5a generación',
  }
]


const MuroGratitud = () => {
  let mod600 = useMediaQuery({ query: `(min-width: 800px)` });
  let mod800 = useMediaQuery({ query: `(min-width: 1000px)` });

  let [lstVideos, setVideoList] = useState([])
  const [foto, setFoto] = useState({})
  useEffect(()=>{

    let lst = []

    let i = 0;
    while(i<videos2.length){
        videos2[i].type = 1
        lst.push(videos2[i])

        if (i<videos.length){
            videos[i].type = 2
            lst.push(videos[i])
        }
        i = i + 1;
    }

    setVideoList(lst)

    var myModalEl  = document.getElementById("exampleModal")
    let listener = myModalEl.addEventListener('hidden.bs.modal', function () {
        setFoto({})
        var video = document.getElementById(`vid${foto.matricula}`);
        video.pause();
          video.currentTime = 0;
    })

  },[])

  return (
    <React.Fragment>
      <div className='bg-primary p-2 pt-3 pb-3 w-100' style={{
            position:"absolute",
            top:0,
            zIndex:10,

          }

      }>
        <div
          style={{
    
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div className="d-none d-md-block" style={{ flex: 1, textAlign: "center" }}>
            <img src={logo1} alt='' height={50} />
          </div>
          <div style={{ flex: 2, textAlign: "center" }}>
            <span className="grey-text">
              Muro de Gratitud
              <br />
            </span>
            <span className=' glow-pink cursiva'>¡Gracias!</span>

          </div>
          <div className="d-none d-md-block" style={{ flex: 1, textAlign: "center" }}>
            <img src={logo2} alt='' height={55} />
          </div>
        </div>
      </div>

      <div className='container-fluid p-0' style={{ maxWidth: "100%", height:"calc(100vh)",        backgroundColor:"#1a3192", }} >
        <div className="row g-0" style={{ }}>
          <div className="order-1 order-sm-0 col-12 col-sm-6 col-md-9" style={{ height:"100vh", overflowY:"scroll"  }}>
          <div className="d-none d-md-block" style={{height:101}}/>
          <div className='myGallery2'
      >
              {lstVideos.map((item, key) => (

               item.type == 2 ?
                <div style={{position:"relative", background:"black"}} key={key}>
                  <iframe
                    style={{ border: "1px solid #f5f5f5" }}
                    frameBorder='0'
                    src={item.video_url}
                    allow='autoplay'
                    width='100%'
                    height={mod800 ? "270" : mod600 ? "210" : "300"}
                  >
                    <div style={{ height: "300px" }}>
                      <div className={"detailVideo"}>
                        <div className={"detSdetailVideo"}></div>
                        <div className={"detailVideo_info"}>
                          <div
                            level={4}
                            style={{ float: "right" }}
                            className={"detailVideo_info2"}
                          >
                            {item.carrera_id}
                          </div>
                          <div level={3} className={"detailVideo_info2"}>
                            {item.nombre}
                          </div>
                          <p
                            className={"detailVideo_info2"}
                            style={{ fontSize: "12px" }}
                          >
                            Campus {item.campus} | {item.periodo}
                          </p>

                          <div
                            className={"detailVideo_info2"}
                            ellipsis={{ rows: 5 }}
                            style={{
                              paddingTop: 5,
                              fontSize: "10px",
                              fontStyle: "italic",
                            }}
                          >
                            {item.contenido_breve}
                          </div>
                        </div>
                        <div className={"detS2"}></div>
                      </div>
                    </div>
                  </iframe>
                  <div className="w-100 p-2 mb-1 glow" style={{position:"absolute", bottom:0, background:"rgba(0,0,0,0.7)", color:"white"}}>
                    <b>{item.nombre}</b><br/>
                    <span  style={{fontSize:12}}> Campus {item.campus} | { item.periodo}</span>
                    </div>
                </div>:   
                <div className="d-flex flex-column align-items-center p-4 position-relative" style={{  height: mod800 ? "270px" : mod600 ? "210px" : "300px" }} key={key}>
                    <img src="fondo.png" s className="position-absolute top-50 start-50 translate-middle" style={{filter:"drop-shadow(3px 3px 3px rgba(0,0,0,0.25))"}} height={mod800 ? 450 : mod600 ? 390 : 480} />
                    <figure className="text-center p-4 position-absolute top-50 start-50 translate-middle w-100" style={{maxWidth:400}}>
  <blockquote className="blockquote" style={{fontSize:14}}>
    
    <p>{item.contenido_breve}</p>
  </blockquote>
  <figcaption style={{fontSize:12}} className="blockquote-footer ">
  
  <cite > {item.nombre}</cite><br/>
     Campus {item.campus} | {item.gen} |  {item.carrera_id}
     <br/>
     <span className="btn"  data-bs-toggle="modal"  data-bs-target="#exampleModal" onClick={()=>setFoto(item)}>
<i className="fas fa-play-circle"/> Ver video
</span>
  </figcaption>

                    </figure>

                    </div>
              ))}
            </div>
          </div>

          <div className="order-0 order-sm-1 col-12 col-sm-6 col-md-3" style={{height:"100vh", paddingTop:101}}>
                    
              <iframe
              style={{height:"100%", width:"100%"}}
                src='https://padlet.com/embed/5t1njpou19ttu7ra'
                
                allow='camera;microphone;geolocation'
              ></iframe>
 
           
          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl">
    <div className="modal-content bg-dark-transparent" style={{backgroundColor:"black"}}>

      <div className="text-white d-flex  align-items-center">
        <div className='text-white flex-fill ps-3'><i className='fas fa-play'/>{" "}{foto.nombre}</div>
        <a href={foto} target={"_blank"} download={foto} rel="noreferrer"className='btn text-white fs-4' ><i className='fas fa-download'/></a>
        <button type="button" className="btn text-white fs-3" data-bs-dismiss="modal" aria-label="Close"><i className='fas fa-times'/></button>
      </div>
      <div className="modal-body">
      <div className="bg-black w-100 text-center pb-2">
          <video id={`vid${foto.matricula}`} src={foto.video_url} key={foto.matricula} controls="controls" autoPlay allowFullScreen={true} frameBorder="0" width="100%" height="500" name="Video Name">

       
        </video>
        <a className=" text-white" href={foto.video_url} target={"_blank"} rel="noreferrer"><i className="fa fa-link"></i> Liga directa al video</a>
        </div>
      </div>
 
    </div>
  </div>
</div>
    </React.Fragment>
  );
};

export default MuroGratitud;
