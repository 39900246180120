import React from "react"
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId'

class BaseField extends React.Component{

    constructor(props) {
        super(props);
        this.id  = uniqueId("component_")
        this.state = {
            value: props.defaultValue ?  props.defaultValue : null,
            error: null
        }
    }

    getValue(){
        return this.state.value
    }

    setValue = (value) => {
        console.log(value)
      this.setState({value})
    }

    getValidations = () => {
        return this.props.validations
    }

    displayError = (display, message) => {
        //console.log(display, message, this.props.requiredMessage)
        this.setState({error: display, errorMessage: (message ?  message :  this.props.requiredmessage)})
    }

    isRequired = () => {
        return this.props.required
    }

    renderErrorMessage = () =>{
        return (this.state.error ? <div
                className={"invalid-feedback"}
                style={{display:"block"}}
                >
                {this.state.errorMessage}
                </div>:null)
    }

}

BaseField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]).isRequired,
    required: PropTypes.bool,
    requiredmessage: PropTypes.string,
    validations: PropTypes.array,
    formfield: PropTypes.string,
}

BaseField.defaultProps = {
    required:false,
    requiredmessage: "Required field",
    formfield:"true"
}

export default BaseField
