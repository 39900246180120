import React, { useEffect, useRef, useState } from "react"

import {  Link, withRouter } from "react-router-dom"
import { Card, CardBody} from "reactstrap"

import List from "../../../components/Table/List"
import { Loader } from "../../../utils/Configs"
import { API } from "../../../utils/backend/api_helper"
import * as bootstrap from "bootstrap"
import Form from "../../../components/Form/Form"
import { Button } from "../../../components"
import TextArea from "../../../components/Fields2/TextArea"
import Checkbox from "../../../components/Fields/Checkbox"
import Select from "../../../components/Fields2/Select"
import Header from "../../Candidatos/Header"
import swal from "sweetalert"




const VideosEvaluadosView = (props) => {

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")

  const [listInfo, setlistInfo] = useState({items:[]})
  const [listInfo2, setlistInfo2] = useState({items:[]})
  const [video, setVideo] = useState({})
  const [evaluadores, setEvaluadores] = useState({})

  const form = useRef();


const formatter = (value, row) => {

  return (
    <button onClick={()=>{
      let url = row.url_video
      console.log(url)
      setVideo({...row, url})
      form.current.reset()
    

      setTimeout(()=>{
        var myModalEl  = document.getElementById("offcanvasRight")
        form.current.fill(row)
        myModalEl = bootstrap.Offcanvas.getOrCreateInstance(myModalEl)
        myModalEl.toggle()
      },1)
  
  

    }} className={"btn btn-primary"}>
      Ver o evaluar video
    </button>
  )

}


const HEADERS = [ 

  {key:"VIDEO", label:"Video", formatter:formatter},

  {key:"matricula", label:"Matrícula"},
  {key:"full_name", label:"Nombre"},
  {key:"email", label:"Correo"},
  {key:"phone", label:"Teléfono"},


]

const HEADERS2 = [ 

{key:"VIDEO", label:"Video", formatter:formatter},

{key:"matricula", label:"Matrícula"},
{key:"full_name", label:"Nombre"},
{key:"email", label:"Correo"},
{key:"phone", label:"Teléfono"},

{key:"evaluacion_video", label:"Evaluación del video"},
{key:"star_video", label:"Video destacado", formatter:(value)=>{ return value? "Sí":"No" }   },
{key:"comentarios_video", label:"Comentarios del video"},
{key:"evaluador", label:"Evaluador", formatter:(value)=>{ return (evaluadores[value] || {}).email }   },

]


  const getCandidatos = (page, size) => {
    setLoading(true)
    API.get(
      `/candidato/videos/eval/${page}/${size}/all`, 
      (response)=>{
        console.log(response)
        setlistInfo2(response.data)
        setLoading(false)

      },
      (response)=>{
        setLoading(false)
      }
    )
  }

  const getCandidatos2 = (page, size) => {
    setLoading(true)
    API.get(
      `/candidato/videos/eval/${page}/${size}/all`, 
      (response)=>{
        console.log(response)
        setlistInfo2(response.data)
        setLoading(false)

      

      },
      (response)=>{
        setLoading(false)
      }
    )
  }

  const getEvaluadores = (page, size) => {
    setLoading(true)
    API.get(
      `/candidato/videos/evaluadores`, 
      (response)=>{
        console.log(response)
        let dict = {}
        response.data.map((e)=>{
          dict[e.id] = e
        })
        setEvaluadores(dict)


      

      },
      (response)=>{
    
      }
    )
  }

  useEffect(()=>{
    getEvaluadores()
    getCandidatos(1,25)
    //getCandidatos2(1,25)

    var myModalEl  = document.getElementById("offcanvasRight")
    let listener = myModalEl.addEventListener('hidden.bs.offcanvas', function () {
      setVideo({})
    })

   

  },[])

  const onChangePage = (page, nextPage) => {
      //console.log(page,nextPage)
      getCandidatos(nextPage, 25)
      //getCandidatos2(nextPage, 25)

  }

  const submit = (data, valid) => {
      console.log(data)

      if (valid){
        setLoading(true)
        API.post(
          `/candidato/eval-video`, 
          {...data, id:video.id},
          (response)=>{
            setLoading(false)
            if (response && response.status == -1){
              swal({
                title: "¡Oops!",
                text: response.message,
                icon: "error"
              })
  
            }else{

              getCandidatos(1,25)
              //getCandidatos2(1,25)
  
              var myModalEl  = document.getElementById("offcanvasRight")
  
              myModalEl = bootstrap.Offcanvas.getOrCreateInstance(myModalEl)
              myModalEl.toggle()

              swal({
                title: "¡Gracias!",
                text: "La evaluación se registro correctamente.",
                icon: "success"
              })
            
            }
   

    
          },
          (response)=>{
            setLoading(false)
            swal({
              title: "Error",
              text: response.message,
              icon: "error"
            })

          }
        )
      }
      
}



  return (
    <div >
         <Header/>
         <div className="container mt-4">
      {loading?<Loader message={message}/> :""}

      <div className="offcanvas offcanvas-end"   tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div className="offcanvas-header pb-1">
          <h2 id="offcanvasRightLabel">  
          Evaluación de video
   </h2>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      

        </div>
      
        <div className="offcanvas-body p-0">
        <div className="bg-black w-100 text-center pb-2">
          <video  key={video.id} controls="controls" allowFullScreen="true" frameBorder="0" width="100%" height="300" name="Video Name">

        <source src={video.url}></source>
        </video>
        <a className=" text-white" href={video.url} target={"_blank"} rel="noreferrer"><i className="fa fa-link"></i> Liga directa al video</a>
        </div>
        <div className="p-3">
           Candidato
          <h1>{video.full_name}</h1>
          <a href={`mailto:${video.email}?Subject=Actividades%20Adicionales%20LDM%20-%20Video&Body=Apreciable%20${video.full_name}%2C%20te%20confirmamos%20que%20recibimos%20tu%20video%2C%20agradecemos%20tu%20participaci%C3%B3n%20en%20esta%20actividad%20y%2C%20quisi%C3%A9ramos%20invitarte%20a%20revisar%20nuevamente%20las%20instrucciones%20y%20enriquecer%20el%20contenido%20de%20tu%20grabaci%C3%B3n%20con%20el%20objetivo%20de%20resaltar%20y%20reforzar%20m%C3%A1s%20los%20aspectos%20que%20te%20distinguen%20como%20candidato%20al%20Programa%20L%C3%ADderes%20del%20Ma%C3%B1ana.%20En%20esta%20misma%20linea%2C%20te%20invitamos%20a%20revisar%20este%20video%20para%20que%20lo%20tomes%20como%20ejemplo%3A%20https%3A%2F%2Fdrive.google.com%2Ffile%2Fd%2F1BC0d3278zW7s0t7rEydlXtgQ_aj1XGH2%2Fview%3Fusp%3Dsharing.%0A%0APuedes%20actualizar%20tu%20video%20en%20la%20misma%20plataforma%3A%20https%3A%2F%2Fwww.micasaldm.mx%2Fcandidatos%2Fvideo%0A%0ATe%20mandamos%20un%20saludo%20con%20afecto%20y%20agradecemos%20tu%20participaci%C3%B3n.%0A%0APrograma%20L%C3%ADderes%20del%20Ma%C3%B1ana`}>Enviar un correo a {video.email}</a>
          <hr/>
          {video.evaluador ?  <div>Evaluador: <b>{evaluadores[video.evaluador].email}</b>    <hr/></div> : null}

          <Form ref={form} onSubmit={submit}>
           
            <div className="row mb-2">
              <div className="col-8">
                <Select required label={"Evaluación del 1 al 10"} name="evaluacion_video" options={[10,9,8,7,6,5,4,3,2,1].map((v)=>{return {value:v, label:v}})}/>
              </div>
              <div className="col-4">
                <label></label>
                <Checkbox name="star_video" label={"Marcar como video destacado"} value={true}/>
              </div>
            </div>

            <TextArea maxLength={255} name={"comentarios_video"} label={"Comentarios (máximo 255 carácteres)"} />
            <div className="text-center mt-2">
              <Button  type={"submit"} color={"primary"}>Registrar evaluación</Button>
            </div>
          </Form>

          </div>
        </div>
      </div>



      <div className="row mb-2">
        <div className="col">
          <Link className="btn btn-outline-primary w-100 fs-3" to={"/candidatos/videos"}>Videos por evaluar</Link>
        </div>
        <div className="col">
          <Link className="btn btn-primary w-100 fs-3" to={"/candidatos/videos/evaluados"}>Videos evaluados</Link>
        </div>
      </div>

      <div className={"mt-2"}/>
        <Card className={"box-shadow mt-0 pt-0"}>
                
                <CardBody className={"mt-2 pt-0"}>

      <List
        onChangePage={onChangePage}
        hasPagination={true}
        pagination={listInfo2}
        data={listInfo2 ? listInfo2.items : []}
        headers={HEADERS2}
      />
      </CardBody></Card>

    </div>
    </div>
  )
}


VideosEvaluadosView.propTypes = {
 
}


export default (withRouter(VideosEvaluadosView))



