
import React from "react"
import { FormGroup, InputGroup, Label } from "reactstrap"
import { Button } from "../../components"
import DivRender from "../Fields/Div";

import BaseField from "./BaseField"

class Upload extends BaseField {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
            fileValue: null, 
            inputDisplay: true
        }
       
      }

      getValue = () =>{
          return this.state.value || this.state.fileValue  ? {value: this.state.value , fileValue: this.state.fileValue  } : null
      }

      setFileValue = (value) => {
            this.displayError(false, "")
          if (value && value.length > 0 &&  value[0] ){
            //console.log(value[0])

            if (this.props.maxSize  && value[0].size > (this.props.maxSize+1) * 1000000 ){
                this.displayError(true, "Tamaño máximo superado. Tamaño máximo admitido es "+this.props.maxSize + "MB")
            }else{
                this.setState({fileValue:value[0]})
            }            

          }
      }

    setValue = (value) => {
       


        this.setState({value:value})
        this.setState({inputDisplay: value ? false:true})
    
    }


    render(){
        const { containerClassName, label, progress } = this.props;

        
        return (<>
            {progress >= 0 ?<div>            {progress >=100 ? null: <label>No salgas, ni cierres la ventana. Estamos subiendo el archivo, esto puede tardar dependiento de tu internet y el tamaño del archivo...</label>}

                <div className="progress" style={{height:20}}>
       <div className={`progress-bar  ${progress >=100 ? "":"progress-bar-striped progress-bar-animated"} ${progress >=100 ?"bg-success":""}`} role="progressbar" style={{width: `${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
       {progress >=100 ?<b className="fs-5"><i className="fas fa-check"/></b>:<b className="fs-5">{parseInt(progress)}%</b>}
         </div>
     </div></div>:

            <div className={`${containerClassName}`}>{this.state.inputDisplay ?  
            <div>
        
              <Label className="d-flex">
                    <div className="">
                  <DivRender  html={label}/>
                  </div>
                  <div>
                  {this.props.required ? <div className={"text-red ms-1"} style={{display:"block", fontSize:14}}>{" "}*</div>:null}
                  </div>
              </Label>
                <div className={`${this.state.error?"has-validation":""}`}>
                    <div className="w-100">
                        <input
                        type="file"
                        className="form-control"
                    
                        accept={this.props.accept ? this.props.accept  : undefined}
                        onChange={(event)=>this.setFileValue(event.target.files)}
                        />
                        <label className="custom-file-label" htmlFor="customFile">
                            {this.state.fileValue ? this.state.fileValue.name : "Selecciona un archivo"}
                        </label>
                    </div>

                    
                    {this.state.value ?  <div style={{marginLeft:10}}><Button color={"danger"} onClick={()=>this.setState({inputDisplay:false})} >Cancelar</Button></div>:null}

                </div>
               
                {this.renderErrorMessage()}
             

        </div>:
                   <FormGroup>
        <div style={{flexDirection:"row", display:"flex", marginBottom:20}}>
            <div style={{flex:1}}><a target={"_blank"} rel="noreferrer" href={this.state.value}><i className={"fas fa-file"}/> Documento subido</a></div>
            <div><Button onClick={()=>this.setState({inputDisplay:true})} ><i className={"fas fa-update"}/> Cambiar documento</Button></div>
        </div></FormGroup>}
        </div>}
        </>
        )
    }
}


export default Upload