
import React, { useEffect, useRef, useState } from "react"


import {  Link, useHistory, withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import Form from "../../components/Form/Form"
import MaskInput from "../../components/Fields2/MaskInput"
import Button from "../../components/Buttons/Button"

import { API } from "../../utils/backend/api_helper"
import swal from "sweetalert"
import { Loader } from "../../utils/Configs"
import Header from "./Header"



const VerificationCode = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);
    const [time, setTime] = useState(120)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const history = useHistory()
    let interval = null
    useEffect(()=>{

      var totalSeconds = 0;
      interval = setInterval(()=>{
        totalSeconds = totalSeconds + 1;
    
        setTime(120-totalSeconds)

       
      }, 1000);
      return ()=>{
        if (time <= 0 && interval) {
          clearInterval(interval)
      }
      }

    },[])

    useEffect(()=>{
      if (time <= 0 && interval) {
          clearInterval(interval)
      }
    })

  
    const submit = (values, valid) =>{
      console.log(values, valid)

      if (valid){
        setLoading(true)
        API.post(
          "/candidato/verify-phone",
          values,
          (response)=>{
       
            setLoading(false)
            swal({
              title:"¡Gracias!",
              text:"Esta sección ha sido completada.",
              icon:"success"
          }).then(
            ()=>{
              //window.location.href = "/candidatos/home"
              history.push( "/candidatos/home")

            }

          )

          },
          (response)=>{
            swal({
              title:"¡Error!",
              message:response,
              icon:"error"
            })
            setLoading(false)

          })
  
      }



    }


  return (
    <React.Fragment >

      {loading ? <Loader message={"Enviando..."} />:null}
      
      <div className="vh-100">
        <Header/>

        <div className="container my-4  h-100 ">
            <div className="card shadow-sm">
            <div className="card-body p-0">

              <div className="bg-primary rounded-top p-4 text-white">
              <h3 className="fw-bold">WhatsApp</h3>
         
                <p className=" fs-5 fs-md-4">
                Hemos enviado un código de verificación al whatsapp {urlParams.get("phone")}
                </p>

              </div>
              <div className={"p-4"}>
                  <Form  onSubmit={submit}>
                    <div className="row">
                  
                      <div className={"col-12 "}>
                      <MaskInput required name={"security_code"}  mask={"999999"} label={"Código de verificación"}/>

                      </div>

                    </div>
                    <div className="mt-2 d-flex justify-content-end">
                      <Link to={time <= 0 ? "/candidatos/whatsapp" :"#"}  className={`btn btn-outline-primary me-2 ${time > 0 ? "disabled":""}`}>{time > 0 ? time + " segundos para solicitar un nuevo código." :"Solicitar código nuevo." } </Link>
                      <Button  type={"submit"} color={"primary fw-bold"}>Continuar <i className="ms-1 border-start ps-1 fas fa-arrow-right"/></Button>

                    </div>
                  </Form>

                  </div>
                </div>
            </div>
        </div>
        </div>
    </React.Fragment>
  )
}


VerificationCode.propTypes = {
 
}


export default (withRouter(VerificationCode))



