import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"

import { Dropdown, DropdownMenu, DropdownToggle, Spinner, DropdownItem } from 'reactstrap';
import { API } from "../utils/backend/api_helper";
import Input from "../components/Fields2/Input"
import Form from "../components/Form2/Form"
import TextArea from "../components/Fields2/TextArea"
import Select from "../components/Fields2/Select"
// import ODSGrid from "./ODSGrid";

const TYPES = [
  {
      "label":"Cultura y recreación",
      "value":1
  },
  {
      "label":"Enseñanza e investigación",
      "value":2
  },
  {
      "label":"Salud",
      "value":3
  },
  {
      "label":"Servicios Sociales",
      "value":4
  },
  {
      "label":"Desarrollo y vivienda",
      "value":5
  },
  {
      "label":"Derechos, promoción y política",
      "value":6
  },
  {
      "label":"Religión",
      "value":7
  },
  {
      "label":"Asociaciones empresariales y profesionales, sindicatos",
      "value":8
  },
  {
      "label":"Otros",
      "value":9
  }
]

const Stepper = (props, ref) => {

    const [result, setResult] = useState([])
    const form = useRef();
    const form2 = useRef();
    const [currIndex, setCurrIndex] = useState(0)
    const [loading, setLoading] = useState(false)
    const [project, setProject] = useState({})
    const [searchValue, setSearchValue] = useState("")
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(()=>{

        disableControls(currIndex);

    },[])

    useEffect(()=>{
        let indicators = document.getElementsByClassName("screen-indicator");
        props.onChange && props.onChange(currIndex, indicators.length )

    },[currIndex])


    useEffect(() => {
      const timeout = setTimeout(() => {
        if (searchValue != null){
          setDropdownOpen(true)
          setLoading(true)
          if (searchValue){
            //console.log("Seraching...", value)
  
            API.post("/social-projects/search/1/5",{query:searchValue},(data)=>{
              console.log(data)
              setLoading(false)
              setResult(data.data.data.items)
            },()=>{setLoading(false)})
         
  
  
  
          }else{
            setLoading(false)
            setResult([])
          }
        }
      
      }, 700)
  
      // if this effect run again, because `value` changed, we remove the previous timeout
      return () => clearTimeout(timeout)
    }, [searchValue])

const previous = () => {
 let indicators = document.getElementsByClassName("screen-indicator");
 let stepForms = document.getElementsByClassName("step-form");

 for (let f of stepForms) {
    f.classList.remove("active");
 }

 let progressElm = document.getElementsByClassName("progress")[0];
  // Delay should not be applied when removing class
  indicators[currIndex].style.transitionDelay = "0s";
  indicators[currIndex].classList.remove("current");
  let nIndex = currIndex - 1
  if (nIndex >= 0 && nIndex < stepForms.length){
    stepForms[nIndex].classList.add("active");
  }
  
  if (nIndex  >= 0 && nIndex  < indicators.length){
    indicators[nIndex ].classList.remove("completed");
  }

  setCurrIndex( currIndex - 1)
  progressElm.style.width = `${(nIndex / (indicators.length - 1)) * 100}%`;
  disableControls(nIndex);
}

const next = () => {
  let stepForms = document.getElementsByClassName("step-form");


  if(currIndex == 1){
    let val = form.current.submit()

    if (!val.validData) {
      return;
    }else{
      onSteppSubmit(val.data)
    }
  }else if (currIndex == 3 ){
    let val = form2.current.submit()

    if (!val.validData) {
      return;
    }else{
      onSteppSubmit(val.data)
    }
  }

  if (currIndex == stepForms.length - 1){
    return;
  }

  let nIndex = currIndex + 1
  console.log(currIndex)
  setCurrIndex(currIndex + 1)
  // console.log(stepForms)
  for (let f of stepForms) {
     f.classList.remove("active");
  }
  let indicators = document.getElementsByClassName("screen-indicator");
  let progressElm = document.getElementsByClassName("progress")[0];


  if (nIndex >= 0 && nIndex < stepForms.length){
    stepForms[nIndex].classList.add("active");
  }
  // Delay should be applied when adding class
  indicators[nIndex].style.transitionDelay = "0.6s";



    if (nIndex - 1 >= 0 && nIndex - 1 < indicators.length){
      indicators[nIndex - 1].classList.add("completed");
    }
  indicators[nIndex].classList.add("current");
  progressElm.style.width = `${(nIndex / (indicators.length - 1)) * 100}%`;
  disableControls(nIndex);
}

const displayNextButton = () =>{
  let indicators = document.getElementsByClassName("screen-indicator");

  if (currIndex >= indicators.length - 1 || currIndex == 0) {
    return false
  } 
  return true
}
const displayPrevButton = () =>{
  if (currIndex <= 0) {
    return false;
  }

  return true
}

const onSteppSubmit = (data) => {

  props.onSteppSubmit && props.onSteppSubmit(currIndex, data);

}

useImperativeHandle(ref, () => ({
    previous,
    next,
    displayNextButton,
    displayPrevButton
}));


const disableControls = (currIndex) => {
  //   let indicators = document.getElementsByClassName("screen-indicator");
  // let prevBtn = document.getElementById("prev-btn");
  // let nextBtn = document.getElementById("next-btn");
  // if (currIndex <= 0) {
  //   prevBtn.disabled = true;
  // } else if (currIndex >= indicators.length - 1) {
  //   nextBtn.disabled = true;
  // } else {
  //   prevBtn.disabled = false;
  //   nextBtn.disabled = false;
  // }
}

  

  
  return (

    <React.Fragment>

      
        <div className="bg-light px-3">
         
            <div className="stepper-wrapper py-2 m-0 ">
                <div className="progress"></div>
                <div className="step"><div className="screen-indicator current">1</div><div className="stepper-title"></div></div>
                <div  className="step"><div className="screen-indicator">2</div><div className="stepper-title"></div></div>
                <div  className="step"><div className="screen-indicator">3</div><div className="stepper-title"></div></div>
                <div  className="step"><div className="screen-indicator">4</div><div className="stepper-title"></div></div>


            </div>

            </div>
            <div className="border-top">

              <div className="step-form p-4 active">
                  <div>
                    <label>{"Busca tu iniciativa o proyecto social, si en tu iniciativas colaboras con otros Líderes del Mañana posiblemente alguno de ellos ya lo registro. (Si no lo encuentras selecciona  'Registrar nueva iniciativa o proyecto')"}</label>
                    <hr/>
                    <div className="app-search flex-fill" style={{flex:1}}>

                    <Dropdown isOpen={dropdownOpen} toggle={()=>setDropdownOpen(!dropdownOpen)}>
                    <DropdownToggle
                    tag="div"
                    >
                          <div className="position-relative">
                                  <input
                            
                                    onChange={(e)=>{
                                      setSearchValue(e.target.value)
                                    }}
                                    type="text"
                                    className="form-control"
                                    placeholder={"Buscar a una iniciativa proyecto..."}
                                  />
                                  <span className="fas fa-search"/>
                                </div>
                                </DropdownToggle>
                        <DropdownMenu className={"mt-1 box-shadow w-100 p-1"}>
                          {loading ?  <div className={"p-2"} style={{textAlign:"center", width:"100%"}}><Spinner/> </div>
                          :
                          <div className={"p-1"}>
                          {result && result.length > 0 ? result.map((c, index)=>{
                            return (
                              <DropdownItem  onClick={()=>{
                                setProject(c)
                                onSteppSubmit({"project":c})
                                setLoading(true)
                                setTimeout(() => {
                                  form.current.fill(c)
                                  next()
                                  setLoading(false)
                                },100)
                
                              }}  key={index}><button className="btn">
                              <div
                                  
                                  key={index}
                        
                                  style={{
                                  
                                  display: "flex",
                                  flexDirection:"row",
                                  alignItems: "center",
                                  
                              }}>
                                  <div className="d-flex align-items-center">
                                    <img height={30} width={30} className={`rounded-circle border me-2 ${c.image ? "" : "d-none"}`} style={{objectFit:"cover"}} src={c.image}/>
                                    <div>{c.name}{c.foundation ? " • " + c.foundation : ""}</div>
                                      
                                  </div>

                                

                              </div>
                              </button></DropdownItem>)
                      
                            
                          }):<DropdownItem disabled><div className={"p-2"} style={{textAlign:""}}>Escribe el nombre de la iniciativa o proyecto social a buscar</div></DropdownItem>}
                          </div>
                          
                          }

                      <DropdownItem   onClick={()=>{
                          setProject({id:undefined})
                          onSteppSubmit({"project":{id:undefined}})
                          setLoading(true)
                          setTimeout(() => {
                            form.current.fill({})
                            next()
                            setLoading(false)
                          },100)

                        }}>
                        <button  className="btn"><i className="fas fa-plus"/> Registrar nueva iniciativa o proyecto</button>
                      </DropdownItem>

                        </DropdownMenu>
                    </Dropdown>
                      
                    </div>

                  </div>

              </div>
              <div className="step-form p-4">
              <label>{"Actualiza o completa la información básica de tu proyecto"}</label>
          
   <hr/>
          <div>
            <Form ref={form}>
              <Input containerClass={"mb-1"} required  label={"Nombre de la iniciativa"} name={"name"}/>
              <Input containerClass={"mb-1"} required  label={"Año de fundación o inicio"} type={"number"} name={"foundation"}/>
              <TextArea className={"mb-1"} required  label={"Descripción"} name={"description"}/>
              <Select className={"mb-1"} required label={"Tipo/categoria"} name={"type_id"} options={TYPES} />

            </Form>
  
          </div>
              </div>
              <div className="step-form p-4">
              <label>{"Selecciona el Obejtivos de desarrollo sostenible principal al que impacte tu iniciativa"}</label>
              <hr/>
              {/* <ODSGrid onClick={(ods)=>{
                next();
                onSteppSubmit({"ods":ods})
                }}/> */}
              </div>

              <div className="step-form p-4">
              <label>{"¿Cuál es tu rol principal en la inicitaiva?"}</label>
              <hr/>
              <Form ref={form2}>
              <Select required className={"mb-1"} label={"Mi rol"} name={"rol"} 
                options={[
                  {value:"Fundador(a) / Co-Fundador(a)",label:"Fundador(a) / Co-Fundador(a)"},
                  {value:"CEO / Líder / Presidente",label:"CEO / Líder / Presidente"},
                  {value:"Vicepresicencia de aréa / Dirección departamental / Gerente",label:"Vicepresicencia de aréa / Dirección departamental / Gerente"}, 
                  {value:"Secretario / Subsecretario",label:"Secretario / Subsecretario"}, 
                  {value:"Coordinador / Colaborador",label:"Coordinador / Colaborador"}, 
                  {value:"Mentor(a) / Consultor",label:"Mentor(a) / Consultor"}, 
                  {value:"Otro", label:"Otro"}
                 ]} />
</Form>
              </div>

          
            {/* <button className="control-btn" id="prev-btn" onClick={previous}>Back</button>
              <button className="control-btn" id="next-btn" onClick={next}>Continue</button>  */}
        </div>
      
    
    </React.Fragment>

  )
}





export default forwardRef(Stepper)



