
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import { Container} from "reactstrap"

import Solicitud from "./Solicitud"


const Expediente = (props) => {

  const [loading, setLoading] = useState(false)

  //const convocatorias = useSelector(state => state.convocatoria.data);
  //const solicitudes = useSelector(state => state.solicitud.data);
  //const modal = useRef();
  const dispatch = useDispatch();

  useEffect(()=>{
    //dispatch(getConvocatorias())
    //dispatch(getSolicituds())
  },[dispatch])

  /*const createSolicitud = (values, validData) => {
    console.log(values, validData)
    
    if (validData){
      setLoading(true)
      dispatch(postSolicitud(values, 
        ()=>{
          setLoading(false)
          dispatch(getSolicituds())
          modal.current.toggle()
          props.history.push("/solicitud")
        }))
    }
  }*/
  
  return (
    <React.Fragment>
      {loading?"":""}
      <div className="page-content mt-6">
        <Container fluid>
            <Solicitud />
        </Container>
      </div>
    </React.Fragment>
  )
}


Expediente.propTypes = {
 
}


export default (withRouter(Expediente))



