
import {
  GET_ALL_CONTACT_SUCCESS,
  GET_CONTACT_SUCCESS,
  PUT_CONTACT_LOADING,
  SEARCH_SUCCESS,
  UPDATE_LIST_VIEW
} from "./actionTypes"

const INIT_STATE = {
  all: null,
  current: null, 
  loading:false,
  dictionary:{},
  matDicts:{},
  listData:null,
}

const contact = (state = INIT_STATE, action) => {

  var newDict = state.dictionary
  var mts = state.matDicts

  switch (action.type) {

    case UPDATE_LIST_VIEW:
      //console.log(action.payload)
      return {
        ...state,
        listData:action.payload.data,
      }
    
    case SEARCH_SUCCESS:

      action.payload.data.map((c)=>{

        newDict[c.id] = c
        if (!newDict[c.id]){
          newDict[c.id] = {}
        }
        mts[c.matricula] = c.id

        newDict[c.id] = {...newDict[c.id], ...c}
      })

      return {
        ...state,
        dictionary:  JSON.parse(JSON.stringify(newDict)),
        matDicts: JSON.parse(JSON.stringify(mts))
      }
    case GET_ALL_CONTACT_SUCCESS:
     
      action.payload.data.map((c)=>{
        newDict[c.id] = c
        mts[c.matricula] = c.id
      })

      return {
        ...state,
        all: action.payload,
        dictionary:  JSON.parse(JSON.stringify(newDict)),
        matDicts: JSON.parse(JSON.stringify(mts))
      }

    case GET_CONTACT_SUCCESS:
  
      ////console.log(newDict[action.payload.id])
      ////console.log(action.payload)

      if (newDict[action.payload.id]){
        newDict[action.payload.id] = {...newDict[action.payload.id], ...action.payload}
      }else{
        newDict[action.payload.id] = action.payload

      }


      mts[action.payload.matricula] = action.payload.id


      
      return {
        ...state,
        current: action.payload,
        dictionary: JSON.parse(JSON.stringify(newDict)),
        matDicts: JSON.parse(JSON.stringify(mts)),
      }

    case PUT_CONTACT_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
  

    default:
      return state
  }
}

export default contact 

