
import React, { useEffect, useRef, useState } from "react"


import {  Link, useHistory, withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import Form from "../../components/Form/Form"
import Button from "../../components/Buttons/Button"

import { API } from "../../utils/backend/api_helper"
import swal from "sweetalert"
import { Loader } from "../../utils/Configs"
import Upload from "../../components/Fields2/Upload"
import Select from "../../components/Fields2/Select"
import Header from "./Header"

const INLGES_L = [
  {value:"A1", label:"A1 (Beginner)"},
  {value:"A2", label:"A2 (Elementary)"},
  {value:"B1", label:"B1 (Intermediate)"},
  {value:"B2", label:"B2 (Upper-Intermediate)"},
  {value:"C1", label:"C1 (Advanced)"},
  {value:"C2", label:"C2 (Proficiency)"},

]

const   Ingles = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);

    const [candidato, setCandidato] = useState({})
    const history = useHistory()

    const [completed, setCompleted] = useState(-1)
    const [progress1, setProgress1] = useState(-1)
    const [progress2, setProgress2] = useState(-1)
    const [progress3, setProgress3] = useState(-1)
    const [progress4, setProgress4] = useState(-1)
    let progressBars = {Ingles1:setProgress1, Ingles2:setProgress2,Ingles3:setProgress3,Ingles4:setProgress4}
    let files = 0;

    const completeUpload = (fileData, totalFiles) => {
      API.post(
        "/candidato/complete-upload",
        {url:fileData.upload_url.split("?")[0],name:fileData.name,bucket:fileData.bucket },
        ()=>{
          files = files +1
          if (files == totalFiles){
            setCompleted(1)
            swal({
              title:"¡Gracias!",
              text:"Esta sección ha sido completada.",
              icon:"success"
            }).then(
              ()=>{
                //window.location.href = "/candidatos/home"
                history.push( "/candidatos/home")
              }
            )
          }
        
        },
        (response)=>{
          swal({
            title:"¡Error en el documento!",
            message:response,
            icon:"error"
          })
        })
    }
  
    const upload = (url, file, fileData, totalFiles) => {
      API.S3put(
        url,
        file,
        (response)=>{
          console.log(response)
          completeUpload(fileData, totalFiles)
      },(response)=>{
        swal({
          title:"¡Error al subir el documento!",
          message:response,
          icon:"error"
        })
      },(progressEvent)=>{
        let percentComplete = progressEvent.loaded / progressEvent.total
        percentComplete = percentComplete * 100;


        progressBars[fileData.name](percentComplete)
      })
    }
    
      const submit = (values, valid) =>{
        console.log(values, valid)
  
        if (valid){
          setLoading(true)
          setCompleted(0)
          let data = {files:[]}

          for (const [key, value] of Object.entries(values)) {
            if (value && value.fileValue){
              data.files.push(key)
            }else{
              data[key] = value
            }
          }
          API.post(
            "/candidato/upload-file",
            data,
            (response)=>{
              setLoading(false)
              response.data.map((uploadFileData)=>{
                upload(uploadFileData.upload_url, values[uploadFileData.name].fileValue, uploadFileData, response.data.length)
              })
            },
            (response)=>{
              swal({
                title:"¡Error!",
                message:response,
                icon:"error"
              })
              setLoading(false)
  
            })
    
        }
  
  
  
      }

    useEffect(()=>{
      setLoading(true)
      API.get(
          "/candidato",
          (resp)=>{
              setCandidato(resp.data)
              setLoading(false)



          },
          (resp)=>{
              swal({
                  title:"¡Error!",
                  text:resp,
                  icon:"error"
              })
              setLoading(false)

          }
      )
  },[])


  return (
    <React.Fragment >

      {loading ? <Loader message={"Enviando..."} />:null}
      
      <div className="vh-100">
        <Header/>

        <div className="container mt-4  h-100 ">
            <div className="card shadow-sm">
            <div className="card-body p-0">

              <div className="rounded-top ">
              <h3 className="fw-bold bg-primary  rounded-top text-white p-4">OXFORD ENGLISH-LEVEL-TEST</h3>
                <p className=" fs-5 fs-md-4 p-4">
                Con el fin de que evalúes tus conocimientos sobre el dominio del idioma inglés, te invitamos a realizar esta prueba.                 Sigue estas indicaciones:<br/>
                <br/>
                <ol>
                <li>Accede a la dirección electrónica:  <a rel="noreferrer" href="https://www.oxfordonlineenglish.com/english-level-test" target="_blank">https://www.oxfordonlineenglish.com/english-level-test</a>.</li>
                
                <li>Ingresa a cada una de las 4 evaluaciones que aparecen en pantalla:<br/>
                <ul>
                  <li> Listening Level Test</li>
                  <li> Vocabulary Level Test</li>
                  <li> Grammar Level Test</li>
                  <li> Reading Level Test</li>
                </ul></li>
                <li><b>Realiza una captura de pantalla del resultado de cada test (4 imágenes).</b></li>

   </ol>
   <div className="alert alert-warning" role="alert">
                4.	Registra aquí el resultado y sube aquí la foto o captura de pantalla de cada evaluación.
</div>

 <div className="text-end">Tiempo estimado de duración: 90-120 minutos.<br/>
                Contacto para dudas: Jorge Méndez <a href="mailto:jorge@tec.mx">jorge@tec.mx</a><br/>
                </div>
                </p>
                <hr/>
              </div>
          

              <div className={"p-4"}>
              {candidato.INGLES== "COMPLETA"  ? <div className="text-center">

<h3 className="mb-5"><i className="fas fs-1 text-success fa-check-circle"/><br/>¡Gracias!<br/>Esta sección ya esta completa.</h3>

<div className="d-flex justify-content-center">
<Link to="/candidatos/home" className={"btn btn-outline-primary"}>Regresar</Link>

<Button onClick={()=>setCandidato({})}  className={"ms-2"} color={"primary"}>Actualizar la información</Button>
</div>
</div>: <Form  onSubmit={submit}>
                    <div className="row">

                        <div className="row mb-2">
                          <div className="col-4">
                        <Select required label={"RESULTADO - Listening Level Test"} name={"listening"} options={INLGES_L}/>
                        </div>
                        <div className="col-8">
                        <Upload maxSize={10} progress={progress1}  required name={"Ingles1"}  label={"Sube aquí captura de pantalla de tu resultado de Listening Level Test"}/>
                        </div></div>
                      <hr/>

                      <div className="row mb-2">
                          <div className="col-4">
                      <Select required label={"RESULTADO - Vocabulary Level Test"} name={"vocabulary"} options={INLGES_L}/>
                      </div>
                        <div className="col-8">
                      <Upload maxSize={10} progress={progress2} required name={"Ingles2"}  label={"Sube aquí captura de pantalla de tu resultado de Vocabulary Level Test"}/>
                      </div></div>
                      <hr/>

                      <div className="row mb-2">
                          <div className="col-4">
                      <Select required label={"RESULTADO - Grammar Level Test"} name={"grammar"} options={INLGES_L}/>
                      </div>
                        <div className="col-8">
                      <Upload maxSize={10} progress={progress3} required name={"Ingles3"}  label={"Sube aquí captura de pantalla de tu resultado de Grammar Level Test"}/>
                      </div></div>
                      <hr/>

                      <div className="row mb-2">
                          <div className="col-4">
                      <Select required label={"RESULTADO - Reading Level Test"} name={"reading"} options={INLGES_L}/>
                      </div>
                        <div className="col-8">
                      <Upload maxSize={10} progress={progress4} required name={"Ingles4"}  label={"Sube aquí captura de pantalla de tu resultado de Reading Level Test"}/>
                      </div></div>
                 

                    </div>
                    {completed == -1 ?<div className="mt-2 d-flex justify-content-end">
                      <Link to="/candidatos/home" className={"btn btn-outline-primary me-2"}>Regresar</Link>

                      <Button  type={"submit"} color={"primary fw-bold"}>Continuar <i className="ms-1 border-start ps-1 fas fa-arrow-right"/></Button>

                    </div>:null}
                  </Form>}

                  </div>
                </div>
            </div>
            <div style={{height:50}}/>
        </div>
        </div>
    </React.Fragment>
  )
}


Ingles.propTypes = {
 
}


export default (withRouter(Ingles))



