import React from "react"
import { getContact } from "../store/actions";

export function chunkArrayInGroups(arr, size) {
    var myArray = [];
    for(var i = 0; i < arr.length; i += size) {
      myArray.push(arr.slice(i, i+size));
    }
    return myArray;
  }

  export function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  export const STAGES = [
    "Sin definir",
    "Incio o creación",
    "Diseño o planificación",
    "Ejecución o desarrollo",
    "Analisis del proyecto",
    "Evaluación o lecciones aprendidas(FIN)"
  ]

  export const INDUSTRIAS = [
    'Administración gubernamental',
'Aeronáutica/Aviación',
'Agricultura',
'Alimentación y bebidas',
'Almacenamiento',
'Animación',
'Apuestas y casinos',
'Arquitectura y planificación',
'Artículos de consumo',
'Artículos de lujo y joyas',
'Artículos deportivos',
'Artesanía',
'Artes escénicas',
'Asuntos internacionales',
'Atención a la salud mental',
'Atención sanitaria y hospitalaria',
'Automación industrial',
'Banca',
'Banca de inversiones',
'Bellas Artes',
'Bibliotecas',
'Bienes inmobiliarios',
'Bienes inmobiliarios comerciales',
'Biotecnología',
'Capital de riesgo y capital privado',
'Construcción',
'Construcción naval',
'Consultoría de estrategia y operaciones',
'Contabilidad',
'Cosmética',
'Cristal, cerámica y hormigón',
'Cumplimiento de la ley',
'Departamento de defensa y del espacio exterior',
'Deportes',
'Derecho',
'Desarrollo de programación',
'Desarrollo y comercio internacional',
'Diseño',
'Diseño gráfico',
'Dotación y selección de personal',
'Educación primaria/secundaria',
'Ejército',
'E-learning',
'Electrónica de consumo',
'Embalaje y contenedores',
'Energía renovable y medio ambiente',
'Enseñanza superior',
'Entretenimiento',
'Envío de paquetes y carga',
'Equipos informáticos',
'Escritura y edición',
'Filantropía',
'Formación profesional y capacitación',
'Fotografía',
'Gabinetes estratégicos',
'Ganadería',
'Gestión de inversiones',
'Gestión de organizaciones sin ánimo de lucro',
'Gestión educativa',
'Hostelería',
'Importación y exportación',
'Imprenta',
'Industria aeroespacial y aviación',
'Industria farmacéutica',
'Industria textil y moda',
'Ingeniería civil',
'Ingeniería industrial o mecánica',
'Instalaciones y servicios recreativos',
'Instituciones religiosas',
'Interconexión en red',
'Internet',
'Investigación',
'Investigación de mercado',
'Judicial',
'Lácteos',
'Logística y cadena de suministro',
'Manufactura eléctrica/electrónica',
'Manufactura ferroviaria',
'Maquinaria',
'Marketing y publicidad',
'Materiales de construcción',
'Material y equipo de negocios',
'Medicina alternativa',
'Medios de comunicación en línea',
'Medios de difusión',
'Mercados de capital',
'Minería y metalurgia',
'Mobiliario',
'Música',
'Museos e instituciones',
'Nanotecnología',
'Naval',
'Ocio, viajes y turismo',
'Oficina ejecutiva',
'Oficina legislativa',
'Organización cívica y social',
'Organización política',
'Películas y cine',
'Periódicos',
'Petróleo y energía',
'Piscicultura',
'Plásticos',
'Política pública',
'Producción alimentaria',
'Producción multimedia',
'Productos de papel y forestales',
'Productos químicos',
'Profesiones médicas',
'Protección civil',
'Publicaciones',
'Recaudación de fondos',
'Recursos humanos',
'Relaciones gubernamentales',
'Relaciones públicas y comunicaciones',
'Resolución de conflictos por terceras partes',
'Restaurantes',
'Sanidad, bienestar y ejercicio',
'Sector automovilístico',
'Sector textil',
'Seguridad del ordenador y de las redes',
'Seguridad e investigaciones',
'Seguros',
'Semiconductores',
'Servicio al consumidor',
'Servicio de información',
'Servicios de eventos',
'Servicios financieros',
'Servicios infraestructurales',
'Servicios jurídicos',
'Servicios médicos',
'Servicios medioambientales',
'Servicios para el individuo y la familia',
'Servicios públicos',
'Servicios y tecnologías de la información',
'Software',
'Subcontrataciones/Offshoring',
'Supermercados',
'Tabaco',
'Tecnología inalámbrica',
'Telecomunicaciones',
'Traducción y localización',
'Transporte por carretera o ferrocarril',
'Venta al por mayor',
'Venta al por menor',
'Veterinaria',
'Videojuegos',
'Vinos y licores',
"Otra"
  ]
  export const DECILES = [
    "Sin dato",
    "$0 a $3,037.00 (decil 1)",
    "$3,038.00 a $5,366.00 (decil 2)",
    "$5,367.00 a $7,142.00 (decil 3)",
    "$7,143.00 a $8,898.00 (decil 4)",
    "$8,899.00 a $10,772.00 (decil 5)",
    "$10,773.00 a $12,985.00 (decil 6)",
    "$12,986.00 a $15,754.00 (decil 7)",
    "$15,755.00 a $19,628.00 (decil 8)",
    "$19,629.00 a $26,197.00 (decil 9)",
    "$26,198.00 a $55,583.00 (decil 10)",
    "Más de $55,583.00",
  ]

  export const DONANTES = [
    'Sin distinción',
    'Inmobiliaria RARR, S.A. de C.V.',
    'A nuestros profesores: H. Márquez, H. Jasso, R. Sandoval y María del Carmen de la Garza CP’64-69',
    'Alberto Santos de Hoyos',
    'Alfonso Vega González',
    'American Beef',
    'American Industries',
    'Antonio Cosío Ariño',
    'APELSA',
    'Apoya y Punto A.C.',
    'Armando Martín Borque',
    'Beatriz Garza H.',
    'Beca AGA',
    'Beca César Sánchez Lozano',
    'Beca Coppel',
    'Beca Don Roberto Plasencia Saldaña',
    'Beca Eduardo Gómez López',
    'Beca ETEHAC',
    'Beca Facultad de Medicina y Ciencias de la Salud, Región Occidente',
    'Beca Familia Arizpe',
    'Beca Flexi',
    'Beca Fundación Fleishman',
    'Beca Fundación Frater',
    'Beca Melchor Alfonso Cayón Villanueva',
    'Becas Cinépolis en honor a Enrique Ramírez Miguel',
    'Becas Familia Martínez Rodríguez',
    'Becas HHH',
    'Becas Once Once',
    'Beck Internacional de México',
    'Bocar Group',
    'Carlota Braniff Garza Lagüera',
    'Carmen Junco',
    'Casa Córdoba',
    'César Humberto Cantú Benavides',
    'Champion Almeida',
    'Combugas Lic. Nesim Issa Tafich',
    'Combugas Lic. Salomón Issa Tafich',
    'Comprometido con un Mejor México',
    'Compromiso y Honradez',
    'Concepción Legorreta de Angoitia',
    'Consejo Campus Chihuahua 2016-2018 Beca 1',
    'Consejo campus Cuernavaca',
    'Consejo Campus Guadalajara',
    'Consuelo Lagüera Zambrano',
    'Daniela Garza Lagüera Gonda',
    'David Noel Ramírez Padilla',
    'De Líder a Líder',
    'Dr. Agustín Carstens Carstens',
    'Eduardo Garza T. Fernández',
    'Eduardo M. Villareal Bell',
    'Eduardo Ritz Iturrios',
    'Efraín López Sánchez ',
    'Elías Murra Marcos y Victoria Marcos de Murra',
    'Eloína Carbajal y Angel Fernández Quiroz',
    'Emma Barrera de Cueva',
    'Empatía e Inclusión',
    'Emprendedores del Futuro de Farmacias Guadalajara',
    'Empresa Garis',
    'Eric Hágsater',
    'Ernesto Coppel Campaña',
    'ESCAC',
    'EXATEC por los Líderes del Mañana',
    'EXATEC Sonorense AC',
    'Familia Beltrán Bernal',
    'Familia Cantú Fernández',
    'Familia Chico Servitje',
    'Familia Estrada Karachi',
    'Familia Garza García',
    'Familia Garza Lagüera Gonda',
    'Familia Hill ',
    'Familia Mares Cano',
    'Familia Rodríguez Durán',
    'Familia Ruíz-Mendoza',
    'Familia Salazar Cervantes',
    'Familia Treviño Garza',
    'Familia Yamuni',
    'Federico Ruíz Rubio',
    'Federico Terrazas Falomir y Margarita Torres de Terrazas',
    'Francisco A. Riestra y Concepción Montemayor de Riestra',
    'Fundación Arzentia',
    'Fundación Beckmann',
    'Fundación Canales Adame',
    'Fundación Daniel y Gracia C. de Cárdenas, A.C.',
    'Fundación GCC',
    'Fundación Grupo Lala',
    'Fundación IDEI',
    'Fundación José Manuel Lomelín Guerra A.C.',
    'Fundación Luebbert ',
    'Gela Gallardo',
    'Genaro Cueva Domínguez',
    'Grupo Bimbo',
    'Grupo Chihuahua',
    'Grupo DESC',
    'Grupo Estrella Roja',
    'Grupo GOCA',
    'Grupo Murillo',
    'Gudelio Jesús Cavazos Marroquín',
    'Gustavo Alfredo Cárdenas Villafaña',
    'HEB en la Comunidad',
    'HEINEKEN MÉXICO',
    'HEINEKEN México ',
    'Herminia Cantú Charles',
    'Hortensia Mendoza y Arturo Blanco',
    'Iconn',
    'Industrial Orgánica',
    'Ing. Jaime Woldenberg Marcovich',
    'Iqbeca',
    'Isidro López del Bosque',
    'Isidro López Zertuche',
    'Javier Cabello y Nanis Elizondo',
    'Joan y Mauricio Schwarz',
    'Jorge Adolfo Pons Padilla',
    'Jorge Garza Herrera',
    'José Berrondo Mir',
    'José y Luis Amarante Uribe',
    'Juan Enriquez Cabot',
    'LAE 87',
    'Las delicias de aprender',
    'LCC 79',
    'Lic. Agustín López Munguía',
    'Líderes del Mañana - BBVA',
    'Líderes del Mañana Coca-Cola FEMSA',
    'Líderes del Mañana OXXO',
    'Líderes del Mañana, Grandeza de Guanajuato',
    'Lorenzo Servitje Sendra',
    'Loyola Concha',
    'Luis Dionisio Barrera González',
    'Luisa Ricaud de Cano, Marcela Cano Ricaud y Augusto Gállego Ochoa',
    'Luz Esther García de Almeida',
    'Manuel y Juan Carlos López Villarreal',
    'María Eugenia Elizondo de Martínez',
    'MARU',
    'Miguel Schwarz Marx y Familia',
    'Miguel y Betty Schwarz Marx',
    'Monte Pio de Sonora IAP',
    'Por mi, por ti',
    'RED CHIHUAHUA, A.C.',
    'Red de Filantropía: Monterrey 2020 ',
    'Red de Filantropía: Por los Líderes del Mañana 2020',
    'Ricardo Serhan',
    'Roberto Villarreal y Patricia Margarita Vargas de Villarreal',
    'Rogelio Zambrano Lozano',
    'Rosa Carmen Soberón de Martín',
    'Santa Fe de Hermosillo A.C.',
    'Schou Foundation',
    'Softtek',
    'Spring Air México',
    'Tere Nuñez',
    'Tláloc Seguros, S.A. de C.V.',
    'Ventana del Cielo',
    'Otra'
  ]


  export const SOLICITUD_STATUS = {
    "LLENANDO":"warning",
    "INCOMPLETA":"warning",
    "EN REVISIÓN":"info"
  }

  export const avatar = (user, size=30, fontSize=22) =>{ 
    return (
      <div>
      {user && user.url_photo
        ? <img
                className="rounded-circle header-profile-user"
                src={encodeURI(user.url_photo)}
                style={{
                height: size,
                objectFit:"cover",
                width: size
            }}
                alt="Header Avatar"/>

        : <span className={"d-inline-block"}>
                  
      <span
                className={"avatar-title rounded-circle bg-soft-primary text-primary"}
                style={{
                width: size,
                height: size,
                fontSize:fontSize
            }}>
                {user
                    .full_name
                    .charAt(0)}
            </span>     
        </span>
}</div>
    )
  }

  export const getContactDict = (id, contacts, dispatch) =>{
    
    if (!contacts[id] ){
      dispatch(getContact(id,(data)=>{
        ////console.log(data)
      }))
      return false
    }

    return contacts[id]

  }

  

  export const linkify = (str) =>{

    let newStr = str.replaceAll(/(<a href=")?((https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)))(">(.*)<\/a>)?/gi, function () {
      return '<a target="blank" href="' + arguments[2] + '">' + (arguments[7] || arguments[2]) + '</a>'
    });

    return newStr.replaceAll("\n","<br/>")

  } 

  export const CARRERAS = [
    {
      "id" : "AMC",
      "carrera" : "Ambiente Construido/ Exploración",
      "created_at" : null,
      "updated_at" : null
    },
    {
      "id" : "ARQ",
      "carrera" : "Arquitecto",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "CIS",
      "carrera" : "Ciencias Sociales / Exploración",
      "created_at" : null,
      "updated_at" : null
    },
    {
      "id" : "ESC",
      "carrera" : "Estudios Creativos / Exploración",
      "created_at" : null,
      "updated_at" : null
    },
    {
      "id" : "IA",
      "carrera" : "Ingeniero Agrónomo",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "IAG",
      "carrera" : "Ingeniería en Biosistemas Agroalimentarios",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "IAL",
      "carrera" : "Ingeniería en Alimentos",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "IBN",
      "carrera" : "Ingeniero en Bionegocios",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "IBQ",
      "carrera" : "Ingeniería - Bioingeniería y Procesos Químicos (avenida) / Exploración",
      "created_at" : null,
      "updated_at" : null
    },
    {
      "id" : "IBT",
      "carrera" : "Ingeniero en Biotecnología",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "IC",
      "carrera" : "Ingeniero Civil",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "ICI",
      "carrera" : "Ingeniería - Ciencias Aplicadas (avenida) / Exploración",
      "created_at" : null,
      "updated_at" : null
    },
    {
      "id" : "ICT",
      "carrera" : "Ingeniería - Computación y Tecnologías de Información (avenida) / Exploración",
      "created_at" : null,
      "updated_at" : null
    },
    {
      "id" : "IDA",
      "carrera" : "Ingeniero en Diseño Automotriz",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "IDM",
      "carrera" : "Ingeniería en Ciencia de Datos y Matemáticas",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "IDS",
      "carrera" : "Ingeniero en Desarrollo Sustentable",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "IE",
      "carrera" : "Ingeniería en Electrónica",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "IFI",
      "carrera" : "Ingeniero Físico Industrial",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "IIA",
      "carrera" : "Ingeniero en Industrias Alimentarias",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "IID",
      "carrera" : "Ingeniero en Innovación y Desarrollo",
      "created_at" : "2019-05-15 20:47:48",
      "updated_at" : "2019-05-15 20:47:48"
    },
    {
      "id" : "IIS",
      "carrera" : "Ingeniero Industrial y de Sistemas",
      "created_at" : "2019-05-15 20:47:49",
      "updated_at" : "2019-05-15 20:47:49"
    },
    {
      "id" : "IIT",
      "carrera" : "Ingeniería - Innovación y Transformación (avenida) / Exploración",
      "created_at" : null,
      "updated_at" : null
    },
    {
      "id" : "IMA",
      "carrera" : "Ingeniero Mecánico Administrador",
      "created_at" : "2019-05-15 20:47:49",
      "updated_at" : "2019-05-15 20:47:49"
    },
    {
      "id" : "IMD",
      "carrera" : "Ingeniería Biomédica",
      "created_at" : "2019-05-15 20:47:49",
      "updated_at" : "2019-05-15 20:47:49"
    },
    {
      "id" : "IME",
      "carrera" : "Ingeniero Mecánico Electricista",
      "created_at" : "2019-05-15 20:47:49",
      "updated_at" : "2019-05-15 20:47:49"
    },
    {
      "id" : "IMI",
      "carrera" : "Ingeniero en Producción Musical Digital",
      "created_at" : "2019-05-15 20:47:49",
      "updated_at" : "2019-05-15 20:47:49"
    },
    {
      "id" : "IMT",
      "carrera" : "Ingeniero en Mecatrónica",
      "created_at" : "2019-05-15 20:47:49",
      "updated_at" : "2019-05-15 20:47:49"
    },
    {
      "id" : "INA",
      "carrera" : "Ingeniería en Nanotecnología",
      "created_at" : "2019-05-15 20:47:49",
      "updated_at" : "2019-05-15 20:47:49"
    },
    {
      "id" : "INCQ",
      "carrera" : "Ingeniero en Nanotecnología y Ciencias Químicas",
      "created_at" : "2019-05-15 20:47:49",
      "updated_at" : "2019-05-15 20:47:49"
    },
    {
      "id" : "INT",
      "carrera" : "Ingeniero en Negocios y Tecnologías de Información",
      "created_at" : "2019-05-15 20:47:49",
      "updated_at" : "2019-05-15 20:47:49"
    },
    {
      "id" : "IQ",
      "carrera" : "Ingeniero Químico",
      "created_at" : "2019-05-15 20:47:49",
      "updated_at" : "2019-05-15 20:47:49"
    },
    {
      "id" : "IQA",
      "carrera" : "Ingeniero Químico Administrador",
      "created_at" : "2019-05-15 20:47:49",
      "updated_at" : "2019-05-15 20:47:49"
    },
    {
      "id" : "IQP",
      "carrera" : "Ingeniero Químico en Procesos Sustentables",
      "created_at" : "2019-05-15 20:47:50",
      "updated_at" : "2019-05-15 20:47:50"
    },
    {
      "id" : "IRS",
      "carrera" : "Ingeniería en Robótica y Sistemas Digitales",
      "created_at" : "2019-05-15 20:47:50",
      "updated_at" : "2019-05-15 20:47:50"
    },
    {
      "id" : "ISC",
      "carrera" : "Ingeniero en Sistemas Computacionales",
      "created_at" : "2019-05-15 20:47:50",
      "updated_at" : "2019-05-15 20:47:50"
    },
    {
      "id" : "ISD",
      "carrera" : "Ingeniero en Sistemas Digitales y Robótica",
      "created_at" : "2019-05-15 20:47:50",
      "updated_at" : "2019-05-15 20:47:50"
    },
    {
      "id" : "ITC",
      "carrera" : "Ingeniero en Tecnologías Computacionales",
      "created_at" : "2019-05-15 20:47:50",
      "updated_at" : "2019-05-15 20:47:50"
    },
    {
      "id" : "ITD",
      "carrera" : "Ingeniería en Transformación Digital de Negocios",
      "created_at" : "2019-05-15 20:47:50",
      "updated_at" : "2019-05-15 20:47:50"
    },
    {
      "id" : "ITE",
      "carrera" : "Ingeniero en Tecnologías Eléctricas",
      "created_at" : "2019-05-15 20:47:50",
      "updated_at" : "2019-05-15 20:47:50"
    },
    {
      "id" : "ITI",
      "carrera" : "Ingeniero en Tecnologías de Información",
      "created_at" : "2019-05-15 20:47:50",
      "updated_at" : "2019-05-15 20:47:50"
    },
    {
      "id" : "ITS",
      "carrera" : "Ingeniero en Telecomunicaciones y Sistemas Electrónicos",
      "created_at" : "2019-05-15 20:47:50",
      "updated_at" : "2019-05-15 20:47:50"
    },
    {
      "id" : "LAD",
      "carrera" : "Licenciado en Animaciòn y Arte Digital",
      "created_at" : "2019-05-15 20:47:50",
      "updated_at" : "2019-05-15 20:47:50"
    },
    {
      "id" : "LAE",
      "carrera" : "Licenciado en administración y Estrategia de Negocios",
      "created_at" : "2019-05-15 20:47:50",
      "updated_at" : "2019-05-15 20:47:50"
    },
    {
      "id" : "LAF",
      "carrera" : "Licenciado en Administración Financiera",
      "created_at" : "2019-05-15 20:47:50",
      "updated_at" : "2019-05-15 20:47:50"
    },
    {
      "id" : "LBC",
      "carrera" : "Licenciado en Biociencias",
      "created_at" : "2019-05-15 20:47:51",
      "updated_at" : "2019-05-15 20:47:51"
    },
    {
      "id" : "LC",
      "carrera" : "Licenciatura en Comunicación",
      "created_at" : "2019-05-15 20:47:51",
      "updated_at" : "2019-05-15 20:47:51"
    },
    {
      "id" : "LCDE",
      "carrera" : "Licenciado en Creación y Desarrollo de Empresas",
      "created_at" : "2019-05-15 20:47:51",
      "updated_at" : "2019-05-15 20:47:51"
    },
    {
      "id" : "LCMD",
      "carrera" : "Licenciado en Comunicaciòn y Medio Digitales",
      "created_at" : "2019-05-15 20:47:51",
      "updated_at" : "2019-05-15 20:47:51"
    },
    {
      "id" : "LCPF",
      "carrera" : "Licenciado en Contaduría Pública y Finanzas",
      "created_at" : "2019-05-15 20:47:51",
      "updated_at" : "2019-05-15 20:47:51"
    },
    {
      "id" : "LDF",
      "carrera" : "Licenciado en Derecho y Finanzas",
      "created_at" : "2019-05-15 20:47:51",
      "updated_at" : "2019-05-15 20:47:51"
    },
    {
      "id" : "LDI",
      "carrera" : "Licenciado en Diseño",
      "created_at" : "2019-05-15 20:47:51",
      "updated_at" : "2019-05-15 20:47:51"
    },
    {
      "id" : "LDP",
      "carrera" : "Licenciado en Derecho y Ciencia Política",
      "created_at" : "2019-05-15 20:47:51",
      "updated_at" : "2019-05-15 20:47:51"
    },
    {
      "id" : "LEC",
      "carrera" : "Licenciado en Economía",
      "created_at" : "2019-05-15 20:47:51",
      "updated_at" : "2019-05-15 20:47:51"
    },
    {
      "id" : "LED",
      "carrera" : "Licenciado en Derecho",
      "created_at" : "2019-05-15 20:47:51",
      "updated_at" : "2019-05-15 20:47:51"
    },
    {
      "id" : "LEF",
      "carrera" : "Licenciado en Economía y Finanzas",
      "created_at" : "2019-05-15 20:47:51",
      "updated_at" : "2019-05-15 20:47:51"
    },
    {
      "id" : "LEI",
      "carrera" : "Licenciatura en Innovación Educativa",
      "created_at" : "2019-05-15 20:47:51",
      "updated_at" : "2019-05-15 20:47:51"
    },
    {
      "id" : "LIT",
      "carrera" : "Licenciatura en Inteligencia de Negocios",
      "created_at" : "2019-05-15 20:47:51",
      "updated_at" : "2019-05-15 20:47:51"
    },
    {
      "id" : "LEM",
      "carrera" : "Licenciado en Mercadotecnia",
      "created_at" : "2019-05-15 20:47:52",
      "updated_at" : "2019-05-15 20:47:52"
    },
    {
      "id" : "LIN",
      "carrera" : "Licenciado en Negocios Internacionales",
      "created_at" : "2019-05-15 20:47:52",
      "updated_at" : "2019-05-15 20:47:52"
    },
    {
      "id" : "LLE",
      "carrera" : "Licenciado en Letras Hispánicas",
      "created_at" : "2019-05-15 20:47:52",
      "updated_at" : "2019-05-15 20:47:52"
    },
    {
      "id" : "LLN",
      "carrera" : "Licenciado en Logística Internacional",
      "created_at" : "2019-05-15 20:47:52",
      "updated_at" : "2019-05-15 20:47:52"
    },
    {
      "id" : "LMC",
      "carrera" : "Licenciado en Mercadotecnia y Comunicación",
      "created_at" : "2019-05-15 20:47:52",
      "updated_at" : "2019-05-15 20:47:52"
    },
    {
      "id" : "LMI",
      "carrera" : "Licenciado en Periodismo y Medios de Comunicación",
      "created_at" : "2019-05-15 20:47:52",
      "updated_at" : "2019-05-15 20:47:52"
    },
    {
      "id" : "LNB",
      "carrera" : "Licenciado en Nutrición y Bienestar Integral",
      "created_at" : "2019-05-15 20:47:52",
      "updated_at" : "2019-05-15 20:47:52"
    },
    {
      "id" : "LP",
      "carrera" : "Licenciado en Psicología",
      "created_at" : "2019-05-15 20:47:52",
      "updated_at" : "2019-05-15 20:47:52"
    },
    {
      "id" : "LPE",
      "carrera" : "Licenciatura en Periodismo",
      "created_at" : "2019-05-15 20:47:52",
      "updated_at" : "2019-05-15 20:47:52"
    },
    {
      "id" : "LPL",
      "carrera" : "Licenciado en Ciencia Política",
      "created_at" : "2019-05-15 20:47:52",
      "updated_at" : "2019-05-15 20:47:52"
    },
    {
      "id" : "LPM",
      "carrera" : "Licenciado en Publicidad y Comunicación de Mercados",
      "created_at" : "2019-05-15 20:47:52",
      "updated_at" : "2019-05-15 20:47:52"
    },
    {
      "id" : "LPO",
      "carrera" : "Licenciado en Psicología Organizacional",
      "created_at" : "2019-05-15 20:47:53",
      "updated_at" : "2019-05-15 20:47:53"
    },
    {
      "id" : "LPS",
      "carrera" : "Licenciado en Psicología Clínica y de la Salud",
      "created_at" : "2019-05-15 20:47:53",
      "updated_at" : "2019-05-15 20:47:53"
    },
    {
      "id" : "LRI",
      "carrera" : "Licenciado en Relaciones Internacionales",
      "created_at" : "2019-05-15 20:47:53",
      "updated_at" : "2019-05-15 20:47:53"
    },
    {
      "id" : "LTP",
      "carrera" : "Licenciatura en Gobierno y Transformación Pública",
      "created_at" : "2019-05-15 20:47:53",
      "updated_at" : "2019-05-15 20:47:53"
    },
    {
      "id" : "LTS",
      "carrera" : "Licenciado en Transformación Social",
      "created_at" : "2019-05-15 20:47:53",
      "updated_at" : "2019-05-15 20:47:53"
    },
    {
      "id" : "LTM",
      "carrera" : "Licenciatura en Tecnología y Producción Musical",
      "created_at" : "2019-05-15 20:47:53",
      "updated_at" : "2019-05-15 20:47:53"
    },
    {
      "id" : "LUB",
      "carrera" : "Licenciatura en Urbanismo",
      "created_at" : "2019-05-15 20:47:53",
      "updated_at" : "2019-05-15 20:47:53"
    },
    {
      "id" : "MC",
      "carrera" : "Médico Cirujano",
      "created_at" : "2019-05-15 20:47:53",
      "updated_at" : "2019-05-15 20:47:53"
    },
    {
      "id" : "MO",
      "carrera" : "Medico Cirujano Odontologo",
      "created_at" : "2019-05-15 20:47:53",
      "updated_at" : "2019-05-15 20:47:53"
    },
    {
      "id" : "NEG",
      "carrera" : "Negocios / Exploración",
      "created_at" : null,
      "updated_at" : null
    },
    {
      "id" : "SLD",
      "carrera" : "Salud / Exploración",
      "created_at" : null,
      "updated_at" : null
    }
  ]

  export const getValue = (o, s) =>{
    if (!o){
      return null
    }
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
  }
  export const CAMPUS = [
    {
      "id" : 1,
      "campus" : "Aguascalientes",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "OCCIDENTE"
    },
    {
      "id" : 2,
      "campus" : "Central de Veracruz",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "SUR"
    },
    {
      "id" : 3,
      "campus" : "Chiapas",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "SUR"
    },
    {
      "id" : 4,
      "campus" : "Chihuahua",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "NORTE"
    },
    {
      "id" : 5,
      "campus" : "Ciudad de México",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "CDMX"
    },
    {
      "id" : 6,
      "campus" : "Ciudad Juárez",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "NORTE"
    },
    {
      "id" : 7,
      "campus" : "Ciudad Obregón",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "OCCIDENTE"
    },
    {
      "id" : 8,
      "campus" : "Navojoa",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : null
    },
    {
      "id" : 9,
      "campus" : "Cuernavaca",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : "SUR"
    },
    {
      "id" : 10,
      "campus" : "Estado de México",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : "CDMX"
    },
    {
      "id" : 11,
      "campus" : "Esmeralda (Preparatoria)",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : null
    },
    {
      "id" : 12,
      "campus" : "Guadalajara",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : "OCCIDENTE"
    },
    {
      "id" : 13,
      "campus" : "Sede Colima",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : null
    },
    {
      "id" : 14,
      "campus" : "Hidalgo",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : "SUR"
    },
    {
      "id" : 15,
      "campus" : "Irapuato",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : null
    },
    {
      "id" : 16,
      "campus" : "Laguna",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : "NORTE"
    },
    {
      "id" : 17,
      "campus" : "León",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : "CENTRO"
    },
    {
      "id" : 18,
      "campus" : "Monterrey",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : "NORTE"
    },
    {
      "id" : 19,
      "campus" : "Cumbres (Preparatoria)",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : null
    },
    {
      "id" : 20,
      "campus" : "Eugenio Garza Lagüera",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : null
    },
    {
      "id" : 21,
      "campus" : "Eugenio Garza Sada (Preparatoria)",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : null
    },
    {
      "id" : 22,
      "campus" : "Santa Catarina (Preparatoria)",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : null
    },
    {
      "id" : 23,
      "campus" : "Valle Alto (Preparatoria)",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : null
    },
    {
      "id" : 24,
      "campus" : "Morelia",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : "CENTRO"
    },
    {
      "id" : 25,
      "campus" : "Puebla",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : "SUR"
    },
    {
      "id" : 26,
      "campus" : "Querétaro",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : "CENTRO"
    },
    {
      "id" : 27,
      "campus" : "Saltillo",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : "NORTE"
    },
    {
      "id" : 28,
      "campus" : "San Luis Potosí",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "CENTRO"
    },
    {
      "id" : 29,
      "campus" : "Santa Fe",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "CDMX"
    },
    {
      "id" : 30,
      "campus" : "Sinaloa",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "OCCIDENTE"
    },
    {
      "id" : 31,
      "campus" : "Sonora Norte",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "OCCIDENTE"
    },
    {
      "id" : 32,
      "campus" : "Tampico",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "NORTE"
    },
    {
      "id" : 33,
      "campus" : "Matamoros(Preparatoria)",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : null
    },
    {
      "id" : 34,
      "campus" : "Toluca",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "SUR"
    },
    {
      "id" : 35,
      "campus" : "Zacatecas",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "OCCIDENTE"
    }
  ]
  export const CAMPUS_2 = [
    {
      "id" : 1,
      "campus" : "Aguascalientes",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "OCCIDENTE"
    },
    {
      "id" : 2,
      "campus" : "Central de Veracruz",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "SUR"
    },
    {
      "id" : 3,
      "campus" : "Chiapas",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "SUR"
    },
    {
      "id" : 4,
      "campus" : "Chihuahua",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "NORTE"
    },
    {
      "id" : 5,
      "campus" : "Ciudad de México",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "CDMX"
    },
    {
      "id" : 6,
      "campus" : "Ciudad Juárez",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "NORTE"
    },
    {
      "id" : 7,
      "campus" : "Ciudad Obregón",
      "created_at" : "2019-05-15 20:47:44",
      "updated_at" : "2019-05-15 20:47:44",
      "region" : "OCCIDENTE"
    },
    // {
    //   "id" : 8,
    //   "campus" : "Navojoa",
    //   "created_at" : "2019-05-15 20:47:45",
    //   "updated_at" : "2019-05-15 20:47:45",
    //   "region" : null
    // },
    {
      "id" : 9,
      "campus" : "Cuernavaca",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : "SUR"
    },
    {
      "id" : 10,
      "campus" : "Estado de México",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : "CDMX"
    },
    // {
    //   "id" : 11,
    //   "campus" : "Esmeralda (Preparatoria)",
    //   "created_at" : "2019-05-15 20:47:45",
    //   "updated_at" : "2019-05-15 20:47:45",
    //   "region" : null
    // },
    {
      "id" : 12,
      "campus" : "Guadalajara",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : "OCCIDENTE"
    },
    // {
    //   "id" : 13,
    //   "campus" : "Sede Colima",
    //   "created_at" : "2019-05-15 20:47:45",
    //   "updated_at" : "2019-05-15 20:47:45",
    //   "region" : null
    // },
    {
      "id" : 14,
      "campus" : "Hidalgo",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : "SUR"
    },
    {
      "id" : 15,
      "campus" : "Irapuato",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : null
    },
    {
      "id" : 16,
      "campus" : "Laguna",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : "NORTE"
    },
    {
      "id" : 17,
      "campus" : "León",
      "created_at" : "2019-05-15 20:47:45",
      "updated_at" : "2019-05-15 20:47:45",
      "region" : "CENTRO"
    },
    {
      "id" : 18,
      "campus" : "Monterrey",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : "NORTE"
    },
    // {
    //   "id" : 19,
    //   "campus" : "Cumbres (Preparatoria)",
    //   "created_at" : "2019-05-15 20:47:46",
    //   "updated_at" : "2019-05-15 20:47:46",
    //   "region" : null
    // },
    // {
    //   "id" : 20,
    //   "campus" : "Eugenio Garza Lagüera",
    //   "created_at" : "2019-05-15 20:47:46",
    //   "updated_at" : "2019-05-15 20:47:46",
    //   "region" : null
    // },
    // {
    //   "id" : 21,
    //   "campus" : "Eugenio Garza Sada (Preparatoria)",
    //   "created_at" : "2019-05-15 20:47:46",
    //   "updated_at" : "2019-05-15 20:47:46",
    //   "region" : null
    // },
    // {
    //   "id" : 22,
    //   "campus" : "Santa Catarina (Preparatoria)",
    //   "created_at" : "2019-05-15 20:47:46",
    //   "updated_at" : "2019-05-15 20:47:46",
    //   "region" : null
    // },
    // {
    //   "id" : 23,
    //   "campus" : "Valle Alto (Preparatoria)",
    //   "created_at" : "2019-05-15 20:47:46",
    //   "updated_at" : "2019-05-15 20:47:46",
    //   "region" : null
    // },
    {
      "id" : 24,
      "campus" : "Morelia",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : "CENTRO"
    },
    {
      "id" : 25,
      "campus" : "Puebla",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : "SUR"
    },
    {
      "id" : 26,
      "campus" : "Querétaro",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : "CENTRO"
    },
    {
      "id" : 27,
      "campus" : "Saltillo",
      "created_at" : "2019-05-15 20:47:46",
      "updated_at" : "2019-05-15 20:47:46",
      "region" : "NORTE"
    },
    {
      "id" : 28,
      "campus" : "San Luis Potosí",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "CENTRO"
    },
    {
      "id" : 29,
      "campus" : "Santa Fe",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "CDMX"
    },
    {
      "id" : 30,
      "campus" : "Sinaloa",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "OCCIDENTE"
    },
    {
      "id" : 31,
      "campus" : "Sonora Norte",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "OCCIDENTE"
    },
    {
      "id" : 32,
      "campus" : "Tampico",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "NORTE"
    },
    // {
    //   "id" : 33,
    //   "campus" : "Matamoros(Preparatoria)",
    //   "created_at" : "2019-05-15 20:47:47",
    //   "updated_at" : "2019-05-15 20:47:47",
    //   "region" : null
    // },
    {
      "id" : 34,
      "campus" : "Toluca",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "SUR"
    },
    {
      "id" : 35,
      "campus" : "Zacatecas",
      "created_at" : "2019-05-15 20:47:47",
      "updated_at" : "2019-05-15 20:47:47",
      "region" : "OCCIDENTE"
    }
  ]
  

  export function validateYouTubeUrl(text)
{ 
        var result = false
        var url = text;
        if (url != undefined || url != '') {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            var match = url.match(regExp);
            if (match && match[2].length == 11) {
                // Do anything for being valid
                // if need to change the url to embed url then use below line
           
                    result = match[2]
            }
            else {
                // Do anything for not being valid
            }
        }

        return result
      
}
export function getVideoCover(file, seekTo = 0.0,callback=()=>{}) {
    //console.log("getting video cover for file: ", file);
    new Promise(
      (resolve, reject) => {
        // load the file to a video player
        const videoPlayer = document.createElement('video');
        videoPlayer.setAttribute('src', URL.createObjectURL(file));
        videoPlayer.load();
        videoPlayer.addEventListener('error', (ex) => {
            reject("error when loading video file", ex);
        });
        // load metadata of the video to get video duration and dimensions
        videoPlayer.addEventListener('loadedmetadata', () => {
            // seek to user defined timestamp (in seconds) if possible
            if (videoPlayer.duration < seekTo) {
                reject("video is too short.");
                return;
            }
            // delay seeking or else 'seeked' event won't fire on Safari
            setTimeout(() => {
              videoPlayer.currentTime = seekTo;
            }, 200);
            // extract video thumbnail once seeking is complete
            videoPlayer.addEventListener('seeked', () => {
                //console.log('video is now paused at %ss.', seekTo);
                // define a canvas to have the same dimension as the video
                const canvas = document.createElement("canvas");
                canvas.width = videoPlayer.videoWidth;
                canvas.height = videoPlayer.videoHeight;
                // draw the video frame to canvas
                const ctx = canvas.getContext("2d");
                ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                // return the canvas image as a blob
                ctx.canvas.toBlob(
                    blob => {
                        resolve(blob);
                    },
                    "image/jpeg",
                    0.75 /* quality */
                );
            });
        });
    }).then((file)=>{
      callback(file)
    })
}

export const  saveData =(function () {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  return function (data, fileName) {
      var json = JSON.stringify(data),
          blob = new Blob([json], {type: "octet/stream"}),
          url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
  };
}());

export const COUNTRIES = [
  {
    "name_en": "Afghanistan",
    "name_es": "Afganistán",
    "dial_code": "+93",
    "code": "AF"
  },
  {
    "name_en": "Albania",
    "name_es": "Albania",
    "dial_code": "+355",
    "code": "AL"
  },
  {
    "name_en": "Algeria",
    "name_es": "Argelia",
    "dial_code": "+213",
    "code": "DZ"
  },
  {
    "name_en": "AmericanSamoa",
    "name_es": "Samoa Americana",
    "dial_code": "+1684",
    "code": "AS"
  },
  {
    "name_en": "Andorra",
    "name_es": "Andorra",
    "dial_code": "+376",
    "code": "AD"
  },
  {
    "name_en": "Angola",
    "name_es": "Angola",
    "dial_code": "+244",
    "code": "AO"
  },
  {
    "name_en": "Anguilla",
    "name_es": "Anguilla",
    "dial_code": "+1264",
    "code": "AI"
  },
  {
    "name_en": "Antarctica",
    "name_es": "Antártida",
    "dial_code": "+672",
    "code": "AQ"
  },
  {
    "name_en": "Antigua and Barbuda",
    "name_es": "Antigua y Barbuda",
    "dial_code": "+1268",
    "code": "AG"
  },
  {
    "name_en": "Argentina",
    "name_es": "Argentina",
    "dial_code": "+54",
    "code": "AR"
  },
  {
    "name_en": "Armenia",
    "name_es": "Armenia",
    "dial_code": "+374",
    "code": "AM"
  },
  {
    "name_en": "Aruba",
    "name_es": "Aruba",
    "dial_code": "+297",
    "code": "AW"
  },
  {
    "name_en": "Australia",
    "name_es": "Australia",
    "dial_code": "+61",
    "code": "AU"
  },
  {
    "name_en": "Austria",
    "name_es": "Austria",
    "dial_code": "+43",
    "code": "AT"
  },
  {
    "name_en": "Azerbaijan",
    "name_es": "Azerbaiyán",
    "dial_code": "+994",
    "code": "AZ"
  },
  {
    "name_en": "Bahamas",
    "name_es": "Bahamas",
    "dial_code": "+1242",
    "code": "BS"
  },
  {
    "name_en": "Bahrain",
    "name_es": "Baréin",
    "dial_code": "+973",
    "code": "BH"
  },
  {
    "name_en": "Bangladesh",
    "name_es": "Banglades",
    "dial_code": "+880",
    "code": "BD"
  },
  {
    "name_en": "Barbados",
    "name_es": "Barbados",
    "dial_code": "+1246",
    "code": "BB"
  },
  {
    "name_en": "Belarus",
    "name_es": "Bielorrusia",
    "dial_code": "+375",
    "code": "BY"
  },
  {
    "name_en": "Belgium",
    "name_es": "Bélgica",
    "dial_code": "+32",
    "code": "BE"
  },
  {
    "name_en": "Belize",
    "name_es": "Belice",
    "dial_code": "+501",
    "code": "BZ"
  },
  {
    "name_en": "Benin",
    "name_es": "Benin",
    "dial_code": "+229",
    "code": "BJ"
  },
  {
    "name_en": "Bermuda",
    "name_es": "Bermudas",
    "dial_code": "+1441",
    "code": "BM"
  },
  {
    "name_en": "Bhutan",
    "name_es": "Butan",
    "dial_code": "+975",
    "code": "BT"
  },
  {
    "name_en": "Bolivia",
    "name_es": "Bolivia",
    "dial_code": "+591",
    "code": "BO"
  },
  {
    "name_en": "Bosnia and Herzegovina",
    "name_es": "Bosnia-Herzegovina",
    "dial_code": "+387",
    "code": "BA"
  },
  {
    "name_en": "Botswana",
    "name_es": "Botsuana",
    "dial_code": "+267",
    "code": "BW"
  },
  {
    "name_en": "Brazil",
    "name_es": "Brasil",
    "dial_code": "+55",
    "code": "BR"
  },
  {
    "name_en": "British Indian Ocean Territory",
    "name_es": "Territorio Británico del Océano Índico",
    "dial_code": "+246",
    "code": "IO"
  },
  {
    "name_en": "Brunei Darussalam",
    "name_es": "Brunei",
    "dial_code": "+673",
    "code": "BN"
  },
  {
    "name_en": "Bulgaria",
    "name_es": "Bulgaria",
    "dial_code": "+359",
    "code": "BG"
  },
  {
    "name_en": "Burkina Faso",
    "name_es": "Burkina Faso",
    "dial_code": "+226",
    "code": "BF"
  },
  {
    "name_en": "Burundi",
    "name_es": "Burundi",
    "dial_code": "+257",
    "code": "BI"
  },
  {
    "name_en": "Cambodia",
    "name_es": "Camboya",
    "dial_code": "+855",
    "code": "KH"
  },
  {
    "name_en": "Cameroon",
    "name_es": "Camerún",
    "dial_code": "+237",
    "code": "CM"
  },
  {
    "name_en": "Canada",
    "name_es": "Canadá",
    "dial_code": "+1",
    "code": "CA"
  },
  {
    "name_en": "Cape Verde",
    "name_es": "Cabo Verde",
    "dial_code": "+238",
    "code": "CV"
  },
  {
    "name_en": "Cayman Islands",
    "name_es": "Islas Caimán",
    "dial_code": "+ 345",
    "code": "KY"
  },
  {
    "name_en": "Central African Republic",
    "name_es": "República Centroafricana",
    "dial_code": "+236",
    "code": "CF"
  },
  {
    "name_en": "Chad",
    "name_es": "Chad",
    "dial_code": "+235",
    "code": "TD"
  },
  {
    "name_en": "Chile",
    "name_es": "Chile",
    "dial_code": "+56",
    "code": "CL"
  },
  {
    "name_en": "China",
    "name_es": "China",
    "dial_code": "+86",
    "code": "CN"
  },
  {
    "name_en": "Christmas Island",
    "name_es": "Isla de Navidad",
    "dial_code": "+61",
    "code": "CX"
  },
  {
    "name_en": "Cocos (Keeling) Islands",
    "name_es": "Islas Cocos",
    "dial_code": "+61",
    "code": "CC"
  },
  {
    "name_en": "Colombia",
    "name_es": "Colombia",
    "dial_code": "+57",
    "code": "CO"
  },
  {
    "name_en": "Comoros",
    "name_es": "Comoras",
    "dial_code": "+269",
    "code": "KM"
  },
  {
    "name_en": "Congo",
    "name_es": "Congo",
    "dial_code": "+242",
    "code": "CG"
  },
  {
    "name_en": "Congo, The Democratic Republic of the",
    "name_es": "República Democrática del Congo",
    "dial_code": "+243",
    "code": "CD"
  },
  {
    "name_en": "Cook Islands",
    "name_es": "Islas Cook",
    "dial_code": "+682",
    "code": "CK"
  },
  {
    "name_en": "Costa Rica",
    "name_es": "Costa Rica",
    "dial_code": "+506",
    "code": "CR"
  },
  {
    "name_en": "Cote d'Ivoire",
    "name_es": "Costa de Marfil",
    "dial_code": "+225",
    "code": "CI"
  },
  {
    "name_en": "Croatia",
    "name_es": "Croacia",
    "dial_code": "+385",
    "code": "HR"
  },
  {
    "name_en": "Cuba",
    "name_es": "Cuba",
    "dial_code": "+53",
    "code": "CU"
  },
  {
    "name_en": "Cyprus",
    "name_es": "Chipre",
    "dial_code": "+537",
    "code": "CY"
  },
  {
    "name_en": "Czechia",
    "name_es": "Chequia",
    "dial_code": "+420",
    "code": "CZ"
  },
  {
    "name_en": "Denmark",
    "name_es": "Dinamarca",
    "dial_code": "+45",
    "code": "DK"
  },
  {
    "name_en": "Djibouti",
    "name_es": "Yibuti",
    "dial_code": "+253",
    "code": "DJ"
  },
  {
    "name_en": "Dominica",
    "name_es": "Dominica",
    "dial_code": "+1767",
    "code": "DM"
  },
  {
    "name_en": "Dominican Republic",
    "name_es": "República Dominicana",
    "dial_code": "+1849",
    "code": "DO"
  },
  {
    "name_en": "Ecuador",
    "name_es": "Ecuador",
    "dial_code": "+593",
    "code": "EC"
  },
  {
    "name_en": "Egypt",
    "name_es": "Egipto",
    "dial_code": "+20",
    "code": "EG"
  },
  {
    "name_en": "El Salvador",
    "name_es": "El Salvador",
    "dial_code": "+503",
    "code": "SV"
  },
  {
    "name_en": "Equatorial Guinea",
    "name_es": "Guinea Ecuatorial",
    "dial_code": "+240",
    "code": "GQ"
  },
  {
    "name_en": "Eritrea",
    "name_es": "Eritrea",
    "dial_code": "+291",
    "code": "ER"
  },
  {
    "name_en": "Estonia",
    "name_es": "Estonia",
    "dial_code": "+372",
    "code": "EE"
  },
  {
    "name_en": "Ethiopia",
    "name_es": "Etiopía",
    "dial_code": "+251",
    "code": "ET"
  },
  {
    "name_en": "Falkland Islands (Malvinas)",
    "name_es": "Islas Malvinas",
    "dial_code": "+500",
    "code": "FK"
  },
  {
    "name_en": "Faroe Islands",
    "name_es": "Islas Feroe",
    "dial_code": "+298",
    "code": "FO"
  },
  {
    "name_en": "Fiji",
    "name_es": "Fiyi",
    "dial_code": "+679",
    "code": "FJ"
  },
  {
    "name_en": "Finland",
    "name_es": "Finlandia",
    "dial_code": "+358",
    "code": "FI"
  },
  {
    "name_en": "France",
    "name_es": "Francia",
    "dial_code": "+33",
    "code": "FR"
  },
  {
    "name_en": "French Guiana",
    "name_es": "Guayana Francesa",
    "dial_code": "+594",
    "code": "GF"
  },
  {
    "name_en": "French Polynesia",
    "name_es": "Polinesia Francesa",
    "dial_code": "+689",
    "code": "PF"
  },
  {
    "name_en": "Gabon",
    "name_es": "Gabón",
    "dial_code": "+241",
    "code": "GA"
  },
  {
    "name_en": "Gambia",
    "name_es": "Gambia",
    "dial_code": "+220",
    "code": "GM"
  },
  {
    "name_en": "Georgia",
    "name_es": "Georgia",
    "dial_code": "+995",
    "code": "GE"
  },
  {
    "name_en": "Germany",
    "name_es": "Alemania",
    "dial_code": "+49",
    "code": "DE"
  },
  {
    "name_en": "Ghana",
    "name_es": "Ghana",
    "dial_code": "+233",
    "code": "GH"
  },
  {
    "name_en": "Gibraltar",
    "name_es": "Gibraltar",
    "dial_code": "+350",
    "code": "GI"
  },
  {
    "name_en": "Greece",
    "name_es": "Grecia",
    "dial_code": "+30",
    "code": "GR"
  },
  {
    "name_en": "Greenland",
    "name_es": "Groenlandia",
    "dial_code": "+299",
    "code": "GL"
  },
  {
    "name_en": "Grenada",
    "name_es": "Granada",
    "dial_code": "+1473",
    "code": "GD"
  },
  {
    "name_en": "Guadeloupe",
    "name_es": "Guadalupe",
    "dial_code": "+590",
    "code": "GP"
  },
  {
    "name_en": "Guam",
    "name_es": "Guam",
    "dial_code": "+1671",
    "code": "GU"
  },
  {
    "name_en": "Guatemala",
    "name_es": "Guatemala",
    "dial_code": "+502",
    "code": "GT"
  },
  {
    "name_en": "Guernsey",
    "name_es": "Guernsey",
    "dial_code": "+44",
    "code": "GG"
  },
  {
    "name_en": "Guinea",
    "name_es": "Guinea",
    "dial_code": "+224",
    "code": "GN"
  },
  {
    "name_en": "Guinea-Bissau",
    "name_es": "Guinea-Bisau",
    "dial_code": "+245",
    "code": "GW"
  },
  {
    "name_en": "Guyana",
    "name_es": "Guyana",
    "dial_code": "+595",
    "code": "GY"
  },
  {
    "name_en": "Haiti",
    "name_es": "Haití",
    "dial_code": "+509",
    "code": "HT"
  },
  {
    "name_en": "Holy See (Vatican City State)",
    "name_es": "Ciudad del Vaticano",
    "dial_code": "+379",
    "code": "VA"
  },
  {
    "name_en": "Honduras",
    "name_es": "Honduras",
    "dial_code": "+504",
    "code": "HN"
  },
  {
    "name_en": "Hong Kong",
    "name_es": "Hong Kong",
    "dial_code": "+852",
    "code": "HK"
  },
  {
    "name_en": "Hungary",
    "name_es": "Hungría",
    "dial_code": "+36",
    "code": "HU"
  },
  {
    "name_en": "Iceland",
    "name_es": "Islandia",
    "dial_code": "+354",
    "code": "IS"
  },
  {
    "name_en": "India",
    "name_es": "India",
    "dial_code": "+91",
    "code": "IN"
  },
  {
    "name_en": "Indonesia",
    "name_es": "Indonesia",
    "dial_code": "+62",
    "code": "ID"
  },
  {
    "name_en": "Iran, Islamic Republic of",
    "name_es": "Irán",
    "dial_code": "+98",
    "code": "IR"
  },
  {
    "name_en": "Iraq",
    "name_es": "Iraq",
    "dial_code": "+964",
    "code": "IQ"
  },
  {
    "name_en": "Ireland",
    "name_es": "Irlanda",
    "dial_code": "+353",
    "code": "IE"
  },
  {
    "name_en": "Isle of Man",
    "name_es": "Isla de Man",
    "dial_code": "+44",
    "code": "IM"
  },
  {
    "name_en": "Israel",
    "name_es": "Israel",
    "dial_code": "+972",
    "code": "IL"
  },
  {
    "name_en": "Italy",
    "name_es": "Italia",
    "dial_code": "+39",
    "code": "IT"
  },
  {
    "name_en": "Jamaica",
    "name_es": "Jamaica",
    "dial_code": "+1876",
    "code": "JM"
  },
  {
    "name_en": "Japan",
    "name_es": "Japón",
    "dial_code": "+81",
    "code": "JP"
  },
  {
    "name_en": "Jersey",
    "name_es": "Jersey",
    "dial_code": "+44",
    "code": "JE"
  },
  {
    "name_en": "Jordan",
    "name_es": "Jordania",
    "dial_code": "+962",
    "code": "JO"
  },
  {
    "name_en": "Kazakhstan",
    "name_es": "Kazajistán",
    "dial_code": "+7",
    "code": "KZ"
  },
  {
    "name_en": "Kenya",
    "name_es": "Kenia",
    "dial_code": "+254",
    "code": "KE"
  },
  {
    "name_en": "Kiribati",
    "name_es": "Kiribati",
    "dial_code": "+686",
    "code": "KI"
  },
  {
    "name_en": "Korea, Democratic People's Republic of",
    "name_es": "Corea del Norte",
    "dial_code": "+850",
    "code": "KP"
  },
  {
    "name_en": "Korea, Republic of",
    "name_es": "Corea del Sur",
    "dial_code": "+82",
    "code": "KR"
  },
  {
    "name_en": "Kosovo",
    "name_es": "Kosovo",
    "dial_code": "+383",
    "code": "XK"
  },
  {
    "name_en": "Kuwait",
    "name_es": "Kuwait",
    "dial_code": "+965",
    "code": "KW"
  },
  {
    "name_en": "Kyrgyzstan",
    "name_es": "Kirguistán",
    "dial_code": "+996",
    "code": "KG"
  },
  {
    "name_en": "Lao People's Democratic Republic",
    "name_es": "Laos",
    "dial_code": "+856",
    "code": "LA"
  },
  {
    "name_en": "Latvia",
    "name_es": "Letonia",
    "dial_code": "+371",
    "code": "LV"
  },
  {
    "name_en": "Lebanon",
    "name_es": "Líbano",
    "dial_code": "+961",
    "code": "LB"
  },
  {
    "name_en": "Lesotho",
    "name_es": "Lesoto",
    "dial_code": "+266",
    "code": "LS"
  },
  {
    "name_en": "Liberia",
    "name_es": "Liberia",
    "dial_code": "+231",
    "code": "LR"
  },
  {
    "name_en": "Libyan Arab Jamahiriya",
    "name_es": "Libia",
    "dial_code": "+218",
    "code": "LY"
  },
  {
    "name_en": "Liechtenstein",
    "name_es": "Liechtenstein",
    "dial_code": "+423",
    "code": "LI"
  },
  {
    "name_en": "Lithuania",
    "name_es": "Lituania",
    "dial_code": "+370",
    "code": "LT"
  },
  {
    "name_en": "Luxembourg",
    "name_es": "Luxemburgo",
    "dial_code": "+352",
    "code": "LU"
  },
  {
    "name_en": "Macao",
    "name_es": "Macao",
    "dial_code": "+853",
    "code": "MO"
  },
  {
    "name_en": "Macedonia, The Former Yugoslav Republic of",
    "name_es": "República de Macedonia",
    "dial_code": "+389",
    "code": "MK"
  },
  {
    "name_en": "Madagascar",
    "name_es": "Madagascar",
    "dial_code": "+261",
    "code": "MG"
  },
  {
    "name_en": "Malawi",
    "name_es": "Malaui",
    "dial_code": "+265",
    "code": "MW"
  },
  {
    "name_en": "Malaysia",
    "name_es": "Malasia",
    "dial_code": "+60",
    "code": "MY"
  },
  {
    "name_en": "Maldives",
    "name_es": "Maldivas",
    "dial_code": "+960",
    "code": "MV"
  },
  {
    "name_en": "Mali",
    "name_es": "Malí",
    "dial_code": "+223",
    "code": "ML"
  },
  {
    "name_en": "Malta",
    "name_es": "Malta",
    "dial_code": "+356",
    "code": "MT"
  },
  {
    "name_en": "Marshall Islands",
    "name_es": "Islas Marshall",
    "dial_code": "+692",
    "code": "MH"
  },
  {
    "name_en": "Martinique",
    "name_es": "Martinica",
    "dial_code": "+596",
    "code": "MQ"
  },
  {
    "name_en": "Mauritania",
    "name_es": "Mauritania",
    "dial_code": "+222",
    "code": "MR"
  },
  {
    "name_en": "Mauritius",
    "name_es": "Mauricio",
    "dial_code": "+230",
    "code": "MU"
  },
  {
    "name_en": "Mayotte",
    "name_es": "Mayotte",
    "dial_code": "+262",
    "code": "YT"
  },
  {
    "name_en": "Mexico",
    "name_es": "México",
    "dial_code": "+52",
    "code": "MX"
  },
  {
    "name_en": "Micronesia, Federated States of",
    "name_es": "Estados Federados de Micronesia",
    "dial_code": "+691",
    "code": "FM"
  },
  {
    "name_en": "Moldova, Republic of",
    "name_es": "Moldavia",
    "dial_code": "+373",
    "code": "MD"
  },
  {
    "name_en": "Monaco",
    "name_es": "Monaco",
    "dial_code": "+377",
    "code": "MC"
  },
  {
    "name_en": "Mongolia",
    "name_es": "Mongolia",
    "dial_code": "+976",
    "code": "MN"
  },
  {
    "name_en": "Montenegro",
    "name_es": "Montenegro",
    "dial_code": "+382",
    "code": "ME"
  },
  {
    "name_en": "Montserrat",
    "name_es": "Montserrat",
    "dial_code": "+1664",
    "code": "MS"
  },
  {
    "name_en": "Morocco",
    "name_es": "Marruecos",
    "dial_code": "+212",
    "code": "MA"
  },
  {
    "name_en": "Mozambique",
    "name_es": "Mozambique",
    "dial_code": "+258",
    "code": "MZ"
  },
  {
    "name_en": "Myanmar",
    "name_es": "Birmania",
    "dial_code": "+95",
    "code": "MM"
  },
  {
    "name_en": "Namibia",
    "name_es": "Namibia",
    "dial_code": "+264",
    "code": "NA"
  },
  {
    "name_en": "Nauru",
    "name_es": "Nauru",
    "dial_code": "+674",
    "code": "NR"
  },
  {
    "name_en": "Nepal",
    "name_es": "Nepal",
    "dial_code": "+977",
    "code": "NP"
  },
  {
    "name_en": "Netherlands",
    "name_es": "Holanda",
    "dial_code": "+31",
    "code": "NL"
  },
  {
    "name_en": "Netherlands Antilles",
    "name_es": "Antillas Holandesas",
    "dial_code": "+599",
    "code": "AN"
  },
  {
    "name_en": "New Caledonia",
    "name_es": "Nueva Caledonia",
    "dial_code": "+687",
    "code": "NC"
  },
  {
    "name_en": "New Zealand",
    "name_es": "Nueva Zelanda",
    "dial_code": "+64",
    "code": "NZ"
  },
  {
    "name_en": "Nicaragua",
    "name_es": "Nicaragua",
    "dial_code": "+505",
    "code": "NI"
  },
  {
    "name_en": "Niger",
    "name_es": "Niger",
    "dial_code": "+227",
    "code": "NE"
  },
  {
    "name_en": "Nigeria",
    "name_es": "Nigeria",
    "dial_code": "+234",
    "code": "NG"
  },
  {
    "name_en": "Niue",
    "name_es": "Niue",
    "dial_code": "+683",
    "code": "NU"
  },
  {
    "name_en": "NorfolkIsland",
    "name_es": "IslaNorfolk",
    "dial_code": "+672",
    "code": "NF"
  },
  {
    "name_en": "NorthernMarianaIslands",
    "name_es": "IslasMarianasdelNorte",
    "dial_code": "+1670",
    "code": "MP"
  },
  {
    "name_en": "Norway",
    "name_es": "Noruega",
    "dial_code": "+47",
    "code": "NO"
  },
  {
    "name_en": "Oman",
    "name_es": "Omán",
    "dial_code": "+968",
    "code": "OM"
  },
  {
    "name_en": "Pakistan",
    "name_es": "Pakistán",
    "dial_code": "+92",
    "code": "PK"
  },
  {
    "name_en": "Palau",
    "name_es": "Palaos",
    "dial_code": "+680",
    "code": "PW"
  },
  {
    "name_en": "Panama",
    "name_es": "Panamá",
    "dial_code": "+507",
    "code": "PA"
  },
  {
    "name_en": "Papua New Guinea",
    "name_es": "Papúa Nueva Guinea",
    "dial_code": "+675",
    "code": "PG"
  },
  {
    "name_en": "Paraguay",
    "name_es": "Paraguay",
    "dial_code": "+595",
    "code": "PY"
  },
  {
    "name_en": "Peru",
    "name_es": "Perú",
    "dial_code": "+51",
    "code": "PE"
  },
  {
    "name_en": "Philippines",
    "name_es": "Filipinas",
    "dial_code": "+63",
    "code": "PH"
  },
  {
    "name_en": "Pitcairn",
    "name_es": "Islas Pitcairn",
    "dial_code": "+872",
    "code": "PN"
  },
  {
    "name_en": "Poland",
    "name_es": "Polonia",
    "dial_code": "+48",
    "code": "PL"
  },
  {
    "name_en": "Portugal",
    "name_es": "Portugal",
    "dial_code": "+351",
    "code": "PT"
  },
  {
    "name_en": "Puerto Rico",
    "name_es": "Puerto Rico",
    "dial_code": "+1939",
    "code": "PR"
  },
  {
    "name_en": "Qatar",
    "name_es": "Qatar",
    "dial_code": "+974",
    "code": "QA"
  },
  {
    "name_en": "Romania",
    "name_es": "Rumania",
    "dial_code": "+40",
    "code": "RO"
  },
  {
    "name_en": "Russia",
    "name_es": "Rusia",
    "dial_code": "+7",
    "code": "RU"
  },
  {
    "name_en": "Rwanda",
    "name_es": "Ruanda",
    "dial_code": "+250",
    "code": "RW"
  },
  {
    "name_en": "Réunion",
    "name_es": "Reunion",
    "dial_code": "+262",
    "code": "RE"
  },
  {
    "name_en": "Saint Barthélemy",
    "name_es": "San Bartolome",
    "dial_code": "+590",
    "code": "BL"
  },
  {
    "name_en": "Saint Helena, Ascension and Tristan Da Cunha",
    "name_es": "Santa Elena, Ascensión y Tristán de Acuña",
    "dial_code": "+290",
    "code": "SH"
  },
  {
    "name_en": "Saint Kitts and Nevis",
    "name_es": "San Cristóbal y Nieves",
    "dial_code": "+1869",
    "code": "KN"
  },
  {
    "name_en": "Saint Lucia",
    "name_es": "Santa Lucía",
    "dial_code": "+1758",
    "code": "LC"
  },
  {
    "name_en": "Saint Martin",
    "name_es": "Isla de San Martín",
    "dial_code": "+590",
    "code": "MF"
  },
  {
    "name_en": "Saint Pierre and Miquelon",
    "name_es": "San Pedro y Miquelon",
    "dial_code": "+508",
    "code": "PM"
  },
  {
    "name_en": "Saint Vincent and the Grenadines",
    "name_es": "San Vicente y las Granadinas",
    "dial_code": "+1784",
    "code": "VC"
  },
  {
    "name_en": "Samoa",
    "name_es": "Samoa",
    "dial_code": "+685",
    "code": "WS"
  },
  {
    "name_en": "San Marino",
    "name_es": "San Marino",
    "dial_code": "+378",
    "code": "SM"
  },
  {
    "name_en": "Sao Tome and Principe",
    "name_es": " Santo Tomé y Príncipe",
    "dial_code": "+239",
    "code": "ST"
  },
  {
    "name_en": "Saudi Arabia",
    "name_es": "Arabia Saudita",
    "dial_code": "+966",
    "code": "SA"
  },
  {
    "name_en": "Senegal",
    "name_es": "Senegal",
    "dial_code": "+221",
    "code": "SN"
  },
  {
    "name_en": "Serbia",
    "name_es": "Serbia",
    "dial_code": "+381",
    "code": "RS"
  },
  {
    "name_en": "Seychelles",
    "name_es": "Seychelles",
    "dial_code": "+248",
    "code": "SC"
  },
  {
    "name_en": "Sierra Leone",
    "name_es": "Sierra Leona",
    "dial_code": "+232",
    "code": "SL"
  },
  {
    "name_en": "Singapore",
    "name_es": "Singapur",
    "dial_code": "+65",
    "code": "SG"
  },
  {
    "name_en": "Slovakia",
    "name_es": "Eslovaquia",
    "dial_code": "+421",
    "code": "SK"
  },
  {
    "name_en": "Slovenia",
    "name_es": "Eslovenia",
    "dial_code": "+386",
    "code": "SI"
  },
  {
    "name_en": "Solomon Islands",
    "name_es": "Islas Salomón",
    "dial_code": "+677",
    "code": "SB"
  },
  {
    "name_en": "Somalia",
    "name_es": "Somalia",
    "dial_code": "+252",
    "code": "SO"
  },
  {
    "name_en": "South Africa",
    "name_es": "Sudáfrica",
    "dial_code": "+27",
    "code": "ZA"
  },
  {
    "name_en": "South Sudan",
    "name_es": "Sudán del Sur",
    "dial_code": "+211",
    "code": "SS"
  },
  {
    "name_en": "Spain",
    "name_es": "España",
    "dial_code": "+34",
    "code": "ES"
  },
  {
    "name_en": "Sri Lanka",
    "name_es": "Sri Lanka",
    "dial_code": "+94",
    "code": "LK"
  },
  {
    "name_en": "State of Palestine",
    "name_es": "Estado de Palestina",
    "dial_code": "+970",
    "code": "PS"
  },
  {
    "name_en": "Sudan",
    "name_es": "Sudán",
    "dial_code": "+249",
    "code": "SD"
  },
  {
    "name_en": "Suriname",
    "name_es": "Surinam",
    "dial_code": "+597",
    "code": "SR"
  },
  {
    "name_en": "Svalbard and Jan Mayen",
    "name_es": "Svalbard y Jan Mayen",
    "dial_code": "+47",
    "code": "SJ"
  },
  {
    "name_en": "Swaziland",
    "name_es": "Suazilandia",
    "dial_code": "+268",
    "code": "SZ"
  },
  {
    "name_en": "Sweden",
    "name_es": "Suecia",
    "dial_code": "+46",
    "code": "SE"
  },
  {
    "name_en": "Switzerland",
    "name_es": "Suiza",
    "dial_code": "+41",
    "code": "CH"
  },
  {
    "name_en": "Syrian Arab Republic",
    "name_es": "Siria",
    "dial_code": "+963",
    "code": "SY"
  },
  {
    "name_en": "Taiwan, Province of China",
    "name_es": "Taiwán",
    "dial_code": "+886",
    "code": "TW"
  },
  {
    "name_en": "Tayikistan",
    "name_es": "Tayikistán",
    "dial_code": "+992",
    "code": "TJ"
  },
  {
    "name_en": "Tanzania, United Republic of",
    "name_es": "Tanzania",
    "dial_code": "+255",
    "code": "TZ"
  },
  {
    "name_en": "Thailand",
    "name_es": "Tailandia",
    "dial_code": "+66",
    "code": "TH"
  },
  {
    "name_en": "Timor-Leste",
    "name_es": "Timor Oriental",
    "dial_code": "+670",
    "code": "TL"
  },
  {
    "name_en": "Togo",
    "name_es": "Togo",
    "dial_code": "+228",
    "code": "TG"
  },
  {
    "name_en": "Tokelau",
    "name_es": "Tokelau",
    "dial_code": "+690",
    "code": "TK"
  },
  {
    "name_en": "Tonga",
    "name_es": "Tonga",
    "dial_code": "+676",
    "code": "TO"
  },
  {
    "name_en": "Trinidad and Tobago",
    "name_es": "Trinidad y Tobago",
    "dial_code": "+1868",
    "code": "TT"
  },
  {
    "name_en": "Tunisia",
    "name_es": "Túnez",
    "dial_code": "+216",
    "code": "TN"
  },
  {
    "name_en": "Turkey",
    "name_es": "Turquía",
    "dial_code": "+90",
    "code": "TR"
  },
  {
    "name_en": "Turkmenistan",
    "name_es": "Turkmenistán",
    "dial_code": "+993",
    "code": "TM"
  },
  {
    "name_en": "Turks and Caicos Islands",
    "name_es": "Islas Turcas y Caicos",
    "dial_code": "+1649",
    "code": "TC"
  },
  {
    "name_en": "Tuvalu",
    "name_es": "Tuvalu",
    "dial_code": "+688",
    "code": "TV"
  },
  {
    "name_en": "Uganda",
    "name_es": "Uganda",
    "dial_code": "+256",
    "code": "UG"
  },
  {
    "name_en": "Ukraine",
    "name_es": "Ucrania",
    "dial_code": "+380",
    "code": "UA"
  },
  {
    "name_en": "United Arab Emirates",
    "name_es": "Emiratos Árabes Unidos",
    "dial_code": "+971",
    "code": "AE"
  },
  {
    "name_en": "United Kingdom",
    "name_es": "Reino Unido",
    "dial_code": "+44",
    "code": "GB"
  },
  {
    "name_en": "United States",
    "name_es": "Estados Unidos",
    "dial_code": "+1",
    "code": "US"
  },
  {
    "name_en": "Uruguay",
    "name_es": "Uruguay",
    "dial_code": "+598",
    "code": "UY"
  },
  {
    "name_en": "Uzbekistan",
    "name_es": "Uzbekistán",
    "dial_code": "+998",
    "code": "UZ"
  },
  {
    "name_en": "Vanuatu",
    "name_es": "Vanuatu",
    "dial_code": "+678",
    "code": "VU"
  },
  {
    "name_en": "Venezuela, Bolivarian Republic of",
    "name_es": "Venezuela",
    "dial_code": "+58",
    "code": "VE"
  },
  {
    "name_en": "Vietnam",
    "name_es": "Vietnam",
    "dial_code": "+84",
    "code": "VN"
  },
  {
    "name_en": "Virgin Islands, British",
    "name_es": "Islas Vírgenes Británicas",
    "dial_code": "+1284",
    "code": "VG"
  },
  {
    "name_en": "Virgin Islands, U.S.",
    "name_es": "Islas Vírgenes de los Estados Unidos",
    "dial_code": "+1340",
    "code": "VI"
  },
  {
    "name_en": "Wallis and Futuna",
    "name_es": "Wallis y Futuna",
    "dial_code": "+681",
    "code": "WF"
  },
  {
    "name_en": "Yemen",
    "name_es": "Yemen",
    "dial_code": "+967",
    "code": "YE"
  },
  {
    "name_en": "Zambia",
    "name_es": "Zambia",
    "dial_code": "+260",
    "code": "ZM"
  },
  {
    "name_en": "Zimbabwe",
    "name_es": "Zimbabue",
    "dial_code": "+263",
    "code": "ZW"
  },
  {
    "name_en": "Åland Islands",
    "name_es": "Åland",
    "dial_code": "+358",
    "code": "AX"
  }
]

export const COUNTRIES_DIC = Object.assign({}, ...COUNTRIES.map((x) => ({[x.code]: x})));

export const COUNTRIES_PHONE_VALUES = Object.assign({}, ...COUNTRIES.map((x) => ({value: x.dial_code})));

export const MODALIDADES = [
  {label: "Remota", value:"REM"},
  {label: "Presencial", value:"PRE"},
  {label: "Híbirda", value:"HIB"},
]

export const MODALIDADES_DIC = Object.assign({}, ...MODALIDADES.map((x) => ({[x.value]: x})));

export const TIPO_OFERTA = [
  {label: "Prácticas profesionales", value:"REM"},
  {label: "Internships", value:"INTER"},
  {label: "Becarios", value:"BE"},
  {label: "Programas de verano", value:"VER"},
  {label: "Sesiones Informativas", value:"SI"},
  {label: "Trainees", value:"TRA"},
  {label: "Tiempo completo", value:"TC"},
]


export const TIPO_OFERTA_DIC = Object.assign({}, ...TIPO_OFERTA.map((x) => ({[x.value]: x})));


export const ESTADOS = [{ "id": 1, "name": "Aguascalientes" },{ "id": 2, "name": "Baja California" },{ "id": 3, "name": "Baja California Sur" },{ "id": 4, "name": "Campeche" },{ "id": 5, "name": "Coahuila" },{ "id": 6, "name": "Colima" },{ "id": 7, "name": "Chiapas" },{ "id": 8, "name": "Chihuahua" },{ "id": 9, "name": "Distrito Federal" },{ "id": 10, "name": "Durango" },{ "id": 11, "name": "Guanajuato" },{ "id": 12, "name": "Guerrero" },{ "id": 13, "name": "Hidalgo" },{ "id": 14, "name": "Jalisco" },{ "id": 15, "name": "México" },{ "id": 16, "name": "Michoacán" },{ "id": 17, "name": "Morelos" },{ "id": 18, "name": "Nayarit" },{ "id": 19, "name": "Nuevo León" },{ "id": 20, "name": "Oaxaca" },{ "id": 21, "name": "Puebla" },{ "id": 22, "name": "Querétaro" },{ "id": 23, "name": "Quintana Roo" },{ "id": 24, "name": "San Luis Potosí" },{ "id": 25, "name": "Sinaloa" },{ "id": 26, "name": "Sonora" },{ "id": 27, "name": "Tabasco" },{ "id": 28, "name": "Tamaulipas" },{ "id": 29, "name": "Tlaxcala" },{ "id": 30, "name": "Veracruz" },{ "id": 31, "name": "Yucatán" },{ "id": 32, "name": "Zacatecas" }]
