import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {  useParams, withRouter } from "react-router-dom"
import { Container } from "reactstrap"


import Post from "../../components/Post"
import { getPostSC } from "../../store/actions"
import { getContactDict } from "../../utils/Tools"

const PostView = (props) => {

    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const post = useSelector(state => state.postSC.current || {});

    const contacts = useSelector(state => state.contact.dictionary);

    
    useEffect(() => {
        dispatch(getPostSC(id,(r)=>{
            //console.log(r)
            getContactDict(r.data.user_id,contacts, dispatch)
        }))
    }, [])
 


  
  return (

    <React.Fragment>
          {loading
                ? ""
                : ""}
            <div className="page-content">
                <div>
                <Container fluid >
        <Post user={post  && post.user_id && contacts && contacts[post.user_id] ? contacts[post.user_id]: {}} post={post}/>
        </Container>
        </div>
        </div>

    </React.Fragment>

  )
}


PostView.propTypes = {
 
}


export default (withRouter(PostView))



