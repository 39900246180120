import React from "react"
import PropTypes from 'prop-types'
import { Redirect, Route, withRouter } from "react-router-dom"
import { useSelector } from "react-redux"



const Div = (props)=><div className={""} style={{marginTop:-70}}>{props.children}</div>

const Authmiddleware = (props) => {
  const { component: Component, layout: Layout, loader: Loader, redirectRoute= "/login", candidato, authRequired, authSuccess, authError, layoutProps, admin, marginNeg, omitLayout, candidatoAdmin  } = props
  const currentUser = useSelector(state => state.profile.user);

  
  if (authRequired){
    console.log("admin && !currentUser.admin", currentUser)

    if (authSuccess){

      console.log("admin && !currentUser.admin", admin && !currentUser.admin)
      console.log("!candidato && currentUser.candidato", !candidato && currentUser.candidato)
      console.log("candidato && !currentUser.candidato", candidato && !currentUser.candidato)
      if ((admin && !currentUser.admin) || (candidato && !currentUser.candidato) || (!candidato && currentUser.candidato) || (candidatoAdmin && !currentUser.candidato_admin )  ){

        return (<Redirect to={redirectRoute + "?rediretTo="+window.location.href} />)
        
      }

      if (currentUser.admin){
        setTimeout(()=>{
          var e = document.getElementById("WACContainer"); 


          e?.classList.add("d-block");
        },1500)

      }
      
      return (<Route 
  
        path={props.path}
        render={props => {

          let LayoutToRender = omitLayout ? Div:Layout
          return (
              <LayoutToRender {...layoutProps}>
                <Component  {...props}/>
              </LayoutToRender>
          )
        }}
      />)
    }

    if (authError){
      return (<Redirect to={redirectRoute + "?rediretTo="+window.location.href} />)
    }
    
    return (<Loader/>)
  }

  return (<Route   path={props.path}
    render={props => {
        return (
            <div style={{marginTop:marginNeg ? -100: 0}}>
            <Layout>
              <Component {...props}/>
            </Layout>
            </div>
        )
      }}
    />)
}



Authmiddleware.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  authRequired: PropTypes.bool,
  loading: PropTypes.bool,
  authSuccess: PropTypes.bool,
  authError: PropTypes.bool,
}
  

export default withRouter(Authmiddleware)

