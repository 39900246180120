import React from "react"
import { FormGroup, Label } from "reactstrap"

import PropTypes from 'prop-types';

import BaseField from "./BaseField"
import {default as SelectReact} from "react-select"

class Select extends BaseField {

  constructor(props) {
      super(props);
      this.state = {
          value: null
      }

      
     
    }

    getValue = () =>{
        //console.log(this.state.value )

        if (this.props.multiple){
          let values  = [];
          if (this.state.value){
            this.state.value.map((op)=> {
              values.push(op.value)
              return null
            })
          }

          return values
        }else{
          return this.state.value ?  this.state.value.value : undefined
        }

    }

    setValue = (value) => {

      if(this.props.multiple){

        if (value){
          let values  = []
          this.props.options.map((op)=> {
            if (value.includes(op.value)){
              values.push(op)
            }
            return null
          })
          //console.log(values)
          this.setState({value:values})
        }else{
          this.setState({value:[]})
        }

      }else{
        this.setState({value:null})
        this.props.options.map((op)=> {
          if (value === op.value){
            
            if (this.props.onChange){
              this.props.onChange(op.value)
            }

            this.setState({value:op})
          }
          return null
        })
      }

    }


render(){
    const {  label, options } = this.props;
    return (
        <FormGroup>
            <Label>{label}{this.props.required ? <span className={"invalid-feedback"} style={{display:"inline", fontSize:14}}> *</span>:null}</Label>
            <div>
            
                <SelectReact
                  {...this.props}
                  value={this.state.value}
                  isMulti={this.props.multiple}
                  onChange={(selection)=>{
                    if(!this.props.multiple){
                      this.setValue(selection.value)
                    }else{
                      //console.log(selection)
                      let values  = []
                      if (selection){
                        selection.map((op)=> {
                            values.push(op.value)
                            return null
                        })
                      }
                      this.setValue(values)

                    }
                  }}
                  options={options.map((o)=>{return {label: o.label || o.value, value:o.value }; })}
                  classNamePrefix="select2-selection"
                />
            </div>
            {this.renderErrorMessage()}
      </FormGroup>
    )}
}

Select.propTypes = {
    options: PropTypes.array.isRequired,  
    multiple: PropTypes.bool
}


export default Select