
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import { Container, Card, CardBody} from "reactstrap"
import {  Button } from "../../components"
//import { getConvocatorias, getSolicitud, getSolicituds, postSolicitud } from "../../store/actions"


const LiderazgoPositivo = (props) => {

  const [loading, setLoading] = useState(false)

  //const convocatorias = useSelector(state => state.convocatoria.data);
  //const solicitudes = useSelector(state => state.solicitud.data);
  //const modal = useRef();
  const dispatch = useDispatch();

  useEffect(()=>{
    //dispatch(getConvocatorias())
    //dispatch(getSolicituds())
  },[dispatch])

  /*const createSolicitud = (values, validData) => {
    console.log(values, validData)
    
    if (validData){
      setLoading(true)
      dispatch(postSolicitud(values, 
        ()=>{
          setLoading(false)
          dispatch(getSolicituds())
          modal.current.toggle()
          props.history.push("/solicitud")
        }))
    }
  }*/
  
  return (
    <React.Fragment>
      {loading?"":""}
      <div className="page-content mt-6">
        <Container fluid>
          <h2><b>Liderazgo Positivo y Transformación Personal</b></h2>
          <Card className={"box-shadow"}>
            
            <CardBody style={{padding:0}}>

              <div style={{display:"flex", alignItems:"center"}}>
                <div className={"d-none d-lg-block"}  style={{flex:1}}>
                  <img  src={"/liderazgo-positivo.png"} style={{width:"100%", borderRadius:5,  borderBottomRightRadius:0, borderTopRightRadius:0, objectFit:"cover"}}/>
                </div>

                <div style={{flex:1.3, fontSize:18, padding:50}}>

                <div className={"d-lg-none"}  style={{flex:1}}>
                  <img  src={"/liderazgo-positivo.png"} style={{width:"100%", borderRadius:5, objectFit:"cover", marginBottom:10}}/>
                </div>

                  <b>Curso de Inducción para alumnos de nuevo ingreso</b> <br/> <br/>

                  <div style={{display:"flex", alignItems:"center"}}>

                    <div style={{display:"flex", alignItems:"center", flex:1}}>
                      <div>
                        <img src={"/paloma.jpeg"} style={{width:"100%", borderRadius:5, maxWidth:50}}/>
                      </div>
                      <div style={{flex:1, fontSize:14, padding:10}}>
                            Encaminado al florecimiento humano
                      </div>
                    </div>

                    <div style={{display:"flex", alignItems:"center", flex:1}}>
                      <div>
                        <img src={"/paloma.jpeg"} style={{width:"100%", borderRadius:5, maxWidth:50}}/>
                      </div>
                      <div style={{flex:1, fontSize:14, padding:10}}>
                          Autogestión del conocimiento
                      </div>
                    </div>

                  </div>

                  <div style={{display:"flex", alignItems:"center"}}>

                    <div style={{display:"flex", alignItems:"center", flex:1}}>
                      <div>
                        <img src={"/paloma.jpeg"} style={{width:"100%", borderRadius:5, maxWidth:50}}/>
                      </div>
                      <div style={{flex:1, fontSize:14, padding:10}}>
                          Profesores tutores con especialidad en psicología positiva y desarrollo humano
                      </div>
                    </div>

                    <div style={{display:"flex", alignItems:"center", flex:1}}>
                      <div>
                        <img src={"/paloma.jpeg"} style={{width:"100%", borderRadius:5, maxWidth:50}}/>
                      </div>
                      <div style={{flex:1, fontSize:14, padding:10}}>
                          Sesiones virtuales de aprendizaje y reflexión grupal
                      </div>
                    </div>

                    </div>
                  
                  <div style={{display:"flex", alignItems:"center"}}>

                    <div style={{display:"flex", alignItems:"center", flex:1}}>
                      <div>
                        <img src={"/paloma.jpeg"} style={{width:"100%", borderRadius:5, maxWidth:50}}/>
                      </div>
                      <div style={{flex:1, fontSize:14, padding:10}}>
                        Foros de discusión para compartir vivencias
                      </div>
                    </div>

                    <div style={{display:"flex", alignItems:"center", flex:1}}>
                      <div>
                        <img src={"/paloma.jpeg"} style={{width:"100%", borderRadius:5, maxWidth:50}}/>
                      </div>
                      <div style={{flex:1, fontSize:14, padding:10}}>
                      Contenido disponible 24/7
                      </div>
                    </div>

                    </div>
                  
                    <Button href={"https://experiencia21.tec.mx/"} target={"blank"} color={"primary"} block style={{padding:12, fontSize:17, marginTop:10}}>Accede al curso en CANVAS</Button>
                </div>
          
              </div>
            
              
            </CardBody>
          </Card>
            
        </Container>
      </div>
    </React.Fragment>
  )
}


LiderazgoPositivo.propTypes = {
 
}


export default (withRouter(LiderazgoPositivo))



