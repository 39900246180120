
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { Row, Col, CardBody, Card, Container } from "reactstrap"
import { loginUser } from "../../store/actions"

// Redux
//import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { APP_NAME } from '../../utils/Configs'

import { useTranslation } from "react-i18next"
//import logo_small from "../../assets/images/logo-small-dark.png"
import logo from "../../assets/images/logo-dark.png"

import { Form, Input, Button} from "../../components"
import { connect } from "react-redux"
import "firebase/database";
import "firebase/auth";

const ExatecLogin = props => {
  const {t} = useTranslation()

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [redirect, setRedirect] = useState(false)


  useEffect(()=>{
    const queryString = window.location.search;
    ////console.log(queryString);
    const urlParams = new URLSearchParams(queryString);

    //console.log(redirect)
    var hash = window.location.hash.substr(1) || "";
    var red = urlParams.get('rediretTo')
    setRedirect((red? red:"") + (hash ? "#" + hash : "") )

  }, [])


  const handleSubmit = (values, valid) => {
   // //console.log("valid")
   // //console.log(values, valid)
   // //console.log("valid")

    setMessage("")
    setLoading(true)
    if (valid){
      props.loginUser(values, 
        (response)=>{
          console.log(response)
          setLoading(false)
          if (redirect && redirect != "null"){
            window.location.href = redirect
          }else{
            window.location.href = "/home"

          }

        },
        (messsage)=>{
     
          setLoading(false)
          setMessage(messsage)
   
        },
        ()=>{
          handleSubmit(values, valid)
        },true
      )
    }
    //console.log("valid")
  }

  return (
    <React.Fragment>

<div style={{backgroundImage:`url(${"/back.jpeg"})`, backgroundPosition:"center center", backgroundRepeat:"no-repeat", minHeight:"100vh" }}>
<div style={{backgroundColor:"rgba(26,49,146,0.5)",minHeight:"100vh", display:"flex", alignItems:"center" }}>
  
   

      <div className="account-pages  pt-5" style={{flex:1}}>
        <Container >
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card >
                <div className="bg-primary p-4">
                <Row className="justify-content-center">
                  <img src={logo} alt="" height={50} />

                  </Row>
                 
                </div>
                <CardBody className="pt-3">

                <Row>
                    <Col className="col-12">
                      <div className="text-primary">
                        <h5 className="text-primary">{t("Welcome")} a mi casa LDM</h5>
                        <p>{t("Sign-in-to")}. Utiliza tu matrícula y PIN EXATEC LDM. Si no tienes tu PIN puedes solicitarlo al correo: <a href={"mailto:graciela.arroyo@tec.mx"}>graciela.arroyo@tec.mx</a> </p>


                      </div>
                     
                    </Col>
                   
                  </Row>
                  
                  <div className="p-2">
                    <Form onSubmit={handleSubmit}>
                      <Input name={"matricula"} label={t("Matricula")}  required/>
                      <Input name={"pin"} label={t("PIN Exatec")} type={"password"} required/>
                      {message?<div className={"invalid-feedback"} style={{display:"block"}}>{message}</div>:null}
                      <Button  className={"  mt-2"} disabled={loading} btnClassName={"w-100"} color={"primary"} block text={loading ? <span><i className="fas fa-spinner bx-spin"/>{" "}Cargando...</span> : t("Login")}  type="submit" value="Submit"/>
                    </Form>
                    
                  </div>
                </CardBody>
              </Card>


              <div className="mt-5 text-center" style={{color:"#fff"}}>
   
                <p>
                  © {new Date().getFullYear()} {APP_NAME}.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({ 
      loading: state.login.loading,
      error: state.login.error,
      message: state.login.message,
})

export default withRouter(
  connect(mapStateToProps, { loginUser })(ExatecLogin)
)

ExatecLogin.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func
}
