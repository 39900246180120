import React from "react"
import { FormGroup, Input, InputGroup, Label } from "reactstrap"
import PropTypes from 'prop-types';

import BaseField from "./BaseField"

class TextArea extends BaseField {

    constructor(props) {
        super(props);
        this.state = {
            value: ""
        }
       
      }

      getValue = () =>{
          
          return this.state.value ? this.state.value  :""
      }

      setValue = (value) => {
        this.setState({value:value})
      }


    render(){
        const {  label } = this.props;

        
        return (
            <FormGroup>
                <Label>{label}{this.props.required ? <span className={"invalid-feedback"} style={{display:"inline", fontSize:14}}> *</span>:null}</Label>
                <InputGroup>
                <Input
                    type="textarea"
                    row={5}
                    maxLength={this.props.maxLength}
                    name="defaultconfig"
                    multiple
                    value={this.state.value }
                    onChange={e => {
                      //threshholdDefault(e)
                      this.setValue(e.target.value)
                    }}
                    id="defaultconfig"
                  />
                </InputGroup>
                {this.renderErrorMessage()}
        </FormGroup>
        
        )
    }
}

TextArea.propTypes =  Object.assign({}, BaseField.propTypes,   {
  maxLength: PropTypes.number,
});

TextArea.defaultProps = Object.assign({}, BaseField.defaultProps, {
  maxLength:255
});


export default TextArea