import { PROFILE_ERROR, PROFILE_SUCCESS, GET_PROFILE } from "./actionTypes"

const initialState = {
  error: false,
  success: false,
  loading:false,
  user:{}
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, error: false , success: true, user: action.payload}
      break
    case PROFILE_ERROR:
      state = { ...state, error: true , success: false,  user:{}}
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
