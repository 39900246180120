import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { withRouter } from "react-router-dom"

import { getAllForms, getAllExpedientes, getContact, setFormsNames } from "../../../store/actions"
import { LDMDB, Loader } from "../../../utils/Configs"
import StackGrid from "react-stack-grid";

import FormCard from "../../../components/FormCard"

const ExpedientesView = (props) => {

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [data, setData] = useState([])

  const listInfo = useSelector(state => state.form.listExpData);
  const formNames = useSelector(state => state.form.formNames);

  const contacts = useSelector(state => state.contact.dictionary);


  const dispatch = useDispatch();

  const getContactDict = (id) =>{
    
    if (!contacts[id] ){
      dispatch(getContact(id,(data)=>{
        ////console.log(data)
      }))
      return false
    }

    return contacts[id]

  }


  useEffect(()=>{

    if (!listInfo){
        setLoading(true)
        dispatch(getAllExpedientes(1,10,(data)=>{
            //console.log(data)
            setLoading(false)
          },()=>{}))
    }
  },[])

  const onChangePage = (page, nextPage) => {
      //console.log(page,nextPage)
    setLoading(true)
    dispatch(getAllForms("",nextPage,10,(data)=>{
        //console.log(data)
        setLoading(false)
    },()=>{}))
  }

  useEffect(()=>{
    if (listInfo && listInfo.data ){


      listInfo.data.map((i)=>{
        getContactDict(i.user_id)
        LDMDB.database().ref("expedientes/"+i.form_id+"/title/").once('value').then((snapshot)=>{
          if (snapshot.exists()) {
            //console.log(snapshot.val())
            const nf = formNames
            nf[i.form_id] = snapshot.val()
            dispatch(setFormsNames(nf))

          } else {
            //console.log(snapshot)
          }
        });
  
      })

      setData(listInfo.data)
    }

  },[listInfo])


  //console.log(listInfo)
  return (
    <React.Fragment>
      {loading?<Loader message={message}/> :""}

      
      <StackGrid  gutterWidth={10} gutterHeight={10} columnWidth={"25%"}>
          {data.map((item, key) =>
                <FormCard expediente={true} key={key} item={item}/>)}
             </StackGrid>
    </React.Fragment>
  )
}


ExpedientesView.propTypes = {
 
}


export default (withRouter(ExpedientesView))



