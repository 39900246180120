
import {
  GET_ALL_POST_SC_SUCCESS,
  GET_POST_SC_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  all: null,
  current: null
}

const postSC = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_ALL_POST_SC_SUCCESS:
      return {
        ...state,
        all: action.payload,
      }

    case GET_POST_SC_SUCCESS:
      return {
        ...state,
        current: action.payload,
      }

    default:
      return state
  }
}

export default postSC 

