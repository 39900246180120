
import classNames from "classnames"
import React from "react"
import { ReactLiquid } from "react-liquid"

import iconSet from "../../assets/fonts/selection.json";
import IcomoonReact from "icomoon-react";

import  BaseField from "./BaseField"



class RadioButton extends BaseField {

    onChange = (value) =>{

        console.log(value)

        if (value == 0 || (value != null && value != "")){
            this.setValue(value)
            this.props.onChange && this.props.onChange(value)
        }else{
            this.setValue(null)
            this.props.onChange && this.props.onChange(null)
        }

       

    }
   

    render(){
        const { options, name, className, label, rowClass, cols="3", radioType="Button", buttonColor="primary" } = this.props
        const { error } = this.state

        let radioClassType = (radioType == "Button" ? "btn-check" : "form-check-input")
        let labelRadioClassType = (radioType == "Button" ? `btn  w-100` : "form-check-label")


        return (
            <div className={`${className}`}>
               <div>
               {label?<label htmlFor={name} className="form-label mb-0"><ReactLiquid template={label} data={{}} html={true} /></label>:null}
               </div>
               
                
                <div className={`${radioType == "Button" ? `row g-1 row-cols-${cols}` :""}  ${rowClass}`}>
                    {options.map((option, index)=>
                        <div key={index} className={`${radioType == "Button" ? "col" :"form-check "}`}>
                            <input onClick={()=>this.onChange(option.value)} className={`${radioClassType}`} type="radio" hidden name={name} autoComplete="off" checked={this.state.value == option.value} id={`${name}${index}`}/>
                            <label className={`${labelRadioClassType} btn-${(this.state.value == option.value ? buttonColor : "outline-"+buttonColor)} ${option.color ? " btn-"+  (this.state.value == option.value ? option.color : "outline-"+option.color ) :""}`} htmlFor={`${name}${index}`}>
                                {option.label  ? option.label :
                                    option.html ? <ReactLiquid template={option.html } data={{}} html={true} />: 
                                    option.icomoon ?  <IcomoonReact className={"img-fluid"} iconSet={iconSet} color={this.state.value == option.value && option.icomoon.checkColor ?  option.icomoon.checkColor : option.icomoon.color || "#444"} size={option.icomoon.size} icon={option.icomoon.icon} />:
                                    option.value }


                            </label>
                        </div>
                    )}
                </div>

                <div className={`invalid-feedback ${classNames(error?"d-block":"")}`}>
                    {error}
                </div>
            </div>
        )
    }
}


export default RadioButton