
import React, { useEffect, useRef, useState } from "react"


import {  Link, useHistory, withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import Form from "../../components/Form/Form"
import MaskInput from "../../components/Fields2/MaskInput"
import Button from "../../components/Buttons/Button"
import Select from "../../components/Fields2/Select"
import { COUNTRIES } from "../../utils/Tools"
import { API } from "../../utils/backend/api_helper"
import swal from "sweetalert"
import { Loader } from "../../utils/Configs"
import Header from "./Header"



const WhatsApp = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);
    const [candidato, setCandidato] = useState({})
    const history = useHistory()
    useEffect(()=>{
      setLoading(true)
      API.get(
          "/candidato",
          (resp)=>{
              setCandidato(resp.data)
              setLoading(false)

          },
          (resp)=>{
              swal({
                  title:"¡Error!",
                  text:resp,
                  icon:"error"
              })
              setLoading(false)

          }
      )
  },[])


  
    const submit = (values, valid) =>{
      console.log(values, valid)

      if (valid){
        setLoading(true)
        API.post(
          "/candidato/validate-phone",
          values,
          (response)=>{

            //window.location.href = "/candidatos/verification?phone=" + values.phone_code+values.phone
            setLoading(false)
            history.push( "/candidatos/verification?phone=" + values.phone_code+values.phone)

          },
          (response)=>{
            swal({
              title:"¡Error!",
              message:response,
              icon:"error"
            })
            setLoading(false)

          })
  
      }



    }


  return (
    <React.Fragment >

      {loading ? <Loader message={"Enviando..."} />:null}
      
      <div className="vh-100">
        <Header/>

        <div className="container mt-4  h-100 ">
            <div className="card shadow-sm">
            <div className="card-body p-0">

              <div className="rounded-top p-0">
              <h3 className="fw-bold bg-primary  rounded-top text-white p-4">Whatsapp</h3>
         
                <p className=" fs-5 fs-md-4 p-4">
                Con el objetivo de mantener un canal de comunicación mas ágil te invitamos a registrar y confirmar tu número de whatsapp. 
                <div className="text-end mt-3">
                Contacto para dudas: Jorge Méndez <a href="mailto:jorge@tec.mx">jorge@tec.mx</a><br/>
                </div>
                </p>
                <hr/>
              </div>
              <div className={"p-4"}>
                  {candidato.WA== "COMPLETA"  ? <div className="text-center">

                    <h3 className="mb-5"><i className="fas fs-1 text-success fa-check-circle"/><br/>¡Gracias!<br/>Esta sección ya esta completa.</h3>
                    
                    <div className="d-flex justify-content-center">
                    <Link to="/candidatos/home" className={"btn btn-outline-primary"}>Regresar</Link>

                    <Button onClick={()=>setCandidato({})} className={"ms-2"} color={"primary"}>Actualizar número de WhatsApp</Button>
                    </div>
                  </div>: <Form  initialValues={{phone_code:"+52"}} onSubmit={submit}>
                    <div className="row">
                    <div className={"col-12 col-sm-2"}>
                      <Select required name={"phone_code"} label={"País"} options={COUNTRIES.map((c)=>{return { label:c.name_es + " (" + c.dial_code + ")" , value:c.dial_code }})}></Select>

                      </div>
                      <div className={"col-12  col-sm-10"}>
                      <MaskInput required name={"phone"}  mask={"9999999999"} label={"Número de WhatsApp a 10 dígitos"}/>

                      </div>

                    </div>
                    <div className="mt-2 d-flex justify-content-end">
                      <Link to="/candidatos/home" className={"btn btn-outline-primary me-2"}>Regresar</Link>

                      <Button  type={"submit"} color={"primary fw-bold"}>Continuar <i className="ms-1 border-start ps-1 fas fa-arrow-right"/></Button>

                    </div>
                  </Form>}

                  </div>
                </div>
            </div>
        </div>
        <div style={{height:50}} />
        </div>
    </React.Fragment>
  )
}


WhatsApp.propTypes = {
 
}


export default (withRouter(WhatsApp))



