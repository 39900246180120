import React, {useRef, useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import {Redirect, withRouter} from "react-router-dom"
import {
    Badge,
    Label
} from "reactstrap"
import swal from 'sweetalert';

//import { putPrfProfile, putSolicitud } from "../../store/actions"
import {
    CAMPUS,
    CARRERAS,
    INDUSTRIAS,
    DECILES,
    linkify,
    COUNTRIES,
    ESTADOS
} from "../../utils/Tools"

import {LDMDB, Loader} from "../../utils/Configs"
import Input from "../../components/Fields2/Input"
import MaskInput from "../../components/Fields2/MaskInput"
import TextArea from "../../components/Fields2/TextArea"
import Select from "../../components/Fields2/Select"
import CheckboxGroup from "../../components/Fields2/CheckboxGroup"
import Upload from "../../components/Fields2/Upload"

import { Button, FormWizard } from "../../components"
import Form from "../../components/Form2/Form"
import "firebase/database";
import "firebase/auth";
import RadioGroup from "../../components/Fields2/RadioGroup"
import { putFiles, putPrfProfile } from "../../store/actions"
import { ReactLiquid } from "react-liquid";
import DivRender from "../../components/Fields/Div";
import RadioButton from "../../components/Fields2/RadioButton";

let currentFile = ""
let fileListener = null
let ansListener = null

let updateDBFields = []
let omitSave = []
let filesToUpload = []


let getFullProfile = true

const Div = (props)=>{
    return (
        <div {...props}>
             <ReactLiquid template={linkify(props.text?props.text:"")} data={props.data ? props.data :{}} html />
        </div>
    )
}

const Image = (props)=>{
    return (
        <div>
            <img {...props}/>
        </div>
    )
}
let lControlFields = {}
let timer = null
function Solicitud(props){

    const userR = useSelector(state => state.profile.user);

    let user = props.user ?  props.user : userR;
    let forms = {}
    //const solicitud = useSelector(state => state.solicitud.item);
    const solicitud = {}
    const fomrWizard = useRef()
   
    let baseFile = props.formID ? "forms/":"expedientes/"

    const dispatch = useDispatch();
    const [loading,
        setLoading] = useState(false);
    const [configs,
        setConfigs] = useState({});
    const [file,
        setFile] = useState({});
    const [controlFields,
            setControlFields] = useState({});
    const [fullUser,
            setFullUser] = useState({});
    const [sections, setSections] = useState(null);
    const [fileCompleted,
        setFileCompleted] = useState(false);

    const [validation,
            setValidation] = useState(false);
    const [
      answers,
      setAnswersX] = useState({});
      const [
        ready,
        setReady] = useState(false);
        const [
            status,
            setStatus] = useState("OPEN");

    const setAnswers = (ans) =>{
        //////console.log(ans)
        setLoading(ans)

        setControlFields(JSON.parse(JSON.stringify(ans)))
        lControlFields = JSON.parse(JSON.stringify(ans))
        console.log(lControlFields)
        setTimeout(function () {
       

            setAnswersX(JSON.parse(JSON.stringify(ans)))
            console.log(ans)

            setLoading(false)

   

        }, 300);

    }
  


 

    useEffect(() => {
        let listener = ()=>{}
  
        forms = {}
        ////////////console.log("mounting")
        currentFile = "" 
        //setConfigs({})

        if (props.formID){
            //currentFile = props.formID
            setConfigs({current_file:props.formID})
        }else{
            setLoading(true)
            var query = LDMDB
                .database()
                .ref("configs/");
            listener = query.on("value", (snapshot) => {
                ////////////console.log(snapshot)
                if (snapshot) {
                    let data = {}
                    
                    snapshot.forEach(function (d) {
                        if (d.key) {
                            ////////////console.log("The " + d.key + " data is " + d.val());
                            data[d.key] = d.val()
                        }
                    });
                    ////////////console.log(data)
                    setConfigs({...data,current_file: data["current_file"] + (user.student.exatec ? "-EXATEC":"")})
                    setLoading(false)

                }
            });

            }
        return listener
    }, [])

    useEffect(() => {
        ////////////console.log(configs["current_file"])

        if (configs["current_file"] && currentFile != configs["current_file"]) {
            //////////console.log(fileListener)
            if (fileListener) {
                ////////////console.log("shutdown")
                LDMDB
                    .database()
                    .ref(baseFile+ currentFile + "/")
                    .off('value', fileListener)
            }

            currentFile = configs["current_file"]

            setLoading(true)
            var query = LDMDB
                .database()
                .ref(baseFile + currentFile + "/");

            fileListener = query.on("value", (snapshot) => {
                
                if (snapshot) {
                    let file = {}
                    snapshot.forEach(function (d) {
                        if (d.key) {
                            //////////console.log("The " + d.key + " data is " + d.val());
                            file[d.key] = d.val()
                        }
                    });
                    //////////console.log(file)


                    setFile(file)

                    if (file && file["status"]){
                        setStatus(file["status"])
                    }

                    if (file && file["validated-section"]){
                        let field = file["validated-section"]["validation-source"];
    

                            if (field.datasource && field.datasource == "firebase"){
                                
                            
                
                                    LDMDB.database().ref(field.path + user.matricula + "/").once('value').then((snapshot)=>{
                                        if (snapshot.exists()) {
                                            //////////console.log(snapshot.val());
                                            setValidation(snapshot.val())
                
                                        } else {
                                            //////////console.log("No data available");
                                            setValidation(false)
                                        }
                                
                                    });
                                }
                            
                    }
                            
                     
              
                    setLoading(false)
                    
                }
            });

            let ansListenerReturn = listenAswnsers(currentFile)


            return  ()=> {
                fileListener()
                ansListenerReturn()
            }


        }

    }, [configs])


   
  const listenAswnsers = (fileAns) => {
        ////////////console.log(configs["current_file"])
    
            
        ////////console.log(user.matricula )
            if (ansListener) {
                //////////console.log("shutdown")
                LDMDB
                    .database()
                    .ref("answers/" + fileAns + "/" + user.matricula + "/")
                    .off('value', ansListener)
            }
            
            var query = LDMDB
                .database()
                .ref("answers/" + fileAns + "/" + user.matricula  + "/");


            //////console.log("answers/" + fileAns + "/" + user.matricula  + "/");
            ansListener = query.on("value", (snapshot) => {
                //////console.log(snapshot)
                if (snapshot) {
                    let answersCo = Object.assign({}, answers|| {});
                    //////console.log(snapshot);


                    snapshot.forEach(function (d) {
                        if (d.key) {

                            if (!answersCo[d.key]){
                                answersCo[d.key] = {}
                            }
                            ////////console.log("The " + d.key + " data is " + d.val());
                            //////////console.log("Data is " + JSON.stringify(d.val()));
                            answersCo[d.key] = {...answersCo[d.key], ...d.val()}
                        }
                    });

                    console.log(answersCo);
                    setAnswers(answersCo);
                 
                  
                }
            },(a)=>{  //////console.log(a);
            });
            
            return ansListener

    }

    useEffect(()=>{
        let completed = file && file.sections ? true:false;

        (file.sections || []).filter(a=>!a.hidden && !a.validate_admin).map((section) => {
            (section.forms || []).filter(a=>{
                ////////console.log(a)

           

                if (a.validate_ldm){
                    return !a.hidden && user.is_ldm
                }

                if (a.validate_no_ldm){
                    return !a.hidden && !user.is_ldm
                }

               
                return !a.hidden
            }).map((form, index) => {
                //////////console.log(form.name, answers[form.name] ? true:false)
                completed =  completed && answers[form.name]

            })
        })

        //////////console.log("checking...", file)
        setFileCompleted(completed)

    }, [file, answers])

    useEffect(()=>{

 
        if (fileCompleted && fomrWizard && fomrWizard.current ){
       
                fomrWizard.current.goToLastTab()

          
        }

    },[fileCompleted])

    


    /*const send = () => {
    //////////console.log(userData)
    //////////console.log(applicationForm)
    applicationForm.q7 = applicationForm.q7_arr.join(", ")

    let data = {}
    data.student = userData
    data.phone = userData.phone
    data.birthdate = userData.birthdate
    data.gender = userData.gender
    setLoading(true)
    //////////console.log(user.id)
    if (!loading){
    dispatch(
      putPrfProfile(user.id,data,()=>{
        dispatch(putSolicitud(solicitud.id,applicationForm,
          ()=>{
            setLoading(false)
            props.history.push("/home")
        },()=>{
          setLoading(false)
        }))
      },()=>{
        setLoading(false)
      })
    )
    }
    //////////console.log("dddd")
  }*/

    if (solicitud.status == "EN REVISIÓN") {
        return <Redirect to="/home"/>
    }

    const saveForm = (values, valid, formName, tab, title, form={}) =>{

      if (valid){
          setLoading(true)

        let files = {}
        let hasFile = false
        filesToUpload.map(f=>{
            ////console.log(f.name, values[f.name] && values[f.name].fileValue)
            if (values[f.name] && values[f.name].fileValue){
                ////console.log(values[f.name].fileValue)
       
                hasFile = true
                files["file"] = values[f.name].fileValue
                files["form_id"] = props.formID 
                files["form"] = formName 
                files["name"] = f.name
            }else if (values[f.name] && values[f.name].value){
                values[f.name] = values[f.name].value;
               
            }
        })

        let dataDB = {}
         updateDBFields.map(f=>{
            dataDB[f] = values[f]
         })


         omitSave.map(f=>{
            values[f] = null
         })

        var query = LDMDB
                .database()
                .ref("answers/"+configs["current_file"] + "/" + user.matricula  +"/"+formName+"/created_at");

                
        values["created_at"] = Date.now();

        values["updated_at"] = Date.now();


        //console.log(user)


        if (hasFile){

            dispatch(putFiles(user.id,files,(resp)=>{
                values[files.name] = resp.data.url

                dispatch(putPrfProfile(user.id,dataDB,()=>{

                    LDMDB.database().ref("answers/"+configs["current_file"] + "/" + user.matricula  +"/"+formName+"/" ).set(values,()=>{
                        setLoading(false)
                        if (!form.omit_confirmation)
                            swal("¡Listo!", "Información de \"" + title+ "\" actualizada", "success", );
                        fomrWizard.current.toggleTab(tab+2)
            
                    });
                },()=>{
                    setLoading(false)
                    swal("Oops!", "Algo salio mal", "danger", );
        
                }))
            },()=>{
                setLoading(false)
                swal("Oops!", "Algo salio mal", "danger", );

            }))

        }else{
            dispatch(putPrfProfile(user.id,dataDB,()=>{

                LDMDB.database().ref("answers/"+configs["current_file"] + "/" + user.matricula  +"/"+formName+"/" ).set(values,()=>{
                    setLoading(false)
                    if (!form.omit_confirmation)
                        swal("¡Listo!", "Información de \"" + title+ "\" actualizada", "success", );
                    fomrWizard.current.toggleTab(tab+2)
                });
            },()=>{
                setLoading(false)
                swal("Oops!", "Algo salio mal", "danger", );
    
            }))
    
        }
      }
  }

    const fieldSelect = (field, idx, form) => {
        let Component = React.Fragment
        let props = {}
       
        if (field.props) {
          props = field.props
        }
        switch (field.type) {
            case "FileUpload":
                Component = Upload
                filesToUpload.push({name:field.name, bucket:field.s3Bucket})
                break;
            case "Image":
                Component = Image
                break;
            case "Input":
                Component = Input
                break;
            case "Div":
                Component = Div
                props.text = field.text ? field.text:""
                props.text = field.html ? field.html:  props.text

                props.data = {user:user}


                break;
            case "DivRender":
                Component = DivRender
     
                props.html = field.html ? field.html:  props.text
                props.data = {user:user}
                props.datasources = field.datasources  ? field.datasources :[]



                break;
                
            case "MaskInput":
                Component = MaskInput
                break;
            case "TextArea":
                Component = TextArea
                break;
            case "Label":
                Component = ({label=""}) =>{
                    return <Label {...props}>{label}</Label>
                } 
                
                break;
            case "Select":
                Component = Select
                if (field.options) {
                    props.options = field
                        .options
                        .map((op) => {
                            return {
                                label: op.label
                                    ? op.label
                                    : op.value,
                                value: op.value
                            }
                        })
                }else{
                    props.options = [{
                        label: "Sí",
                        value: "Sí"
                    },{
                        label: "No",
                        value: "No"
                    }]
                }

                if (field.constant_set) {
                    switch (field.constant_set) {
                        case "campus":
                            props.options = CAMPUS
                                    .map((op) => {
                                        return {
                                            label: op.campus,
                                            value: op.id
                                        }
                                    })
                            break;
                    
                        case "carrera":
                            props.options = CARRERAS
                                .map((op) => {
                                    return {
                                        label: op.carrera,
                                        value: op.id
                                    }
                                })
                            break;
                        case "industrias":
                            props.options = INDUSTRIAS
                                .map((op) => {
                                    return {
                                        label: op,
                                        value: op
                                    }
                                })
                            break;
                        case "deciles":
                            props.options = DECILES
                                .map((op) => {
                                    return {
                                        label: op,
                                        value: op
                                    }
                                })
                            break;
                        case "phone_countries":
                            props.options = COUNTRIES
                                .map((op) => {
                                    return {
                                        label: `${op.code} (${op.dial_code})`,
                                        value: op.dial_code
                                    }
                                })
                            break;
                        case "states":
                            props.options = ESTADOS
                                .map((op) => {
                                    return {
                                        label: `${op.name}`,
                                        value: op.name
                                    }
                                })
                            break;
                    }

                }
                break;
            case "Checkbox":
                Component = CheckboxGroup


                if (field.options) {
                    props.options = field
                        .options
                        .map((op) => {
                            return {
                                label: op.label
                                    ? op.label
                                    : op.value,
                                value: op.value
                            }
                        })
                }

                if (field.constant_set) {
                    switch (field.constant_set) {
                        case "campus":
                            props.options = CAMPUS
                                    .map((op) => {
                                        return {
                                            label: op.campus,
                                            value: op.id
                                        }
                                    })
                            break;
                    
                        case "carrera":
                            props.options = CARRERAS
                                .map((op) => {
                                    return {
                                        label: op.carrera,
                                        value: op.id
                                    }
                                })
                            break;
                    }

                }

                break;
            case "Radio":
                Component = RadioGroup
                if (field.options){
                    props.options = field.options.map((op)=>{return {label:op.label ? op.label  : op.value , value:op.value}})
                }else{
                    props.options = [{
                        label: "Sí",
                        value: "Sí"
                    },{
                        label: "No",
                        value: "No"
                    }]
                }

                if (field.constant_set) {
                    switch (field.constant_set) {
                        case "campus":
                            props.options = CAMPUS
                                    .map((op) => {
                                        return {
                                            label: op.campus,
                                            value: op.id
                                        }
                                    })
                            break;
                    
                        case "carrera":
                            props.options = CARRERAS
                                .map((op) => {
                                    return {
                                        label: op.carrera,
                                        value: op.id
                                    }
                                })
                            break;
                    }

                }
                break;
            case "RadioButton":
                Component = RadioButton
                if (field.options){
                    props.options = field.options.map((op)=>{return {label:op.label , value:op.value, 
                        color: op.color,html: op.html,icomoon: op.icomoon}})
                }else{
                    props.options = [{
                        label: "Sí",
                        value: "Sí"
                    },{
                        label: "No",
                        value: "No"
                    }]
                }

                if (field.constant_set) {
                    switch (field.constant_set) {
                        case "campus":
                            props.options = CAMPUS
                                    .map((op) => {
                                        return {
                                            label: op.campus,
                                            value: op.id
                                        }
                                    })
                            break;
                    
                        case "carrera":
                            props.options = CARRERAS
                                .map((op) => {
                                    return {
                                        label: op.carrera,
                                        value: op.id
                                    }
                                })
                            break;
                    }

                }
                break;
            default:
                
                break;
        }

        if (field.preloaded) {
          //////console.log(field.name)
          //////console.log(user[field.name])
          //////console.log(user.student[field.name])

          if (field.datasource){

            if (field.datasource.source == "firebase"){
                
                    let answersCo = Object.assign({}, answers|| {});

                    if (!answersCo[form.name]){
                        answersCo[form.name] = {}

                    }
                    if (!answersCo[form.name][field.name]){

                    LDMDB.database().ref(field.datasource.path + user.matricula +"/"+field.name+"/").once('value').then((snapshot)=>{
                        if (snapshot.exists()) {
                            //////////console.log(snapshot.val());
                            let answersCo = Object.assign({}, answers|| {});

                            if (!answersCo[form.name]){
                                answersCo[form.name] = {}
        
                            }

                           
                            answersCo[form.name][field.name] = snapshot.val()
                            //////////console.log(answersCo)
                            
                            setAnswers(answersCo)

                        } else {
                            //////////console.log("No data available");
                            let answersCo = Object.assign({}, answers|| {});

                            if (!answersCo[form.name]){
                                answersCo[form.name] = {}
        
                            }
                           
                            answersCo[form.name][field.name] = "No data available"
                      
                            
                            setAnswers(answersCo)
                        }
                
                    });
                }
            
            }
            
          }else{            
            let find  = false
            //////console.log(user)

            if (user[field.name]){
                props.default = user[field.name]
                find = true
            }
    
            if (user.student && user.student[field.name]){
                props.default = user.student[field.name]
                find = true
            }

        
        }

        }

        if (field.hidden) {

            return null
        }
        let display = true
        if (field.conditions) {
            let validate = true


            field.conditions.map((condition, index)=>{

                let evalValu = controlFields[form.name] && controlFields[form.name][condition.name] == condition.value
                

                switch (condition.operation) {
                    case "OR":
                        validate = validate || controlFields[form.name] && controlFields[form.name][condition.name] == condition.value

                        break;
                    case "includes":
                        validate = validate && controlFields[form.name] && controlFields[form.name][condition.name] && controlFields[form.name][condition.name].includes(condition.value)

                        break;
                    default:
                        validate = validate && controlFields[form.name] && controlFields[form.name][condition.name] == condition.value

                        break;
                }

                // if (index == 0){
                //     validate = evalValu
                // }

              
                
               
                //console.log(condition, controlFields[form.name], validate)
                
            })

            if (!validate){
                display = false
            }
        }

        if (field.update_db){
            updateDBFields.push(field.name)
        }

        if (field.omitSave){
            omitSave.push(field.name)
        }


    

        if (React.Fragment == Component){
          return (<Component key={idx}/>)
        }
        ////console.log(form)
        return (<React.Fragment>
            <div className={`${display ? "":"d-none"}`}><Component
   
            key={idx}
            {...props}
            disabled={props.disabled}
            required={field.required && display}
            name={field.name}

            label={field.label ?  (form.fields_numbers ? (idx+1) +". " :"") + field.label :""}/>
            {form.divider && form.fields &&  idx < form.fields.length - 1 ? <hr/>:null}
            </div>
            </React.Fragment> 
            )

    }

    //////////console.log(file)

    const parseUserData = (data) => {
        let userR = Object.assign({}, data || {});
 
        if (userR.student){
            let userSR = Object.assign({}, userR.student|| {});
            userR.student = null
            userR = {...userR, ...userSR}
        }
        ////console.log(userR)

        return userR
    }


    const getSections = () =>{
            filesToUpload = []

            
            let sectionsToRender = Object.assign([], file.sections || []);


           

            if (fileCompleted && file.completed_section){
                file.completed_section.type = "SUCCESS"
                sectionsToRender.push(file.completed_section)
            }

            if (!validation && file["validated-section"]){
                file["validated-section"].type = "VALIDATION"
                sectionsToRender = [file["validated-section"]]
            }

            if (status && status != "open" && file[status]){
                file[status].type = "VALIDATION"
                sectionsToRender = [file[status]]
            }
            

            return (sectionsToRender).filter(a=>!a.hidden && !a.validate_admin).concat(    (sectionsToRender).filter(a=>a.validate_admin && userR.admin)) .map((section) => {
         
        
        return {
            header: section.title,
            icon: "fas fa-user",
            forms: (section.forms || []).filter(a=>{
                ////////console.log(a)
                if (a.validate_ldm){
                    return !a.hidden && user.is_ldm
                }

                if (a.validate_no_ldm){
                    return !a.hidden && !user.is_ldm
                }
                
                return !a.hidden
            }).map((form, index) => {

                if (form.hidden){
                    return {};
                }
              
                
                return {
                    key:index,
                    data:parseUserData(user),
                    title: form.title, 
                    description: form.description, 
                    icon: form.icon, 
                    success: answers[form.name] ? (section.type == "SUCCESS" ? false : true) : false,
                    form: <Form  baseData={fullUser} key={index + form.name } onChangeAnswers={(ans, valid)=>{
                        console.log(controlFields)
                        console.log(ans)
                        clearTimeout(timer); 

                        timer = setTimeout(()=>{
                            lControlFields = JSON.parse(JSON.stringify(lControlFields))

                            // if (!lControlFields[form.name]){
                            //     lControlFields[form.name] = {}
                            // }

                            lControlFields[form.name] =  {...controlFields[form.name], ...ans}
                            console.log(lControlFields)
                            console.log(answers)
                            setControlFields(JSON.parse(JSON.stringify(lControlFields)))
                        }, 200)
               

                        
                        /*if (field.conditions) {
                            let validate = true
                            field.conditions.map((condition)=>{
                            
                                validate = validate && answers[form.name] && answers[form.name][condition.name] == condition.value
                                //console.log(condition, answers[form.name], validate)
                                if (!validate){
                                    return null
                                }
                            })
                        }*/
                
                    }} answers={answers[form.name]} onSubmit={(values, valid)=>saveForm(values, valid, form.name, index, form.title, form)}>
                      
                            {(form
                                .fields || []).sort((a,b)=>a.order-b.order)
                                .map((field, id) => fieldSelect(field, form.name + id, form))}


                                {section.type == "SUCCESS" || section.type == "VALIDATION"? null:<div style={{textAlign:"right"}}>
                                  <Button
                                    type={"submit"}
                                    color={"primary"}>
                                    {form.btn_text ? form.btn_text :"Guardar información de \"" + form.title +"\""}
                                </Button>
                                </div>}
                        </Form>
                }
            })
        }}
        )
    }

  
    updateDBFields = []
    omitSave = []
    return (
        <React.Fragment>
           {loading ? <Loader/>: null}
            <div>
                <div>
                        <div style={{flex:1}}><h4>{user.full_name}</h4></div>
                        <div style={{display:"flex", flexDirection:"row"}}>
                            <div style={{flex:1}}><h2><b>{file.title}</b></h2></div>
                            <div><h4>{fileCompleted? <Badge color={"success"} >Completo</Badge> : <Badge color={"danger"}>Incompleto</Badge>}</h4></div>
                        </div>
                        <p>{file.description}</p>

                </div>
                

                <FormWizard
                    
                    vertical
                    ref={fomrWizard}
                    hideTabs={file.disabled_navigation}
                    beforeTabChange={(tab, nexy) => {
                        // console.log(tab, nexy)
                        // setAnswers(JSON.parse(JSON.stringify(answers)))
                    }}
                    sections={getSections()}/>

            </div>
        </React.Fragment>
    )
}

Solicitud.propTypes = {}

export default(withRouter(Solicitud))
