import React from "react"
import { FormGroup } from "reactstrap"
import PropTypes from 'prop-types';
import { Button as ButtonReactStrap } from "reactstrap"

class Button extends React.Component {

    

      onClick = (event) =>{
        if (   this.props.onClick){
          event.preventeDefault();
          this.props.onClick()
        }

      }

    render(){
        const { text, children, className, btnClassName } = this.props;


        return (
            <FormGroup className={`${className}`} style={{marginBottom:0}}>
                    <ButtonReactStrap
                            {...this.props}
                           
                            className={`${btnClassName} btn btn-primary ` + (this.props.icon ?" btn-label":"")}
                          >
                            {this.props.icon ? <i className={this.props.icon + " label-icon me-2"}></i>: null}
                            {text}{children}
                          </ButtonReactStrap>

            </FormGroup>
        )
    }
}

Button.propTypes = {
    onClick: PropTypes.func,
    block: PropTypes.bool,
}

Button.defaultProps = {
    onClick:()=>{},
    text:""
}


export default Button