
import React, { useEffect, useRef, useState } from "react"


import {  Link, withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import Button from "../../components/Buttons/Button"

import { API } from "../../utils/backend/api_helper"
import swal from "sweetalert"
import { Loader } from "../../utils/Configs"
import Header from "./Header"


const TMMS = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);

    const [candidato, setCandidato] = useState({})

    useEffect(()=>{
      setLoading(true)
      API.get(
          "/candidato",
          (resp)=>{
              setCandidato(resp.data)
              setLoading(false)



          },
          (resp)=>{
              swal({
                  title:"¡Error!",
                  text:resp,
                  icon:"error"
              })
              setLoading(false)

          }
      )
  },[])


 

  return (
    <React.Fragment >

      {loading ? <Loader message={"Enviando..."} />:null}
      
      <div className="vh-100 mb-5">
        <Header/>

        <div className="container mt-4  h-100 ">
            <div className="card shadow-sm">
            <div className="card-body p-0">

              <div className="rounded-top ">
              <h3 className="fw-bold bg-primary  rounded-top text-white p-4">PRUEBA DE INTELIGENCIA EMOCIONAL Y LIDERAZGO (TMMS)</h3>
                <p className=" fs-5 fs-md-4 p-4">
                La prueba de Inteligencia Emocional y Liderazgo está organizada en función de capacidades o habilidades que se expresan a través de situaciones específicas, o enunciados, que ejemplifican cada una de las capacidades en cuestión. Con ello se busca que cada persona realice un análisis introspectivo profundo y objetivo para determinar el grado de concordancia percibida (que tan de acuerdo o en desacuerdo está) con cada situación o enunciado que se le presenta. 
<br/><br/>Es importante destacar que en este tipo de pruebas no hay respuestas correctas o incorrectas, de manera que la mejor manera de responder a este tipo de evaluación es haciéndolo de forma honesta, objetiva y sin temor a equivocarse. Cada pregunta presenta una situación en la cual se expresa algunas de las capacidades o habilidades mencionadas, y en todos los casos la respuesta esperada es aquella con la cual quien contesta la prueba se identifica o concuerda mejor. Cada persona es única, en términos de sus vivencias y formas de enfrentar, expresar o identificar una experiencia emocional, y del mismo modo cada respuesta es única y representativa de cada persona.
  </p>
                <hr/>
              </div>
          

              <div className={"p-4"}>
              {candidato.TMMS == "COMPLETA" ? <div className="text-center">

<h3 className="mb-5"><i className="fas fs-1 text-success fa-check-circle"/><br/>¡Gracias!<br/>Esta sección ya esta completa.</h3>

<div className="d-flex justify-content-center">
<Link to="/candidatos/home" className={"btn btn-outline-primary"}>Regresar</Link>

<Button onClick={()=>setCandidato({})}  className={"ms-2"} color={"primary"}>Volver a contestar prueba TMMS</Button>
</div>
</div>: <div className="d-flex justify-content-center">


<Link to="/candidatos/home" className={"btn btn-outline-primary me-2"}>Regresar</Link>

  <Link to={"/candidatos/take-tmms"} className={"btn btn-primary"}>Iniciar prueba <i className="fas fa-arrow-right"/></Link>
  </div>}

 

                  </div>
                </div>
            </div>
        </div>
        <div style={{height:50}} />
        </div>
    </React.Fragment>
  )
}


TMMS.propTypes = {
 
}


export default (withRouter(TMMS))



