
import { call, put as reduxPut, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { 
  GET_ALL_POST_SC, 
  GET_POST_SC, 
  POST_POST_SC, 
  PUT_POST_SC, 
  DELETE_POST_SC 
} from "./actionTypes"

import {
  getAllPostSCSuccess,
  getPostSCSuccess
} from "./actions"

//Include Both Helper File with needed methods
import { put, get, del, postFile } from "../../../utils/backend/api_helper"

function* getAllPostSC({ payload: { page, user_id, callback, onError} }) {
  try {
    let response = null

    if (user_id){
      response = yield call(get, "/post/"+page+"/"+user_id)

    }else{
      response = yield call(get, "/post/"+page)

    }
    ////console.log(response)
    yield reduxPut(getAllPostSCSuccess(response.data))
    callback(response)
  } catch (error) {
    //console.log("ERROR")
    ////console.log(JSON.stringify(error.response))

    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}

function* getPostSC({ payload: {id, callback, onError} }) {
  try {
    const response = yield call(get, "/post/" + id+"/get")
    ////console.log(response)
    yield reduxPut(getPostSCSuccess(response.data))
    callback(response)
  } catch (error) {

    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}


function* putPostSC({ payload: {id, data, callback, onError} }) {
  try {


    const response = yield call(put, "/post/" + id, data)
    callback(response)
  } catch (error) {

    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}

function* deletePostSC({ payload: {id, callback, onError} }) {
  try {
    const response = yield call(del, "/post/" + id)
    callback(response)
  } catch (error) {

    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}



function* postPostSC({ payload: {data, file=null, callback, onError} }) {
  try {
    var formData = new FormData();
    
    
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
      //console.log(key, value)
   });

   if (file){
      formData.append('resource', file);
      //console.log("resource", file)
   }


    const response = yield call(postFile, "/post", formData)


    ////console.log(response)
    callback(response)
  } catch (error) {
    ////console.log("ERROR")
    ////console.log(JSON.stringify(error.response))

    if (error && error.response && error.response.data && error.response.data.message)
      onError(error, error.response.data.message)
    else
      onError(error, null)
  }
}


function* postSCSaga() {
  yield takeEvery(GET_ALL_POST_SC, getAllPostSC)
  yield takeEvery(GET_POST_SC, getPostSC)
  yield takeEvery(POST_POST_SC, postPostSC)
  yield takeEvery(PUT_POST_SC, putPostSC)
  yield takeEvery(DELETE_POST_SC, deletePostSC)
}

export default postSCSaga

