import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import {  withRouter } from "react-router-dom"
import { Card, CardBody} from "reactstrap"
import { Button } from "../../../components"

import List from "../../../components/Table/List"
import { LDMDB, Loader } from "../../../utils/Configs"
import exportFromJSON from 'export-from-json'
import { API } from "../../../utils/backend/api_helper"
import { CAMPUS_2 as CAMPUS, getValue } from "../../../utils/Tools"
import Header from "../../Candidatos/Header"



const statusFormatter = (value, row)=><div className={"text-center"}>{value ? <i className="fas fa-check text-success"/>:<i className="fas fa-times text-danger"/>}</div>
const xlsStatusFormatter = (value, row)=>`${value ? "COMPLETO":"INCOMPLETO"}`


const defaultFormatter = (v)=>{
     return Array.isArray(v) ? v.join(' / ') : (v ? v : "") ;
}

const HEADERS = [
    {
        "label": "Completo",
        "key": "q1",
        formatter:statusFormatter,
        xlsFormatter:xlsStatusFormatter
    },
    {
        "label": "Matricula (Sobrecargado)",
        "key": "matricula",
        formatter: defaultFormatter
    },
    {
        "label": "Nombre (Sobrecargado)",
        "key": "full_name",
        formatter: defaultFormatter
    },
    {
        "label": "Email (Sobrecargado)",
        "key": "email",
        formatter: defaultFormatter
    },
    {
        "label": "Campus (Sobrecargado)",
        "key": "campus",
        formatter: defaultFormatter
    },
    {
        "label": "Nombre completo",
        "key": "q1",
        formatter: defaultFormatter
    },
    {
        "label": "Matrícula",
        "key": "q2",
        formatter: defaultFormatter
    },
    {
        "label": "Campus",
        "key": "q3",
        formatter: defaultFormatter
    },
    {
        "label": "Estado de origen",
        "key": "q4",
        formatter: defaultFormatter
    },
    {
        "label": "Municipio",
        "key": "q5",
        formatter: defaultFormatter
    },
    {
        "label": "Localidad",
        "key": "q6",
        formatter: defaultFormatter
    },
    {
        "label": "Código postal",
        "key": "q7",
        formatter: defaultFormatter
    },
    {
        "label": "Grado máximo de estudios de tu papá",
        "key": "q8",
        formatter: defaultFormatter
    },
    {
        "label": "Ocupación de tu papá",
        "key": "q9",
        formatter: defaultFormatter
    },
    {
        "label": "Tipo de ocupación de tu papá",
        "key": "q10",
        formatter: defaultFormatter
    },
    {
        "label": "Grado máximo de estudios de tu mamá",
        "key": "q11",
        formatter: defaultFormatter
    },
    {
        "label": "Ocupación de tu mamá",
        "key": "q12",
        formatter: defaultFormatter
    },
    {
        "label": "Tipo de ocupación de tu mamá",
        "key": "q13",
        formatter: defaultFormatter
    },
    {
        "label": "¿Tienes hermanos en edad escolar?",
        "key": "q14",
        formatter: defaultFormatter
    },
    {
        "label": "¿Tienes hermanos que hayan cursado la universidad?",
        "key": "q17",
        formatter: defaultFormatter
    },
    {
        "label": "En su hogar reciben algún tipo de apoyo como (selecciona los que aplican):",
        "key": "q19",
        formatter: defaultFormatter
    },
    {
        "label": "¿Aproximadamente, cuáles son los ingresos de tu familia/hogar en un mes? (Sumando todo el dinero que aportan las personas que trabajan en este hogar)",
        "key": "q18",
        formatter: defaultFormatter
    },
    {
        "label": "Casa",
        "key": "g1",
        formatter: defaultFormatter
    },
    {
        "label": "Alimentación",
        "key": "g2",
        formatter: defaultFormatter
    },
    {
        "label": "Transporte",
        "key": "g3",
        formatter: defaultFormatter
    },
    {
        "label": "Servicios",
        "key": "g4",
        formatter: defaultFormatter
    },
    {
        "label": "Educación/Materiales escolares",
        "key": "g5",
        formatter: defaultFormatter
    },
    {
        "label": "Otros",
        "key": "g6",
        formatter: defaultFormatter
    },
    {
        "label": "Total",
        "key": "gtotal",
        formatter: defaultFormatter
    },
    {
        "label": "Por favor selecciona las prestaciones laborales a las que tiene acceso la persona que representa la principal fuente de ingresos de tu familia",
        "key": "q21",
        formatter: defaultFormatter
    },
    {
        "label": "Por favor indícanos las características de la vivienda de tus padres o donde vive tu familia.",
        "key": "q22",
        formatter: defaultFormatter
    },
    {
        "label": "Cantidad de personas que viven en el domicilio",
        "key": "q23",
        formatter: defaultFormatter
    },
    {
        "label": "Considerando la cocina y sin considerar el baño, cuántos cuartos tiene la casa de tus padres.",
        "key": "q24",
        formatter: defaultFormatter
    },
    {
        "label": "Por favor indica los servicios que tiene la vivienda de tus padres",
        "key": "q25",
        formatter: defaultFormatter
    },
    {
        "label": "¿En caso de ser aceptado en el Tenológico de Monterrey, serías alumno foráneo o local?",
        "key": "q26",
        formatter: defaultFormatter
    },
    {
        "label": "¿Al iniciar tus estudios, con quien estarás viviendo?",
        "key": "q27",
        formatter: defaultFormatter
    },
    {
        "label": "¿Cuál o cuáles serían tus fuentes de ingreso mensuales para tus gastos de vida? (vivienda, alimentación, ropa, trasporte, materiales escolares)",
        "key": "q28",
        formatter: defaultFormatter
    },
    {
        "label": "Casa 1",
        "key": "gi1",
        formatter: defaultFormatter
    },
    {
        "label": "Alimentación 1",
        "key": "gi2",
        formatter: defaultFormatter
    },
    {
        "label": "Transporte 1",
        "key": "gi3",
        formatter: defaultFormatter
    },
    {
        "label": "Servicios 1",
        "key": "gi4",
        formatter: defaultFormatter
    },
    {
        "label": "Materiales escolares 1",
        "key": "gi5",
        formatter: defaultFormatter
    },
    {
        "label": "Otros 1",
        "key": "gi6",
        formatter: defaultFormatter
    },
    {
        "label": "Total 1",
        "key": "gitotal",
        formatter: defaultFormatter
    },
    {
        "label": "Usualmente consumo mis alimentos en:",
        "key": "q29",
        formatter: defaultFormatter
    },
    {
        "label": "Por favor marca las opciones que representen tu situación. Durante los últimos 3 meses...",
        "key": "q30",
        formatter: defaultFormatter
    },
    {
        "label": "¿Cuál sería el  principal medio de transporte que usarías para asistir a campus que seleccionaste?",
        "key": "q31",
        formatter: defaultFormatter
    },
    {
        "label": "¿Cuánto tiempo estimas que necesitarías para trasladarte al campus del Tec?",
        "key": "q32",
        formatter: defaultFormatter
    },
    {
        "label": "¿Actualmente estás trabajando/tienes un empleo remunerado?",
        "key": "q33",
        formatter: defaultFormatter
    },
    {
        "label": "En caso de obtener la beca, ¿requieres de un trabajo en el Tec (On Campus Intern) para tu sostenimiento?",
        "key": "q37",
        formatter: defaultFormatter
    },
    {
        "label": "¿Cuentas con un seguro de gastos médicos menores? (IMSS, ISSSTE o su equivalente estatal, Seguro popular o privados)?",
        "key": "q38",
        formatter: defaultFormatter
    },
    {
        "label": "¿Qué limitaciones enfrentas en tu vida, asociadas a tu situación familiar/socioeconómica, que no han sido consideradas en las preguntas anteriores?",
        "key": "q40",
        formatter: defaultFormatter
    }
]
const VulnerabilidadView = (props) => {

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [campus, setCampus] = useState("all")

  const [listInfo, setlistInfo] = useState({items:[]})

  const dispatch = useDispatch();

  const getCandidatos = (page, size, campus="all") => {
    setLoading(true)



    API.get(
      `/candidato/p/${page}/${size}/${campus}`, 
      (response)=>{
        console.log(response)
        let count = 0
        response.data.items.map((d, index)=>{
          var query = LDMDB
          .database()
          .ref("answers/"+"vulnerabilidad-23"+ "/" + "candidato-"+d.id +"/"+"vulnerabilidad"+"/").once('value').then(v=>{
            console.log(v.val())
            count =  count + 1;
            response.data.items[index] = {...response.data.items[index], ...v.val()}
            if (count ==  response.data.items.length){
              setlistInfo(response.data)
              setLoading(false)
            }
   
          });
    


        })

        

      },
      (response)=>{
        setLoading(false)
      }
    )


    
  }

  useEffect(()=>{
    getCandidatos(1,25)
   
  },[])

  const onChangePage = (page, nextPage) => {
      //console.log(page,nextPage)
      getCandidatos(nextPage, 25, campus)

  }

  const onChangeCampus = (e) => {
    setCampus(e.target.value)
    setTimeout(()=>{
      getCandidatos(listInfo.page, 25, e.target.value)

    },500)

}


  const donwloadData = () => {
    const fileName = `Candidatos Comité Nacional 2022${campus == "all" ? "":" - " + campus} (Cuestionario de Vulnerabilidad)`
    const exportType =  exportFromJSON.types.xls
    const data = []

    setLoading(true)
    setMessage("Descargando datos...")



    API.get(
      `/candidato/p/1/10000/${campus}`, 
      (response)=>{
        console.log(response)
        let count = 0
        response.data.items.map((d, index)=>{
          var query = LDMDB
          .database()
          .ref("answers/"+"vulnerabilidad-23"+ "/" + "candidato-"+d.id +"/"+"vulnerabilidad"+"/").once('value').then(v=>{
            console.log(v.val())
            count =  count + 1;
            response.data.items[index] = {...response.data.items[index], ...v.val()}
            if (count ==  response.data.items.length){
              console.log(response.data)

                response.data.items.map(((item)=>{

                    let row = {}
                    console.log("22")
                    HEADERS.map((header)=>{
                        let value = ""
            
                        if (header.xlsFormatter ){
                            value = header.xlsFormatter(getValue(item, header.key), item)
                        }else{
                            value = header.formatter(getValue(item, header.key), item)
                        }
                   
                        let label = header.label
   
      
                        // if (row[label]){
                        //     console.log(row[label])
                        //     let c = 1
                        //     let newlabel = label + " " + c
            
                        //     while(row[newlabel]){
                        //         c = c + 1;
                        //         newlabel = label + " " +c
                        //     }
                        //     label = newlabel
                        // }
                        
                        row[label] = value
                        
                
            
                    })
                    data.push(row)
                }))
                exportFromJSON({ data: data, fileName, exportType})

                setLoading(false)
            }
   
          });
    


        })

        

      },
      (response)=>{
        setLoading(false)
      }
    )

 
   
    

  }

  return (
    <div >
         <Header/>
         <div className="container-fluid mt-4">
         
      {loading?<Loader message={message}/> :""}

      

      <div className="d-flex align-items-center">
          <div className="flex-fill"><h3><i className="fas fa-star me-2"/>CUESTIONARIO DE VULNERABILIDAD LDM</h3>
          <div>
          <select onChange={onChangeCampus} className="form-select" aria-label="Campus" defaultValue={"all"}>
            <option value="all">Todos los campus</option>
            
            {CAMPUS.map((v, key)=>(
              <option key={key} value={v.campus}>{v.campus}</option>
            ))}
          </select>
          </div>
          </div>
          <div className="ms-3">
         {listInfo ? <Button onClick={donwloadData}  icon={"fas fa-download"} color={"primary"}>Descargar</Button>:null}
         </div>
      </div>

        <div className={"mt-2"}/>
        <Card className={"box-shadow mt-0 pt-0"}>
                
                <CardBody className={"mt-2 pt-0"}>

      <List
        onChangePage={onChangePage}
        hasPagination={true}
        pagination={listInfo}
        data={listInfo ? listInfo.items : []}
        headers={HEADERS}
      />
      </CardBody></Card>
      </div>
    </div>
  )
}


VulnerabilidadView.propTypes = {
 
}


export default (withRouter(VulnerabilidadView))



