import React from "react"
import {  useSelector } from "react-redux";


import { Link, withRouter } from "react-router-dom"
import { Spinner } from "reactstrap";


import { avatar } from "../../../utils/Tools";


const ReportsView = (props) => {

  const formNames = useSelector(state => state.form.formNames);
  const user = useSelector(state => state.profile.user);


  //console.log(listInfo)
  return (
    <React.Fragment>


      <h3>Mis Reportes</h3>
      <hr/>

      <div className="container">
  <div className="row row-cols-3">
      <Link to={"/data-center/reports/xyzrti"} className={" col card box-shadow p-0 flex"} style={{height:250, width:"100%", maxWidth:400}}>
                          
  
  
  
  
                            <div className={"p-2"} style={{"flex":1,flexDirection:"row", textAlign:"center", display:"flex", backgroundColor:"#f8f8f8",borderBottom:"1px solid #e1e1e1", justifyContent:"center", alignItems:"center"}}>
                            
  
                                
                                <h3>{"Reporte Especial"}</h3>
                            </div>
  
                           
                          
                            <div style={{display:"flex", flexDirection:"row", marginBottom:1, justifyContent:"center", alignItems:"center", borderBottom:"1px solid #e1e1e1"}}>
                              
                              {user ? <Link to={"/perfil/"+"jorge"+"/"+user.matricula} className={"p-2"} style={{flex:1}}>
                                <div style={{display:"flex",   flexDirection:"row", marginBottom:1, alignItems:"center"}}>
  
                                <div style={{marginRight:5}}>{avatar(user, 25, 16)}</div>
                                <div style={{flex:1,fontSize:12}}>{user.full_name}</div>
                                </div>
                              </Link>: 
                                <div className={"ms-2"} style={{display:"flex", alignItems:"center", justifyContent:"center", flex:1}}>
                                  
                                  <Spinner/>
  
                               </div>
                              }
                            </div>
  
 
      
      </Link>
</div>
</div>

      

      <h3>Otros reportes</h3>
      <hr/>

 
    </React.Fragment>
  )
}




export default (withRouter(ReportsView))



