
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { useParams, withRouter } from "react-router-dom"
import { LDMDB } from "../../utils/Configs"
import { avatar } from "../../utils/Tools"
 import iconSet from "../../assets/fonts/selection.json";
 import IcomoonReact from "icomoon-react";
import Button from "../../components/Buttons/Button"


const Project = (props) => {
  const { id, course_name } = useParams();
  const user = useSelector(state => state.profile.user);

  const [loading, setLoading] = useState(false)
  const [course, setCourse] = useState({})
  //const convocatorias = useSelector(state => state.convocatoria.data);
  //const solicitudes = useSelector(state => state.solicitud.data);
  //const modal = useRef();
  const dispatch = useDispatch();
  const imgRef = React.useRef()

  const [maxHeight, setMaxHeight] = useState("100%")

  //window.open(curso.src, "DescriptiveWindowName","resizable,scrollbars,status")

  useEffect(() => {
    setLoading(true)
    let cleanup = LDMDB.database().ref("cursos/"+course_name+"/").on("value", function(snapshot) {
      //console.log(snapshot);
      if (snapshot && snapshot.exists()){
        setCourse(snapshot.val())

      }
      
    setLoading(false)
    })
    return () => {
      cleanup()
    }
  }, [course_name])

  useEffect(()=>{

    if (imgRef.current && imgRef.current.clientHeight != maxHeight){
      setMaxHeight(imgRef.current.clientHeight)

    

    }

    if (!imgRef.current || (imgRef.current && !imgRef.current.clientHeight)){
      setMaxHeight("100%")
    }


  })

  const onImgLoad = ({ target: img }) => {
    setMaxHeight(img.height,)
  };

  /*const createSolicitud = (values, validData) => {
    console.log(values, validData)
    
    if (validData){
      setLoading(true)
      dispatch(postSolicitud(values, 
        ()=>{
          setLoading(false)
          dispatch(getSolicituds())
          modal.current.toggle()
          props.history.push("/solicitud")
        }))
    }
  }*/
  
  return (
    <React.Fragment>
      {loading?"":""}
      <div className="page-content mt-6">
        <div>


          <div className={"container"}>


                      
          <div  className=" rounded   row">

      <div className="col-4 p-0 pr-2 ">
      <img src={"/JADID.jpeg"} style={{objectFit:"cover"}}   className="img-fluid box-shadow  me-3   border rounded"/>
      </div >


      
        <div className="p-3 col-8  d-flex flex-column bg-white rounded box-shadow">
    
        <div className={"flex-fill"} style={{fontSize:16}}>
      <h2 >
     
      Iniciativa Social Proyecto Jiadi
      

      </h2>
      <hr/>
      <b>Descripción:</b>

      <p className="f mt-2" >
      Proyecto social capaz de habilitar espacios públicos seguros para las infancias y las adolescencias presentes en la comunidad otomí de Lomas Modelo. En este proyecto, fuimos capaces de impartir un taller de arte y arteterapia en el Centro Comunitario Otom
      </p>

      <div className="row">
        <div className="col">
          <b>Población:</b> Niños, niñas y adolescentes
        </div>
        <div className="col">
        <b>Tipo de iniciativa: </b>Prototipo

</div>
      </div>


      <div className="row">
        <div className="col">
          <b>Campus:</b> Monterrey
        </div>
        <div className="col">
        <b>Región: </b>Monterrey

</div>
      </div>

      </div>

      <div className={"d-flex justify-content-end"} >


      <button  className={"me-1 btn btn-link btn-lg"} color="btn btn-link"><i className="fab fa-facebook"/></button>
      <button className={"me-1 btn btn-link btn-lg"} color="btn btn-link"><i className="fab fa-instagram"/></button>
      <button className={"me-1 btn btn-link btn-lg"} color="btn btn-link"><i className="fab fa-linkedin"/></button>

      <button className={"me-1 btn btn-link btn-lg"} color="btn btn-link"><i className="fab fa-youtube"/></button>






        <Button color="primary"><i className="fas fa-envelope"/>  Contactar al líder</Button>
        
      </div>


    </div>

 
          </div>



          <div className="row ">

          <div  className="col-6 p-0">

          <div  className="bg-white mt-2 me-2  p-3 rounded box-shadow">

<h3 >

Objetivo de la iniciativa
<hr/>

</h3>
Creación de espacios públicos seguros para niñas, niños y adolescentes de la comunidad otomí Lomas Modelo.

</div>

          <div  className="bg-white mt-2 me-2  p-3 rounded box-shadow">

          <h3 >
     
    Enlaces destacados
     <hr/>

     
 
     

     </h3>



     <div className="row">
        <div className="col-3">
           Tipo
        </div>
        <div className="col-6">
         Nombre
        </div>
      </div>
      <hr/>

     <div className="row">
        <div className="col-3">
          <i className="fas fa-file"/> Documento
        </div>
        <div className="col-6">
          Estatutos básicos del proyecto

        </div>
        <div className="col-3">
         <a href="#">Descargar</a>

        </div>
      </div>
   
      <hr/>
      <div className="row mt-1">
        <div className="col-3">
          <i className="fas fa-globe"/> Sitio web
        </div>
        <div className="col-6">
          Nota de conecta. Un proyecto que trasnforma

        </div>
        <div className="col-3">
         <a href="#">Visitar</a>

        </div>
      </div>
  
  <hr/>
      <div className="row mt-1">
        <div className="col-3">
          <i className="fas fa-globe"/> Sitio web
        </div>
        <div className="col-6">
          Periódico el norte. Un proyecto de moviliad

        </div>
        <div className="col-3">
         <a href="#">Visitar</a>

        </div>
      </div>
  
  </div>

</div>

            
<div className="col-6 p-0">


<div  className="bg-white mt-2  p-3 rounded box-shadow">
      <h3 className="me-1">Líderes del Mañana en acción</h3>
      <hr/>
      <div className="d-flex  align-items-center w-100" >
          
            <div>{avatar(user,84)} </div>
            <div className="ms-1"><b>Jorge Luis Méndez Montoya</b><div>Co-fundador, líder</div></div>
        </div>

</div>

        <div  className="bg-white mt-2 p-3 rounded box-shadow">
        <h3 className="me-1">Objetivos de Desarrollo Sostenible</h3>
      <hr/>
        <div className="d-flex  align-items-center w-100" >
              <div><IcomoonReact className={"img-fluid"} iconSet={iconSet} color={"red"} size={84} icon={"ods4"} /></div>

            <div className="ms-1"> <IcomoonReact className={"img-fluid"} iconSet={iconSet} color={"orange"} size={84} icon={"ods11"} /></div>
   
        </div>
      </div>
      </div>
      </div>





      


                <div className="row">
        <div className="p-3 col-12 mt-2  d-flex flex-column ">
        Última modificación: 1/21/2022 7:35:33 PM

        </div>
        </div>
       
          </div>
            
        </div>
      </div>
    </React.Fragment>
  )
}


Project.propTypes = {
 
}


export default (withRouter(Project))



