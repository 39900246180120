
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"


import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FirebaseDatabaseNode } from "@react-firebase/database"
import { LDMDB } from "../../utils/Configs"


const ProgramaFormacion2021 = (props) => {

  const [loading, setLoading] = useState(false)

  //const convocatorias = useSelector(state => state.convocatoria.data);
  //const solicitudes = useSelector(state => state.solicitud.data);
  //const modal = useRef();
  const dispatch = useDispatch();

  useEffect(()=>{
    //dispatch(getConvocatorias())
    //dispatch(getSolicituds())
  },[dispatch])

  /*const createSolicitud = (values, validData) => {
    //console.log(values, validData)
    
    if (validData){
      setLoading(true)
      dispatch(postSolicitud(values, 
        ()=>{
          setLoading(false)
          dispatch(getSolicituds())
          modal.current.toggle()
          props.history.push("/solicitud")
        }))
    }
  }*/

  const timeLine = () => {
    return (

      <VerticalTimeline>
      <FirebaseDatabaseNode firebase={LDMDB} path="programa_formacion_y_desarrollo_2021/calendario/" limitToFirst={100}>
        {d => {

              if (d && d.value){
                //console.log(d.value)


              
            return  (d.value || []).map((c, index)=>
            {

              let color = "rgb(251,79,79)"
              let event = "Evento"
              let icon = "calendar"

              switch(c.type) {
                case "DIA":
                  color = "#1ABC9C"
                  event = "Diálogo"
                  icon = "comments"
                  break;
                case "CONF":
                  color = "#FFD301"
                  event = "Conferencia"
                  icon = "comment"
                  break;
                case "TALL":
                  color = "#FF7800"
                  event = "Taller"
                  icon = "hammer"
                  break;
                case "CUR":
                  color = "#6BB2F1"
                  event = "Curso"
                  icon = "chalkboard-teacher"
                  break;
              }
            
            return <VerticalTimelineElement
            key={index}
            className="vertical-timeline-element--work"
            
            contentStyle={{ background: "white",textAlign: index % 2 == 0 ? "right" : "left"}}
            contentArrowStyle={{ borderRight: '7px solid  #fff' }}
            dateClassName={"text-center"}
            date={<div style={{fontSize:14}}><b>{c.date}</b> • {c.time}</div>}
        
            iconStyle={{ background: color, color: '#fff' }}
            icon={<i className={`fas fa-${icon}`} style={{fontSize:24, padding:18}}></i>}
          >
            <h5 className="vertical-timeline-element-title">{c.name}</h5>
            <div className="vertical-timeline-element-subtitle" style={{color:"rgb(26,49,146)", fontWeight:"bold", marginTop:5}}>{c.speaker}</div>
            
            
            <div className="vertical-timeline-element-subtitle" style={{color:color, fontWeight:"400", marginTop:10}}>{event}</div>

            {c.link ? <div style={{color:"#0b27a3",marginTop:10, textDecorationLine:"underline", fontSize:12, cursor:"pointer"}}>
              <a target={"blank"} href={c.link}>Ver grabación</a>
            </div>: <div style={{color:"grey",marginTop:10,fontSize:12}}>Grabación no disponible</div>}

            {c.material ? <div style={{color:"#0b27a3",marginTop:10, textDecorationLine:"underline", fontSize:12, cursor:"pointer"}}>
              <a target={"blank"} href={c.material}>Materiales de la sesión</a>
            </div>: null}
            
          </VerticalTimelineElement>});
              }else{
                return  null;
              }
        }}
  </FirebaseDatabaseNode>
  <VerticalTimelineElement
    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
    icon={<i className={`fas fa-flag`} style={{fontSize:24, padding:18}}></i>}
  />
</VerticalTimeline>
    )
  }
  
  return (
    <React.Fragment>
      {loading?"":""}
      <div className="page-content mt-6">


          <div style={{display:"flex", alignContent:"center", justifyContent:"center"}}>
                
                <div className={"d-none d-lg-block"} style={{flex:1}}>
                  <img src={"https://s3.amazonaws.com/micasaldm.resources/TEMP/Aesthetic+(22).png"} className={"box-shadow"} style={{width:"100%", maxHeight:1000, borderRadius:5}}/>
                </div>


                <div style={{flex:1, padding:10}}>
                  <div style={{textAlign:"center"}}>
                    <h4>Conferencias/eventos/talleres</h4>
                  </div>
                  <div style={{overflow:"scroll", maxHeight:500}}>
                  {timeLine()}
                  </div>

                </div>
        </div>
            

      </div>
    </React.Fragment>
  )
}


ProgramaFormacion2021.propTypes = {
 
}


export default (withRouter(ProgramaFormacion2021))



