
import React, { useEffect, useRef, useState } from "react"


import {  Link, useHistory, withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import Form from "../../components/Form/Form"
import Button from "../../components/Buttons/Button"

import { API } from "../../utils/backend/api_helper"
import swal from "sweetalert"
import { Loader } from "../../utils/Configs"

import Header from "./Header"
import Upload from "../../components/Fields2/Upload"



const Video = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const [url, setURL] = useState("")

    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);
    const history = useHistory()
    const [candidato, setCandidato] = useState({})

    const [progress, setProgress] = useState(-1)

    useEffect(()=>{
      setLoading(true)
      API.get(
          "/candidato",
          (resp)=>{
              setCandidato(resp.data)
              setLoading(false)



          },
          (resp)=>{
              swal({
                  title:"¡Error!",
                  text:"resp",
                  icon:"error"
              })
              setLoading(false)

          }
      )
  },[])


  
    const submit = (values, valid) =>{
      console.log(values, valid)

      if (valid){
        setLoading(true)

        let files = []
        for (const [key, value] of Object.entries(values)) {
          if (value && value.fileValue){
            files.push(key)
          }
        }

        API.post(
          "/candidato/upload-file",
          {files},
          (response)=>{
            console.log(response)

            response.data.map((uploadFileData)=>{
              console.log(values[uploadFileData.name])
              setLoading(false)
              setProgress(-1)
              API.S3put(
                uploadFileData.upload_url,
                values[uploadFileData.name].fileValue,
                (response)=>{
                  console.log(response)
                  setLoading(true)
                  API.post(
                    "/candidato/complete-upload",
                    {url:uploadFileData.upload_url.split("?")[0],name:uploadFileData.name,bucket:uploadFileData.bucket },
                    ()=>{
                      setLoading(false)
                      swal({
                        title:"¡Gracias!",
                        text:"Esta sección ha sido completada.",
                        icon:"success"
                      }).then(
                        ()=>{
                          //window.location.href = "/candidatos/home"
                          history.push( "/candidatos/home")
                        }
                      )
                    },
                    ()=>{
                      setLoading(false)
                      swal({
                        title:"¡Error en el documento!",
                        message:response,
                        icon:"error"
                      })
                    })

                 
      
              },()=>{
                swal({
                  title:"¡Error en el documento!",
                  message:response,
                  icon:"error"
                })
              },(progressEvent)=>{
                let percentComplete = progressEvent.loaded / progressEvent.total
                percentComplete = percentComplete * 100;
                //console.log(percentComplete)
                setProgress(percentComplete)
              })

            })


            setLoading(false)
          },
          (response)=>{
            swal({
              title:"¡Error!",
              message:response,
              icon:"error"
            })
            setLoading(false)

          })
  
      }



    }


  return (
    <React.Fragment >

      {loading ? <Loader message={"Enviando..."} />:null}
      
      <div className="vh-100 mb-3" >
        <Header/>

        <div className="container my-4   ">
            <div className="card shadow-sm">
            <div className="card-body p-0">

              <div className="rounded-top ">
              <h3 className="fw-bold bg-primary  rounded-top text-white p-4">VIDEO DE HISTORIA DE VIDA, MOTIVADORES PERSONALES Y ACCIONES REALIZADAS</h3>
              <p className=" fs-5 fs-md-4 p-4">
              Con el objetivo de conocerte mejor, es necesario que realices un video que hable sobre ti, tus motivadores personales y las acciones que has llevado a la práctica. Para preaprar este video, por favor toma en cuenta las siguientes indicaciones:
<br/><br/>
<p>Contenido del vídeo:</p>
<ol>
    <li>Presentación:<ol>
            <li>Nombre</li>
            <li>Ciudad de origen</li>
            <li>Ciudad donde vives</li>
        </ol>
    </li>
    <li>Motivación para ser un Líder del Mañana:<ol>
            <li>¿Por qué quieres ser un Líder del Mañana?&nbsp;</li>
            <li>Explica qué harás por los demás si lo consigues y cómo lo harás.</li>
            <li>¿A qué te comprometerías?</li>
        </ol>
    </li>
    <li>Experiencia social y logros personales:<ol>
            <li>Proyecto social en el que has participado y su descripción.</li>
        </ol>
    </li>
    <li>Los logros de los que te sientes orgulloso.</li>
    <li>Contribución a la comunidad/región a corto y mediano plazo.<ol>
            <li>¿Qué aportarás a tu comunidad/región a corto y mediano plazo?</li>
        </ol>
    </li>
</ol>
<p>El vídeo puede ser grabado con el dispositivo que prefieras (teléfono, computadora, tablet, cámara de vídeo). Debe tener una <strong>duración máxima de 3 minutos</strong> y un tamaño máximo de archivo de 500 MB*. Asegúrate de que haya <strong>buena ilimunación</strong> y que se vea adecuadamente en la computadora en <strong>formato horizontal.</strong> Te sugerimos preparar un guión de respuestas antes de grabarlo para asegurarte de cubrir todos los puntos mencionados aquí. El vídeo debe seguir el mismo orden en el que se enumera el contenido aquí. <strong>No añadas edición al vídeo, solo verifica que tu voz se escuche claramente y que no haya música ni ruido de fondo.</strong></p>

*Si tenes problemas con el tamañano de tu video, te sugerimos visitar este sitio: <a rel="noreferrer" target="_blank" href="https://www.freeconvert.com/video-compressor">https://www.freeconvert.com/video-compressor</a> donde podras comprimir el tamaño de tu video.
<br/><br/>
                <div className="text-end">
                Contacto para dudas: Jorge Méndez <a href="mailto:jorge@tec.mx">jorge@tec.mx</a><br/>

                </div>
                
                </p>
                <hr/>
              </div>
          

              <div className={"p-4"}>
              {candidato.VIDEO == "COMPLETA" ? <div className="text-center">

<h3 className="mb-5"><i className="fas fs-1 text-success fa-check-circle"/><br/>¡Gracias!<br/>Esta sección ya esta completa.</h3>

<div className="d-flex justify-content-center">
<Link to="/candidatos/home" className={"btn btn-outline-primary"}>Regresar</Link>

<Button onClick={()=>setCandidato({})}  className={"ms-2"} color={"primary"}>Cambiar video</Button>
</div>
</div>: <Form  onSubmit={submit}>
                        <div className="row">

 <Upload progress={progress} maxSize={500} accept={"video/mp4,video/x-m4v,video/*"} required name={"VIDEO"}  label={"Sube aquí tu video"}/>

                        </div>
                        
                        {progress >= 0 ? null:<div className="mt-2 d-flex justify-content-end">
                      <Link to="/candidatos/home" className={"btn btn-outline-primary me-2"}>Regresar</Link>

                      <Button  type={"submit"} color={"primary fw-bold"}>Continuar <i className="ms-1 border-start ps-1 fas fa-arrow-right"/></Button>

                    </div>}
                  </Form>}

                  </div>
                </div>
            </div>
        </div>
        <div style={{height:50}} />

        </div>
    </React.Fragment>
  )
}


Video.propTypes = {
 
}


export default (withRouter(Video))



