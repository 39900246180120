import React, { createRef } from "react"
import { FormGroup, Label } from "reactstrap"
import PropTypes from 'prop-types';

import Checkbox from "../Fields/Checkbox";

import BaseField from "./BaseField"



class CheckboxGroup extends BaseField{

    constructor(props) {
        super(props);
        this.state = {
            value:[]
        }

        this.checkboxRefs  = {};
        this.props.options.map((option)=>
            this.checkboxRefs[option.value] = createRef()
        )

    }

    getValue = () =>{
        return this.state.value
    }

    setValue = (values) => {
        
       if (values){
            this.props.options.map((v)=>{
                this.checkboxRefs[v.value].current.setValue(values.includes(v.value))
                return this.checkboxRefs[v.value]
            })
            this.setState({value:values})
        }else{
            this.props.options.map((v)=>{
                this.checkboxRefs[v.value].current.setValue(false)
                return this.checkboxRefs[v.value]
            })
            this.setState({value:[]})
        }

    }

    checkValue = (checked, value) =>{
        //console.log(checked, value)
        if (checked && !this.state.value.includes(value)){
            this.state.value.push(value)
            this.setState({value:this.state.value})

            if (this.props.onChange){
                this.props.onChange(this.state.value)
            }

        }else{
            const index = this.state.value.indexOf(value);
            if (index > -1) {
                this.state.value.splice(index, 1);
            }
            this.setState({value:this.state.value})

            if (this.props.onChange){
                this.props.onChange(this.state.value)
            }
        }

    }


    render(){
        const {name, label, options } = this.props;

        return (
            <FormGroup>
                <Label>{label}{this.props.required ? <span className={"invalid-feedback"} style={{display:"inline", fontSize:14}}> *</span>:null}</Label>
       
                {options.map((option, idx)=>{
                    
                    
                    
                    return (
                 
                        <Checkbox 
                            className={"pl-4"}
                            ref={this.checkboxRefs[option.value]}
                            key={idx}
                            checked={this.state.value.includes(option.value)}
                            onChange={(checked)=>this.checkValue(checked, option.value)}
                            name={name + (idx ? "-"+idx:"")}  
    
                            label={option.label} 
                            value={option.value}
                            />
                    )
                })}
        
                {this.renderErrorMessage()}
            </FormGroup>
        )
    }
}



CheckboxGroup.propTypes =  Object.assign({}, BaseField.propTypes,   {
    options: PropTypes.array.isRequired,
});


export default CheckboxGroup