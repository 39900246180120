import * as Msal from "msal";

export default class MSAuth{

    static ACCESS_TOKEN = "";
    static EMAIL = null;
    static NAME = null;
    static URL = null;

    static msalConfig = {
        auth: {
            clientId: "c5de4068-18fe-4c05-8e7a-722da5523858",
            authority: "https://login.microsoftonline.com/c65a3ea6-0f7c-400b-8934-5a6dc1705645",
            //redirectUri: "http://localhost:3000/video-salvador-alva",
            postLogoutRedirectUri: "https://micasaldm.mx/login"
        },
        scopes: ["User.ReadBasic.All","User.ReadWrite"],
        prompt:"select_account",
        loginHint: "@tec.mx",
        extraQueryParameters: {domain_hint: 'organizations'}
    };
    
    static myMSALObj = null;
    static startMSAL = () => {
        if (MSAuth.myMSALObj == null){
            //MSAuthentication.ACCESS_TOKEN = token;
            MSAuth.myMSALObj = new Msal.UserAgentApplication(MSAuth.msalConfig);
            /*MSAuthentication.myMSALObj.handleRedirectCallback(
                function(error, response) {
                    
                    MSAuthentication.redirectTo("/video-salvador-alva")
                })*/
        }
    }

    static getPermissions = (callback) => {
        MSAuth.startMSAL();
        MSAuth.myMSALObj.acquireTokenPopup(MSAuth.msalConfig).then(callback).catch(function (error) {
            //login failure
            //console.log(error);

        });
    }



}