import moment from "moment"
import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
// import LAL from "../../assets/images/lal.svg"
import {Link, withRouter} from "react-router-dom"
import {
    // Card,
    // CardBody,
    Spinner
} from "reactstrap"
import InfiniteScroll from "../../components/InfiniteScroll"
import Share from "../../components/Share"
import {getAllContact} from "../../store/actions"
import {LDMDB} from "../../utils/Configs"
// import { getConvocatorias, getSolicitud, getSolicituds, postSolicitud } from
// "../../store/actions"
import {CAMPUS} from "../../utils/Tools"
import { useMediaQuery } from 'react-responsive';

const ComunidadVirtual = (props) => {
    const [days,
        setDays] = useState(0)
    const [loading,
        setLoading] = useState(false)
    const [vPost,
        setVPost] = useState("")
    const [paginated,
        setPaginated] = useState({})

    const loadingContacts = useSelector(state => state.contact.loading);
    const paginatedContacts = useSelector(state => state.contact.all);

    //const modal = useRef();
    const dispatch = useDispatch();
    const user = useSelector(state => state.profile.user);

    useEffect(()=>{
        var end = new Date('05/03/2023 12:00:00');

        var _second = 1000;
        var _minute = _second * 60;
        var _hour = _minute * 60;
        var _day = _hour * 24;
        var timer;
    
        function showRemaining() {
            var now = new Date();
            var distance = end - now;
            if (distance < 0) {
    
                clearInterval(timer);
    
                return;
            }
            var days = Math.floor(distance / _day);
            setDays(days)
    
        }
        showRemaining()
        timer = setInterval(showRemaining, 300000);

        return ()=>{
            clearInterval(timer);
        }
    },[])

    useEffect(() => {
        if (!paginatedContacts){
          dispatch(getAllContact(1, (response)=>{
            setPaginated(response.data)
          }),)
        }else{
          setPaginated(paginatedContacts)
        }
        //dispatch(getSolicituds())
    }, [dispatch])

    const getPage = (page) => {
        dispatch(getAllContact(page,(response)=>{
          setPaginated(response.data)
        }))
    }

    /*const createSolicitud = (values, validData) => {
    console.log(values, validData)

    if (validData){
      setLoading(true)
      dispatch(postSolicitud(values,
        ()=>{
          setLoading(false)
          dispatch(getSolicituds())
          modal.current.toggle()
          props.history.push("/solicitud")
        }))
    }
  }*/
    const postShare = () => {

        var myRef = LDMDB
            .database()
            .ref("comunidad_virtual/posts/")

        var newData = {
            text: vPost,
            date: moment.now(),
            user_id: user.id
        }

        myRef.push(newData);

    }

    return (
        <React.Fragment>
            {loading
                ? ""
                : ""}
                         
            <div className="bg-white vh-100" >
            <div className="" style={{height:70}}/>
                            <div className="d-none  d-lg-block" style={{height:53}}/>
                <div >

                    <div className="d-flex" style={{
                 
                        height:"auto"
                    }}>
                        <div
                            className={"d-none p-3 py-4  d-md-block shadow-sm"}
                          
                            style={{

                            flex: useMediaQuery({ query: `(min-width: 960px)` }) ? 0.6 : 0.7,
                    
                            textAlign: "center", backgroundColor:"#f8f8fb"
                        }}>

                          <div   className={""}>
                            

                                    <div className="d-flex justify-content-center align-items-center">


                                            <h5
                                                style={{
                                                color: "gray",
                                                marginBottom:0
                                            }}><i className="fas fa-calendar"/>{" "}Eventos</h5>
                                     

                                 
                                 
                                </div>
                           
                                <hr/>
<div className="d-flex justify-content-bottom">
                                {/* <div
                                            style={{
                                            paddingRight: 10
                                        }}>

                                            {user && user.url_photo
                                                ? <img
                                                        className="rounded-circle header-profile-user"
                                                        src={user.url_photo}
                                                        style={{
                                                        minHeight: 50,
                                                        objectFit:"cover",
                                                        minWidth: 50
                                                    }}
                                                        alt="Header Avatar"/>
                                                : null}
                                        </div> */}
                            {/* <Card
                                className={"box-shadow mt-0"}
                                style={{
                           
                            }}>

                                <CardBody
                                    className={"mt-0 p-1 px-3"}

                                    style={{
                                    textAlign: "left",
                            
                                }}>

                                    <div
                                        style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: 5,
                                        padding: 0
                                    }}>
                                       

                                        <div
                                            style={{
                                            flex: 1
                                        }}>
                                            Registra tu solictud de apoyo al programa de ingles<br/>
                                            <div
                                                style={{
                                                textAlign: "right"
                                            }}>
                                                <Link>Ver más</Link>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card> */}
                         
                            {/* <div className="card p-2 w-100 shadow-sm">
                                <div className="position-relative">
                                    <img src={LAL} alt="React Logo" />
                                    <span className="position-absolute lal-counter fw-bold">{days} <span>DÍA{days > 1 ? "S":"" }</span></span>
                                </div>
                              
                            </div> */}

                            </div>

                            </div>
                        </div>
                        <div
                            style={{flex:1,backgroundColor:"#f8f8fb"}}
                            className={"px-2 px-md-5 px-xl-6 bg-"}
                         >
                    
                            <div
                            className={"hide-scroll"}
                            style={{overflowY:"scroll", maxHeight:"100vh", paddingTop:20, marginBottom:100}}>

                                {/* <div className="d-md-none card p-2 w-100 box-shadow mt-3">
                                <div className="position-relative">
                                    <img src={LAL} alt="React Logo" />
                                    <span className="position-absolute lal-counter fw-bold">{days} <span>DÍA{days > 1 ? "S":"" }</span></span>
                                </div>
                              
                            </div> */}
                            <hr/>
                            <Share user={user}/>

                            
                            <InfiniteScroll/>
                            </div>
                        </div>

                        <div
                            className={"d-none border p-3 d-lg-block shadow-sm"}
                            style={{
                            flex: useMediaQuery({ query: `(min-width: 960px)` }) ? 0.6 : 0.7,
                     
                           
                            textAlign: "center"
                        }}>

                            <div>

                                <div
                                    style={{
                                    textAlign: "left",
                                }}>

                                    <div
                                        style={{
                                        display: "flex",
                                        alignItems:"center"
                                    }}>
                                         {paginated.has_prev
                                            ? <div
                                                    className={"selectable p-2"}
                                                    onClick={() => getPage(paginated.prev_num)}
                                                    style={{
                                                    cursor: "pointer"
                                                }}><i className={"fas fa-chevron-left"}/></div>
                                            : null}
                                        <div
                                        className={"p-2"}
                                            style={{
                                            flex: 1,
                                            textAlign:"center"
                                        }}>
                                            <h5
                                                style={{
                                                color: "gray"
                                            }}>Líderes del Mañana</h5>
                                        </div>
                                       
                                         {paginated.has_next
                                            ? <div
                                                    className={"selectable p-2"}
                                                    onClick={() => getPage(paginated.next_num)}
                                                    style={{
                                                    cursor: "pointer"
                                                }}><i className={"fas fa-chevron-right"}/></div>
                                            : null}

                                    </div>

                                    <hr className={"mt-0 mb-0"}/>

                                    <div
                                        style={{
                                        marginTop: 10
                                    }}>{loadingContacts
                                            ? <div
                                                    style={{
                                                    textAlign: "center"
                                                }}><Spinner/></div>
                                            : (paginated.data || []).map((c, index) => {
                                                return (
                                                    <Link to={"/perfil/"+c.id+"/"+c.matricula} key={index}>
                                                    <div
                                                        
                                                        key={index}
                                                        className="selectable"
                                                        style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        padding: 5
                                                    }}>
                                                        <div>
                                                            {c && c.url_photo
                                                                ? <img
                                                                        className="rounded-circle header-profile-user"
                                                                        src={c.url_photo}
                                                                        style={{
                                                                        height: 40,
                                                                        width: 40,
                                                                        objectFit:"cover",
                                                                    }}
                                                                        alt="Header Avatar"/>

                                                                : <span className={"d-inline-block"}>
                                                                    <span
                                                                        className={"avatar-title rounded-circle bg-soft-primary text-primary font-size-22"}
                                                                        style={{
                                                                        width: 40,
                                                                        height: 40
                                                                    }}>
                                                                        {c
                                                                            .full_name
                                                                            .charAt(0)}
                                                                    </span>
                                                                </span>
                                                        }
                                                        </div>

                                                        <div
                                                            style={{
                                                            marginLeft: 10,
                                                            fontSize: 12
                                                        }}>
                                                            <div>{c.full_name}</div>
                                                            <div
                                                                style={{
                                                                color: "gray"
                                                            }}>
                                                                <span>{c.student.generation
                                                                        ? c.student.generation + "a generación • "
                                                                        : ""}{""}{(CAMPUS[c.student.campus - 1] || {}).campus}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </Link>)
                                            })}</div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

ComunidadVirtual.propTypes = {}

export default(withRouter(ComunidadVirtual))
