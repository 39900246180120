import React from "react"
import { FormGroup, Label } from "reactstrap"
import PropTypes from 'prop-types';

import Radio from "../Fields/Radio";

import BaseField from "./BaseField"



class RadioGroup extends BaseField{

    constructor(props) {
        super(props);
        this.state = {
            value:""
        }

        /*this.radiosRefs  = {};
        this.props.options.map((option)=>
            this.radiosRefs[option.value] = createRef()
        )*/

    }

    getValue = () => {
        //let value = null
        /*this.props.options.map((option)=>{
            if (this.radiosRefs[option.value].current.getValue()){
                value = option.value
            }
        })*/
    
        return this.state.value
    }

    setValue = (value) => {
        /*this.props.options.map((v)=>{
            this.radiosRefs[v.value].current.setValue(v.value == value)
        })*/
        this.setState({value:value})
    }

    onChange = (value) => {
        this.setState({value:value})
        if (this.props.onChange){
            this.props.onChange(value)
        }
    }


    render(){
    const {name, label, options} = this.props;

    return (
        <FormGroup>
            <Label>{label}{this.props.required ? <span className={"invalid-feedback"} style={{display:"inline", fontSize:14}}> *</span>:null}</Label>
            {options.map((option, idx)=>
                <Radio 
                    //ref={this.radiosRefs[option.value]}
                    key={idx} 
                    name={name}  
                    checked={this.state.value === option.value}
                    label={option.label} 
                    value={option.value}
                    onChange={()=>this.onChange(option.value)}
            />)}
            {this.renderErrorMessage()}
        </FormGroup>
    )
    }
}

RadioGroup.propTypes = {
    options: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
}

export default RadioGroup