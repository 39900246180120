import exportFromJSON from "export-from-json";
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";


import { useParams, withRouter } from "react-router-dom"
import { Button, List } from "../../../components";
import { searchContact, updateListView } from "../../../store/actions";
import {  getValue } from "../../../utils/Tools"
import {  LDMDB, Loader } from "../../../utils/Configs"


const ReportView = (props) => {
  const { id } = useParams();

  const mats = useSelector(state => state.contact.matDicts);

    const [loading, setLoading] = useState(false)

    const [report, setReport] = useState({fields:[]})
    const [data, setData] = useState([])

    const [baseData, setBaseData] = useState([])
    const listInfo = useSelector(state => state.contact.listData);
    const contacts = useSelector(state => state.contact.dictionary);



  const dispatch = useDispatch();

  useEffect(()=>{
    setLoading(true)
    dispatch(searchContact("",1,10,(data)=>{
        console.log(data)
        setLoading(false)
        dispatch(updateListView(data))
        setBaseData(listInfo.data)
      },()=>{},[]))

  },[])

  const onChangePage = (page, nextPage) => {
    console.log(page,nextPage)
    setLoading(true)
    dispatch(searchContact("",nextPage,10,(data)=>{
        //console.log(data)
        setLoading(false)
        dispatch(updateListView(data))
        setBaseData(listInfo.data)

    },()=>{},[]))
  }




  useEffect(() => {

    setLoading(true)
        var query = LDMDB
            .database()
            .ref("data/reports/"+id);

        

        const listener = query.once("value", (snapshot) => {
            ////// ////console.log(snapshot)
            if (snapshot) {
              console.log(snapshot.val())
              setReport(snapshot.val())

   

            }
            setLoading(false)
        });

  

        return listener
      
  }, [id])

 
 
  useEffect(() => {

    getData()

  },[report])


  useEffect(() => {

    getData()

  },[baseData])

  const getData = () =>{
    let data = baseData

    console.log(baseData)

    report.fields.map((field)=>{

   

    })

    setData(data)
  }


  const getDataXls = (fullData, studentData  ) => {
    const fileName = ("Reporte especial LDM")
    const exportType =  exportFromJSON.types.xls
    const data = []

    fullData.map((item)=>{
        let row = {}
        // ////console.log(item)
        report.fields.map((header)=>{
            let value = ""

      

            if (header.xlsFormatter ){
                value = header.xlsFormatter(getValue(header.db ? (item  && item.matricula ? studentData[item.matricula]:{})  : item,header.key), item)
            }else{
                value = getValue(header.db ? (item  && item.matricula ? studentData[item.matricula]:{})  : item, header.key)
            }

            let label = header.db  ?  header.label + " (sobrecargado)" : (header.txtLabel ? header.txtLabel : header.label)
            
            if (row[label]){

                let c = 1
                let newlabel = label + " " + c

                while(row[newlabel]){
                    c = c + 1;
                    newlabel = label + " " +c
                }
                label = newlabel
            }
            
            row[label] = value
            
    

        })

        data.push(row)

    })
    exportFromJSON({ data: data, fileName, exportType})


  }

  const donwloadData = () => {


    const searchContacts = []
    data.map((row, index)=>{


        if (mats[row.matricula] && contacts[mats[row.matricula]]){

        }else{
            searchContacts.push(row.matricula)
        }
    })

    
    setLoading(true)
    if (searchContacts.length > 0){
        dispatch(searchContact(searchContacts,1,searchContacts.length,(response)=>{
         

            let studentData = {}
            for (const [key, value] of Object.entries(contacts)) {
                studentData[value.matricula] = value
            }

            response.data.map((item)=>{
                studentData[item.matricula] = item
            })

             //console.log(studentData)
            getDataXls(data, studentData)

            setLoading(false)
        },()=>{},[]))
    }else{
        let studentData = {}

        for (const [key, value] of Object.entries(contacts)) {
            studentData[value.matricula] = value
        }
        getDataXls(data, studentData)

        setLoading(false)
    }
  }




  return (
    <React.Fragment>


      {loading?<Loader message={"message"}/> :""}

      <div className="float-end">
<Button onClick={donwloadData} icon={"fas fa-download"} color={"primary"}>Descargar</Button>
</div>
      
      
      <h3>{report.name}</h3>
      <hr/>
      <div className={"box-shadow rounded mt-0 p-3"}>
                
                <div className={"mt-2 pt-0"}>
      <List    onChangePage={onChangePage}
        hasPagination={true}
        pagination={listInfo}  data={data} headers={report.fields}/>
     
 </div>
 </div>
    </React.Fragment>
  )
}




export default (withRouter(ReportView))



