import { floor } from "lodash"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Link, useParams, withRouter } from "react-router-dom"
import { Card, CardBody, Spinner} from "reactstrap"
import { Button } from "../../components"

import List from "../../components/Table/List"
import { searchContact, setFormsNames } from "../../store/actions"
import { LDMDB, Loader } from "../../utils/Configs"
import { CAMPUS, getValue } from "../../utils/Tools"
import exportFromJSON from 'export-from-json'
import JSZip from "jszip"
import FileSaver from "file-saver"


const FILE_SET = {}

const  blobToBase64 = (blob, callback) => {
    var reader = new FileReader();
    reader.onload = function() {
        var dataUrl = reader.result;
        var base64 = dataUrl.split(',')[1];
        callback(base64);
    };
    reader.readAsDataURL(blob);
}

const statusFormatter = (value, row)=><div className={"text-center"}>{value ? <i className="fas fa-check text-success"/>:<i className="fas fa-times text-danger"/>}</div>
const xlsStatusFormatter = (value, row)=>`${value ? "COMPLETO":"INCOMPLETO"}`
let dataHeaders = []



let STATUS_HEADERS = [
]


const FormAnswers = (props) => {

    const { id, type } = useParams();

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [fields, setFBFields] = useState([])

  const [headers, setHeaders] = useState([])
  const [data, setData] = useState([])
  const [matriculas, setMatriculas] = useState([])

  useEffect(() => {
    STATUS_HEADERS = [
        {key:"estatus_general_0", label:"Estatus", formatter:statusFormatter, forms:[], xlsFormatter:xlsStatusFormatter},
      ]
  }, [])


  let OPTIONS_HEADERS = [
    {key:"matricula", label:"Formulario", 
    formatter:(value, row)=>{
        if (!(mats[row.matricula] && contacts[mats[row.matricula]])){
            return <Spinner/> 
        }
        
    
    return <Link target={"_blank"} to={type == "expedientes" ? `/perfil/${contacts[mats[row.matricula]].id}/${value}#expediente` : `/forms/${id}/${contacts[mats[row.matricula]].id}`} className={"text-center selectable"}><div className={"text-center selectable"}><i className="fas fa-external-link-alt text-primary"/>{row.id}</div></Link>},
    xlsFormatter:(value, row)=> ""},
    ]



  const formNames = useSelector(state => state.form.formNames);
  const contacts = useSelector(state => state.contact.dictionary);
  const mats = useSelector(state => state.contact.matDicts);
  
  
  const [dataToDisplay, setDataToDisplay] = useState([])
  const [pagination, setPagination] = useState({})

  const dispatch = useDispatch();

 
  let BASIC_HEADERS = [ 

    {key:"matricula", label:"Matrícula", dbsource: true},
    {key:"full_name", label:"Nombre", dbsource: true, formatter:(value, row)=>{
        // ////console.logog(row.matricula)
        if (!(mats[row.matricula] && contacts[mats[row.matricula]])){
            return <Spinner/> 
        }
        
        return (
                <Link  to={"/perfil/"+contacts[mats[row.matricula]].id+"/"+row.matricula}>{ contacts[mats[row.matricula]].full_name}</Link>
            )
    }},
    {key:"email", dbsource: true, label:"Correo", formatter:(value, row)=>{

        if (!(mats[row.matricula] && contacts[mats[row.matricula]])){
            return <Spinner/> 
        }
        
        return contacts[mats[row.matricula]].email
    }},
    {key:"phone", dbsource: true, label:"Teléfono", formatter:(value, row)=>{

        if (!(mats[row.matricula] && contacts[mats[row.matricula]])){
            return <Spinner/> 
        }
        
        return contacts[mats[row.matricula]].phone
    }},
    {key:"student.generation",xlsFormatter:(value, row)=>`${value}a (${2013+value})`, dbsource: true, label:"Generación", formatter:(valuex, row)=>{
        if (!(mats[row.matricula] && contacts[mats[row.matricula]])){
            return <Spinner/> 
        }
        let value = contacts[mats[row.matricula]].student && contacts[mats[row.matricula]].student.generation ? contacts[mats[row.matricula]].student.generation  : false
        return (
            value ? <div  style={{textAlign:"center"}}>{value}a ({2013+value})</div> : null
            )
    }},
    {key:"student.campus",xlsFormatter:(value, row)=>value ? ((value - 1) < CAMPUS.length ? CAMPUS[value - 1].campus : value) : "", dbsource: true, label:"Campus", formatter:(valuex, row)=>{
        if (!(mats[row.matricula] && contacts[mats[row.matricula]])){
            return <Spinner/> 
        }
        let value = contacts[mats[row.matricula]].student && contacts[mats[row.matricula]].student.campus  ? contacts[mats[row.matricula]].student.campus : false
        return (
                <div  style={{textAlign:"center"}}>{value ? CAMPUS[value - 1].campus :""}</div>
            )
    }}

]

const download = (url, name) => {
    if (!url) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    setLoading(true);
   

    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobURL;
        a.style = "display: none";

        if (name && name.length) a.download = name;
        document.body.appendChild(a);
        a.click();


        setLoading(false);
      })
      .catch(() => {});
  };

  const downloadBulk = (name) => {
    if (!name) {
      throw new Error("Resource URL not provided! You need to provide one");
    }
    setLoading(true);

    //console.logogog(FILE_SET)

    var zip = new JSZip();
    var count = 0;
    var total = FILE_SET[name].files.length;
    var zipFilename = `${name}.zip`;

    FILE_SET[name].files.map((file)=>{
        fetch(file.url)
        .then(response => response.blob())
        .then(blob => {
            blobToBase64(blob,(binaryData)=>{
                zip.file(file.name, binaryData, {base64:true});
          
                count++;
                //console.logogog(count, total);
                if (count ==  total) {
                    zip.generateAsync({type:'blob'}).then(function(content) {
                        FileSaver.saveAs(content, zipFilename);
                        setLoading(false);
                });
                
                }
            })
           
  
         
        })
        .catch((message) => {
            //console.logog(message)
            count++; 
        });
    })
   
  };

  useEffect(() => {
    if (data){
        setFinalHeaders(dataHeaders,(STATUS_HEADERS))
    }
  }, [mats])


  useEffect(() => {

    setLoading(true)
        var query = LDMDB
            .database()
            .ref(type+"/"+id+"/sections/");

        

        const listener = query.once("value", (snapshot) => {
            ////// ////console.log(snapshot)
            if (snapshot) {
           
                let field_headers = []
                snapshot.forEach(function (d) {
                    let section = d.val()
                    if (section && section.forms){
                        let indexSection = STATUS_HEADERS.length

                        if (section.displayStatus){
                            STATUS_HEADERS.push({key:"estatus_section_"+d.key, label:section.title, formatter:statusFormatter, forms:[], xlsFormatter:xlsStatusFormatter},)
                        }


                        section.forms.map((f, index)=>{
                            
                            if (f.fields){
                                if (f.displayStatus){
                                    STATUS_HEADERS.push({key:f.name, label:f.title, formatter:statusFormatter, isForm:true, xlsFormatter:xlsStatusFormatter},)
                                }

                                if (section.displayStatus ){
                                    STATUS_HEADERS[indexSection].forms.push(f.name)
                                }

                                if (!section.validate_admin){
                                    STATUS_HEADERS[0].forms.push(f.name)
                                }
                  
                                f.fields.map((field)=>{
                                     ////console.log(field)
                                    field_headers.push({key:`${f.name}.${field.name}`, label:field.label, txtLabel:field.label,type:field.type})

                                    if (field.constant_set && field.constant_set  == "campus"){
                                        field_headers[field_headers.length - 1].xlsFormatter = (value, row)=>value ? ((value - 1) < CAMPUS.length ? CAMPUS[value - 1].campus : value) : ""
                                        field_headers[field_headers.length - 1].formatter = (value, row)=>{
                                            
                                            return (
                                                    <div  style={{textAlign:"center"}}>{value ? CAMPUS[value - 1].campus :""}</div>
                                                ) 
                                    }
                                    }

                                    if (field.type == "FileUpload"){
                                    

                                        field_headers[field_headers.length - 1].label = (
                                            <div style={{display:"flex", flexDirection:"row", alignContent:"center", alignItems:"center", justifyContent:"center"}}>
                                                <div style={{flex:1}}>{field.label} <a href={"#"} onClick={()=>downloadBulk(`${f.name}.${field.name}`)}>(<i className={"fas fa-file-archive"}/> Descargar zip)</a></div>
                                                </div>
                                        )
                                        field_headers[field_headers.length - 1].xlsFormatter = (value, row)=>value ? value : "No hay archivo"
                                        field_headers[field_headers.length - 1].formatter = (value, row)=>{

                                            if (!value){
                                                return (
                                                    <a href={value} target={"_blank"} style={{textAlign:"center", color:"red"}} rel="noreferrer"><i className={"fas fa-times"}/> No hay archivo</a>
                                                ) 
                                            }
                                            let ext = value.split(".")
                                            ext = ext[ext.length -1]
                                            return (
                                                <div style={{display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", alignContent:"center"}}>
                                                    <div style={{flex:1}}><a  href={value} target={"_blank"} style={{textAlign:"center"}} rel="noreferrer"><i className={"fas fa-file"}/> Ver Archivo</a></div>
                                                    <div style={{flex:1}}><a onClick={()=>download(value, field.label + " - " + row["matricula" ]+"."+ext )}  href={"#"} style={{textAlign:"center"}}><i className={"fas fa-download"}/> Descargar</a></div>

                                                </div>) 
                                    }


                                 }
                                })
                            }

                        })



                    }
                });
                console.log(STATUS_HEADERS)
                dataHeaders = field_headers
                setFBFields(field_headers)
                setFinalHeaders(field_headers, STATUS_HEADERS)
                setLoading(false)
                getDataFB(field_headers)

            }
        });

        if (!formNames[id]){
            LDMDB.database().ref(type+"/"+id+"/title/").once('value').then((snapshot)=>{
                if (snapshot.exists()) {
                   ////console.log(snapshot.val())
                  const nf = formNames
                  nf[id] = snapshot.val()
                  dispatch(setFormsNames(nf))
      
                } else {
                   ////console.log(snapshot)
                }
              });
        }
  

        return listener
      
  }, [])

  const getDataFB = (field_headers) => {


    field_headers = field_headers ? field_headers : headers
    setLoading(true)

        var query = LDMDB
        .database()
        .ref("answers/"+id+"/");
       
         query.once("value", (snapshot) => {
            if (snapshot) {
                let data = []
                let mats = []
                snapshot.forEach(function (d) {
                    let answer = d.val()
                    if (d.key && d.val()){
                 
                        const v = d.val()
                        v.matricula = d.key
                        

                        STATUS_HEADERS.map((header)=>{
                            if (header.forms){
                                v[header.key] = true
                                header.forms.map((fname)=>{
                                    v[header.key] = v[header.key]&& (v[fname] ? true:false)
                                })
                            }
                        })
                        data.push(v)
                        mats.push(d.key)
                
                    }

                });

                field_headers.map((head)=>{
                   
                    if (head.type == "FileUpload"){
                        FILE_SET[head.key] = {files:[]}

                        data.map((row)=>{
                        
                            let value = getValue(row, head.key)
                            if (value){
                                console.log(value)
                                let ext = value.split(".")
                                ext = ext[ext.length -1]

                            
                                FILE_SET[head.key].files.push({url:value, name:head.txtLabel + " - " + row["matricula"] +"."+ext })
                                
                                ////console.logog(value)
                            }
                        })
                    
                    }
                })

                setMatriculas(mats)
                setData(data)
                setLoading(false)
                getDataToDisplay(1,25, data)
             
               
            }
        });
  }



  /*useEffect(()=>{

    if (!listInfo){
        setLoading(true)
        dispatch(searchContact("",1,10,(data)=>{
             ////console.log(data)
            setLoading(false)
            dispatch(updateListView(data))
          },()=>{},[]))
    }
  },[])*/

  const onChangePage = (page, nextPage) => {
    getDataToDisplay(nextPage, 25, data)
  }


  const getDataToDisplay = (page, size, data) => {
    const dataToShow = data.slice((page - 1) * size, ((page - 1) * size) + size)

    const searchContacts = []
    dataToShow.map((row, index)=>{

        console.log(row)
        if (mats[row.matricula] && contacts[mats[row.matricula]]){

        }else{
            searchContacts.push(row.matricula)
        }
    })


    if (searchContacts.length > 0){
        dispatch(searchContact(searchContacts,1,searchContacts.length ,(data)=>{
             console.log(data)
        },()=>{},[])) 
    }




    setDataToDisplay(dataToShow)

    let pages = floor(data.length / size + (data.length % size > 0 ? 1 : 0))

     ////console.log(pages)
    setPagination({
        'has_next': page  < pages,
        'has_prev':  page - 1 > 0,
        'page': page,
        'pages': pages,
        'next_num': page + 1 ,
        'prev_num': page - 1 ,
        "total": data.length,
    })

  }


  const getData = (fullData, studentData  ) => {
    const fileName = (formNames[id] ? formNames[id] : "Archivo")
    const exportType =  exportFromJSON.types.xls
    const data = []
    fullData.map((item)=>{
        let row = {}
        console.log("22")
        headers.map((header)=>{
            let value = ""

      
            console.log("33")
            console.log("ff",header.xlsFormatter)
            if (header.xlsFormatter ){
                value = header.xlsFormatter(getValue(header.dbsource ? (item  && item.matricula ? studentData[item.matricula]:{})  : item,header.key), item)
            }else{
                value = getValue(header.dbsource ? (item  && item.matricula ? studentData[item.matricula]:{})  : item, header.key)
            }
            console.log("--")
            let label = header.dbsource  ?  header.label + " (sobrecargado)" : (header.txtLabel ? header.txtLabel : header.label)
            console.log("---")
            console.log(label)
            if (row[label]){
                console.log(row[label])
                let c = 1
                let newlabel = label + " " + c

                while(row[newlabel]){
                    c = c + 1;
                    newlabel = label + " " +c
                }
                label = newlabel
            }
            
            row[label] = value
            
    

        })
        console.log("44")
        data.push(row)

    })
 
    exportFromJSON({ data: data, fileName, exportType})


  }
  const donwloadData = () => {


    const searchContacts = []
    data.map((row, index)=>{


        if (mats[row.matricula] && contacts[mats[row.matricula]]){

        }else{
            searchContacts.push(row.matricula)
        }
    })

    
    setLoading(true)
    setMessage("Descargando datos...")
    if (searchContacts.length > 0){
        dispatch(searchContact(searchContacts,1,searchContacts.length,(response)=>{
            setMessage("Preparando el archivo...")
            console.log("1")
            let studentData = {}
            for (const [key, value] of Object.entries(contacts)) {
                studentData[value.matricula] = value
            }
            console.log("2")
            response.data.map((item)=>{
                studentData[item.matricula] = item
            })
            console.log("3")
            console.log(studentData)
            getData(data, studentData)
            console.log("4")
            setLoading(false)
        },(error)=>{},[]))
    }else{
        let studentData = {}

        for (const [key, value] of Object.entries(contacts)) {
            studentData[value.matricula] = value
        }
        getData(data, studentData)

        setLoading(false)
    }
  }


  const setFinalHeaders = (fbheaders, statusHeaders=STATUS_HEADERS) => {
      console.log(statusHeaders)
    setHeaders([...OPTIONS_HEADERS,...statusHeaders,...BASIC_HEADERS,...fbheaders])
  }



  return (
    <React.Fragment>

    <div className="page-content m-3">
         
      {loading?<Loader message={message}/> :""}


      <div  style={{flexDirection:"row", display:"flex", alignItems:"center"}}>

          <div onClick={props.history.goBack} className={"selectable"} style={{fontSize:35, padding:10, marginRight:10}}><i className={"fas fa-chevron-left"}/></div>
          
      <div  style={{float:"left", flex:1,  alignItems:"center", justifyContent:"center"}}>
      <div><h2>{formNames[id] ? formNames[id] : 
                                <Spinner/>
                              }</h2></div>
        <div><h5 style={{color:"gray"}}>Respuestas <b style={{color:"black"}}>{data.length}</b></h5></div>

      </div>


        <div className={"me-1"}><Button onClick={()=>getDataFB(null)} className={"ms-1"} color={"primary"}><i className={"fas fa-sync"}/></Button></div>

         <Button onClick={donwloadData} icon={"fas fa-download"} color={"primary"}>Descargar</Button>
      </div>

     

        <div className={"mt-2"}/>
        <Card className={"box-shadow mt-0 pt-0"}>
                
                <CardBody className={"mt-2 pt-0"}>
      <List
  
        onChangePage={onChangePage}
        hasPagination={true}
        pagination={pagination}
        data={dataToDisplay}
        headers={headers}
      />
      </CardBody></Card>
      </div>
    </React.Fragment>
  )
}


FormAnswers.propTypes = {
 
}


export default (withRouter(FormAnswers))



