import React from "react"
import { FormGroup, Input, InputGroup, Label } from "reactstrap"
import PropTypes from 'prop-types';

import BaseField from "./BaseField"

class TextArea extends BaseField {

    constructor(props) {
        super(props);
        this.state = {
          value: props.value ||  props.default  || ""
        }
       
      }

      getValue = () =>{
          
          return this.state.value ? this.state.value  :""
      }

      setValue = (value) => {
        this.setState({value:value})
      }


    render(){
        const {  label, disabled, style, containerClass } = this.props;

        
        return (
            <FormGroup className={containerClass}>
                <Label>{label}{this.props.required ? <span className={"invalid-feedback"} style={{display:"inline", fontSize:14}}> *</span>:null}</Label>
                <InputGroup>
                <Input
                    type="textarea"
                    row={25}
                    {...this.props}
                    maxLength={this.props.maxLength}
        
                    multiple
                    onChange={e => {
                      //threshholdDefault(e)
                      this.setValue(e.target.value)
                    }}
                    style={
                      disabled ? {backgroundColor:"#f5f5f5", height:style && style.height ? style.height:100 }:{height:style && style.height ? style.height:100 } 
 
                    }

          
                    value={this.state.value}
                  
                  />
                </InputGroup>
                {this.renderErrorMessage()} 
        </FormGroup>
        
        )
    }
}

TextArea.propTypes =  Object.assign({}, BaseField.propTypes,   {
  maxLength: PropTypes.number,

});

TextArea.defaultProps = Object.assign({}, BaseField.defaultProps, {
  maxLength:625,
  disabled:false
});


export default TextArea