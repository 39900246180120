import axios from "axios"
import accessToken from "./auth-token-header"


//pass new generated access token here
const token = accessToken

//apply base url for axios
//const API_URL = "http://127.0.0.1:5000"
const API_URL = "https://api-v4.micasaldm.mx"
//const API_URL = "https://api-v2.apoyoestudiantil.mx"

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token
axiosApi.defaults.headers.common["Content-Type"] = 'application/json'
//axiosApi.defaults.headers.common["Access-Control-Allow-Origin"] = '*'


axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = token()
  axiosApi.defaults.headers.common["Content-Type"] = 'application/json'

  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = token()
  axiosApi.defaults.headers.common["Content-Type"] = 'application/json'

  //console.log(data)
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function postFile(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = token()
  axiosApi.defaults.headers.common["Content-Type"] = "multipart/form-data"

  //console.log(data)
  return axios({
    method: "post",
    url: API_URL + url,
    data: data,
    headers: { 
      "Content-Type": "multipart/form-data",
      "Authorization": token(),
      'Access-Control-Allow-Origin': '*',
   },
  })
  .then(response => response.data)

}


export async function put(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = token()
  axiosApi.defaults.headers.common["Content-Type"] = 'application/json'

  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = token()
  axiosApi.defaults.headers.common["Content-Type"] = 'application/json'

  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}


export class API{

  static get = (url,  onSuccess=(response)=>{}, onError=(response)=>{}, config = {}) => {

    axiosApi.defaults.headers.common["Authorization"] = token()
    axiosApi.defaults.headers.common["Content-Type"] = 'application/json'
  
    axiosApi.get(url, { ...config }).then((response)=>onSuccess(response.data)).catch((error=>onError(error)))
  }

  static post = (url,data,  onSuccess=(response)=>{}, onError=(response)=>{}, config = {}) => {

    axiosApi.defaults.headers.common["Authorization"] = token()
    axiosApi.defaults.headers.common["Content-Type"] = 'application/json'
  
  
    axiosApi.post(url, { ...data }, { ...config }).then((response)=>onSuccess(response.data)).catch((error=>onError(error)))
  }

  static S3put = (url,file,  onSuccess=(response)=>{}, onError=(response)=>{}, onUploadProgress = (progressEvent)=>{}) => {

    let axiosAWS = axios.create({
      headers: { 
      "Content-Type": file.type,
      'X-Amz-Acl': 'public-read'
      }
    })

    console.log(file.type)
  
    axiosAWS.put(url,file,{ onUploadProgress: onUploadProgress,} ).then((response)=>onSuccess(response.data)).catch((error=>onError(error)))
  }

  static upload =(url,data,  onSuccess=(response)=>{}, onError=(response)=>{}) =>{
    axiosApi.defaults.headers.common["Authorization"] = token()
    axiosApi.defaults.headers.common["Content-Type"] = "multipart/form-data"
    let formData = new FormData();
    
    for (const [key, value] of Object.entries(data)) {
      console.log(key, value)
      if (value && value.fileValue){
        formData.append(key, value.fileValue);
      }else{
        formData.append(key, value);

      }
    }

  console.log(formData.entries())
  return axiosApi.post( url,
    formData,
    {
      onUploadProgress: progressEvent => console.log(progressEvent.loaded),

      headers: { 
      "Content-Type": "multipart/form-data",
      "Authorization": token(),
      'Access-Control-Allow-Origin': '*',
   },
  }).then((response)=>onSuccess(response.data)).catch((error=>onError(error)))

}

}