import React from 'react';
import {default as I} from './Fields/Input'
import {default as C} from './Fields/Checkbox'
import {default as CG} from './Fields/CheckboxGroup'
import {default as RG} from './Fields/RadioGroup'
import {default as DP} from './Fields/DatePicker'
import {default as S} from './Fields/Select'
import {default as MI} from './Fields/MaskInput'
import {default as TA} from './Fields/TextArea'
import { Table as TBLE } from 'reactstrap';




export const Form = React.lazy(() => import('./Form/Form'));
export const FormWizard = React.lazy(() => import('./Form/FormWizard'));

export const Button = React.lazy(() => import('./Buttons/Button'));

export const Table = TBLE
export const Input = I;
export const Checkbox = C;
export const CheckboxGroup = CG;
export const RadioGroup = RG;
export const DatePicker = DP;
export const Select = S;
export const MaskInput = MI;
export const TextArea = TA;

export const Modal = React.lazy(() => import('./Modals/Modal'));
export const VerticalLayout = React.lazy(() => import('./Layout/VerticalLayout'));
export const HorizontalLayout = React.lazy(() => import('./Layout/HorizontalLayout'));
export const NonLayout = React.lazy(() => import('./Layout/NonLayout')); 
export const DataTable = React.lazy(() => import('./Table/DataTable')); 
export const List = React.lazy(() => import('./Table/List')); 
