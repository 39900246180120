
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { withRouter } from "react-router-dom"
import { Container} from "reactstrap"
import { Button, Modal } from "../../components"
import Input from "../../components/Fields2/Input"
import RadioButton from "../../components/Fields2/RadioButton"
import RichText from "../../components/Fields2/RichText"
import Select from "../../components/Fields2/Select"
import Upload from "../../components/Fields2/Upload"
import Form from "../../components/Form2/Form"
import { API } from "../../utils/backend/api_helper"
import { CARRERAS, COUNTRIES, COUNTRIES_DIC, MODALIDADES, MODALIDADES_DIC, TIPO_OFERTA, TIPO_OFERTA_DIC } from "../../utils/Tools"



const Vinculacion = (props) => {

  const [loading, setLoading] = useState(false)
  const user = useSelector(state => state.profile.user);
  //const convocatorias = useSelector(state => state.convocatoria.data);
  //const solicitudes = useSelector(state => state.solicitud.data);
  const modal = useRef();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1)
  const [offers, setOffers] = useState([])
  const getOffers = () => {
    setLoading(true)
    API.get(
      `/opportunity/pag/${page}/20`, 
      (response)=>{
        console.log(response)
        setOffers(response.data.items)
        setLoading(false)

      },
      (response)=>{
        setLoading(false)
      }
    )
  }


  useEffect(()=>{
    //dispatch(getConvocatorias())
    //dispatch(getSolicituds())
    getOffers()
  },[page])

  /*const createSolicitud = (values, validData) => {
    console.log(values, validData)
    
    if (validData){
      setLoading(true)
      dispatch(postSolicitud(values, 
        ()=>{
          setLoading(false)
          dispatch(getSolicituds())
          modal.current.toggle()
          props.history.push("/solicitud")
        }))
    }
  }*/

  const uploadOffer = (data, valid) =>{
    console.log(data)
    if (valid){
      setLoading(true)
      API.upload("/opportunity",data,()=>{ 
        getOffers()
        modal.current.toggle()
      },()=>{ setLoading(false)})
    }

  }

  const deleteOffer = (id) =>{
    setLoading(true)
      API.post(`/opportunity/${id}/delete`,{},()=>{ getOffers()},()=>{ setLoading(false)})


  }

  const modalOffer = () => {
    return (
      <Modal title={"Agregar oferta"} ref={modal}>

        <Form className={"bg-body"} onSubmit={uploadOffer}>
        <div className="row">
          <Input containerClass={"col-6"} required label={"Titulo"} name={"title"} />
          <Input containerClass={"col-6"} required label={"Empresa/compañia"} name={"company"} />

          </div>
          <div className="row">
          <Select required containerClassName={"col-4"}  options={ TIPO_OFERTA} label={"Tipo de oferta"} name={"offer_type"}/>
          <Select required containerClassName={"col-4"} options={ MODALIDADES} label={"Modalidad"} name={"modality"}/>
          <Select required containerClassName={"col-4"}  options={ COUNTRIES.map((c)=>{return {value:c.code, label:c.name_es}})}  label={"País"} name={"country"}/>
          </div>

          <Select multiple required options={ CARRERAS.map((c)=>{return {value:c.id, label:c.carrera}})}  label={"Carreras"} name={"carrers"}/>

          <RichText required containerClassName={"col-"} label={"Descripción de portada"} name={"small_description"}/>
          <RichText required containerClassName={"col-"} label={"Descripción"} name={"description"}/>

  
          
          <div className="row">
          <Upload containerClassName={"col-6"} label={"Portada"} name={"cover_image"}/>
          <Upload containerClassName={"col-6 "}  label={"Flyer"} name={"flyer"}/>
          </div>

          <div className="row">
            <RadioButton required name={"active"} label={"Estatus de la oferta"} cols={"2"} className={"col-12"} options={[{value:true, label:"Activo"},{value:false, label:"Inactivo"} ]} />
          </div>
<hr/>
          <div className="d-flex justify-content-end">
          <Button  type={"submit"} color={"success"}>Agregar oferta</Button>
          </div>
        
        </Form>
      </Modal>
    )
  }
  
  return (
    <React.Fragment>
      {loading?"":""}
      {modalOffer()}
      <div className=" p-0 mb-6">
      <div className="" style={{height:70}}/>
                            <div className="d-none  d-lg-block" style={{height:53}}/>
        
          
          <div className="border-bottom" style={{background:"linear-gradient(rgba(26, 49, 146, 1),rgba(26, 49, 146, 0.75), rgba(26, 49, 146, 0.65), rgba(26, 49, 146, 0.35)), url('/networking.jpeg')", backgroundPosition:"center", backgroundRepeat:"no-repeat", backgroundSize:"cover" }}>
            <Container className="text-center text-white text-shadow py-6" fluid>
                <h1 className="text-white text-shadow"><b>¡Bienvenido a la sección de Vinculación!</b></h1>
                <p className="fs-2" style={{fontSize:14}}>
                Con el objetivo de impulsar tu carrera profesional, a través de esta sección queremos compartir contigo oportunidades de prácticas profesionales, vacantes en programas de becarios y trainees, además de hacer invitaciones a sesiones informativas del Centro de Vida y Carrera o de empresas en busca de talento joven.
<br/>
Te invitamos visitar esta sección constantemente para que puedas conocer las nuevas oportunidades de vinculación empresarial y para LDM.

                </p>
              </Container>
          </div>
          <Container>
            <div className="card-body">
            <div style={{color:"gray", flex:1, display:"flex", alignItems:"center"}}>
              <div className="text-muted h3 flex-fill ">Ofertas</div>

              {user.admin ? 
                       <Button onClick={()=>modal.current.toggle()} color={"primary"}><i className="fas fa-plus"/> Agregar oferta</Button>:null}
                    </div>
            <div className={"row row-cols-1 row-cols-md-2 row-cols-xl-3"}>

                {offers.map((offer, key)=><div key={key} className="col  pb-2">
                     <div  className="card h-100 box-shadow p-0">


                          {offer.cover_image ? <img src={offer.cover_image} height={200} style={{objectFit:"cover"}} className=" card-img-top border rounded-top"/> : 

                          <div style={{height:200}} className={" border d-flex justify-content-center align-items-center bg-light card-img-top p-4 img-fluid"} >
                            <i className="fas fa-briefcase"/> 
                          </div>
                          }

                        <div className="px-3 pt-2  h-100  d-flex flex-column">
                    

                      <h3 >
                     
                      {offer.title}

                      </h3>
                      <b>{offer.company}</b>
                    


                      <p className="card-text border-top mt-2 flex-grow-1">
                          <div dangerouslySetInnerHTML={{__html:offer.small_description}} />
                      </p>
                    
                   
                      <h6 className="align-self-center">
                     
                     { TIPO_OFERTA_DIC[offer.offer_type].label} • {MODALIDADES_DIC[offer.modality].label} • { COUNTRIES_DIC[offer.country].name_es}

                     </h6>


                     {user.admin ?<div className={"d-flex justify-content-evenly align-items-stretch pb-3"}>

                      <Button onClick={()=>deleteOffer(offer.id)} block className={"w-100"} color={"danger"}><i className="fas fa-trash"/> Eliminar</Button>

                      </div>:null}



                    </div>

                 
                  </div>
                </div>)}
            </div>

            </div>
          </Container>
      
            
        
      </div>
    </React.Fragment>
  )
}


Vinculacion.propTypes = {
 
}


export default (withRouter(Vinculacion))



// <Card className={"box-shadow"}>
            
// <CardBody style={{padding:0}}>

//   <div style={{display:"flex", alignItems:"center"}}>


//     <div style={{flex:1, fontSize:17, padding:40, }}>
//       <b>¡Bienvenido a la sección de Vinculación!</b> <br/> <br/>
      
//       En este espacio encontrarás oportunidades de vinculación y crecimiento profesional derivadas de las alianzas que tenemos con empresas y organismos nacionales e internacionales, así como una red para el fortalecimiento del talento y liderazgo de los egresados Líderes del Mañana.
    
//       <div style={{color:"grey"}}><br/> <br/><b>Por ahora no hay ninguna oferta.</b> </div>
//     </div>

//     <div style={{flex:1.5}}>
//       <img src={"http://lideresdelmanana.itesm.mx/sites/all/themes/lideres/images/LDM-Oportunidades.jpg"} style={{width:"100%", borderBottomRightRadius:5, borderTopRightRadius:5 }}/>
//     </div>



//   </div>
  
// </CardBody>
// </Card>