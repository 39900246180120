import React from 'react';
import { Card
} from 'reactstrap';

import StackGrid from "react-stack-grid";
import logo1 from "../../assets/images/logo-dark1.png"
import logo2 from "../../assets/images/logo-dark2.png"

import { useMediaQuery } from 'react-responsive';

const fontStyle = {}


const Divider = ({props}) => {
    return (
        <div style={{margin:1}} {...props} />
    )
}

const videos = [
    {nombre: "Aleida Machorro",
    periodo: "1er generación",
    matricula: "",
    contenido: "",
    carrera_id: "IFI",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:"https://s3.us-east-2.amazonaws.com/muro.graduados/mg_aleida_machorro.png",
    video_url: "https://drive.google.com/file/d/1SxpfM2c_iLnLULQzcQkDFX0FKoZt0zgu/preview",
    carrera: "Ingeniero Fisico Industrial",
    campus_id: 18},

    {nombre: "Alejandro Quintero",
    matricula: "",
    periodo: "2da generación",
    contenido: "",
    carrera_id: "LAF",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:"https://s3.us-east-2.amazonaws.com/muro.graduados/mg_alejandro_quintero_v2.png",
    video_url: "https://drive.google.com/file/d/1eUX0zn6ia1JEsYRT1mzn9JYQ6lKjG9Fw/preview",
    carrera: "Licenciado en Administración Financiera",
    campus_id: 18},

    {nombre: "Angel Briones",
    matricula: "",
    periodo: "2da genración",
    contenido: "",
    carrera_id: "INT",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:"https://s3.us-east-2.amazonaws.com/muro.graduados/mg_angel_briones_v2.png",
    video_url: "https://drive.google.com/file/d/1RLUUNaNnqgBv8N1WTo6DrFCnYO9h8Vm1/preview",
    carrera: "Ingeniero en Negocios y Tecnologías de la Información",
    campus_id: 18},

    {nombre: "Cynthia Castillo",
    matricula: "",
    periodo: "1er generación",
    contenido: "",
    carrera_id: "IMA",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:"https://s3.us-east-2.amazonaws.com/muro.graduados/mg_cynthia+castillo.png",
    video_url: "https://drive.google.com/file/d/1LBtdheqr0QYMIR4qMsvSCGFy0Ov6uWq9/preview",
    carrera: "Ingeniero Mecánico Administrador",
    campus_id: 18},

    {nombre: "Iván Vázquez",
    matricula: "",
    periodo: "1er generación",
    contenido: "",
    carrera_id: "ITC",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:"https://s3.us-east-2.amazonaws.com/muro.graduados/mg_ivan_vazquez.png",
    video_url: "https://drive.google.com/file/d/1gdiYAiIbdaG4DOFi38dRlRZD0BeBfYcR/preview",
    carrera: "Ingeniero en Tecnologías Computacionales",
    campus_id: 18},

    {nombre: "Jorge Ortega",
    matricula: "",
    periodo: "2da gneración",
    contenido: "",
    carrera_id: "",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:"https://s3.us-east-2.amazonaws.com/muro.graduados/mg_jorge_ortega_v2.png",
    video_url: "https://drive.google.com/file/d/1zrAK65XGI0IRTzLr5WSUEhnYgb2b7j3a/preview",
    carrera: "Ingeniero Químico en Procesos Ssutentables",
    campus_id: 18},

    {nombre: "Liliana Orta",
    matricula: "",
    periodo: "1er generación",
    contenido: "",
    carrera_id: "LIN",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:"https://s3.us-east-2.amazonaws.com/muro.graduados/mg_liliana_orta_v2.png",
    video_url: "https://drive.google.com/file/d/1ZugUpT_9OaiyEuWNdrgGYGpePgM93H2L/preview",
    carrera: "Licenciado en Negocios Internacionales",
    campus_id: 18},

    {nombre: "Mario Rodríguez",
    matricula: "",
    periodo: "2da generación",
    contenido: "",
    carrera_id: "INCQ",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:"https://s3.us-east-2.amazonaws.com/muro.graduados/mg_mario_rodriguez_v2.png",
    video_url: "https://drive.google.com/file/d/1Y-LhobxIz6BHW6yl-M6b378rN5JN9uF6/preview",
    carrera: "Ingeniero en Nanotecnología y Ciencias Químicas",
    campus_id: 18},

    {nombre: "Oscar Laureano",
    matricula: "",
    periodo: "2da generación",
    contenido: "",
    carrera_id: "ITC",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:"https://s3.us-east-2.amazonaws.com/muro.graduados/mg_oscar_laureano_v2.png",
    video_url: "https://drive.google.com/file/d/1OV5VuXpr6tnt2Q0OkKGIA4oRwtXEM8VJ/preview",
    carrera: "Ingeniero en Tecnologías Computacionales",
    campus_id: 18},

    {nombre: "Ricardo Ortiz",
    matricula: "",
    periodo: "1er generación",
    contenido: "",
    carrera_id: "",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:"https://s3.us-east-2.amazonaws.com/muro.graduados/mg_ricardo_ortiz_v2.png",
    video_url: "https://drive.google.com/file/d/1EDuGMlTb8uVO178a0cwrOKBaxCACEMxp/preview",
    carrera: "Licenciado en Administración y Estrategia de Negocios",
    campus_id: 18},

    {nombre: "Sandra Reyes",
    matricula: "",
    periodo: "1er generación",
    contenido: "",
    carrera_id: "IBT",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:"https://s3.us-east-2.amazonaws.com/muro.graduados/mg_sandra_reyes.png",
    video_url: "https://drive.google.com/file/d/1grbgYUjb12JPDRD26vI3HhDaGLRbl3E3/preview",
    carrera: "Ingeniero en Biotecnología",
    campus_id: 18},

    {nombre: "Victor Caballero",
    matricula: "",
    periodo: "1er generación",
    contenido: "",
    carrera_id: "IQA",
    campus: "Monterrey",
    contenido_breve: "",
    image_url:"https://s3.us-east-2.amazonaws.com/muro.graduados/mg_victor_caballero.png",
    video_url: " https://drive.google.com/file/d/1Qk3tFzalLg4tlYY-KZ9kObzGTr1973IZ/preview",
    carrera: "Ingeniero Químico Administrador",
    campus_id: 18},
]



const MuroGraduados = () => {


        let mod600 = useMediaQuery({ query: `(min-width: 800px)` })
        let mod800 = useMediaQuery({ query: `(min-width: 1000px)` }) 
        return (
            <React.Fragment>
            <div className="bg-primary p-2 pt-3 pb-3">
                <div style={{display:"flex", flexDirection:"row", alignContent:"center", alignItems:"center"}}>
                    <div style={{flex:1,textAlign:"center"}}>
                        <img src={logo1} alt="" height={45}/>
                    </div>
                    <div style={{flex:2,textAlign:"center"}}>
                            <b style={{ color:"white", fontSize:22}}>Muro de Gratitud<br/></b>
                    </div>
                    <div style={{flex:1, textAlign:"center"}}>
                    <img src={logo2} alt="" height={40}/>
                    </div>
                </div>
            </div>

            <div className="account-pages">

                <div>
            <div style={{marginBottom:"128px"}}>
                <StackGrid  gutterWidth={1} gutterHeight={1}
                           columnWidth={mod800 ?'33.33%': mod600? "50%": '100%'  }
                        
                >
                    {videos.map((item, key) =>
                        <div key={key}>
                           
                            <iframe     style={{border:"1px solid #f5f5f5"}}
              frameBorder="0" src={item.video_url} allow="autoplay" width="100%" height={mod800 ?'270': mod600? "210": '300' }>


                                <div style={{ height: "300px"}}>
                                    <div className={"detailVideo"}>
                                        
                                        <div className={"detSdetailVideo"}></div>
                                        <div className={"detailVideo_info"}>
                                            <div level={4} style={{float:"right"}} className={"detailVideo_info2"}>{item.carrera_id}</div>
                                            <div level={3} className={"detailVideo_info2"}>{item.nombre}</div>
                                            <p className={"detailVideo_info2"} style={{fontSize:"12px"}}>
                                                Campus {item.campus} | {item.periodo}
                                            </p>

                                                <div className={"detailVideo_info2"}  ellipsis={{ rows: 5 }}  style={{paddingTop:5, fontSize:"10px", fontStyle:"italic"}}>
                                                {item.contenido_breve}
                                                </div>

                                        </div>
                                        <div className={"detS2"}></div>
                                    </div>
                                </div>
                            </iframe>

                        </div>
                    )}
                </StackGrid>
                 
            </div>
            <Card style={{ bottom:0, zIndex:100, position:"fixed", width:"100%", background:"rgba(255,255,255,0.80)"}}>
                    {/*<Input.Search
                        placeholder="Nombre o matrícula"
                        enterButton={true}
                        onSearch={(value)=>{
                            console.log(value);
                            this.searchText = value;
                            this.filter();
                        }}
                        onChange={(e)=>{
                            console.log(e.target.value );
                            if (e.target.value === ""){
                                this.searchText = e.target.value ;
                                this.filter();
                            }
                        }}
                        style={{ width: 300, marginBottom:10 }}
                    />
                    <br/>
                         <label>Animación: <Switch  onChange={this.onChangeAutomatic} /></label>
                    <label>
                        &nbsp;Campus:&nbsp;&nbsp;
                    <Select
                        mode="multiple"
                        style={{ width: '300px' }}
                        placeholder="Filtra por  campus"
                        onChange={(value)=>{
                            console.log(value);
                            this.campusFilter = value;
                            this.filter();

                        }}

                    >
                        {this.state.campus}
                    </Select>
                    </label>
                    <label>
                        &nbsp;Carreras:&nbsp;&nbsp;
                        <Select
                        mode="multiple"
                        style={{ width: '300px', }}
                        placeholder="Filtra por  carrera"
                        onChange={(value)=>{
                            console.log(value);
                            this.carrerasFilter = value;
                            this.filter();

                        }}

                    >
                        {this.state.carreras}
                    </Select>
                    </label>*/}
            

                </Card>
                       </div> 
                       </div>
                       </React.Fragment>
        );

}

export default MuroGraduados