
import {
    GET_ALL_POST_SC,
    GET_POST_SC,
    POST_POST_SC,
    PUT_POST_SC,
    DELETE_POST_SC,
    GET_ALL_POST_SC_SUCCESS,
    GET_POST_SC_SUCCESS
  } from "./actionTypes"

  export const getAllPostSC = (page=1, user_id=null,callback=()=>{}, onError=()=>{}) => ({
    type: GET_ALL_POST_SC,
    payload: {page,user_id,callback, onError},
  })

  export const getPostSC = (id="", callback=()=>{}, onError=()=>{}) => ({
    type: GET_POST_SC,
    payload: {id, callback, onError},
  })

  export const deletePostSC = (id="", callback=()=>{}, onError=()=>{}) => ({
    type: DELETE_POST_SC,
    payload: {id, callback, onError},
  })

  export const postPostSC = (data={}, file=null, callback=()=>{}, onError=()=>{}) => ({
    type: POST_POST_SC,
    payload: {data, file, callback, onError},
  })

  export const putPostSC = (id="",data={}, callback=()=>{}, onError=()=>{}) => ({
    type: PUT_POST_SC,
    payload: {id, data, callback, onError},
  })

  export const getAllPostSCSuccess = data => ({
    type: GET_ALL_POST_SC_SUCCESS,
    payload: data,
  })

  export const getPostSCSuccess = data => ({
    type: GET_POST_SC_SUCCESS,
    payload: data,
  })
  
