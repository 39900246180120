
import React, { useEffect, useRef, useState } from "react"


import {  useHistory, withRouter } from "react-router-dom"
import Input from "../../components/Fields2/Input"
import Form from "../../components/Form2/Form"

import logo from "../../assets/images/logo.png"
import Button from "../../components/Buttons/Button"
import { useDispatch } from "react-redux"
import { loginUser } from "../../store/actions"
import swal from "sweetalert"


const LoginCandidato = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const dispatch =  useDispatch()

    const history = useHistory()
    const [redirect, setRedirect] = useState("")


    useEffect(()=>{
      const queryString = window.location.search;
      ////console.log(queryString);
      const urlParams = new URLSearchParams(queryString);
  
    
      var hash = window.location.hash.substr(1) || "";
      var red = urlParams.get('rediretTo')
      setRedirect((red? red:"") + (hash ? "#" + hash : "") )
  
      console.log(redirect)
    }, [])

    const requestMailCode = (data, valid) => {
      
        if (valid){
            setLoading(true)

            dispatch(loginUser(data,(response)=>{
                console.log(response)
                setLoading(false)

      

                if (redirect && redirect != "null"){
                    window.location.href = redirect
                  }else{
                    window.location.href = "/candidatos/home"
        
                  }



            },
            (response)=>{
                console.log(response)
                swal({
                    title: "Error",
                    text: response,
                    icon: "error"
                })

                setLoading(false)

            },()=>{
                requestMailCode(data, valid)
              },false, true ))
            
        }

    }
 
  return (
    <React.Fragment>
      
      <React.Fragment>
            <div className="row m-0"> 
    <div className= "col-lg-7 col-md-12 p-0">
        <div style={{height: "100%", width: "100%", backgroundImage: ` linear-gradient(to top, rgba(0,156, 217, .25), rgba(0,156, 217, 0.05)), url(/Captura.png)`,backgroundPosition: "center center",backgroundSize: "cover",backgroundRepeat: "no-repeat"}} >
           
        </div>
    </div>
    <div className= "col-lg-5 col-md-12 p-0 bg-white">
        <div className= "login-content">
            <div className={"d-flex vh-100 justify-content-center"}>
                <div className="align-self-center p-4 p-xl-6 "  >
                    <div className="login-form">
                       
                        <div className={"mb-4 text-center"}>
                                    <img src={logo} className={"img-fluid"}/>

                                </div>
                        
                            <h3>¡Bienvenid@!</h3>
                            <p>Te damos la bienvenida a la siguiente etapa del proceso Líderes del Mañana. En este espacio encontrarás los pasos necesarios para continuar con tu solicitud. </p>
                            <Form ref={form} onSubmit={requestMailCode}>
                                    <div className= "mt-3 mb-3">
                                        <Input required name={"email"} label="Correo con el que te registraste en el sistema de preselección de Líderes del Mañana" />
                                    </div>

                                    <div className= " mb-3">
                                        <Input type={"password"} required name={"password"} label="Contraseña" />
                                    </div>

                                    <div className="d-flex justify-content-center mb-3">
                                        <Button
                                            size={"lg"}
                             
                                            onClick={() => {
                                                form.current.submit()
                                            }}
                                            type={"submit"}
                                            color={"primary"}>
                                                {loading ? <span><div className="spinner-border text-light spinner-border-sm" role="status">
  <span className="visually-hidden">Loading...</span>
</div>{" "}Cargando...</span> :"Continuar"}
                                        </Button>
                                        </div>

                                        {/* <div className=" d-flex justify-content-center mb-3">
                        
                                            <a href="/login/#">¿Olvidaste tu contraseña? o ¿No tienes contraseña?</a>
                                   
                                        </div> */}
                                        
                                       
                                    </Form>
                                </div>
                            </div>
             </div>
            </div>
            </div>
        </div>

        </React.Fragment>
    </React.Fragment>
  )
}


LoginCandidato.propTypes = {
 
}


export default (withRouter(LoginCandidato))



