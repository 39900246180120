
import React, {  useRef,forwardRef, useImperativeHandle, createRef, useEffect } from "react"


const VALID_FIELDS = [
    "Input",
    "TimePicker",
    "DatePicker",
    "CheckboxGroup",
    "Checkbox",
    "Radio",
    "RadioGroup",
    "Select",
    "MaskInput",
    "Upload",
    "TextArea"
]

const RULES = [
    "length"
]

const Form = (props, ref) => {


    let form = useRef()
    let fields = {};


    useEffect(() => {
        if (props.didMount){
            props.didMount()
        }
           
        
 

        if (props.onChangeAnswers){
            let respDta = processData({}, false)
            props.onChangeAnswers(respDta.data, respDta.validData)
            
        }
    }, [])

    useEffect(() => {
        if (props.didMount){
            props.didMount()
        }

        if (props.answers){
           fill(props.answers)
        }


        if (props.onChangeAnswers){
            let respDta = processData({}, false)
            props.onChangeAnswers(respDta.data, respDta.validData)
            
        }


    }, [props.answers])




    const field = (field, key) => {
        //console.log(field)
        if (field && field.type){
            //console.log(field)
            const FieldComponent = field.type
            // console.log(field)
            if (field.props && field.props.name && field.props.formfield === "true"){
                fields[field.props.name] = createRef()
            }

            if (props.defaultData){
                //field.props.default = props.defaultData[field.props.name]
            }

            return <FieldComponent key={field.props.name} onChange={()=>{

                if (props.onChangeAnswers){
                    setTimeout(()=>{
                        let respDta = processData({}, false)
                        props.onChangeAnswers(respDta.data, respDta.validData)
                     }, 300);
                  
                    
                }
            }}  ref={fields[field.props.name]}  {...field.props}/>
        }else{
            return field
        }
    }
    
    const layout = (arr, key=1) =>{
        if (arr && Array.isArray(arr)){
            return <>
                    {arr.map((element, id)=>{
                        return layout(element, id)
                    }) } </> 
    
        }else if (arr && arr.props && arr.props.children && Array.isArray(arr.props.children)){
           
                //console.log(arr.type.name)
                let Wrapper = arr.type
                return <Wrapper key={key} {...arr.props}>
                    {arr.props.children.map((element, id)=>{
                        return layout(element, id)
                    }) } </Wrapper>  
      
    
        } else if (arr && arr.props && arr.props.children){
                //console.log(arr.type.name)
                let Wrapper = arr.type

                if (VALID_FIELDS.includes(Wrapper.name) && field.props.name){
                    fields[field.props.name] = createRef()
                }

                return <Wrapper key={key}  {...arr.props}>
                    {[arr.props.children].map((element, id)=>{

                        if (element && element.type){
                            if (!VALID_FIELDS.includes(element.type.name)){
                                
                                return layout(element, id)
                            }
                        }

                        return field(element, id)


                    }) } </Wrapper>  
    
        }else{
            return field(arr, key)
    
        }
    }    


    const fill = (data) => {
        //console.log(form)
        if (data){
            let keys = Object.keys(data)
            for(let i = 0; i < keys.length; i++){
                if (fields[keys[i]]){
                    fields[keys[i]].current.setValue(data[keys[i]])
                }
            }
        }

    }

    const reset = () =>{
        let keys = Object.keys(fields)
        for(let i = 0; i < keys.length; i++){
            fields[keys[i]].current.setValue("")
        }
    }
    
    const validateData  = (name, value, field, displayErrors) => {

        if (field.isRequired()){
            //console.log(name, value, field)
            if (value === undefined  || value === null || value === ""){
               
        
                if (displayErrors){
                    field.displayError(true)
                }
                return false
            }
        }

        if (field.getValidations()){
            let validations = field.getValidations()

            for(let i = 0; i < validations.length; i++){
                let validation = validations[i]
                switch (validation.type) {
                    case RULES[0]:
                        if (value.length < validation.min || value.length > validation.max){
                            if (displayErrors){
                                field.displayError(true,validation.message )
                            }
                            return false
                        }
                        break;
                    default:
                        break;
                }

            }

        }

        return true
    }

    let processData = (dataIn, displayErrors) =>{
        let data = {}
        let validData = true;
        let keys = Object.keys(fields)
        console.log(fields)
        for(let i = 0; i < keys.length; i++){
            if (fields[keys[i]].current){
                data[keys[i]] = fields[keys[i]].current.getValue();
                fields[keys[i]].current.displayError(false);
    
                validData = (validateData(keys[i], data[keys[i]], fields[keys[i]].current, displayErrors) && validData);
                //console.log(keys[i], data[keys[i]],validData )
            }else{

            }
         
        }
        return {data:data, validData:validData}
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        //console.log(fields)

        let respData = processData(null, true)

        if (props.onSubmit){
            props.onSubmit(respData.data, respData.validData)
        }

        return respData
    }

    const submit = () => {
        //console.log(form)
        return handleSubmit(new Event("submit"));
    }

    useImperativeHandle(ref, () => ({
        fill,
        submit,
        reset
    }));

    //console.log(props.children)
    //layout(props.children)

    //console.log(fields)
    return (
        <form ref={f => (form = f)} onSubmit={handleSubmit} onReset={reset}>
            {props.children ? layout(props.children) : null}
        </form>
     
    )
};

export default forwardRef(Form)