import { PROFILE_ERROR, PROFILE_SUCCESS, GET_PROFILE, PUT_PROFILE, PUT_EXATEC, PUT_FILES} from "./actionTypes"

export const getProfile = user => {
  return {
    type: GET_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const putFiles= (id="",files={}, callback=()=>{}, onError=()=>{}) => {
  return {
    type: PUT_FILES,
    payload: {id, files, callback, onError}
  }
}

export const putPrfProfile = (id="",data={}, callback=()=>{}, onError=()=>{}) => {
    return {
      type: PUT_PROFILE,
      payload: {id, data, callback, onError}
    }
}

export const updateExatec = (id="",data={}, callback=()=>{}, onError=()=>{}) => {
  return {
    type: PUT_EXATEC,
    payload: {id, data, callback, onError}
  }
}
