import React from "react"
import { FormGroup, InputGroup, Label } from "reactstrap"

import BaseField from "./BaseField"
import InputMask from "react-input-mask"
import PropTypes from 'prop-types';

class MaskInput extends BaseField {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value ||  props.default  || ""
        }
       
      }

      getValue = () =>{
          
          return this.state.value && (this.state.value.replace("_","").length === this.props.mask.length ) ? this.state.value  : ""
      }

      

      setValue = (value) => {
        this.setState({value:value})
      }


    render(){
        const { mask, label, disabled } = this.props;

        
        return (
            <FormGroup>
                <Label>{label}{this.props.required ? <span className={"invalid-feedback"} style={{display:"inline", fontSize:14}}> *</span>:null}</Label>
                <InputGroup>
                    <InputMask
                        mask={mask}
                        value={this.state.value}
                        className="form-control input-color"
                        style={disabled? {backgroundColor:"#f5f5f5"}:{}}
                        onChange={(event)=>{
                            if (disabled){
                                return
                            }
                            this.setState({value:event.target.value})
                        }}
                        >
                        {inputProps => (
                            <input {...inputProps} />
                        )}
                    </InputMask>
                </InputGroup>
                {this.renderErrorMessage()}
        </FormGroup>
        
        )
    }
}

MaskInput.propTypes = {
    mask: PropTypes.string.isRequired,
    disabled:false
}


export default MaskInput