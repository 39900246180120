
import React, { useEffect, useRef, useState } from "react"


import {  Link, useHistory, withRouter } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import Form from "../../components/Form/Form"
import Button from "../../components/Buttons/Button"

import { API } from "../../utils/backend/api_helper"
import swal from "sweetalert"
import { Loader } from "../../utils/Configs"
import Header from "./Header"
import { Checkbox } from "../../components"
import Input from "../../components/Fields/Input"



const Video = (props) => {

    const form = useRef()
    const [loading, setLoading] = useState(false)
    const [url, setURL] = useState("")

    const dispatch =  useDispatch()
    const currentUser = useSelector(state => state.profile.user);
    const history = useHistory()
    const [candidato, setCandidato] = useState({})

    useEffect(()=>{
      setLoading(true)
      API.get(
          "/candidato",
          (resp)=>{
              setCandidato(resp.data)
              setLoading(false)



          },
          (resp)=>{
              swal({
                  title:"¡Error!",
                  text:resp,
                  icon:"error"
              })
              setLoading(false)

          }
      )
  },[])


  
    const submit = (values, valid) =>{
      console.log(values, valid)

      if (valid){
        setLoading(true)
        API.post(
          "/candidato",
          values,
          (response)=>{
       
            setLoading(false)
            swal({
              title:"¡Gracias!",
              text:"Esta sección ha sido actualizada.",
              icon:"success"
          }).then(
            ()=>{
              //window.location.href = "/candidatos/home"
              history.push( "/candidatos/home")

            }
          )

          },
          (response)=>{
            swal({
              title:"¡Error!",
              message:response,
              icon:"error"
            })
            setLoading(false)

          })
  
      }



    }


  return (
    <React.Fragment >

      {loading ? <Loader message={"Enviando..."} />:null}
      
      <div className="vh-100 mb-3" >
        <Header/>

        <div className="container my-4   ">
            <div className="card shadow-sm">
            <div className="card-body p-0">

              <div className="rounded-top ">
              <h3 className="fw-bold bg-primary  rounded-top text-white p-4">ENTREVISTA CON EL CAMPUS</h3>
                <p className=" fs-5 fs-md-4 p-4">
                En los próximos días, personal del Campus al que estás solicitando admisión te buscará para realizar una entrevista, por lo que te invitamos a estar atento al correo o teléfono que registraste, pues te contactarán para programar una sesión virtual.                <br/>
                <br/>

                <div className="alert alert-warning" role="alert">
                Cuando completes la entrevista con personal del campus confirma la realización de la misma aquí.</div>
                <div className="text-end"><br/>
                <br/>
                Contacto para dudas: Mariana Ortega <a href="mailto:marianaortega@tec.mx">marianaortega@tec.mx</a><br/>
                </div>
                </p>
                <hr/>
              </div>
          

              <div className={"p-4"}>
              {candidato.ENTREVISTA_STATUS == "COMPLETA" ? <div className="text-center">

<h3 className="mb-5"><i className="fas fs-1 text-success fa-check-circle"/><br/>¡Gracias!<br/>Esta sección ya esta completa.</h3>

<div className="d-flex justify-content-center">
<Link to="/candidatos/home" className={"btn btn-outline-primary"}>Regresar</Link>

<Button onClick={()=>setCandidato({})}  className={"ms-2"} color={"primary"}>Actualizar respuesta</Button>
</div>
</div>: <Form  onSubmit={submit}>
                    <div className="row">
          

                            <div className="mt-2">
                              <label>Confirma si ya tuviste tu entrevista:</label>
                            <Checkbox required name={"entrevista"} value={true} label={"Ya tuve mi entrevistado con personal del campus"} />
                            </div>
                            </div>
                            <div className="mt-2">
                            <Input name={"entrevistador"} required label={"Captura el nombre de tu entrevistador"}/>
                            </div>
                    <div className="mt-2 d-flex justify-content-end">
                      <Link to="/candidatos/home" className={"btn btn-outline-primary me-2"}>Regresar</Link>

                      <Button  type={"submit"} color={"primary fw-bold"}>Continuar <i className="ms-1 border-start ps-1 fas fa-arrow-right"/></Button>

                    </div>
                  </Form>}

                  </div>
                </div>
            </div>
        </div>
        <div style={{height:50}} />

        </div>
    </React.Fragment>
  )
}


Video.propTypes = {
 
}


export default (withRouter(Video))



