import { combineReducers } from "redux"


// Front
import Layout from "./layout/reducer"

import login from "./auth/login/reducer"

import profile from "./auth/profile/reducer"
import contact from "./data/contact/reducer"
import postSC from "./data/postSC/reducer"

import form from "./data/forms/reducer"

//import convocatoria from "./data/convocatoria/reducer"
//import solicitud from "./data/solicitud/reducer"

const rootReducer = combineReducers({
  // public
  Layout,

  login,
  profile,
  contact,
  postSC,
  form
  //solicitud
  
})

export default rootReducer
