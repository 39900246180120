import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {  useParams, withRouter } from "react-router-dom"
import { Container } from "reactstrap"


import { getContactProfile } from "../../store/actions"
import { ON_LOGOUT } from "../../utils/Configs"
import Solicitud from "./Solicitud"

const ExtFormView = (props) => {

    const { id, user_id } = useParams();
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();
    const user = useSelector(state => state.profile.user);

    const contacts = useSelector(state => state.contact.dictionary);
    const [profile, setProfile] = useState({student:{}})

    


      const getUser = () => {
        

        dispatch(getContactProfile(user_id,(data)=>{
            //console.log(data)
            ////console.log("data")
            setProfile(data)
            setLoading(false)
        },()=>{}, contacts))


    }

    useEffect(() => {
        if (user_id){
          setLoading(true)
          getUser()
        }else{
          setProfile(null)
          setLoading(false)
        }


    }, [])
  
  return (

    <React.Fragment>
          {loading
                ? ""
                : ""}
              
            <div style={{marginTop:70}}>
            <div className="bg-white p-3 shadow justify-content-end align-items-end d-flex">
            <button className="btn btn-danger text-white px-4" onClick={()=>{ ON_LOGOUT(dispatch, props)}}>
            <i className="fas fa-times me-2 font-size-16 align-middle me-1 text-white"/>
            <span>Salir</span>
          </button>
                </div>
                <div>
                <Container fluid  className="pt-3">
               
                {!loading
                ? <Solicitud formID={id} user={profile}/>:null}
        </Container>
        </div>
        </div>

    </React.Fragment>

  )
}


ExtFormView.propTypes = {
 
}


export default (withRouter(ExtFormView))



