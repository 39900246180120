import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { withRouter } from "react-router-dom"

import {  getContact, setFormsNames } from "../../../store/actions"
import { LDMDB, Loader } from "../../../utils/Configs"
import StackGrid from "react-stack-grid";

import FormCard from "../../../components/FormCard"
import { API } from "../../../utils/backend/api_helper"

const FormsStudenView = (props) => {

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")

  const [data, setData] = useState([])
  const [dataMy, setDataMy] = useState([])
  const [listInfo, setListInfo] = useState([])
  const [listInfoMy, setListInfoMy] = useState([])



  const formNames = useSelector(state => state.form.formNames);

  const contacts = useSelector(state => state.contact.dictionary);


  const dispatch = useDispatch();

  const getContactDict = (id) =>{
    
    if (!contacts[id] ){
      dispatch(getContact(id,(data)=>{
        ////console.log(data)
      }))
      return false
    }

    return contacts[id]

  }


  useEffect(()=>{
    setListInfoMy([])
    setListInfo([])
    
      setLoading(true)
      API.get("/forms/my/1/100",(resposne)=>{
        setListInfoMy(resposne.data)
        API.get("/forms/1/100",(resposne)=>{
          setListInfo(resposne.data)
          setLoading(false)
        },()=>{})
      },()=>{})
  },[])



  useEffect(()=>{
    if (listInfo && listInfo.data ){

     

      listInfo.data.map((i)=>{
        getContactDict(i.user_id)
        LDMDB.database().ref("answers/" +i.form_id+ "/"+props.user.matricula+"/").once('value').then((snapshot)=>{
         
          //console.log("x", "answers/" +i.form_id+ "/"+props.user.matricula+"/", snapshot.exists())
          if (snapshot.exists()) {

            if (!formNames[i.form_id]){
              getStudentForms(i.form_id)
            }
            let dataN = data
            dataN.push(i)
   
            setData(JSON.parse(JSON.stringify(dataN)))

          } else {
       
          }
        });
  
      })

     
    }

  },[listInfo])


  useEffect(()=>{
    if (listInfoMy && listInfoMy.data ){

     

      listInfoMy.data.map((i)=>{
        getContactDict(i.user_id)
        LDMDB.database().ref("answers/" +i.form_id+ "/"+props.user.matricula+"/").once('value').then((snapshot)=>{
          
          //console.log("my", "answers/" +i.form_id+ "/"+props.user.matricula+"/", snapshot.exists())
          if (snapshot.exists()) {

            if (!formNames[i.form_id]){
              getStudentForms(i.form_id)
            }

            let dataN = dataMy
            dataN.push(i)
            setDataMy(JSON.parse(JSON.stringify(dataN)))

          } else {
       
          }
        });
  
      })

     
    }

  },[listInfoMy])

  


  const getStudentForms = (form_id) => {

    LDMDB.database().ref("forms/"+form_id+"/title/").once('value').then((snapshot)=>{
      if (snapshot.exists()) {

        const nf = formNames
        nf[form_id] = snapshot.val()
        dispatch(setFormsNames(nf))

      } else {
        //console.log(snapshot)
      }
    });
 
  }


  //console.log(listInfo)
  return (
    <React.Fragment>
      {loading?<Loader message={message}/> :""}

      <h3>Mis Formularios</h3>
      <hr/>
      
      <StackGrid  gutterWidth={10} gutterHeight={10} columnWidth={"50%"}>
          {dataMy.map((item, key) =>
                               <FormCard user={props.user} key={key} item={item}/>)}
             </StackGrid>
      <h3>Otros Formularios</h3>
      <hr/>
      <StackGrid  gutterWidth={10} gutterHeight={10} columnWidth={"50%"}>
          {data.map((item, key) =>
                <FormCard user={props.user} key={key} item={item}/>)}
             </StackGrid>
    </React.Fragment>
  )
}


FormsStudenView.propTypes = {
 
}


export default (withRouter(FormsStudenView))



