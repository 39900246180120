import React, { forwardRef, useImperativeHandle, useRef, useState } from "react"
import Stepper from "./Stepper"
import * as bootstrap from "bootstrap"
import { API } from "../utils/backend/api_helper"
import { Loader } from "../utils/Configs"
import { useSelector} from "react-redux"

let data = {}

const NewProjectModal = (props, ref) => {
    const stepperForm = useRef();
    const [displaySubmit, setDisplaySubmit] = useState(false)
    const [showNext, setShowNext] = useState(false)
    const [showPrev, setShowPrev] = useState(false)
    const [loading, setLoading] = useState(false)
    const user = useSelector(state => state.profile.user);

    const postProject = (step, values) =>{

        console.log(step, values)
        // API.post("",data,)

        data =  JSON.parse(JSON.stringify({...data, ...values}))

        if (step == 3){
            console.log("DATA:", data)
            setLoading(true)
            API.post("/social-projects",
                {...data, ods: [{ods:data.ods, main:true}], colaborators: [{id: user.id}] },
                (respose)=>{
                    console.log(respose)
                    setLoading(false)
                },
                ()=>{
                    setLoading(false)
                })
        }

    }

    const disableControls = (currIndex, size) => {

        setDisplaySubmit(currIndex==3)

        if (currIndex <= 0) {
          setShowPrev(false)
          setShowNext(false)
        }
        else if (currIndex >= size - 1 || currIndex == 2) {
          setShowNext(false)
        } else {
          setShowNext(true)
          setShowPrev(true)
        }
      }


      const toggle = () =>{
        data =  JSON.parse(JSON.stringify({}))
        var myModalEl  = document.getElementById("exampleModal")

        var modal = bootstrap.Modal.getOrCreateInstance(myModalEl)
        modal.toggle();
      }

      useImperativeHandle(ref, () => ({
        toggle
    }));
    

  
  return (

    <React.Fragment>
                       {loading?<Loader message={"Cargando"}/>:""}

     <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog 	modal-lg modal-fullscreen-sm-down" >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">Iniciativas y proyectos sociales</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body p-0 m-0 align-itesm-top">
            <Stepper onSteppSubmit={postProject} onChange={disableControls} ref={stepperForm}/>
        </div>
      <div className="modal-footer">
          {showPrev ? <button type="button" onClick={()=>stepperForm.current.previous()} className="btn btn-primary">Anterior</button>:null}

          {showNext ?  <button type="button" onClick={()=>stepperForm.current.next()} className="btn btn-primary">Siguiente</button>:null}

          {displaySubmit ?  <button type="button" onClick={()=>{
            stepperForm.current.next()
        
          }} className="btn btn-primary">Registrar iniciativa</button>:null}
      </div>
    </div>
  </div>
</div>
          
          
    </React.Fragment>

  )
}





export default forwardRef(NewProjectModal)



