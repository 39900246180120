
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { withRouter } from "react-router-dom"
import { Container, Card, CardBody, Table} from "reactstrap"



const ConservaTuBeca = (props) => {

  const [loading, setLoading] = useState(false)

  //const convocatorias = useSelector(state => state.convocatoria.data);
  //const solicitudes = useSelector(state => state.solicitud.data);
  //const modal = useRef();
  const dispatch = useDispatch();

  useEffect(()=>{
    //dispatch(getConvocatorias())
    //dispatch(getSolicituds())
  },[dispatch])

  /*const createSolicitud = (values, validData) => {
    console.log(values, validData)
    
    if (validData){
      setLoading(true)
      dispatch(postSolicitud(values, 
        ()=>{
          setLoading(false)
          dispatch(getSolicituds())
          modal.current.toggle()
          props.history.push("/solicitud")
        }))
    }
  }*/

  const tableBeca = () => {
    return (
      <Table responsive>
<thead>
<tr>
<th>Parámetros</th>
<th colSpan="5">Estatus de beca</th>
</tr>
<tr>
<th>Promedio semestre</th>
<th className="bldm-green">Reguar</th>
<th className="bldm-yellow">Sujeto a condición</th>
<th className="bldm-orange">Condicionado</th>
<th className="bldm-red">Comité</th>
<th className="bldm-gray">Cancelación</th>
</tr>
</thead>
<tbody>
<tr>
<td>Promedio &gt;= 85<br/>Sin reprobar</td>
<td><span className="minibox bldm-green"></span>Regular</td>
<td><span className="minibox bldm-green"></span>Regular</td>
<td><span className="minibox bldm-green"></span>Regular</td>
<td><span className="minibox bldm-green"></span>Regular</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
</tr>
<tr>
<td>Promedio &gt;= 85<br/>Reprobando 1</td>
<td><span className="minibox bldm-yellow"></span>Sujeto a condición</td>
<td><span className="minibox bldm-orange"></span>Condicionado</td>
<td><span className="minibox bldm-red"></span>Comité</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
</tr>
<tr>
<td>Promedio &gt;= 85<br/>Reprobando &gt;= 2</td>
<td><span className="minibox bldm-orange"></span>Condicionado</td>
<td><span className="minibox bldm-red"></span>Comité</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
</tr>
<tr>
<td>Promedio &lt; 85<br/>Sin reprobar</td>
<td><span className="minibox bldm-yellow"></span>Sujeto a condición</td>
<td><span className="minibox bldm-orange"></span>Condicionado</td>
<td><span className="minibox bldm-red"></span>Comité</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
</tr>
<tr>
<td>Promedio &lt; 85<br/>Reprobando 1</td>
<td><span className="minibox bldm-orange"></span>Condicionado</td>
<td><span className="minibox bldm-red"></span>Comité</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
</tr>
<tr>
</tr><tr>
<td>Promedio &lt; 85<br/>Reprobando &gt;= 2</td>
<td><span className="minibox bldm-red"></span>Comité</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
<td><span className="minibox bldm-gray"></span>Cancelación</td>
</tr>
<tr>
</tr></tbody>
</Table>
    )
  }
  
  return (
    <React.Fragment>
      {loading?"":""}
      <div className="page-content mt-6">
        <Container fluid>
          <h2><b>Conserva tu beca</b></h2>
          <Card className={"box-shadow"}>
            
            <CardBody style={{padding:0}}>

              <div style={{display:"flex", alignItems:"center"}}>

              <div className={"d-none"} style={{flex:1}}>
                  <img src={"http://lideresdelmanana.itesm.mx/sites/all/themes/lideres/images/LDM-Bienestar.jpg"} style={{width:"100%", borderBottomLeftRadius:5, borderTopLeftRadius:5 }}/>
                </div>

                <div style={{flex:1.3, fontSize:17, padding:40, }}>

                <div className={"d-none"}>
                  <img src={"http://lideresdelmanana.itesm.mx/sites/all/themes/lideres/images/LDM-Bienestar.jpg"} style={{width:"100%", borderRadius:5}}/>
                </div>
                  <b>Los reglamentos que debes conocer</b> <br/> <br/>

                  Entra al <a href={"https://portalrep.itesm.mx/na/normatividad_academica/index.html"} target={"blank"}>Portal de Normativa Académica</a> para revisar todos los reglamentos institucionales.<br/> <br/>

                  También puedes dar click en los siguientes enlaces:
                  <div className={"row"} style={{textAlign:"center", fontSize:12, textDecorationLine:"underline"}}>
                    <div className={"col col-3 selectable p-2"}>
                    <a href={"http://sitios.itesm.mx/va/reglamentos/regapedybec_novo.pdf"} target={"blank"}>
                      <img src={"/reglamento.jpeg"} width={"100%"}/>
                      Reglamento de Apoyos Educativos y Becas para los Alumnos de Preparatoria y Profesional
                      </a>
                    </div>
                    <div className={"col col-3 selectable p-2"}>
                    <a href={"https://portalrep.itesm.mx/na/normatividad_academica/doc_pro_reglamento_general_estudiantes.html"} target={"blank"}>
                      <img src={"/reglamento.jpeg"} width={"100%"}/>
                      Reglamento General de Estudiantes
                      </a>
                    </div>
                    <div className={"col col-3 selectable p-2"}>
                    <a href={"https://portalrep.itesm.mx/na/normatividad_academica/documentos_profesional.html"} target={"blank"}>
                      <img src={"/reglamento.jpeg"} width={"100%"}/>
                      Políticas y Normas Académicas Generales
                      </a>
                    </div>
                    <div className={"col col-3 selectable p-2"}>
                    <a href={"https://portalrep.itesm.mx/na/normatividad_academica/doc_pro_reglamento_general_servicio_social.html"} target={"blank"}>
                      <img src={"/reglamento.jpeg"} width={"100%"}/>
                      Reglamento General del Servicio Social
                      </a>
                    </div>

                  </div>
                  <br/>

                  <b>Refrendo semestral</b> <br/> <br/>

                  La beca Líderes del Mañana se refrendará cada semestre siempre y cuando el alumno cumpla con lo siguiente:
                  <br/><br/>
                    <div>●	Participación activa en el compromiso de reciprocidad.</div>
                    <div>●	Obtenga un promedio igual o mayor a 85 y no repruebe materias, en caso de no obtener el promedio o reprobar materias revisar (parámetros para la conservación de la beca)</div>
                    <div>●	Realice su servicio becario.</div>
                    <div>●	Actualización de tu Expediente Electrónico.</div>
                    <div>●	Inscribir y aprobar el curso de inglés de acuerdo a la ruta definida para cada estudiante. </div>
                    <br/> 
                    <b>Refrendo semestral</b> <br/> <br/>

                  Un alumno del programa de Líderes del Mañana podrá tener los siguientes estatus para su beca, de acuerdo a su desempeño:
                  <br/><br/>

                  <div>●	<b className={"bldm-green"}>Regular</b>: El alumno cumple satisfactoriamente con los requisitos establecidos para la conservación del programa de becas. </div>
                  <div>●	<b className={"bldm-yellow"}>Sujeto a condición</b>: El alumno tiene un incumplimiento a este reglamento. El alumno recibe un aviso de incumplimiento por escrito y continúa con su beca. </div>
                  <div>●	<b className={"bldm-orange"}>Condicionado</b>: El alumno tiene uno o más incumplimientos a este reglamento. El alumno recibe una llamada de atención por escrito. El alumno continúa con su beca. </div>
                  <div>●	<b className={"bldm-red"}>Comité</b>: Estatus en el cual a partir de reincidencias se reúne un comité local, en campus que definirá si el alumno cuenta con una última oportunidad o procede su cancelación. </div>
                  <div>●	<b className={"bldm-gray"}> Cancelación de la beca</b>: Es la interrupción definitiva del apoyo, por bajo desempeño académico, habiendo pasado por los estatus anteriormente descritos. </div>
                  <br/>
                 
       
                  {tableBeca()}
               

                
                </div>

              

              </div>
            </CardBody>
          </Card>
            
        </Container>
      </div>
    </React.Fragment>
  )
}


ConservaTuBeca.propTypes = {
 
}


export default (withRouter(ConservaTuBeca))



